import {
  Avatar,
  Button,
  Col,
  Row,
  Space,
  Upload,
  Form,
  DatePicker,
  Input,
  Divider,
  Select,
  message,
  Skeleton,
  Empty,
} from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./index.css";
import ImgCrop from "antd-img-crop";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { useState } from "react";
import { CommonAvatar } from "../../CommonAvatar";
import { CommonButton } from "../../CommonButton/button";
import { SearchBox } from "../../CommonSearchBox";
import { Text } from "../../CommonFont/text";
import {
  useGetCustomerByIdQuery,
  useGetManagersListQuery,
  useGetSalesByIdQuery,
  useAllocateManagerMutation,
} from "../../../services/api";
import moment from "moment";
interface IOrder {
  setPreview?: (data: boolean) => void;
}
export const CustomerPreview: React.FC<IOrder> = ({ setPreview }) => {
  const navigate = useNavigate();
  const [isAdd, setIsAdd] = useState(false);
  const [isSelect, setIsSelect] = useState(false);
  const [selectedManager, setSelectedManager] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const customerId = searchParams.get("id");

  const { data: salesData, isLoading: salesLoading } =
    useGetCustomerByIdQuery(customerId);
  const userId = localStorage.getItem("user_id");

  const Managerparams = {
    user_id: userId,
    id: customerId,
  };

  const { data: dropDownData, isLoading: dropDownIsLoading } =
    useGetManagersListQuery(Managerparams);
  const [allocateManager, { data: allocateData, isLoading: allocateLoading }] =
    useAllocateManagerMutation();

  const validationSchema = Yup.object().shape({
    date: Yup.date().required("This date is required"),
    note: Yup.string().required("Note is required"),
    followUp: Yup.date().required("Follow Up date is required"),
  });

  const formik = useFormik({
    initialValues: {
      date: undefined,
      note: "",
      followUp: undefined,
    },
    validationSchema,
    onSubmit: (values) => {},
  });

  const options: { value: any; label: string }[] = [];

  const dropDownOptions = dropDownData?.data?.map((item: any) => {
    options.push({
      value: item?.id,
      label: item?.get_name,
    });
  });

  const handleAllocate = (value: any) => {
    setSelectedManager(value);
  };

  return (
    <>
      {salesLoading ? (
        <Skeleton avatar paragraph={{ rows: 8 }} />
      ) : salesData ? (
        <div>
          <div className="sales-detail-border-main ">
            <Row gutter={16} className="p5">
              <Col span={6}>
                {/* <Avatar size={100}>C</Avatar> */}
                <CommonAvatar text={"Chema"} size={100} />
              </Col>
              <Col span={18}>
                <Space direction="vertical">
                  <div className="sales-detail-title-2">
                    {salesData?.data?.client_name}
                  </div>
                  <div className="flex gap-2 ">
                    <img src="/assets/message.svg"></img>
                    <div>
                      {salesData?.data?.email ? salesData?.data?.email : "-"}
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <img src="/assets/phone.svg"></img>
                    <div>{salesData?.data?.phone}</div>
                  </div>
                  <div className="flex gap-2">
                    <img
                      src="/assets/locattion.svg"
                      className="img-align-self"
                    ></img>
                    <div className="break-all">{salesData?.data?.address}</div>
                  </div>
                  {/* <Button
                    onClick={() => {
                      navigate("/create-quotation");
                    }}
                    className="sales-detail-btn-clr "
                  >
                    Create Quotations
                  </Button> */}
                  <div className="flex gap-2 ">
                    <CommonButton
                      variant="wealay-secondary-btn"
                      className="customer-preview-h1 "
                      value=" Allocate Lead"
                      onClick={() => {
                        setIsSelect(true);
                      }}
                    />
                    {/* <CommonButton
                    variant="wealay-primary-btn"
                    className="sales-detail-btn-clr "
                    value=" Create Quotations"
                    onClick={() => {
                      navigate("/create-quotation");
                    }}
                  /> */}
                  </div>
                  {isSelect && (
                    <div className="flex gap-2 customer-button">
                      <div>
                        <Select
                          showSearch
                          placeholder="Select a person"
                          optionFilterProp="children"
                          onChange={(value) => handleAllocate(value)}
                          // onSearch={onSearch}
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={options}
                        />
                      </div>
                      <div className="flex gap-2">
                        <CommonButton
                          variant="wealay-secondary-btn"
                          value="Cancel"
                          className="customer-preview-h2"
                          onClick={() => {
                            setIsSelect(false);
                          }}
                        />
                        <CommonButton
                          variant="wealay-primary-btn"
                          value="Allocate"
                          className="customer-preview-h2"
                          onClick={async () => {
                            if (selectedManager) {
                              const allocateParams = {
                                body: {
                                  lead: selectedManager,
                                },
                                managerId: selectedManager,
                                salesId: customerId,
                              };
                              try {
                                await allocateManager(allocateParams)
                                  .unwrap()
                                  .then((data) => {
                                    setPreview && setPreview(false);
                                    message.success(data?.message);
                                  });

                                // Allocation successful, handle any necessary UI updates or notifications
                              } catch (error) {
                                // Error occurred during allocation, handle error state or show error message
                              }
                            }
                          }}
                        />
                      </div>
                    </div>
                  )}
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={24} md={{ span: 12 }}>
                <div className="sales-detail-border flex flex-col gap-2">
                  <div className="sales-detail-placeholder">Code</div>
                  <div className="sales-detail-sub-title ">
                    {salesData?.data?.code ? salesData?.data?.code : "-"}
                  </div>
                </div>
                <div className="sales-detail-border flex flex-col gap-2">
                  <div className="sales-detail-placeholder">Designation</div>
                  <div className="sales-detail-sub-title ">
                    {salesData?.data?.designation_name}
                  </div>
                </div>
                <div className="sales-detail-border flex flex-col gap-2">
                  <div className="sales-detail-placeholder">GST</div>
                  <div className="sales-detail-sub-title ">
                    {salesData?.data?.gst ? salesData?.data?.gst : "-"}
                  </div>
                </div>
                <div className="sales-detail-border flex flex-col gap-2">
                  <div className="sales-detail-placeholder">Type</div>
                  <div className="sales-detail-sub-title ">
                    {salesData?.data?.type_name}
                  </div>
                </div>
                <div className="sales-detail-border flex flex-col gap-2">
                  <div className="sales-detail-placeholder">
                    Enquiry Category
                  </div>
                  <div className="sales-detail-sub-title ">
                    {salesData?.data?.enquiry_category_name}
                  </div>
                </div>
              </Col>
              <Col span={24} md={{ span: 12 }}>
                <div className="sales-detail-border flex flex-col gap-2">
                  <div className="sales-detail-placeholder">Client Name</div>
                  <div className="sales-detail-sub-title ">
                    {salesData?.data?.client_name}
                  </div>
                </div>
                <div className="sales-detail-border flex flex-col gap-2">
                  <div className="sales-detail-placeholder">Region</div>
                  <div className="sales-detail-sub-title ">
                    {salesData?.data?.region_name}
                  </div>
                </div>
                <div className="sales-detail-border flex flex-col gap-2">
                  <div className="sales-detail-placeholder">Source</div>
                  <div className="sales-detail-sub-title ">
                    {salesData?.data?.source_name}
                  </div>
                </div>
                <div className="sales-detail-border flex flex-col gap-2">
                  <div className="sales-detail-placeholder">Industry</div>
                  <div className="sales-detail-sub-title ">
                    {salesData?.data?.industry_name}
                  </div>
                </div>
                {/* <div className="sales-detail-border flex flex-col gap-2">
                  <div className="sales-detail-placeholder">Nature</div>
                  <div className="sales-detail-sub-title ">
                    {salesData?.data?.nature_name}
                  </div>
                </div> */}
              </Col>
            </Row>
          </div>
          <div className="sales-detail-title">
            <Row>
              <Col span={14} className="sales-detail-border ">
                <Text variant="wearlay-paragraph-bold" value="Items" />
              </Col>
              <Col span={5} className="sales-detail-border ">
                <Text variant="wearlay-paragraph-bold" value="Brand" />
              </Col>

              <Col span={5} className="sales-detail-border ">
                <Text variant="wearlay-paragraph-bold" value="Qty" />
              </Col>
            </Row>
            {salesData?.data?.items?.map((item: any) => (
              <Row>
                <Col span={14} className="sales-detail-border ">
                  <Text
                    variant="wearlay-paragraph"
                    value={item?.item_information}
                  />
                </Col>
                <Col span={5} className="sales-detail-border ">
                  <Text variant="wearlay-paragraph" value={item?.brand} />
                </Col>

                <Col span={5} className="sales-detail-border ">
                  <Text variant="wearlay-paragraph" value={item?.qty} />
                </Col>
              </Row>
            ))}
            {/* <Row>
            <Col span={14} className="sales-detail-border ">
              <Text
                variant="wearlay-paragraph"
                value="BLIND PANELS 200 X1200 X 30"
              />
            </Col>
            <Col span={5} className="sales-detail-border ">
              <Text variant="wearlay-paragraph" value="Wearlay" />
            </Col>

            <Col span={5} className="sales-detail-border ">
              <Text variant="wearlay-paragraph" value="1" />
            </Col>
          </Row> */}
          </div>
          <Row className=" sales-detail-border flex flex-col">
            <Text variant="wearlay-label-bold" value="Note" />
            <Text
              variant="wearlay-paragraph"
              value={salesData?.data?.note ? salesData?.data?.note : "-"}
            />
          </Row>
          <div className="sales-detail-title">Follow Up Status</div>
          <Space direction="vertical" className="w-full">
            {
              <Form layout="vertical" onFinish={formik.handleSubmit}>
                <div className=" sales-detail-border">
                  <div className="flex flex-col gap-2">
                    <div className="sales-follow-up">Follow up 1</div>
                    {salesData?.data?.follow_up[0] && (
                      <div className="create-quotation-border">
                        <div className="flex flex-col gap-2">
                          <Text
                            value="Date"
                            variant="wearlay-label-bold"
                            className="create-quotation-text-color"
                          ></Text>
                          <Text
                            value={moment(
                              salesData?.data?.follow_up[0]?.date
                            ).format("DD-MM-YYYY")}
                            variant="wearlay-paragraph-bold"
                          ></Text>
                        </div>
                        <Divider />
                        <div className="flex flex-col gap-2">
                          <Text
                            value="Note"
                            variant="wearlay-label-bold"
                            className="create-quotation-text-color"
                          ></Text>
                          <Text
                            value={
                              salesData?.data?.follow_up[0]?.notes
                                ? salesData?.data?.follow_up[0]?.notes
                                : "-"
                            }
                            variant="wearlay-paragraph"
                          ></Text>
                        </div>
                        <Divider />
                        <div className="flex flex-col gap-2">
                          <Text
                            value="Next Follow up Date"
                            variant="wearlay-label-bold"
                            className="create-quotation-text-color"
                          ></Text>
                          <Text
                            value={moment(
                              salesData?.data?.follow_up[0]?.next_follow_up_date
                            ).format("DD-MM-YYYY")}
                            variant="wearlay-paragraph-bold"
                          ></Text>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Form>
            }

            <div className="flex flex-col sales-detail-border gap-2">
              <div className="sales-follow-up">Follow up 2</div>
              {salesData?.data?.follow_up[1] && (
                <div className="create-quotation-border">
                  <div className="flex flex-col gap-2">
                    <Text
                      value="Date"
                      variant="wearlay-label-bold"
                      className="create-quotation-text-color"
                    ></Text>
                    <Text
                      value={moment(salesData?.data?.follow_up[1]?.date).format(
                        "DD-MM-YYYY"
                      )}
                      variant="wearlay-paragraph-bold"
                    ></Text>
                  </div>
                  <Divider />
                  <div className="flex flex-col gap-2">
                    <Text
                      value="Note"
                      variant="wearlay-label-bold"
                      className="create-quotation-text-color"
                    ></Text>
                    <Text
                      value={
                        salesData?.data?.follow_up[1]?.notes
                          ? salesData?.data?.follow_up[1]?.notes
                          : "-"
                      }
                      variant="wearlay-paragraph"
                    ></Text>
                  </div>
                  <Divider />
                  <div className="flex flex-col gap-2">
                    <Text
                      value="Next Follow up Date"
                      variant="wearlay-label-bold"
                      className="create-quotation-text-color"
                    ></Text>
                    <Text
                      value={moment(
                        salesData?.data?.follow_up[1]?.next_follow_up_date
                      ).format("DD-MM-YYYY")}
                      variant="wearlay-paragraph-bold"
                    ></Text>
                  </div>
                </div>
              )}
            </div>
            <div className="flex flex-col sales-detail-border gap-2">
              <div className="sales-follow-up">Follow up 3</div>
              {salesData?.data?.follow_up[2] && (
                <div className="create-quotation-border">
                  <div className="flex flex-col gap-2">
                    <Text
                      value="Date"
                      variant="wearlay-label-bold"
                      className="create-quotation-text-color"
                    ></Text>
                    <Text
                      value={moment(salesData?.data?.follow_up[2]?.date).format(
                        "DD-MM-YYYY"
                      )}
                      variant="wearlay-paragraph-bold"
                    ></Text>
                  </div>
                  <Divider />
                  <div className="flex flex-col gap-2">
                    <Text
                      value="Note"
                      variant="wearlay-label-bold"
                      className="create-quotation-text-color"
                    ></Text>
                    <Text
                      value={
                        salesData?.data?.follow_up[2]?.notes
                          ? salesData?.data?.follow_up[2]?.notes
                          : "-"
                      }
                      variant="wearlay-paragraph"
                    ></Text>
                  </div>
                  <Divider />
                  <div className="flex flex-col gap-2">
                    <Text
                      value="Next Follow up Date"
                      variant="wearlay-label-bold"
                      className="create-quotation-text-color"
                    ></Text>
                    <Text
                      value={moment(
                        salesData?.data?.follow_up[2]?.next_follow_up_date
                      ).format("DD-MM-YYYY")}
                      variant="wearlay-paragraph-bold"
                    ></Text>
                  </div>
                </div>
              )}
            </div>
          </Space>
        </div>
      ) : (
        <Empty className="h-full justify-center align-center flex flex-col" />
      )}
    </>
  );
};
