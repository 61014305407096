import {
  AppstoreOutlined,
  LeftOutlined,
  MailOutlined,
  RightOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  Button,
  Drawer,
  Menu,
  MenuProps,
  message,
  Pagination,
  PaginationProps,
  Row,
  Space,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CommonButton } from "../../components/CommonButton/button";
import { TableCard } from "../../components/CommonTableCard";
import { Text } from "../../components/CommonFont/text";
import { SearchBox } from "../../components/CommonSearchBox";
import { CommonTable } from "../../components/commonTable";
import { AddNewClient } from "../../components/Drawer/AddNewClient";
import { ClientPreview } from "../../components/Drawer/Client-preview";
import _ from "lodash";
import { saveAs } from "file-saver";

import "./index.css";
import {
  useGetAllContactsQuery,
  useGetContactsByIdQuery,
  useGetDownloadDataQuery,
} from "../../services/api";
import { getContactsParams } from "../../helpers/quarery";
import FilterDropdown, { FilterMenu } from "../../components/CommonFilter";
export const Contacts = () => {
  const [open, setOpen] = useState(false);
  const [preview, setIsPreview] = useState(false);
  const [edit, setIsEdit] = useState(false);
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState<any>();
  const [downloadIds, setDownloadIds] = useState<any>();
  const [selectedRegion, setSelectedRegion] = useState<string[]>([]);
  const [selectedType, setSelectedType] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [pageData, setPageData] = useState<{
    page: number;
    pageSize: number;
  }>({
    page: 0,
    pageSize: 5,
  });
  const params = getContactsParams(pageData.page, pageData.pageSize);

  const [searchParams, setSearchParams] = useSearchParams();

  const search = searchParams.get("search");
  const contact_id = searchParams.get("id");

  const onPaginationChange = (page: number, pageSize: number) => {
    setPageData({ page, pageSize });
  };
  const [id, setIsId] = useState("");
  const [indexId, setIndexId] = useState();

  const userId = localStorage.getItem("user_id");
  const getAllContactsParams = {
    id: userId,
    params: params,
    search: search ? search : "",
    body: JSON.stringify(filterData)
      ? JSON.stringify(filterData)
      : JSON.stringify({}),
  };

  const { data, isLoading } = useGetAllContactsQuery(getAllContactsParams);
  const { data: contactById, isLoading: contactLoading } =
    useGetContactsByIdQuery(contact_id);

  const findIndexForObject = (array: [], object: any) => {
    return _.findIndex(array, object);
  };

  const targetObject = { id: Number(contact_id) };
  const index = findIndexForObject(data?.results, targetObject);

  const [currentIndex, setCurrentIndex] = useState(index);

  const getObjectAtIndex = (array: [], index: number) => {
    const object = _.get(array, index);
    return object;
  };

  useEffect(() => {
    setCurrentIndex(index);
  }, [index]);
  useEffect(() => {
    if (currentIndex > -1) {
      const updatedObject: any = getObjectAtIndex(data?.results, currentIndex);

      navigate(`/contacts?id=${updatedObject?.id}`);
    }
  }, [currentIndex]);
  // useEffect(() => {
  //   if (currentIndex > -1) {
  //     console.log("hoooooo");

  //     const object: any = getObjectAtIndex(data?.results, currentIndex);
  //     setIndexId(object?.id);
  //     console.log("this is objectsss", object?.id);
  //   }
  // }, [object]);

  const handleNext = () => {
    // console.log("final object trance", !contactLoading && object?.id);
    // navigate(`/contacts?id=${object?.id}`);

    if (currentIndex < data?.results?.length - 1) {
      setCurrentIndex(currentIndex + 1);

      // navigate(`/contacts?id=${object?.id}`);
      // setSearchParams({ ["id"]: object?.id });
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);

      // navigate(`/contacts?id=${object?.id}`);
    }
  };

  // const currentData = data && data?.data[currentIndex];

  const onClose = () => {
    setOpen(false);
    setIsPreview(false);
    navigate("/contacts");
    setIsEdit(false);
  };
  const navigate = useNavigate();

  const titleData = [
    "Company",
    "Region",
    "Contact",
    "Email",
    "Type",
    "Industry",
  ];

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const columns = [
    {
      title: "Company",
      dataIndex: "company_name",

      // sorter: (a: any, b: any) => a.company_name.length - b.company_name.length,
      sorter: (a: any, b: any) => a.company_name.localeCompare(b.company_name),

      ellipsis: true,
    },
    {
      title: "CUI No",
      dataIndex: "contacts_customer_unique_identification_number",

      ellipsis: true,
    },
    {
      title: "Region",
      dataIndex: "region_name",

      // sorter: (a: any, b: any) => {
      //   const regionA = a.location.region.toUpperCase();
      //   const regionB = b.location.region.toUpperCase();

      //   if (regionA < regionB) {
      //     return -1;
      //   }
      //   if (regionA > regionB) {
      //     return 1;
      //   }
      //   return 0;
      // },
      // sorter: (a: any, b: any) => a.region_name.length - b.region_name.length,
      sorter: (a: any, b: any) => a.region_name.localeCompare(b.region_name),

      ellipsis: true,
    },

    {
      title: "Contact",
      dataIndex: "phone",

      ellipsis: true,
    },

    {
      title: "Email",
      dataIndex: "email",
      render: (text: any) => {
        return (
          <>
            <div>{text ? text : "-"}</div>
          </>
        );
      },
      ellipsis: true,
    },
    {
      title: "Type",
      dataIndex: "type_name",

      ellipsis: true,
    },
    {
      title: "Industry",
      dataIndex: "industry_name",

      ellipsis: true,
    },
  ];
  type MenuItem = Required<MenuProps>["items"][number];

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group"
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }
  const rootSubmenuKeys = ["sub1", "sub2", "sub4"];
  const items: MenuItem[] = [
    getItem("Navigation One", "sub1", <MailOutlined />, [
      getItem("Option 1", "1"),
      getItem("Option 2", "2"),
      getItem("Option 3", "3"),
      getItem("Option 4", "4"),
    ]),
    getItem("Navigation Two", "sub2", <AppstoreOutlined />, [
      getItem("Option 5", "5"),
      getItem("Option 6", "6"),
      getItem("Submenu", "sub3", null, [
        getItem("Option 7", "7"),
        getItem("Option 8", "8"),
      ]),
    ]),
    getItem("Navigation Three", "sub4", <SettingOutlined />, [
      getItem("Option 9", "9"),
      getItem("Option 10", "10"),
      getItem("Option 11", "11"),
      getItem("Option 12", "12"),
    ]),
  ];
  const [openKeys, setOpenKeys] = useState(["sub1"]);

  const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  const idArray = _.map(data?.results, "id");

  const handleDownload = () => {
    // Convert the data to a Blob
    // const blob = new Blob([downloadedData], {
    //   type: "application/vnd.ms-excel",
    // });

    // // Use the saveAs function to trigger the download
    // saveAs(blob, "contact-export.xlsx");
    if (downloadedData) {
      saveAs(downloadedData, "contact-export.xlsx");
    } else {
      message.error("Error: Downloaded data is undefined.");
    }
  };
  const { data: downloadedData, isLoading: downloadLoading } =
    useGetDownloadDataQuery(downloadIds?.length > 0 ? downloadIds : idArray);
  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
    current,
    pageSize
  ) => {
    setPageData({
      page: current,
      pageSize: pageSize,
    });
  };
  return (
    <>
      <div className="mt-24 ">
        <div className="flex justify-between items-center">
          <div className="sales-title">
            <Text value="Contacts" variant="wearlay-display" />
          </div>
          {/* <Button
            className="primary-btn"
            onClick={() => {
              setOpen(true);
            }}
          >
            + Add New Client
          </Button> */}
          <CommonButton
            variant="wealay-primary-btn"
            value="    + Add New Client"
            className="primary-btn"
            onClick={() => {
              setOpen(true);
            }}
          />
        </div>
        {/* <div className="contacts-main-div">
          <div className="flex justify-between">
            <Text value="Contacts List" variant="wearlay-headline-2" />
            <div className="flex items-center h-full gap-2">
              <SearchBox placeholder="Search" />
              <CommonButton
                variant="wearlay-with-icon"
                icon="/assets/filter.svg"
              />
              <CommonButton
                variant="wearlay-with-icon"
                icon="/assets/download.svg"
              />
            </div>
          </div>
          <CommonTable
            datasource={dataSource}
            coloumn={columns}
            setDetailsOpen={setIsPreview}
          />
        </div> */}
        <div className="p-10 mt-24p">
          <div className="flex justify-between">
            <Text
              value="Customers List"
              variant="wearlay-headline-2"
              className="p-1 "
            />

            <div className="flex items-center h-full gap-2">
              <div className="search-resp ">
                <SearchBox placeholder="Search" />
              </div>
              <div className="relative">
                <CommonButton
                  variant="wearlay-with-icon"
                  icon={filter ? "/assets/close.svg" : "/assets/filter.svg"}
                  onClick={() => {
                    setFilter(!filter);
                  }}
                  className="download-btn-color"
                />

                {filter && (
                  <FilterMenu
                    setFilter={setFilter}
                    setFilterData={setFilterData}
                    filer={filter}
                    filterData={filterData}
                    selectedRegion={selectedRegion}
                    setSelectedRegion={setSelectedRegion}
                    setSelectedIndustry={setSelectedIndustry}
                    selectedIndustry={selectedIndustry}
                    setSelectedType={setSelectedType}
                    selectedType={selectedType}
                  />
                )}
              </div>
              <CommonButton
                variant="wearlay-with-icon"
                icon={
                  data?.results?.length === 0
                    ? "/assets/disabledDownload.svg"
                    : "/assets/download.svg"
                }
                onClick={() => {
                  handleDownload();
                }}
                // isLoading={downloadLoading}
                className="download-btn-color"
                disabled={data?.results?.length === 0}
              />
            </div>
          </div>
          <div className="resp-search">
            <SearchBox placeholder="Search" />
          </div>
          <div className="resp-table">
            <CommonTable
              datasource={data?.results}
              coloumn={columns}
              setDetailsOpen={setIsPreview}
              isLoading={isLoading}
              setId={setIsId}
              setDownloadIds={setDownloadIds}
            />
            {data?.count > 0 && (
              <Row justify={"end"}>
                <Pagination
                  // current={pageData.page}
                  onChange={onPaginationChange}
                  total={data?.count}
                  pageSize={pageData?.pageSize}
                  defaultCurrent={1}
                  responsive={true}
                  onShowSizeChange={onShowSizeChange}
                  showSizeChanger={data?.count > 10 ? true : false}
                />
              </Row>
            )}
          </div>
        </div>
        <TableCard
          headingData={titleData}
          setOpen={setIsPreview}
          data={data?.results}
          setId={setIsId}
          loading={isLoading}
        />
        {/* <div className="pagination-resp"> */}
        {data?.count > 0 && (
          <Pagination
            // current={pageData.page}
            onChange={onPaginationChange}
            total={data?.count}
            pageSize={pageData?.pageSize}
            className="pagination-resp"
            defaultCurrent={1}
            onShowSizeChange={onShowSizeChange}
            showSizeChanger={data?.count > 10 ? true : false}
          />
        )}

        {/* <TableCard headingData={titleData} setOpen={setIsPreview} /> */}
      </div>
      <Drawer
        title={edit ? "Edit Client" : "Add New Client"}
        placement="right"
        onClose={onClose}
        open={open || edit}
        width="500"
        destroyOnClose={true}
      >
        <AddNewClient
          setOpen={setOpen}
          datas={contactById?.data}
          isEdit={edit}
          setEdit={setIsEdit}
        />
      </Drawer>
      <Drawer
        title={
          <div className="flex justify-between items-center">
            {/* <div>Client Preview</div> */}
            <Text value="Client Preview" variant="wearlay-subtitle" />
            <div>
              <Space>
                <Button
                  onClick={() => {
                    handlePrevious();
                    // navigate(`/contacts?id=${object?.id}`);
                  }}
                  disabled={currentIndex === 0}
                >
                  <LeftOutlined />
                </Button>
                <Button
                  onClick={() => {
                    // navigate(`/contacts?id=${object?.id}`);
                    handleNext();
                  }}
                  disabled={currentIndex === data?.results?.length - 1}
                >
                  <RightOutlined />
                </Button>
              </Space>
            </div>
          </div>
        }
        placement="right"
        onClose={onClose}
        open={preview}
        width="500"
      >
        <ClientPreview
          data={contactById?.data}
          setEdit={setIsEdit}
          setPreview={setIsPreview}
          loading={contactLoading}
        />
      </Drawer>
    </>
  );
};
