import {
  AppstoreOutlined,
  LeftOutlined,
  MailOutlined,
  RightOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  Button,
  Drawer,
  Space,
  Tag,
  MenuProps,
  Pagination,
  Row,
  message,
  PaginationProps,
} from "antd";
import { useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { SearchBox } from "../../components/CommonSearchBox";
import { CommonTable } from "../../components/commonTable";
import { AddNewLead } from "../../components/Drawer/AddNewLead";
import { SalesDetailById } from "../../components/Drawer/DetailById/sales";
import "./index.css";
import { getSalesParams } from "../../helpers/quarery";
import { Text } from "../../components/CommonFont/text";
import { CommonButton } from "../../components/CommonButton/button";
import { TableCard } from "../../components/CommonTableCard";
import {
  useGetAllSalesLeadQuery,
  useGetSalesDownloadDataQuery,
  useGetSalesByIdQuery,
  useGetLeadByIdQuery,
} from "../../services/api";
import _ from "lodash";
import { saveAs } from "file-saver";
import { useEffect } from "react";
import { FilterMenu } from "../../components/CommonFilter";
import SalesFilterMenu from "../../components/CommonFilter/salesFilter";

export const Sales = () => {
  const [open, setOpen] = useState(false);
  const [openLead, setOpenLead] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState<any>();
  const [downloadIds, setDownloadIds] = useState<any>();
  const [selectedSales, setSelectedSales] = useState<any>({});
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const navigate = useNavigate();
  const userId = localStorage.getItem("user_id");
  const [id, setIsId] = useState("");
  const location = useLocation();

  const isQuotation = location.pathname;
  const isQuotationUrl = isQuotation && isQuotation.includes("/sales/details");

  const [pageData, setPageData] = useState<{
    page: number;
    pageSize: number;
  }>({
    page: 0,
    pageSize: 5,
  });

  const params = getSalesParams(pageData.page, pageData.pageSize);

  const [searchParams, setSearchParams] = useSearchParams();

  const search = searchParams.get("search");
  const sales_id = searchParams.get("id");

  const onPaginationChange = (page: number, pageSize: number) => {
    setPageData({ page, pageSize });
  };
  const getAllSalesParams = {
    id: userId,
    params: params,
    search: search ? search : "",
    body: JSON.stringify(filterData)
      ? JSON.stringify(filterData)
      : JSON.stringify({}),
  };

  const [indexId, setIndexId] = useState();

  // const { data, isLoading } = useGetAllSalesLeadQuery(userId);
  const { data, isLoading } = useGetAllSalesLeadQuery(getAllSalesParams);
  const { data: salesData, isLoading: salesLoading } =
    useGetLeadByIdQuery(sales_id);
  //useGetSalesByIdQuery

  const findIndexForObject = (array: [], object: any) => {
    return _.findIndex(array, object);
  };

  const targetObject = { id: Number(sales_id) };
  const index = findIndexForObject(data?.results, targetObject);

  const [currentIndex, setCurrentIndex] = useState(index);

  const getObjectAtIndex = (array: [], index: number) => {
    const object = _.get(array, index);
    return object;
  };

  useEffect(() => {
    setCurrentIndex(index);
  }, [index]);
  useEffect(() => {
    if (currentIndex > -1) {
      const updatedObject: any = getObjectAtIndex(data?.results, currentIndex);

      navigate(
        !isQuotationUrl
          ? `/sales?id=${updatedObject?.id}`
          : `/sales/details?id=${updatedObject?.id}`
      );
    }
  }, [currentIndex]);

  const handleNext = () => {
    // navigate(`/sales?id=${object?.id}`);

    if (currentIndex < data?.results?.length - 1) {
      setCurrentIndex(currentIndex + 1);

      // navigate(`/sales?id=${object?.id}`);
      // setSearchParams({ ["id"]: object?.id });
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const onClose = () => {
    setOpen(false);
    setOpenLead(false);
    navigate("/sales");
    setIsEdit(false);
  };
  const showDrawer = () => {
    setOpenLead(true);
  };
  const titleData = [
    "Code",
    "Lead Name",
    "Region",
    "Contact",
    "Email",
    "Type",
    "Industry",
    "Status",
  ];

  const columns = [
    {
      title: "Code",
      dataIndex: "sales_lead_id",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Lead Name",
      dataIndex: "lead_name",
      key: "lead",
      sorter: (a: any, b: any) => a.lead_name.localeCompare(b.lead_name),
      ellipsis: false,
    },
    {
      title: "Company",
      dataIndex: "company_name",
      key: "name",
      sorter: (a: any, b: any) => a.company_name.localeCompare(b.company_name),
      ellipsis: true,
    },
    {
      title: "Region",
      dataIndex: "region_name",
      key: "age",
      sorter: (a: any, b: any) => a.region_name.localeCompare(b.region_name),
      ellipsis: true,
    },
    {
      title: "Contact",
      dataIndex: "phone",
      key: "address",
      ellipsis: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Type",
      dataIndex: "type_name",
      key: "age",
      ellipsis: true,
    },
    {
      title: "Industry",
      dataIndex: "industry_name",
      key: "address",
      ellipsis: true,
    },
    {
      title: "Category",
      dataIndex: "enquiry_category_name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      ellipsis: true,

      key: "age",

      render: (text: any) => {
        return (
          <>
            {text?.title_name === "Follow Up 1" ? (
              <Tag color="cyan">Follow up 1</Tag>
            ) : text?.title_name === "Follow Up 2" ? (
              <Tag color="orange">Follow up 2</Tag>
            ) : text?.title_name === "Follow Up 3" ? (
              <Tag color="purple">Follow up 3</Tag>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
  ];
  type MenuItem = Required<MenuProps>["items"][number];

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group"
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }
  const rootSubmenuKeys = ["sub1", "sub2", "sub4"];

  const items: MenuItem[] = [
    getItem("Navigation One", "sub1", <MailOutlined />, [
      getItem("Option 1", "1"),
      getItem("Option 2", "2"),
      getItem("Option 3", "3"),
      getItem("Option 4", "4"),
    ]),
    getItem("Navigation Two", "sub2", <AppstoreOutlined />, [
      getItem("Option 5", "5"),
      getItem("Option 6", "6"),
      getItem("Submenu", "sub3", null, [
        getItem("Option 7", "7"),
        getItem("Option 8", "8"),
      ]),
    ]),
    getItem("Navigation Three", "sub4", <SettingOutlined />, [
      getItem("Option 9", "9"),
      getItem("Option 10", "10"),
      getItem("Option 11", "11"),
      getItem("Option 12", "12"),
    ]),
  ];

  const [openKeys, setOpenKeys] = useState(["sub1"]);

  const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  const idArray = _.map(data?.results, "id");

  const handleDownload = () => {
    // Convert the data to a Blob
    // const blob = new Blob([downloadedData], {
    //   type: "application/vnd.ms-excel",
    // });
    // Use the saveAs function to trigger the download
    //saveAs(blob, "sales-export.xlsx");
    if (downloadedData) {
      saveAs(downloadedData, "quotation-export.xlsx");
    } else {
      message.error("Error: Downloaded data is undefined.");
    }
  };

  const { data: downloadedData, isLoading: downloadLoading } =
    useGetSalesDownloadDataQuery(
      downloadIds?.length > 0 ? downloadIds : idArray
    );
  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
    current,
    pageSize
  ) => {
    setPageData({
      page: current,
      pageSize: pageSize,
    });
  };
  return (
    <>
      <div className="mt-24">
        <div className="flex justify-between items-center">
          <span className="sales-title">
            <Text value="Leads" variant="wearlay-display" />
          </span>
          <CommonButton
            variant="wealay-primary-btn"
            value="+ Add New Lead"
            className="primary-btn"
            onClick={() => {
              navigate("/add-lead");
            }}
          />
        </div>

        <div className="p-10 mt-24p">
          <div className="flex justify-between">
            <Text
              value="Customers List"
              variant="wearlay-headline-2"
              className="p-1 "
            />

            <div className="flex items-center h-full gap-2">
              <div className="search-resp ">
                <SearchBox placeholder="Search" />
              </div>
              <div className="relative">
                <CommonButton
                  variant="wearlay-with-icon"
                  icon={filter ? "/assets/close.svg" : "/assets/filter.svg"}
                  onClick={() => {
                    setFilter(!filter);
                  }}
                  className="download-btn-color"
                />
                {filter && (
                  <SalesFilterMenu
                    setFilter={setFilter}
                    setFilterData={setFilterData}
                    filer={filter}
                    selectedRegion={selectedRegion}
                    setSelectedRegion={setSelectedRegion}
                    setSelectedIndustry={setSelectedIndustry}
                    selectedIndustry={selectedIndustry}
                    setSelectedType={setSelectedType}
                    selectedType={selectedType}
                  />
                )}
              </div>

              <CommonButton
                variant="wearlay-with-icon"
                icon={
                  data?.results?.length === 0
                    ? "/assets/disabledDownload.svg"
                    : "/assets/download.svg"
                }
                onClick={() => {
                  handleDownload();
                }}
                // isLoading={downloadLoading}
                className="download-btn-color"
                disabled={data?.results?.length === 0}
              />
            </div>
          </div>
          <div className="resp-search">
            <SearchBox placeholder="Search" />
          </div>
          <div className="resp-table">
            <CommonTable
              // datasource={{data?.results}}
              // coloumn={columns}
              // setDetailsOpen={setOpen}
              // setId={setIsId}
              datasource={data?.results}
              coloumn={columns}
              setDetailsOpen={setOpen}
              isLoading={isLoading}
              setId={setIsId}
              setDownloadIds={setDownloadIds}
            />
            {data?.count > 0 && (
              <Row justify={"end"}>
                <Pagination
                  // current={pageData.page}
                  onChange={onPaginationChange}
                  total={data?.count}
                  pageSize={pageData.pageSize}
                  defaultCurrent={1}
                  responsive={true}
                  onShowSizeChange={onShowSizeChange}
                  showSizeChanger={data?.count > 10 ? true : false}
                />
              </Row>
            )}
          </div>
        </div>
        {/* <TableCard headingData={titleData} setOpen={setOpen} /> */}

        <TableCard
          headingData={titleData}
          setOpen={setOpen}
          data={data?.results}
          setId={setIsId}
          loading={isLoading}
        />
        {data?.count > 0 && (
          <Pagination
            // current={pageData.page}
            onChange={onPaginationChange}
            total={data?.count}
            pageSize={pageData?.pageSize}
            className="pagination-resp"
            defaultCurrent={1}
            onShowSizeChange={onShowSizeChange}
            showSizeChanger={data?.count > 10 ? true : false}
          />
        )}
      </div>
      <Drawer
        title={
          (isQuotationUrl as boolean) || open ? (
            // <div className="flex justify-between items-center">
            //   <Text value="Customer Preview" variant="wearlay-subtitle" />
            //   <div>
            //     <Space>
            //       <Button>
            //         <LeftOutlined />
            //       </Button>
            //       <Button>
            //         <RightOutlined />
            //       </Button>
            //     </Space>
            //   </div>
            // </div>
            <div className="flex justify-between items-center">
              {/* <div>Client Preview</div> */}
              <Text value="Customer Preview" variant="wearlay-subtitle" />
              <div>
                <Space>
                  <Button
                    onClick={() => {
                      handlePrevious();
                      // navigate(`/contacts?id=${object?.id}`);
                    }}
                    disabled={currentIndex === 0}
                  >
                    <LeftOutlined />
                  </Button>
                  <Button
                    onClick={() => {
                      // navigate(`/contacts?id=${object?.id}`);
                      handleNext();
                    }}
                    disabled={currentIndex === data?.results?.length - 1}
                  >
                    <RightOutlined />
                  </Button>
                </Space>
              </div>
            </div>
          ) : (
            "Add New Lead"
          )
        }
        // placement="right"
        // onClose={onClose}
        // open={open || openLead}
        // className="drawer-width"
        // width="500"
        placement="right"
        onClose={onClose}
        open={(isQuotationUrl as boolean) || open}
        width="500"
      >
        {(isQuotationUrl as boolean) || open ? (
          <SalesDetailById
            datas={salesData?.data}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            setOpen={setOpen}
            id={id}
            salesLoading={salesLoading}
          />
        ) : (
          <AddNewLead />
        )}
      </Drawer>
    </>
  );
};
