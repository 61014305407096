import { PathConstants } from "../pathConstants/pathConstants";
import { removeTokens } from "./localStorage";

export const errorHandling = (error: any) => {
  if (error.status === 403) {
    // removeTokens();
    return `${PathConstants.ERROR_403}`;
  }
};
export const concatTextFunction = (data: string) => {
  if (data?.length > 24) {
    return data?.slice(0, 24).concat("...");
  } else {
    return data;
  }
};
export const getUrlEndpoints = (
  endPoint: string,
  params: any,
  method?: string,
  requestBody?: any
) => {
  let url: any;
  if (method === "GET") {
    return {
      url: url.join(""),
      method,
    };
  } else {
    return {
      url: url.join(""),
      method,
      body: requestBody,
    };
  }
};
