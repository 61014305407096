import { Card, Col, Row, Space } from "antd";
import "./index.css";
import CountUp from "react-countup";
interface IDashboard {
  count: string;
  subTitle: string;
  img: string;
}

export const DashboardCard: React.FC<IDashboard> = ({
  count,
  subTitle,
  img,
}) => {
  return (
    <>
      {/* <Card style={{ width: 300 }}> */}
      <Row className="dashboard-card">
        <Col span={18}>
          <div className="dashboard-title-1 ">
            <CountUp end={Number(count)} separator="," />
          </div>
          <div className="w-full">{subTitle}</div>
        </Col>
        <Col span={4}>
          <img src={img}></img>
        </Col>
      </Row>
      {/* </Card> */}
    </>
  );
};
