import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Empty,
  Menu,
  message,
  Row,
  Skeleton,
  Space,
} from "antd";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDeleteContactsByIdMutation } from "../../../services/api";
import { CommonAvatar } from "../../CommonAvatar";
import { Text } from "../../CommonFont/text";
import "./index.css";
interface ICreateContact {
  data?: any;
  setEdit: (data: boolean) => void;
  setPreview?: (data: boolean) => void;
  loading?: boolean;
}
export const ClientPreview: React.FC<ICreateContact> = ({
  data,
  setEdit,
  setPreview,
  loading,
}) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const id = searchParams.get("id");

  const [trigger, { data: deleteData, isLoading }] =
    useDeleteContactsByIdMutation();
  const handleDelete = async () => {
    await trigger(id)
      .unwrap()
      .then((data) => {
        message.success(data?.message);
        setPreview && setPreview(false);
      })
      .catch((err) => {
        message.error(err?.data?.message);
      });
  };
  const widgetMenu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          setEdit(true);
        }}
      >
        <div>Edit</div>
      </Menu.Item>
      {/* <Menu.Item
        onClick={() => {
          handleDelete();
        }}
      >
        <div className="logout-div">
          <Space>
           
            <span className="logout-text ">Delete</span>
          </Space>
        </div>
      </Menu.Item> */}
    </Menu>
  );
  return (
    <>
      {loading ? (
        <Skeleton avatar paragraph={{ rows: 8 }} />
      ) : data ? (
        <div className="sales-detail-border-main ">
          <Row gutter={16} className="p5">
            <Col span={6}>
              {/* <Avatar size={100}>C</Avatar> */}
              <CommonAvatar text={data?.company_name} size={100} />
            </Col>
            <Col span={24} md={{ span: 18 }} className="flex flex-col gap-2">
              <div className="flex justify-between ">
                <Text variant="wearlay-headline-1" value={data?.company_name} />
                <Dropdown
                  overlay={widgetMenu}
                  trigger={["click"]}
                  className="dropdown-click"
                >
                  <img src="/assets/more.svg"></img>
                </Dropdown>
              </div>
              <div className="flex gap-2 ">
                <img src="/assets/message.svg"></img>
                <div>{data?.email ? data?.email : "-"}</div>
              </div>
              <div className="flex gap-2">
                <img src="/assets/phone.svg"></img>
                <div>{data?.phone}</div>
              </div>
              <div className="flex gap-2">
                <img
                  src="/assets/locattion.svg"
                  className="img-align-self"
                ></img>
                <div className="break-all">{data?.address}</div>
              </div>
              {!data?.created_from_sales && (
                <Button
                  onClick={() => {
                    navigate(`/add-lead?contact_id=${data?.id}`);
                  }}
                  className="sales-detail-btn-clr button-width"
                >
                  Create Sales
                </Button>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={24} md={{ span: 12 }}>
              <div className="sales-detail-border flex flex-col gap-2">
                <div className="sales-detail-placeholder">Client Name</div>
                <div className="sales-detail-sub-title ">
                  {data?.client_name}
                </div>
              </div>
              <div className="sales-detail-border flex flex-col gap-2">
                <div className="sales-detail-placeholder">Region</div>
                <div className="sales-detail-sub-title ">
                  {data?.region_name}
                </div>
              </div>
              <div className="sales-detail-border flex flex-col gap-2">
                <div className="sales-detail-placeholder">Source</div>
                <div className="sales-detail-sub-title ">
                  {data?.source_name}
                </div>
              </div>
              <div className="sales-detail-border flex flex-col gap-2">
                <div className="sales-detail-placeholder">Industry</div>
                <div className="sales-detail-sub-title ">
                  {data?.industry_name}
                </div>
              </div>
            </Col>
            <Col span={24} md={{ span: 12 }}>
              <div className="sales-detail-border flex flex-col gap-2">
                <div className="sales-detail-placeholder">Designation</div>
                <div className="sales-detail-sub-title ">
                  {data?.designation_name}
                </div>
              </div>
              <div className="sales-detail-border flex flex-col gap-2">
                <div className="sales-detail-placeholder">GST</div>
                <div className="sales-detail-sub-title ">
                  {data?.gst ? data?.gst : "-"}
                </div>
              </div>
              <div className="sales-detail-border flex flex-col gap-2">
                <div className="sales-detail-placeholder">Type</div>
                <div className="sales-detail-sub-title ">{data?.type_name}</div>
              </div>

              <div className="sales-detail-border flex flex-col gap-2">
                <div className="sales-detail-placeholder">
                  Customer Unique Identification Number
                </div>
                <div className="sales-detail-sub-title ">
                  {data?.contacts_customer_unique_identification_number
                    ? data?.contacts_customer_unique_identification_number
                    : "-"}
                </div>
              </div>
            </Col>
            <Row className="w-full">
              <div className="sales-detail-border flex flex-col gap-2 w-full">
                <div className="sales-detail-placeholder">Special Note</div>
                <div className="sales-detail-sub-title ">
                  {data?.note ? data?.note : "-"}
                </div>
              </div>
            </Row>
          </Row>
        </div>
      ) : (
        <Empty className="h-full flex items-center flex-col justify-center" />
      )}
    </>
  );
};
