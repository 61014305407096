import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Svg,
} from "@react-pdf/renderer";
import { useGetQuotationByIdQuery } from "../../services/api";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingHorizontal: 0,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "center",
    // fontFamily: "Inter",
  },
  image: {
    // marginVertical: 15,
    // marginHorizontal: 100,
    width: "100",
    marginRight: 30,
    // height: "100",
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  table: {
    display: "flex",
    width: "100%",
    // borderStyle: "solid",
    // borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  table1: {
    display: "flex",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    // borderRightWidth: 0,
    // borderBottomWidth: 0,
    justifyContent: "center",
    alignItems: "center",
    borderColor: "#e6ebec",
  },
  tableRow: {
    // margin: "auto",
    flexDirection: "row",
    backgroundColor: "#f9fdfd",
  },
  tableRow1: {
    // margin: "auto",
    flexDirection: "row",
    backgroundColor: "white",
  },
  tableCol: {
    width: "30%",
    borderStyle: "solid",
    borderWidth: 1,
    padding: "10",
    borderColor: "#e6ebec",
    // borderLeftWidth: 0,
    // borderTopWidth: 0,
  },
  tableCol2: {
    width: "45%",
    borderStyle: "solid",
    wordBreak: "break-all",
    // borderWidth: 1,
    padding: "10",

    // borderLeftWidth: 0,
    // borderTopWidth: 0,
  },
  tableCol3: {
    width: "90%",
    borderStyle: "solid",
    borderWidth: 1,
    padding: "10",
    borderColor: "#e6ebec",
    // borderLeftWidth: 0,
    // borderTopWidth: 0,
  },
  tableCol4: {
    width: "90%",
    // borderStyle: "solid",
    // borderWidth: 1,
    padding: "10",
    // borderColor: "#e6ebec",
    // borderLeftWidth: 0,
    // borderTopWidth: 0,
  },
  tableCell: {
    textAlign: "left",
    fontSize: 13,
    color: "#043843",

    fontFamily: "Times-BoldItalic",
  },
  tableCell2: {
    textAlign: "left",
    fontSize: 14,
    fontWeight: "extrabold",
    fontFamily: "Helvetica-Bold",
    wordBreak: "break-all",
    width: "90%",
  },
  tableCell4: {
    textAlign: "left",
    fontSize: 14,
    fontWeight: "extrabold",
    fontFamily: "Helvetica-Bold",
    wordBreak: "break-all",
  },
  tableCell3: {
    textAlign: "left",
    fontSize: 12,
  },
  marginTop: {
    marginTop: 24,
  },
  imageDiv: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "30",
    marginTop: "20",
  },
  title1: {
    fontWeight: "extrabold",
    fontSize: "24",
    fontFamily: "Helvetica-Bold",
  },
  titleBorder: {
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#e6ebec",
  },
  borderRight: {
    borderStyle: "solid",
    borderWidth: 1,
    borderRight: 1,
    borderLeft: 0,
    borderBottom: 0,
    borderTop: 0,
    borderColor: "#e6ebec",
  },
});

// Create Document Component
interface IExport {
  quotationData: any;
  quotationByIdData: any;
  npdData: any;
  data: any;
}
export const OrderPdf: React.FC<IExport> = ({
  quotationData,
  quotationByIdData,
  npdData,
  data,
}) => {
  const [daysDifference, setDaysDifference] = useState(0);
  useEffect(() => {
    calculateDaysDifference();
  }, [data?.data?.dispatch_schedule]);

  const calculateDaysDifference = () => {
    const currentDate = moment();
    const targetDate = moment(
      data?.data?.dispatch_schedule[data?.data?.dispatch_schedule?.length - 1]
        ?.dispatch_date,
      "YYYY-MM-DD"
    );
    const daysDifference = targetDate.diff(currentDate, "days") + 1;
    setDaysDifference(daysDifference);
  };

  //   const { data, isLoading } = useGetQuotationByIdQuery(14);
  return (
    <Document>
      <Page style={styles.body} size="A4">
        <View style={styles.imageDiv}>
          <Text style={styles.title1}>Order Card</Text>

          <Image src="/assets/jaws.png" style={styles.image} />
        </View>

        <View style={{ ...styles.table, ...styles.marginTop }}>
          <View style={styles.titleBorder}>
            <View style={styles.tableRow}>
              <View style={{ ...styles.tableCol2, ...styles.borderRight }}>
                <Text style={styles.tableCell}>Issued Date</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>Order Number</Text>
              </View>
              {/* <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Period</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Price</Text>
          </View> */}
            </View>
            <View style={styles.tableRow}>
              <View style={{ ...styles.tableCol2, ...styles.borderRight }}>
                <Text style={styles.tableCell2}>
                  {" "}
                  {moment(data?.data?.order_punched_date).format("DD-MM-YYYY")}
                </Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell4}>{data?.data?.order_no}</Text>
              </View>
              {/* <View style={styles.tableCol}>
            <Text style={styles.tableCell}>2019-02-20 - 2020-02-19</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>5€</Text>
          </View> */}
            </View>
          </View>
          <View style={styles.titleBorder}>
            <View style={styles.tableRow}>
              <View style={{ ...styles.tableCol2, ...styles.borderRight }}>
                <Text style={styles.tableCell}>Company Name</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>Lead Code</Text>
              </View>
              {/* <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Period</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Price</Text>
          </View> */}
            </View>
            <View style={styles.tableRow}>
              <View style={{ ...styles.tableCol2, ...styles.borderRight }}>
                <Text style={styles.tableCell2}>
                  {quotationData?.data[0]?.company_name
                    ? quotationData?.data[0]?.company_name
                    : npdData?.data[0]?.company_name
                    ? npdData?.data[0]?.company_name
                    : "-"}
                </Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell4}>
                  {quotationData?.data[0]?.sales_lead_id
                    ? quotationData?.data[0]?.sales_lead_id
                    : npdData?.data[0]?.sales_lead_id
                    ? npdData?.data[0]?.sales_lead_id
                    : "-"}
                </Text>
              </View>
              {/* <View style={styles.tableCol}>
            <Text style={styles.tableCell}>2019-02-20 - 2020-02-19</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>5€</Text>
          </View> */}
            </View>
          </View>
          <View style={styles.titleBorder}>
            <View style={styles.tableRow}>
              <View style={{ ...styles.tableCol2, ...styles.borderRight }}>
                <Text style={styles.tableCell}>Lead Name</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>Dispatch Committed On</Text>
              </View>
              {/* <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Period</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Price</Text>
          </View> */}
            </View>
            <View style={styles.tableRow}>
              <View style={{ ...styles.tableCol2, ...styles.borderRight }}>
                <Text style={styles.tableCell2}>
                  {quotationData?.data[0]?.lead_name
                    ? quotationData?.data[0]?.lead_name
                    : npdData?.data[0]?.lead_name
                    ? npdData?.data[0]?.lead_name
                    : "-"}
                </Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell4}>
                  {data?.data?.dispatch_schedule[0]?.dispatch_date
                    ? moment(
                        data?.data?.dispatch_schedule[
                          data?.data?.dispatch_schedule?.length - 1
                        ]?.dispatch_date
                      ).format("DD-MM-YYYY")
                    : "-"}
                  {data?.data?.dispatch_schedule[
                    data?.data?.dispatch_schedule?.length - 1
                  ]?.dispatch_date &&
                    (moment(
                      data?.data?.dispatch_schedule[
                        data?.data?.dispatch_schedule?.length - 1
                      ]?.dispatch_date
                    ).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
                      ? "(Today)"
                      : moment(
                          data?.data?.dispatch_schedule[
                            data?.data?.dispatch_schedule?.length - 1
                          ]?.dispatch_date
                        ).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")
                      ? "(expired)"
                      : `(within ${daysDifference} days)`)}
                </Text>
              </View>
              {/* <View style={styles.tableCol}>
            <Text style={styles.tableCell}>2019-02-20 - 2020-02-19</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={styles.tableCell}>5€</Text>
          </View> */}
            </View>
          </View>
        </View>
        <View style={{ ...styles.table, ...styles.marginTop }}>
          <View style={styles.tableRow1}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Item Information</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Brand</Text>
            </View>
            {/* {/* <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Period</Text>
          </View> */}
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Qty</Text>
            </View>
          </View>
          {quotationByIdData?.data?.items?.map((item: any) => (
            <View style={styles.tableRow1}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell2}>{item?.item_information}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell2}>{item?.brand}</Text>
              </View>
              {/* {/* <View style={styles.tableCol}>
            <Text style={styles.tableCell}>2019-02-20 - 2020-02-19</Text>
          </View> */}
              <View style={styles.tableCol}>
                <Text style={styles.tableCell2}>{item?.qty}</Text>
              </View>
            </View>
          ))}
        </View>
        <View style={{ ...styles.table, ...styles.marginTop }}>
          <View style={styles.titleBorder}>
            <View style={styles.tableRow}>
              <View style={{ ...styles.tableCol2, ...styles.borderRight }}>
                <Text style={styles.tableCell}>Mould Code</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>Design Code</Text>
              </View>
              {/* {/* <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Period</Text>
          </View> */}
              {/* <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Qty</Text>
          </View> */}
            </View>
            <View style={styles.tableRow}>
              <View style={{ ...styles.tableCol2, ...styles.borderRight }}>
                <Text style={styles.tableCell2}>
                  {quotationByIdData?.data?.mould_code}
                </Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell4}>
                  {quotationByIdData?.data?.design_code}
                </Text>
              </View>
              {/* {/* <View style={styles.tableCol}>
            <Text style={styles.tableCell}>2019-02-20 - 2020-02-19</Text>
          </View> */}
              {/* <View style={styles.tableCol}>
            <Text style={styles.tableCell}>100</Text>
          </View> */}
            </View>
          </View>
          <View style={styles.titleBorder}>
            <View style={styles.tableRow}>
              <View style={{ ...styles.tableCol2, ...styles.borderRight }}>
                <Text style={styles.tableCell}>Drawing Number</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>Material Code</Text>
              </View>
              {/* {/* <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Period</Text>
          </View> */}
              {/* <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Qty</Text>
          </View> */}
            </View>
            <View style={styles.tableRow}>
              <View style={{ ...styles.tableCol2, ...styles.borderRight }}>
                <Text style={styles.tableCell2}>
                  {quotationByIdData?.data?.drawing_number}
                </Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell4}>
                  {quotationByIdData?.data?.material_grade}
                </Text>
              </View>
              {/* {/* <View style={styles.tableCol}>
            <Text style={styles.tableCell}>2019-02-20 - 2020-02-19</Text>
          </View> */}
              {/* <View style={styles.tableCol}>
            <Text style={styles.tableCell}>100</Text>
          </View> */}
            </View>
          </View>
        </View>

        <View style={{ ...styles.table, ...styles.marginTop }}>
          <View style={styles.titleBorder}>
            <View style={styles.tableRow1}>
              <View style={styles.tableCol4}>
                <Text style={styles.tableCell}>Note</Text>
              </View>

              {/* {/* <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Period</Text>
          </View> */}
              {/* <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Qty</Text>
          </View> */}
            </View>
            <View style={styles.tableRow1}>
              <View style={styles.tableCol4}>
                <Text style={styles.tableCell3}>
                  {quotationByIdData?.data?.note}
                </Text>
              </View>
              {/* <View style={styles.tableCol}>
            <Text style={styles.tableCell}>WERR2985</Text>
          </View> */}
              {/* {/* <View style={styles.tableCol}>
            <Text style={styles.tableCell}>2019-02-20 - 2020-02-19</Text>
          </View> */}
              {/* <View style={styles.tableCol}>
            <Text style={styles.tableCell}>100</Text>
          </View> */}
            </View>
          </View>
        </View>
        {/* <Text style={styles.text}>
        Oh right. I forgot about the battle. Wow, you got that off the Internet?
      </Text> */}
      </Page>
    </Document>
  );
};
