import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import {
  Button,
  Drawer,
  message,
  Pagination,
  PaginationProps,
  Row,
  Space,
  Tag,
} from "antd";
import { useEffect, useState } from "react";
import { CommonButton } from "../../components/CommonButton/button";
import { SearchBox } from "../../components/CommonSearchBox";
import { CommonTable } from "../../components/commonTable";
import { AllocatedPreview } from "../../components/Drawer/AllocatedPreview";
import { Text } from "../../components/CommonFont/text";
import { TableCard } from "../../components/CommonTableCard";
import {
  useGetAllAllocatedQuery,
  useGetAllocatedDownloadQuery,
  useGetDownloadDataQuery,
  useGetUserDetailByIdQuery,
} from "../../services/api";
import { getContactsParams } from "../../helpers/quarery";
import { useNavigate, useSearchParams } from "react-router-dom";
import SalesFilterMenu from "../../components/CommonFilter/salesFilter";
import { saveAs } from "file-saver";
import _ from "lodash";

export const Allocated = () => {
  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterData, setFilterData] = useState<any>();
  const [filter, setFilter] = useState(false);
  const [downloadIds, setDownloadIds] = useState<any>();
  const [role, setRole] = useState<any>();

  const [selectedRegion, setSelectedRegion] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const navigate = useNavigate();
  const userId = localStorage.getItem("user_id");
  const userRole = localStorage.getItem("user_role");

  const search = searchParams.get("search");
  const [pageData, setPageData] = useState<{
    page: number;
    pageSize: number;
  }>({
    page: 0,
    pageSize: 5,
  });

  const params = getContactsParams(pageData.page, pageData.pageSize);

  const getAllContactsParams = {
    id: role,
    params: params,
    search: search ? search : "",
    body: JSON.stringify(filterData)
      ? JSON.stringify(filterData)
      : JSON.stringify({}),
  };

  const { data, isLoading } = useGetAllAllocatedQuery(getAllContactsParams);

  useEffect(() => {
    if (userRole === "1") {
      setRole(`manager_id=${userId}`);
    } else {
      setRole(`executive_id=${userId}`);
    }
  }, [userRole]);
  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];
  const AllocatedName = ({ id }: any) => {
    const { data: userData, isLoading: userLoading } =
      useGetUserDetailByIdQuery(id);

    // useEffect(() => {
    //   if (id) {
    //     trigger(id);
    //   }
    // }, [id]);
    return (
      <>
        <div>{userData?.data[0]?.full_name}</div>
      </>
    );
  };
  const columns = [
    {
      title: "Code",
      dataIndex: "sales_lead_id",
      key: "name",
      // sorter: (a: any, b: any) => a.age - b.age,
      ellipsis: true,
    },
    {
      title: "Company",
      dataIndex: "company_name",
      key: "name",
      sorter: (a: any, b: any) => a.company_name.localeCompare(b.company_name),

      ellipsis: true,
    },
    {
      title: "Region",
      dataIndex: "region_name",
      key: "age",
      sorter: (a: any, b: any) => a.region_name.localeCompare(b.region_name),

      ellipsis: true,
    },
    {
      title: "Contact",
      dataIndex: "address",
      key: "address",
      ellipsis: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Type",
      dataIndex: "type_name",
      key: "age",
      ellipsis: true,
    },
    {
      title: "Industry",
      dataIndex: "industry_name",
      key: "address",
      ellipsis: true,
    },
    {
      title: "Enquiry Category",
      dataIndex: "enquiry_category_name",
      key: "address",
      ellipsis: true,
    },
    {
      title: userRole === "1" ? "Allocated To" : "Allocated By",
      dataIndex: userRole === "1" ? "allocated_to_name" : "allocated_by_name",
      key: "address",
      ellipsis: true,
      // render: (text: any) => {
      //   return (
      //     <>
      //       <AllocatedName id={text} />
      //     </>
      //   );
      // },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "address",
      render: (text: any) => {
        return (
          <>
            {text?.title_name === "Follow Up 1" ? (
              <Tag color="cyan">Follow up 1</Tag>
            ) : text?.title_name === "Follow Up 2" ? (
              <Tag color="orange">Follow up 2</Tag>
            ) : text?.title_name === "Follow Up 3" ? (
              <Tag color="purple">Follow up 3</Tag>
            ) : (
              "-"
            )}
          </>
        );
      },
      ellipsis: true,
    },
  ];
  const titleData = [
    "Code",
    "Company",
    "Region",
    "Contact",
    "Email",
    "Type",
    "Industry",
    "Enquiry Category",
    userRole === "1" ? "Allocated To" : "Allocated By",
    "Status",
  ];
  const onClose = () => {
    setOpen(false);
  };
  const onPaginationChange = (page: number, pageSize: number) => {
    setPageData({ page, pageSize });
  };
  const idArray = _.map(data?.results, "id");

  const handleDownload = () => {
    // Convert the data to a Blob
    // const blob = new Blob([downloadedData], {
    //   type: "application/vnd.ms-excel",
    // });

    // // Use the saveAs function to trigger the download
    // saveAs(blob, "contact-export.xlsx");
    if (downloadedData) {
      saveAs(downloadedData, "allocated-export.xlsx");
    } else {
      message.error("Error: Downloaded data is undefined.");
    }
  };
  const { data: downloadedData, isLoading: downloadLoading } =
    useGetAllocatedDownloadQuery(
      downloadIds?.length > 0 ? downloadIds : idArray
    );
  const findIndexForObject = (array: [], object: any) => {
    return _.findIndex(array, object);
  };
  const sales_id = searchParams.get("id");

  const targetObject = { id: Number(sales_id) };
  const index = findIndexForObject(data?.results, targetObject);

  const [currentIndex, setCurrentIndex] = useState(index);

  const getObjectAtIndex = (array: [], index: number) => {
    const object = _.get(array, index);
    return object;
  };

  useEffect(() => {
    setCurrentIndex(index);
  }, [index]);
  useEffect(() => {
    if (currentIndex > -1) {
      const updatedObject: any = getObjectAtIndex(data?.results, currentIndex);

      navigate(`/allocated?id=${updatedObject?.id}`);
    }
  }, [currentIndex]);
  const handleNext = () => {
    // console.log("final object trance", !contactLoading && object?.id);
    // navigate(`/contacts?id=${object?.id}`);

    if (currentIndex < data?.results?.length - 1) {
      setCurrentIndex(currentIndex + 1);

      // navigate(`/contacts?id=${object?.id}`);
      // setSearchParams({ ["id"]: object?.id });
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);

      // navigate(`/contacts?id=${object?.id}`);
    }
  };
  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
    current,
    pageSize
  ) => {
    setPageData({
      page: current,
      pageSize: pageSize,
    });
  };
  return (
    <>
      <div className="mt-24">
        {/* <div className="orders-title ">Allocated</div> */}
        <Text
          value="Allocated"
          variant="wearlay-display"
          className="orders-title"
        />
        <div className="p-10 mt-24p">
          <div className="flex justify-between">
            {/* <div className="sales-title-1">Allocated List</div> */}
            <Text value="Allocated List" variant="wearlay-headline-2" />
            <div className="flex items-center h-full gap-2">
              <div className="search-resp ">
                <SearchBox placeholder="Search" />
              </div>

              {/* <Button className="download-btn-color">
                <img src="/assets/filter.svg"></img>
              </Button>
              <Button className="download-btn-color">
                <img src="/assets/download.svg"></img>
              </Button> */}
              <div className="relative">
                <CommonButton
                  variant="wearlay-with-icon"
                  icon={filter ? "/assets/close.svg" : "/assets/filter.svg"}
                  onClick={() => {
                    setFilter(!filter);
                  }}
                  className="download-btn-color"
                />
                {filter && (
                  <SalesFilterMenu
                    setFilter={setFilter}
                    setFilterData={setFilterData}
                    filer={filter}
                    selectedRegion={selectedRegion}
                    setSelectedRegion={setSelectedRegion}
                    setSelectedIndustry={setSelectedIndustry}
                    selectedIndustry={selectedIndustry}
                    setSelectedType={setSelectedType}
                    selectedType={selectedType}
                  />
                )}
              </div>
              <CommonButton
                variant="wearlay-with-icon"
                icon={
                  data?.results?.length === 0
                    ? "/assets/disabledDownload.svg"
                    : "/assets/download.svg"
                }
                onClick={() => {
                  handleDownload();
                }}
                // isLoading={downloadLoading}
                className="download-btn-color"
                disabled={data?.results?.length === 0}
              />
            </div>
          </div>
          <div className="resp-search">
            <SearchBox placeholder="Search" />
          </div>
          <div className="resp-table">
            <CommonTable
              datasource={data?.results}
              coloumn={columns}
              setDetailsOpen={setOpen}
              isLoading={isLoading}
              setDownloadIds={setDownloadIds}
            />
            {data?.count > 0 && (
              <Row justify={"end"}>
                <Pagination
                  // current={pageData.page}
                  onChange={onPaginationChange}
                  total={data?.count}
                  pageSize={pageData?.pageSize}
                  defaultCurrent={1}
                  responsive={true}
                  onShowSizeChange={onShowSizeChange}
                  showSizeChanger={data?.count > 10 ? true : false}
                />
              </Row>
            )}
          </div>
        </div>
        <TableCard
          headingData={titleData}
          setOpen={setOpen}
          data={data?.results}
          loading={isLoading}
          // setId={setIsId}
        />
        {/* <div className="pagination-resp"> */}
        {data?.count > 0 && (
          <Pagination
            // current={pageData.page}
            onChange={onPaginationChange}
            total={data?.count}
            pageSize={pageData?.pageSize}
            className="pagination-resp"
            defaultCurrent={1}
            onShowSizeChange={onShowSizeChange}
            showSizeChanger={data?.count > 10 ? true : false}
          />
        )}
      </div>
      <Drawer
        title={
          <div className="flex justify-between items-center">
            {/* <div>Customer Preview</div> */}
            <Text value="Customer Preview" variant="wearlay-subtitle" />
            <div>
              <Space>
                <Button
                  onClick={() => {
                    handlePrevious();
                    // navigate(`/contacts?id=${object?.id}`);
                  }}
                  disabled={currentIndex === 0}
                >
                  <LeftOutlined />
                </Button>
                <Button
                  onClick={() => {
                    // navigate(`/contacts?id=${object?.id}`);
                    handleNext();
                  }}
                  disabled={currentIndex === data?.results?.length - 1}
                >
                  <RightOutlined />
                </Button>
              </Space>
            </div>
          </div>
        }
        placement="right"
        onClose={onClose}
        open={open}
        width="500"
        destroyOnClose={true}
      >
        <AllocatedPreview setOpen={setOpen} />
      </Drawer>
    </>
  );
};
