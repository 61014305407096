import "./text-module.css";
import classNames from "classnames";
import "../../Pages/AddNewLead/index.css";
enum commonStyles {
  "wearlay-display",
  "wearlay-headline-1",
  "wearlay-headline-2",
  "wearlay-subtitle",
  "wearlay-paragraph",
  "wearlay-paragraph-bold",
  "wearlay-caption",
  "wearlay-label",
  "wearlay-label-bold",
}
export type TextVariantType = keyof typeof commonStyles;
interface IFont {
  value: string;
  variant: TextVariantType;
  className?: string;
}

export const Text: React.FC<IFont> = ({ value, variant, className }) => {
  return (
    <div className={`${className} ${classNames(variant, variant)}`}>
      {value}
    </div>
  );
};
