export const getInitials = (name: string) => {
  const nameParts = name && name?.split(" ");
  const firstName = nameParts[0] && nameParts[0];
  const lastName = nameParts[nameParts?.length - 1];

  const initials =
    firstName?.charAt(0)?.toUpperCase() + lastName?.charAt(0)?.toUpperCase();

  return initials;
};
