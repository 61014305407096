import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Svg,
} from "@react-pdf/renderer";
import _ from "lodash";
import moment from "moment";
import { useState } from "react";

const styles = StyleSheet.create({
  body: {
    padding: 10,
  },
  table1: {
    display: "flex",

    width: "100%",
    flexDirection: "row",
  },
  table2: {
    display: "flex",
    width: "50%",
    flexDirection: "column",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "black",
  },
  declaration: {
    display: "flex",
    width: "50%",
    flexDirection: "column",
  },
  declaration1: {
    display: "flex",
    width: "50%",
    flexDirection: "column",
    justifyContent: "space-between",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "black",
  },
  table3: {
    display: "flex",
    width: "50%",
    flexDirection: "column",
  },
  col2: {
    display: "flex",
    width: "50%",
    flexDirection: "column",
  },
  table4: {
    display: "flex",
    flexDirection: "column",
    // width: "50%",
  },
  mainTable: {
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "black",
  },
  image: {
    // marginVertical: 15,
    // marginHorizontal: 100,
    width: "60",

    height: "20",
  },
  text: {
    fontSize: 10,
  },
  padding: {
    padding: "5",
  },
  padding2: {
    padding: "3",
  },
  tableCol: {
    width: "18%",
    borderStyle: "solid",
    borderWidth: 1,
    padding: "5",
    borderColor: "black",
    // borderLeftWidth: 0,
    // borderTopWidth: 0,
  },
  tableCol30: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    padding: "5",
    borderColor: "black",
    // borderLeftWidth: 0,
    // borderTopWidth: 0,
  },
  tableCol15: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    padding: "5",
    borderColor: "black",
    backgroundColor: "white",
    // borderLeftWidth: 0,
    // borderTopWidth: 0,
  },
  tableCol13: {
    width: "80%",
    borderStyle: "solid",
    borderWidth: 1,
    padding: "5",
    borderColor: "black",
    // borderLeftWidth: 0,
    // borderTopWidth: 0,
  },
  tableCol7: {
    width: "6%",
    borderStyle: "solid",
    borderWidth: 1,
    padding: "5",
    borderColor: "black",
    // borderLeftWidth: 0,
    // borderTopWidth: 0,
  },
  tableCol8: {
    width: "16%",
    borderStyle: "solid",
    borderWidth: 1,
    padding: "5",
    borderColor: "black",
    // borderLeftWidth: 0,
    // borderTopWidth: 0,
  },
  tableCol9: {
    width: "17%",
    borderStyle: "solid",
    borderWidth: 1,
    padding: "5",
    borderColor: "black",
    // borderLeftWidth: 0,
    // borderTopWidth: 0,
  },
  tableCol29: {
    width: "7%",
    borderStyle: "solid",
    borderWidth: 1,
    padding: "5",
    borderColor: "black",
    // borderLeftWidth: 0,
    // borderTopWidth: 0,
  },
  tableCol10: {
    width: "16%",
    borderStyle: "solid",
    borderWidth: 1,
    padding: "5",
    borderColor: "black",
    // borderLeftWidth: 0,
    // borderTopWidth: 0,
  },
  tableCol20: {
    width: "32%%",
    borderStyle: "solid",
    borderWidth: 1,
    padding: "5",
    borderColor: "black",
    // borderLeftWidth: 0,
    // borderTopWidth: 0,
  },
  tableCol11: {
    width: "14%",
    borderStyle: "solid",
    borderWidth: 1,
    padding: "5",
    borderColor: "black",
    // borderLeftWidth: 0,
    // borderTopWidth: 0,
  },
  tableCol12: {
    width: "8%",
    borderStyle: "solid",
    borderWidth: 1,
    padding: "5",
    borderColor: "black",
    // borderLeftWidth: 0,
    // borderTopWidth: 0,
  },
  tableCol2: {
    width: "45%",
    borderStyle: "solid",
    // borderWidth: 1,
    padding: "5",

    // borderLeftWidth: 0,
    // borderTopWidth: 0,
  },
  tableCol5: {
    width: "90%",
    borderStyle: "solid",
    // borderWidth: 1,
    padding: "5",

    // borderLeftWidth: 0,
    // borderTopWidth: 0,
  },
  tableCol6: {
    width: "100%",
    borderStyle: "solid",
    // borderWidth: 1,
    padding: "5",

    // borderLeftWidth: 0,
    // borderTopWidth: 0,
  },
  tableCol3: {
    width: "90%",
    borderStyle: "solid",
    borderWidth: 1,
    padding: "10",
    borderColor: "black",
    // borderLeftWidth: 0,
    // borderTopWidth: 0,
  },
  tableCol4: {
    width: "90%",
    // borderStyle: "solid",
    // borderWidth: 1,
    padding: "5",
    // borderColor: "black",
    // borderLeftWidth: 0,
    // borderTopWidth: 0,
  },
  tableCell: {
    textAlign: "left",
    fontSize: 12,
    color: "black",
    fontFamily: "Helvetica-Bold",
  },
  tableCell4: {
    textAlign: "right",
    fontSize: 12,
    // color: "black",
    fontFamily: "Helvetica-Bold",
  },
  tableCell5: {
    textAlign: "left",
    fontSize: 12,
    color: "black",
    backgroundColor: "white",
    fontFamily: "Helvetica-Bold",
  },
  tableCell2: {
    textAlign: "left",
    fontSize: 12,
    fontWeight: "extrabold",
    fontFamily: "Helvetica-Bold",
  },
  tableCell3: {
    textAlign: "left",
    fontSize: 12,
  },
  marginTop: {
    marginTop: 24,
  },
  imageDiv: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "30",
    marginTop: "20",
  },
  title1: {
    fontWeight: "extrabold",
    fontSize: "24",
    fontFamily: "Helvetica-Bold",
  },
  titleBorder: {
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "black",
  },
  borderRight: {
    borderStyle: "solid",
    borderWidth: 1,
    borderRight: 1,
    borderLeft: 0,
    borderBottom: 0,
    borderTop: 0,
    borderColor: "black",
  },
  table: {
    display: "flex",
    width: "100%",
    // borderStyle: "solid",
    // borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    // justifyContent: "center",
    // alignItems: "center",
  },
  tableRow1: {
    // margin: "auto",
    flexDirection: "row",
    backgroundColor: "white",
  },
  textBold: {
    fontFamily: "Helvetica-Bold",
    fontSize: 10,
  },
});
interface IExport {
  datas: any;
  salesData: any;
}
export const QuotationExport: React.FC<IExport> = ({ datas, salesData }) => {
  return (
    <>
      <Document>
        <Page style={styles.body} size="A4">
          <View style={styles.mainTable}>
            <View style={styles.table1}>
              <View style={styles.table2}>
                <View style={styles.table1}>
                  <Image src="/assets/jaws.png" style={styles.image} />
                  <View style={{ ...styles.table4, ...styles.padding2 }}>
                    <Text style={styles.textBold}>
                      JAWS TECH ENGINEERING - (2022-2023)
                    </Text>
                    <Text style={styles.text}>
                      Peringannur,Peramangalam P.O.
                    </Text>
                    <Text style={styles.text}>Thrissur - 680545</Text>
                    <Text style={styles.text}>
                      GSTIN/UIN: 32AAOFJ2054R1ZX
                    </Text>{" "}
                    <Text style={styles.text}>
                      State Name : Kerala, Code : 32
                    </Text>{" "}
                    <Text style={styles.text}>
                      E-Mail : jawstechengineering@gmail.com
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    ...styles.table4,
                    ...styles.mainTable,
                    ...styles.padding,
                  }}
                >
                  <Text style={styles.text}>Consignee (Ship to)</Text>
                  <Text style={styles.text}>
                    {" "}
                    {salesData?.data[0]?.company_name}
                  </Text>
                  <Text style={styles.text}>
                    {" "}
                    {salesData?.data[0]?.address}
                  </Text>
                  <Text style={styles.text}>
                    Mob: {salesData?.data[0]?.phone}
                  </Text>{" "}
                  <Text style={styles.text}>
                    GSTIN/UIN : {salesData?.data[0]?.gst}
                  </Text>{" "}
                  <Text style={styles.text}>
                    State Name :{salesData?.data[0]?.region_name}
                  </Text>
                </View>
                <View style={{ ...styles.table4, ...styles.padding }}>
                  <Text style={styles.text}>Buyer (Bill to)</Text>
                  <Text style={styles.text}>
                    {" "}
                    {salesData?.data[0]?.company_name}
                  </Text>
                  <Text style={styles.text}>
                    {" "}
                    {salesData?.data[0]?.address}
                  </Text>
                  <Text style={styles.text}>
                    Mob: {salesData?.data[0]?.phone}
                  </Text>{" "}
                  <Text style={styles.text}>
                    GSTIN/UIN : {salesData?.data[0]?.gst}
                  </Text>{" "}
                  <Text style={styles.text}>
                    State Name :{salesData?.data[0]?.region_name}
                  </Text>
                </View>
              </View>

              <View style={styles.table3}>
                <View style={styles.table1}>
                  <View style={styles.col2}>
                    <View style={{ ...styles.mainTable, ...styles.padding2 }}>
                      <Text style={styles.textBold}>CUI No. </Text>

                      <Text style={styles.text}>
                        {" "}
                        {salesData?.data[0]
                          ?.contacts_customer_unique_identification_number
                          ? salesData?.data[0]
                              ?.contacts_customer_unique_identification_number
                          : "-"}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.col2}>
                    <View style={{ ...styles.mainTable, ...styles.padding2 }}>
                      <Text style={styles.textBold}>Quotation No</Text>

                      <Text style={styles.text}>{datas?.quotation_no}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.table1}>
                  <View style={styles.col2}>
                    <View style={{ ...styles.mainTable, ...styles.padding2 }}>
                      <Text style={styles.textBold}>Lead Name</Text>

                      <Text style={styles.text}>
                        {" "}
                        {salesData?.data[0]?.lead_name}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.col2}>
                    <View style={{ ...styles.mainTable, ...styles.padding2 }}>
                      <Text style={styles.textBold}>Email</Text>

                      <Text style={styles.text}>
                        {" "}
                        {salesData?.data[0]?.email}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={styles.table1}>
                  <View style={styles.col2}>
                    <View style={{ ...styles.mainTable, ...styles.padding2 }}>
                      <Text style={styles.textBold}>Enquiry Category</Text>

                      <Text style={styles.text}>
                        {" "}
                        {salesData?.data[0]?.enquiry_category_name}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.col2}>
                    <View style={{ ...styles.mainTable, ...styles.padding2 }}>
                      <Text style={styles.textBold}>Mould Code </Text>

                      <Text style={styles.text}>{datas?.mould_code}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.table1}>
                  <View style={styles.col2}>
                    <View style={{ ...styles.mainTable, ...styles.padding2 }}>
                      <Text style={styles.textBold}>Design Code</Text>

                      <Text style={styles.text}>{datas?.design_code}</Text>
                    </View>
                  </View>
                  <View style={styles.col2}>
                    <View style={{ ...styles.mainTable, ...styles.padding2 }}>
                      <Text style={styles.textBold}>Drawing Number</Text>

                      <Text style={styles.text}>{datas?.drawing_number}</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.table1}>
                  <View style={styles.col2}>
                    <View style={{ ...styles.mainTable, ...styles.padding2 }}>
                      <Text style={styles.textBold}>Material Code</Text>

                      <Text style={styles.text}>{datas?.material_grade}</Text>
                    </View>
                  </View>
                  <View style={styles.col2}>
                    <View style={{ ...styles.mainTable, ...styles.padding2 }}>
                      <Text style={styles.textBold}>Date Created</Text>

                      <Text style={styles.text}>
                        {" "}
                        {moment(datas?.date).format("DD-MM-YYYY")}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={styles.table1}>
                  <View style={styles.col2}>
                    <View style={{ ...styles.mainTable, ...styles.padding2 }}>
                      <Text style={styles.textBold}>Delivery Period</Text>

                      <Text style={styles.text}>{datas?.delivery_period}</Text>
                    </View>
                  </View>
                  <View style={styles.col2}>
                    <View style={{ ...styles.mainTable, ...styles.padding2 }}>
                      <Text style={styles.textBold}>Note</Text>

                      <Text style={styles.text}>
                        {datas?.note ? datas?.note : "-"}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>

            <View style={{ ...styles.table }}>
              <View style={styles.tableRow1}>
                <View style={styles.tableCol29}>
                  <Text style={styles.tableCell2}>Sl/No</Text>
                </View>
                <View
                  style={
                    _.some(datas?.items, (item) => item.discount > 0)
                      ? styles.tableCol10
                      : styles.tableCol20
                  }
                >
                  <Text style={styles.tableCell2}>Items</Text>
                </View>
                <View style={styles.tableCol9}>
                  <Text style={styles.tableCell2}>Brand</Text>
                </View>
                {/* {/* <View style={styles.tableCol}>
            <Text style={styles.tableCell}>Period</Text>
          </View> */}
                <View style={styles.tableCol7}>
                  <Text style={styles.tableCell2}>Qty</Text>
                </View>
                <View style={styles.tableCol11}>
                  <Text style={styles.tableCell2}>Rate</Text>
                </View>
                {_.some(datas?.items, (item) => item.discount > 0) && (
                  <View style={styles.tableCol8}>
                    <Text style={styles.tableCell2}>Discount%</Text>
                  </View>
                )}
                <View style={styles.tableCol12}>
                  <Text style={styles.tableCell2}>Gst%</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell2}>Total</Text>
                </View>
              </View>
              {datas?.items?.map((item: any, index: number) => (
                <View style={styles.tableRow1}>
                  <View style={styles.tableCol29}>
                    <Text style={styles.text}>{index + 1}</Text>
                  </View>
                  <View
                    style={
                      _.some(datas?.items, (item) => item.discount > 0)
                        ? styles.tableCol10
                        : styles.tableCol20
                    }
                  >
                    <Text style={styles.text}>{item?.item_information}</Text>
                  </View>
                  <View style={styles.tableCol9}>
                    <Text style={styles.text}>{item?.brand}</Text>
                  </View>
                  {/* {/* <View style={styles.tableCol}>
            <Text style={styles.tableCell}>2019-02-20 - 2020-02-19</Text>
          </View> */}
                  <View style={styles.tableCol7}>
                    <Text style={styles.text}>{item?.qty}</Text>
                  </View>
                  <View style={styles.tableCol11}>
                    <Text style={styles.text}>{item?.rate}</Text>
                  </View>
                  {item?.discount && (
                    <View style={styles.tableCol8}>
                      <Text style={styles.text}>
                        {item?.discount ? item?.discount : "-"}
                      </Text>
                    </View>
                  )}
                  <View style={styles.tableCol12}>
                    <Text style={styles.text}>{item?.gst}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.text}>{item?.total}</Text>
                  </View>
                </View>
              ))}

              <View style={styles.tableRow1}>
                <View style={styles.tableCol13}>
                  <Text style={styles.tableCell4}>Shipping Cost</Text>
                </View>
                <View style={styles.tableCol30}>
                  <Text style={styles.text}>{datas?.shipping_cost}</Text>
                </View>
              </View>
              <View style={styles.tableRow1}>
                <View style={styles.tableCol13}>
                  <Text style={styles.tableCell4}>Grand Total</Text>
                </View>
                <View style={styles.tableCol15}>
                  <Text style={styles.tableCell5}>{datas?.grand_total}</Text>
                </View>
              </View>
            </View>
            <View style={styles.table1}>
              <View style={{ ...styles.declaration, ...styles.padding2 }}>
                <Text style={styles.textBold}>Bank Details</Text>
                <Text style={styles.text}>Bank Name : ICICI BANK</Text>
                <Text style={styles.text}>A/C NO : 109305000723</Text>
                <Text style={styles.text}>IFSC : ICIC0001093</Text>
                <Text style={styles.text}>BRANCH : PUZHAKKAL</Text>
              </View>
            </View>
            <View style={styles.table1}>
              <View style={{ ...styles.declaration1, ...styles.padding2 }}>
                <Text style={styles.textBold}>Declaration</Text>
                <Text style={styles.text}>
                  We declare that this invoice shows the actual price of the
                  goods described and that all particulars are true and correct.
                </Text>
              </View>
              <View style={{ ...styles.declaration1, ...styles.padding2 }}>
                <Text style={styles.textBold}>
                  for JAWS TECH ENGINEERING - (2022-2023)
                </Text>
                <Text style={styles.text}>Authorised Signatory</Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    </>
  );
};
