import {
  Avatar,
  Button,
  Col,
  Row,
  Space,
  Upload,
  Form,
  DatePicker,
  Input,
  Divider,
  Dropdown,
  Menu,
  message,
  Empty,
  Skeleton,
  Tooltip,
} from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./index.css";
import ImgCrop from "antd-img-crop";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { useState } from "react";
import { CommonAvatar } from "../../CommonAvatar";
import { CommonButton } from "../../CommonButton/button";
import { Text } from "../../CommonFont/text";
import {
  useDeleteLeadMutation,
  useEditLeadMutation,
} from "../../../services/api";
import moment from "moment";
import TextArea from "antd/es/input/TextArea";
import { log } from "console";
interface ICreateSales {
  setOpen?: (data: boolean) => void;
  setIsEdit?: (data: boolean) => void;
  datas?: any;
  isEdit?: boolean;
  id?: string;
  salesLoading?: boolean;
}
export const SalesDetailById: React.FC<ICreateSales> = ({
  setOpen,
  datas,
  isEdit,
  setIsEdit,
  salesLoading,
  id,
}) => {
  const navigate = useNavigate();
  const [isAdd, setIsAdd] = useState(true);
  const [isSave, setIsSave] = useState(false);
  const [editLead, { data: editLeads, isLoading: editLeadLoading }] =
    useEditLeadMutation();
  const [form] = Form.useForm();

  const validationSchema = Yup.object().shape({
    date: Yup.date().required("This date is required"),
    note: Yup.string().required("Note is required"),
    next_follow_up_date: Yup.date().required("Follow Up date is required"),
  });

  const formik = useFormik({
    initialValues: {
      date: undefined,
      note: "",
      next_follow_up_date: undefined,
    },
    validationSchema,
    onSubmit: async (values) => {
      const followUp = datas?.follow_up?.length > 0 ? [...datas.follow_up] : [];
      const nextFollowUp = {
        date: values.date,
        notes: values.note,
        next_follow_up_date: values.next_follow_up_date,
        title:
          datas?.follow_up?.length === 0
            ? 1
            : datas?.follow_up?.length === 1
            ? 2
            : datas?.follow_up?.length === 2
            ? 3
            : "",
      };

      if (followUp.length === 0) {
        followUp.push(nextFollowUp);
      } else if (followUp.length === 1) {
        followUp.push(nextFollowUp);
      } else if (followUp.length === 2) {
        followUp.push(nextFollowUp);
      }

      const editParams = {
        body: { follow_up: followUp },
        id: sales_id,
      };
      await editLead(editParams)
        .unwrap()
        .then((data) => {
          setIsSave(false);
          setIsAdd(true);
          setOpen && setOpen(false);

          form.resetFields();
          setOpen && setOpen(false);
          message.success(data?.message);
        })

        .catch((err) => {
          message.error(err?.data?.message);
        });
    },
  });
  const [searchParams, setSearchParams] = useSearchParams();

  const search = searchParams.get("search");
  const sales_id = searchParams.get("id");
  const [trigger, { data: deleteData, isLoading }] = useDeleteLeadMutation();
  const handleDelete = async () => {
    await trigger(sales_id)
      .unwrap()
      .then((data) => {
        message.success(data?.message);
        navigate("/sales");
        setOpen && setOpen(false);
      })
      .catch((err) => {
        message.error(err?.data?.message);
      });
  };
  const widgetMenu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          setIsEdit && setIsEdit(true);
          navigate(`/add-lead?id=${datas?.id}`);
        }}
      >
        <div>Edit</div>
      </Menu.Item>
      {/* <Menu.Item
        onClick={() => {
          handleDelete();
        }}
      >
        <div className="logout-div">
          <Space>
            <span className="logout-text ">Delete</span>
          </Space>
        </div>
      </Menu.Item> */}
    </Menu>
  );

  return (
    <>
      {salesLoading ? (
        <Skeleton avatar paragraph={{ rows: 8 }} />
      ) : datas ? (
        <div>
          <div className="sales-detail-border-main ">
            <Row gutter={16} className="p5">
              <Col span={6}>
                <CommonAvatar text={datas?.company_name} size={100} />
              </Col>
              <Col span={16} className="flex flex-col gap-2">
                <div className="flex justify-between ">
                  <div className="sales-detail-title-2">
                    {datas?.company_name}
                  </div>
                  <Dropdown
                    overlay={widgetMenu}
                    trigger={["click"]}
                    className="dropdown-click"
                  >
                    <img src="/assets/more.svg"></img>
                  </Dropdown>
                </div>
                <div className="flex gap-2 ">
                  <img src="/assets/message.svg"></img>
                  <div>{datas?.email ? datas?.email : "-"}</div>
                </div>
                <div className="flex gap-2">
                  <img src="/assets/phone.svg"></img>
                  <div>{datas?.phone}</div>
                </div>
                <div className="flex gap-2">
                  <img
                    src="/assets/locattion.svg"
                    className="img-align-self"
                  ></img>
                  <div className="break-all">{datas?.address}</div>
                </div>

                <CommonButton
                  variant="wealay-primary-btn"
                  className="sales-detail-btn-clr w-content"
                  value=" Create Quotations"
                  onClick={() => {
                    navigate(`/create-quotation?id=${sales_id}`);
                  }}
                />
              </Col>
              {/* <Col span={2}>
                <Dropdown
                  overlay={widgetMenu}
                  trigger={["click"]}
                  className="dropdown-click"
                >
                  <img src="/assets/more.svg"></img>
                </Dropdown>
              </Col> */}
            </Row>
            <Row>
              <Col span={24}>
                <div className="sales-detail-border flex flex-col gap-2">
                  <div className="sales-detail-placeholder">Lead Name</div>
                  <div className="sales-detail-sub-title ">
                    {datas?.lead_name}
                  </div>
                </div>
              </Col>
              <Col span={24} md={{ span: 12 }}>
                <div className="sales-detail-border flex flex-col gap-2">
                  <div className="sales-detail-placeholder">Code</div>
                  <div className="sales-detail-sub-title ">
                    {/* {datas?.sales_lead_id} */}
                    <Tooltip title={datas?.sales_lead_id}>
                      {datas?.sales_lead_id?.length > 25
                        ? datas?.sales_lead_id.slice(0, 25).concat("...")
                        : datas?.sales_lead_id}
                    </Tooltip>
                  </div>
                </div>
                <div className="sales-detail-border flex flex-col gap-2">
                  <div className="sales-detail-placeholder">Client Name</div>
                  <div className="sales-detail-sub-title ">
                    {datas?.client_name}
                  </div>
                </div>
                <div className="sales-detail-border flex flex-col gap-2">
                  <div className="sales-detail-placeholder">Region</div>
                  <div className="sales-detail-sub-title ">
                    {datas?.region_name}
                  </div>
                </div>
                <div className="sales-detail-border flex flex-col gap-2">
                  <div className="sales-detail-placeholder">Source</div>
                  <div className="sales-detail-sub-title ">
                    {datas?.source_name}
                  </div>
                </div>
                <div className="sales-detail-border flex flex-col gap-2">
                  <div className="sales-detail-placeholder">Industry</div>
                  <div className="sales-detail-sub-title ">
                    {datas?.industry_name}
                  </div>
                </div>
              </Col>
              <Col span={24} md={{ span: 12 }}>
                {/* <div className="sales-detail-border flex flex-col gap-2">
                  <div className="sales-detail-placeholder">Nature</div>
                  <div className="sales-detail-sub-title ">
                    {datas?.nature_name}
                  </div>
                </div> */}
                <div className="sales-detail-border flex flex-col gap-2">
                  <div className="sales-detail-placeholder">Designation</div>
                  <div className="sales-detail-sub-title ">
                    {datas?.designation_name}
                  </div>
                </div>
                <div className="sales-detail-border flex flex-col gap-2">
                  <div className="sales-detail-placeholder">GST</div>
                  <div className="sales-detail-sub-title ">
                    {datas?.gst ? datas?.gst : "-"}
                  </div>
                </div>
                <div className="sales-detail-border flex flex-col gap-2">
                  <div className="sales-detail-placeholder">Type</div>
                  <div className="sales-detail-sub-title ">
                    {datas?.type_name}
                  </div>
                </div>
                <div className="sales-detail-border flex flex-col gap-2">
                  <div className="sales-detail-placeholder">
                    Enquiry Category
                  </div>
                  <div className="sales-detail-sub-title ">
                    {datas?.enquiry_category_name}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="sales-detail-title">
            <Row>
              <Col span={14} className="sales-detail-border ">
                <Text variant="wearlay-paragraph-bold" value="Items" />
              </Col>
              <Col span={5} className="sales-detail-border ">
                <Text variant="wearlay-paragraph-bold" value="Brand" />
              </Col>

              <Col span={5} className="sales-detail-border ">
                <Text variant="wearlay-paragraph-bold" value="Qty" />
              </Col>
            </Row>
            {datas?.items?.map((item: any) => (
              <Row>
                <Col span={14} className="sales-detail-border ">
                  {/* <Text
                    variant="wearlay-paragraph"
                    value={item?.item_information}
                  /> */}
                  <div className="wearlay-paragraph">
                    <Tooltip title={item?.item_information}>
                      {item?.item_information?.length > 15
                        ? item?.item_information?.slice(0, 15).concat("...")
                        : item?.item_information}
                    </Tooltip>
                  </div>
                </Col>
                <Col span={5} className="sales-detail-border ">
                  {/* <Text variant="wearlay-paragraph" value={item?.brand} /> */}
                  <div className="wearlay-paragraph">
                    <Tooltip title={item?.brand}>
                      {item?.brand?.length > 5
                        ? item?.brand?.slice(0, 5).concat("...")
                        : item?.brand}
                    </Tooltip>
                  </div>
                </Col>

                <Col span={5} className="sales-detail-border ">
                  <Text variant="wearlay-paragraph" value={item?.qty} />
                </Col>
              </Row>
            ))}
          </div>
          <Row className=" sales-detail-border flex flex-col break-all">
            <Text variant="wearlay-label-bold" value="Note" />
            <Text variant="wearlay-paragraph" value={datas?.note} />
          </Row>
          <div>
            <Text
              variant="wearlay-headline-2"
              value="Follow Up Status"
              className="create-quotation-margin-inline"
            />
          </div>
          <Space direction="vertical" className="w-full">
            <Form layout="vertical">
              <div className=" sales-detail-border">
                <div className="flex justify-between">
                  <div>
                    <Text
                      value=" Follow Up 1"
                      variant="wearlay-subtitle"
                      className="sales-follow-up"
                    />
                  </div>

                  <div>
                    {datas?.follow_up?.length === 0 && isAdd && (
                      <CommonButton
                        value="Add Note"
                        variant="wealay-secondary-btn"
                        onClick={() => {
                          setIsAdd(false);
                          setIsSave(true);
                        }}
                      />
                    )}
                    {!datas?.follow_up[0] && isSave && (
                      <CommonButton
                        value="Save"
                        variant="wealay-primary-btn"
                        onClick={() => {
                          formik.handleSubmit();
                        }}
                        isLoading={editLeadLoading}
                      />
                    )}
                  </div>
                </div>
                <Divider />
                {!datas?.follow_up[0] && isSave && (
                  <Form layout="vertical">
                    <Form.Item
                      label="Date"
                      required
                      name="noteDate"
                      className="w-full"
                      hasFeedback
                      validateStatus={
                        formik.errors.date && formik.touched.date
                          ? "error"
                          : undefined
                      }
                      help={
                        formik.errors.date &&
                        formik.touched.date &&
                        formik.errors.date
                      }
                    >
                      <DatePicker
                        className="w-full"
                        placeholder="Select Date"
                        onBlur={formik.handleBlur}
                        onChange={(value) =>
                          formik.setFieldValue(
                            "date",
                            value ? value.format("YYYY-MM-DD") : ""
                          )
                        }
                        value={dayjs(formik.values.date)}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Note"
                      required
                      name="note"
                      hasFeedback
                      validateStatus={
                        formik.errors.note && formik.touched.note ? "error" : ""
                      }
                      help={
                        formik.errors.note && formik.touched.note
                          ? formik.errors.note
                          : undefined
                      }
                    >
                      <TextArea
                        placeholder="Note"
                        value={formik.values.note}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Next Follow up Date*"
                      required
                      name="next_follow_up_date"
                      hasFeedback
                      validateStatus={
                        formik.errors.next_follow_up_date &&
                        formik.touched.next_follow_up_date
                          ? "error"
                          : undefined
                      }
                      help={
                        formik.errors.next_follow_up_date &&
                        formik.touched.next_follow_up_date &&
                        formik.errors.next_follow_up_date
                      }
                    >
                      <DatePicker
                        className="w-full"
                        placeholder="Select Date"
                        onBlur={formik.handleBlur}
                        onChange={(value) =>
                          formik.setFieldValue(
                            "next_follow_up_date",
                            value ? value.format("YYYY-MM-DD") : ""
                          )
                        }
                        value={dayjs(formik.values.next_follow_up_date)}
                        format="DD-MM-YYYY"
                        disabledDate={(current) =>
                          current && current < moment().endOf("day")
                        }
                      />
                    </Form.Item>
                  </Form>
                )}
                {datas?.follow_up[0] && (
                  <div className="create-quotation-border">
                    <div className="flex flex-col gap-2">
                      <Text
                        value="Date"
                        variant="wearlay-label-bold"
                        className="create-quotation-text-color"
                      ></Text>
                      <Text
                        value={moment(datas?.follow_up[0]?.date).format(
                          "DD-MM-YYYY"
                        )}
                        variant="wearlay-paragraph-bold"
                      ></Text>
                    </div>
                    <Divider />
                    <div className="flex flex-col gap-2">
                      <Text
                        value="Note"
                        variant="wearlay-label-bold"
                        className="create-quotation-text-color"
                      ></Text>
                      <Text
                        value={
                          datas?.follow_up[0]?.notes
                            ? datas?.follow_up[0]?.notes
                            : "-"
                        }
                        variant="wearlay-paragraph"
                      ></Text>
                    </div>
                    <Divider />
                    <div className="flex flex-col gap-2">
                      <Text
                        value="Next Follow up Date"
                        variant="wearlay-label-bold"
                        className="create-quotation-text-color"
                      ></Text>
                      <Text
                        value={moment(
                          datas?.follow_up[0]?.next_follow_up_date
                        ).format("DD-MM-YYYY")}
                        variant="wearlay-paragraph-bold"
                      ></Text>
                    </div>
                  </div>
                )}
              </div>
            </Form>

            <div className="flex justify-between  items-center">
              <div>
                <Text
                  value=" Follow Up 2"
                  variant="wearlay-subtitle"
                  className="create-quotation-margin-bottom create-quotation-margin-top "
                />
              </div>
              <div>
                {datas?.follow_up.length === 1 && isAdd && (
                  <CommonButton
                    value="Add Note"
                    variant="wealay-secondary-btn"
                    onClick={() => {
                      setIsAdd(false);
                      setIsSave(true);
                    }}
                  />
                )}
                {datas?.follow_up.length === 1 && isSave && (
                  <CommonButton
                    value="Save"
                    variant="wealay-primary-btn"
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                    isLoading={editLeadLoading}
                  />
                )}
              </div>
            </div>
            {datas?.follow_up.length === 1 && isSave && (
              <Form layout="vertical">
                <Form.Item
                  label="Date"
                  required
                  name="noteDate"
                  hasFeedback
                  validateStatus={
                    formik.errors.date && formik.touched.date
                      ? "error"
                      : undefined
                  }
                  help={
                    formik.errors.date &&
                    formik.touched.date &&
                    formik.errors.date
                  }
                >
                  <DatePicker
                    className="w-full"
                    placeholder="Select Date"
                    onBlur={formik.handleBlur}
                    onChange={(value) =>
                      formik.setFieldValue(
                        "date",
                        value ? value.format("YYYY-MM-DD") : ""
                      )
                    }
                    value={dayjs(formik.values.date)}
                  />
                </Form.Item>
                <Form.Item
                  label="Note"
                  required
                  name="note"
                  hasFeedback
                  validateStatus={
                    formik.errors.note && formik.touched.note ? "error" : ""
                  }
                  help={
                    formik.errors.note && formik.touched.note
                      ? formik.errors.note
                      : undefined
                  }
                >
                  <TextArea
                    placeholder="Note"
                    value={formik.values.note}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Form.Item>
                <Form.Item
                  label="Next Follow up Date*"
                  required
                  name="next_follow_up_date"
                  hasFeedback
                  validateStatus={
                    formik.errors.next_follow_up_date &&
                    formik.touched.next_follow_up_date
                      ? "error"
                      : undefined
                  }
                  help={
                    formik.errors.next_follow_up_date &&
                    formik.touched.next_follow_up_date &&
                    formik.errors.next_follow_up_date
                  }
                >
                  <DatePicker
                    className="w-full"
                    placeholder="Select Date"
                    onBlur={formik.handleBlur}
                    onChange={(value) =>
                      formik.setFieldValue(
                        "next_follow_up_date",
                        value ? value.format("YYYY-MM-DD") : ""
                      )
                    }
                    value={dayjs(formik.values.next_follow_up_date)}
                    format="DD-MM-YYYY"
                    disabledDate={(current) =>
                      current && current < moment().endOf("day")
                    }
                  />
                </Form.Item>
              </Form>
            )}
            {datas?.follow_up[1] && (
              <div className="create-quotation-border">
                <div className="flex flex-col gap-2">
                  <Text
                    value="Date"
                    variant="wearlay-label-bold"
                    className="create-quotation-text-color"
                  ></Text>
                  <Text
                    value={moment(datas?.follow_up[1]?.date).format(
                      "DD-MM-YYYY"
                    )}
                    variant="wearlay-paragraph-bold"
                  ></Text>
                </div>
                <Divider />
                <div className="flex flex-col gap-2">
                  <Text
                    value="Note"
                    variant="wearlay-label-bold"
                    className="create-quotation-text-color"
                  ></Text>
                  <Text
                    value={
                      datas?.follow_up[1]?.notes
                        ? datas?.follow_up[1]?.notes
                        : "-"
                    }
                    variant="wearlay-paragraph"
                  ></Text>
                </div>
                <Divider />
                <div className="flex flex-col gap-2">
                  <Text
                    value="Next Follow up Date"
                    variant="wearlay-label-bold"
                    className="create-quotation-text-color"
                  ></Text>
                  <Text
                    value={moment(
                      datas?.follow_up[1]?.next_follow_up_date
                    ).format("DD-MM-YYYY")}
                    variant="wearlay-paragraph-bold"
                  ></Text>
                </div>
              </div>
            )}
            <div className="flex justify-between  items-center">
              <div>
                <Text
                  value=" Follow Up 3"
                  variant="wearlay-subtitle"
                  className="create-quotation-margin-bottom create-quotation-margin-top "
                />
              </div>
              <div>
                {datas?.follow_up.length === 2 && isAdd && (
                  <CommonButton
                    value="Add Note"
                    variant="wealay-secondary-btn"
                    onClick={() => {
                      setIsAdd(false);
                      setIsSave(true);
                    }}
                  />
                )}
                {datas?.follow_up.length === 2 && isSave && (
                  <CommonButton
                    value="Save"
                    variant="wealay-primary-btn"
                    onClick={() => {
                      // setIsSave(false);
                      formik.handleSubmit();
                    }}
                    isLoading={editLeadLoading}
                  />
                )}
              </div>
            </div>
            {datas?.follow_up.length === 2 && isSave && (
              <Form layout="vertical">
                <Form.Item
                  label="Date"
                  required
                  name="noteDate"
                  hasFeedback
                  validateStatus={
                    formik.errors.date && formik.touched.date
                      ? "error"
                      : undefined
                  }
                  help={
                    formik.errors.date &&
                    formik.touched.date &&
                    formik.errors.date
                  }
                >
                  <DatePicker
                    className="w-full"
                    placeholder="Select Date"
                    onBlur={formik.handleBlur}
                    onChange={(value) =>
                      formik.setFieldValue(
                        "date",
                        value ? value.format("YYYY-MM-DD") : ""
                      )
                    }
                    value={dayjs(formik.values.date)}
                  />
                </Form.Item>
                <Form.Item
                  label="Note"
                  required
                  name="note"
                  hasFeedback
                  validateStatus={
                    formik.errors.note && formik.touched.note ? "error" : ""
                  }
                  help={
                    formik.errors.note && formik.touched.note
                      ? formik.errors.note
                      : undefined
                  }
                >
                  <TextArea
                    placeholder="Note"
                    value={formik.values.note}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Form.Item>
                <Form.Item
                  label="Next Follow up Date*"
                  required
                  name="next_follow_up_date"
                  hasFeedback
                  validateStatus={
                    formik.errors.next_follow_up_date &&
                    formik.touched.next_follow_up_date
                      ? "error"
                      : undefined
                  }
                  help={
                    formik.errors.next_follow_up_date &&
                    formik.touched.next_follow_up_date &&
                    formik.errors.next_follow_up_date
                  }
                >
                  <DatePicker
                    className="w-full"
                    placeholder="Select Date"
                    onBlur={formik.handleBlur}
                    onChange={(value) =>
                      formik.setFieldValue(
                        "next_follow_up_date",
                        value ? value.format("YYYY-MM-DD") : ""
                      )
                    }
                    value={dayjs(formik.values.next_follow_up_date)}
                    format="DD-MM-YYYY"
                    disabledDate={(current) =>
                      current && current < moment().endOf("day")
                    }
                  />
                </Form.Item>
              </Form>
            )}
            {datas?.follow_up[2] && (
              <div className="create-quotation-border">
                <div className="flex flex-col gap-2">
                  <Text
                    value="Date"
                    variant="wearlay-label-bold"
                    className="create-quotation-text-color"
                  ></Text>
                  <Text
                    value={moment(datas?.follow_up[2]?.date).format(
                      "DD-MM-YYYY"
                    )}
                    variant="wearlay-paragraph-bold"
                  ></Text>
                </div>
                <Divider />
                <div className="flex flex-col gap-2">
                  <Text
                    value="Note"
                    variant="wearlay-label-bold"
                    className="create-quotation-text-color"
                  ></Text>
                  <Text
                    value={
                      datas?.follow_up[2]?.notes
                        ? datas?.follow_up[2]?.notes
                        : "-"
                    }
                    variant="wearlay-paragraph"
                  ></Text>
                </div>
                <Divider />
                <div className="flex flex-col gap-2">
                  <Text
                    value="Next Follow up Date"
                    variant="wearlay-label-bold"
                    className="create-quotation-text-color"
                  ></Text>
                  <Text
                    value={moment(
                      datas?.follow_up[2]?.next_follow_up_date
                    ).format("DD-MM-YYYY")}
                    variant="wearlay-paragraph-bold"
                  ></Text>
                </div>
              </div>
            )}
          </Space>
        </div>
      ) : (
        <Empty className="h-full justify-center align-center flex flex-col" />
      )}
    </>
  );
};
