import { ArrowLeftOutlined, MinusCircleOutlined } from "@ant-design/icons";
import {
  Col,
  Divider,
  Input,
  Row,
  Form,
  Button,
  Space,
  DatePicker,
  message,
} from "antd";
import { useFormik, FormikErrors } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { CommonButton } from "../../components/CommonButton/button";
import "./index.css";
import { Text } from "../../components/CommonFont/text";
import { useEffect, useState } from "react";
import moment from "moment";
import {
  useCreateContactsMutation,
  useCreateCreateNpdMutation,
  useCreateCreateQuotationMutation,
  useCreateNpdQuotationMutation,
  useEditQuotationMutation,
  useGetDiscountQuery,
  useGetLeadByIdQuery,
  useGetNpdByIdQuery,
  useGetQuotationByIdQuery,
  useGetSalesAfterNpdQuotationQuery,
  useGetSalesAfterQuotationQuery,
} from "../../services/api";
export const CreateQuotion = () => {
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState("");
  const [rate, setRate] = useState("");
  const [discount, setDisount] = useState("");
  const [gst, setGst] = useState("");
  const userId = localStorage.getItem("user_id");
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const validationSchema = Yup.object().shape({
    material_grade: Yup.string().required("This field is required"),
    drawing_number: Yup.string().required("This field is required"),
    mould_code: Yup.string().required("This field is required"),
    design_code: Yup.string().required("This field is required"),
    date: Yup.date().required("This field is required"),
    note: Yup.string(),
    delivery_period: Yup.string().required("This field is required"),
    // .matches(
    //   /^[a-zA-Z0-9\s]+$/,
    //   "Only alphanumeric characters and spaces are allowed"
    // ),
    grand_total: Yup.string().required("This field is required"),
    follow_up: Yup.array().of(
      Yup.object().shape({
        date: Yup.date().required("This date is required"),
        notes: Yup.string().required("Note is required"),
        next_follow_up_date: Yup.date().required("Follow Up date is required"),
      })
    ),
    items: Yup.array().of(
      Yup.object().shape({
        item_information: Yup.string().required("Item Information is required"),
        qty: Yup.string()
          .required("Quantity is required")
          .matches(/^[0-9]+$/, "Only numbers are allowed"),
        rate: Yup.string()
          .required("Rate is required")
          .matches(
            /^[0-9.]+$/,
            "Only numbers and dot (decimal point) are allowed"
          ),
        brand: Yup.string()
          .required("Brand is required")
          .matches(
            /^[a-zA-Z0-9\s]+$/,
            "Only alphanumeric characters and spaces are allowed"
          ),
        total: Yup.number(),

        // discount: Yup.string(),
        gst: Yup.string(),
        // .required("Gst is required")
        // .matches(
        //   /^[0-9.]+$/,
        //   "Only numbers and dot (decimal point) are allowed"
        // ),
      })
    ),
    // salesDiscount: Yup.string().required("Sales discount is required"),
    shipping_cost: Yup.string()
      .required("Shipping cost is required")
      .matches(/^[0-9.]+$/, "Only numbers and dot (decimal point) are allowed"),
  });

  const [
    createQuotation,
    { data: createquotationData, isLoading: createQuotationLoading },
  ] = useCreateCreateQuotationMutation();
  const [
    createNpdQuotation,
    { data: createNpdQuotationData, isLoading: createNpdQuotationLoading },
  ] = useCreateNpdQuotationMutation();
  const [
    editQuotation,
    { data: editQuotationData, isLoading: editQuotationLoading },
  ] = useEditQuotationMutation();
  const { data: discountData, isLoading: discountLoading } =
    useGetDiscountQuery("");

  const formik = useFormik({
    initialValues: {
      material_grade: "",
      drawing_number: "",
      mould_code: "",
      design_code: "",
      delivery_period: "",
      grand_total: "",
      // salesDiscount: "",
      shipping_cost: "",
      date: "",
      note: "",
      items: [
        {
          item_information: "",
          qty: "",
          rate: "",
          // discount: discountData?.data[0]?.discount_percentage,

          brand: "",
          total: "",

          gst: "18",
        },
      ],
      follow_up: [
        {
          date: undefined,
          notes: "",
          next_follow_up_date: undefined,
        },
      ],
    },
    validationSchema,
    onSubmit: async (values) => {
      const followUpData = values.follow_up.map((followUpItem: any) => ({
        title:
          (data || quotationByIdData)?.data?.follow_up?.length === 0
            ? 1
            : (data || quotationByIdData)?.data?.follow_up?.length === 1
            ? 1
            : (data || quotationByIdData)?.data?.follow_up?.length === 2
            ? 2
            : (data || quotationByIdData)?.data?.follow_up?.length === 3
            ? 3
            : 1,
        date: followUpItem.date,
        notes: followUpItem.notes,
        next_follow_up_date: followUpItem.next_follow_up_date,
      }));
      const createParams = {
        body: { ...values, lead: Number(userId) },
        id: npd_id ? npd_id : id,
        follow_up: followUpData,
      };
      const editParams = {
        body: { ...values, lead: Number(userId) },
        id: quotationId,
        follow_up: followUpData,
      };
      await (npd_id && quotationId
        ? editQuotation(editParams)
        : id && quotationId
        ? editQuotation(editParams)
        : npd_id
        ? createNpdQuotation(createParams)
        : createQuotation(createParams)
      )
        .unwrap()
        .then((data) => {
          message.success(data?.message);
          navigate("/quotation");
        })

        .catch((err) => {
          message.error(err?.data?.message);
        });
      // console.log("this iss create", createParams);
    },
  });
  const [searchParams, setSearchParams] = useSearchParams();

  const id = searchParams.get("id");
  const npd_id = searchParams.get("npd_id");
  const quotationId = searchParams.get("quotation_id");
  const { data: quotationByIdData, isLoading: quotationLoading } =
    useGetQuotationByIdQuery(quotationId);

  const { data: npdData, isLoading: npdLoading } = useGetNpdByIdQuery(npd_id);

  const { data, isLoading } = useGetLeadByIdQuery(id);
  const { data: npdDatas, isLoading: npdLoadings } =
    useGetSalesAfterNpdQuotationQuery(npd_id);

  const { data: salesData, isLoading: salesLoading } =
    useGetSalesAfterQuotationQuery(id);
  const formattedDate = dayjs(formik.values.date).format("YYYY-MM-DD");
  const dateObject = dayjs(formattedDate);
  useEffect(() => {
    const currentDate = moment().format("YYYY-MM-DD");
    formik.setFieldValue("date", currentDate);
    formik.setFieldValue(`follow_up[0].date`, currentDate);
  }, []);
  interface Product {
    item_information: string;
    quantity: string;
    rate: string;
    discount: string;
    gst: string;
  }

  interface FormikErrorsType {
    items: Array<FormikErrors<Product>>;
  }
  const [total, setTotal] = useState("");

  const calculateTotal = (
    quantity: any,
    rate: any,
    // discount: any,
    gst: any
  ) => {
    const q = parseFloat(quantity);
    const r = parseFloat(rate);
    const d = parseFloat(discountData?.data[0]?.discount_percentage);
    const g = parseFloat("18");

    const subtotal = q * r;
    const discountedAmount = subtotal - (subtotal * d) / 100;
    const discounded = (subtotal * g) / 100;
    const totalWithGst = discounded;
    const totalWithGST = subtotal + totalWithGst;
    // setTotal(totalWithGST.toFixed(2));

    return totalWithGST.toFixed(2);
  };

  const [grandTotal, setGrandTotal] = useState("");
  const [totalDiscount, setTotalDiscount] = useState<any>();

  const [formiks, setFormik] = useState<any>(null);
  useEffect(() => {
    if (formik) {
      const { values } = formik;
      // const salesDiscount = parseFloat(formik.values.salesDiscount);
      const shippingCost = parseFloat(formik.values.shipping_cost);

      let newGrandTotal = 0;
      let totalDiscount = 0;

      values?.items?.forEach((product, index) => {
        const quantity = parseFloat(product.qty);
        const rate = parseFloat(product.rate);
        // const discount = parseFloat(discountData?.data[0]?.discount_percentage);
        const gst = parseFloat("18");

        const subtotal = quantity * rate;
        const discounded = (subtotal * gst) / 100;
        const totalWithGst = discounded;

        // const discountedAmount = subtotal - (subtotal * discount) / 100;
        const totalWithGST = subtotal + totalWithGst;

        newGrandTotal += totalWithGST;
        // totalDiscount += discounded;
      });
      // setTotalDiscount(
      //   salesDiscount ? totalDiscount + salesDiscount : totalDiscount
      // );
      setGrandTotal((newGrandTotal + shippingCost).toFixed(2));
    }
  }, [formik]);
  const handleRemove = (index: any) => {
    const { setFieldValue } = formik;
    const { items } = formik.values;

    const updatedItems = [...items];
    updatedItems.splice(index, 1);

    setFieldValue("items", updatedItems);
  };
  // useEffect(() => {
  //   if (discountData || grandTotal || data) {
  //     const updatedItems = formik?.values?.items?.map((item, index) => {
  //       const total = calculateTotal(
  //         parseFloat(item?.qty),
  //         parseFloat(item?.rate),
  //         parseFloat(item?.gst)
  //       );
  //       const formattedTotal = isNaN(Number(total))
  //         ? "0.00"
  //         : parseFloat(total).toFixed(2);
  //       const itemInformation =
  //         data?.data?.items?.[index]?.item_information || "";
  //       const brand = data?.data?.items?.[index]?.brand || "";
  //       const qty = data?.data?.items?.[index]?.qty || "";
  //       return {
  //         ...item,
  //         discount: discountData?.data[0]?.discount_percentage || "",
  //         item_information: itemInformation,
  //         brand: brand,
  //         qty: qty,
  //         // item_information:
  //         //   data?.data?.items?.map((map: any) => map?.item_information) || "",
  //         // brand: data?.data?.items?.map((map: any) => map?.brand) || "",
  //         // qty: data?.data?.items?.map((map: any) => map?.qty) || "",

  //         total: Number(formattedTotal),
  //       };
  //     });

  //     // setTotalDiscount(updatedItems);

  //     formik.setFieldValue("items", updatedItems);
  //     formik.setFieldValue("grand_total", grandTotal);
  //   }
  // }, [discountData, grandTotal, data]);

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission on Enter key press
    }
  };
  useEffect(() => {
    if (grandTotal) {
      const updatedItems = formik.values.items?.map(
        (item: any, index: number) => {
          const rate = parseFloat(
            formik.values.items?.[index]?.rate || item?.rate || "0"
          );
          const gst = parseFloat("18");
          const total = calculateTotal(parseFloat(item?.qty), rate, gst);
          const formattedTotal = isNaN(Number(total))
            ? "0.00"
            : parseFloat(total).toFixed(2);

          return {
            ...formik?.values?.items?.[index],
            // discount: discountData?.data[0]?.discount_percentage || "",
            // item_information: item?.item_information && item?.item_information,
            // brand: item?.brand || "",
            // qty: item?.qty || "",
            total: Number(formattedTotal),
          };
        }
      );

      formik.setFieldValue("items", updatedItems);
      formik.setFieldValue("grand_total", grandTotal);
    }
  }, [grandTotal]);

  useEffect(() => {
    if (data || quotationByIdData) {
      (quotationByIdData || data)?.data?.items?.forEach(
        (item: any, index: number) => {
          const itemName = `items[${index}].item_information`;
          const itemBrand = `items[${index}].brand`;
          const itemQty = `items[${index}].qty`;
          const itemRate = `items[${index}].rate`;
          const itemGst = `items[${index}].gst`;

          formik.setFieldValue(itemName, item.item_information);
          formik.setFieldValue(itemBrand, item.brand);
          formik.setFieldValue(itemQty, item.qty);
          formik.setFieldValue(itemRate, item.rate);

          formik.setFieldValue(itemGst, "18");
        }
      );
      quotationByIdData?.data?.follow_up?.forEach(
        (item: any, index: number) => {
          const date = `follow_up[${index}].date`;
          const next_follow_up_date = `follow_up[${index}].next_follow_up_date`;
          const notes = `follow_up[${index}].notes`;
          formik.setFieldValue(date, item?.date);
          formik.setFieldValue(next_follow_up_date, item.next_follow_up_date);
          formik.setFieldValue(notes, item.notes);
        }
      );
    }
  }, [data, quotationByIdData]);

  useEffect(() => {
    if (data || quotationByIdData) {
      formik.setFieldValue("note", quotationByIdData?.data?.note);
      formik.setFieldValue(
        "material_grade",
        quotationByIdData?.data?.material_grade
      );
      formik.setFieldValue(
        "drawing_number",
        quotationByIdData?.data?.drawing_number
      );
      formik.setFieldValue("mould_code", quotationByIdData?.data?.mould_code);
      formik.setFieldValue("design_code", quotationByIdData?.data?.design_code);
      formik.setFieldValue(
        "date",
        quotationByIdData?.data?.date
          ? quotationByIdData?.data?.date
          : moment().format("YYYY-MM-DD")
      );
      formik.setFieldValue(
        "delivery_period",
        quotationByIdData?.data?.delivery_period
      );
      formik.setFieldValue(
        "shipping_cost",
        quotationByIdData?.data?.shipping_cost
      );
    }
  }, [data, quotationByIdData]);
  // useEffect(() => {
  //   if (formik) {
  //     const updatedItems = formik.values.items?.map(
  //       (item: any, index: number) => {
  //         return {
  //           ...formik?.values?.items?.[index],
  //           // discount: discountData?.data[0]?.discount_percentage || "",
  //           // item_information: item?.item_information && item?.item_information,
  //           // brand: item?.brand || "",
  //           // qty: item?.qty || "",
  //           gst: "18",
  //         };
  //       }
  //     );
  //     formik.setFieldValue("items", updatedItems);
  //   }
  // }, [formik?.values?.items[index]?.gst]);

  useEffect(() => {
    if (grandTotal && npdData) {
      const updatedItems = formik.values.items?.map(
        (item: any, index: number) => {
          const rate = parseFloat(
            formik.values.items?.[index]?.rate || item?.rate || "0"
          );
          const gst = parseFloat("18");
          const total = calculateTotal(parseFloat(item?.qty), rate, gst);
          const formattedTotal = isNaN(Number(total))
            ? "0.00"
            : parseFloat(total).toFixed(2);

          return {
            ...formik?.values?.items?.[index],

            total: Number(formattedTotal),
          };
        }
      );

      formik.setFieldValue("items", updatedItems);
      formik.setFieldValue("grand_total", grandTotal);
    }
  }, [grandTotal]);
  const disabledDate = (current: any) => {
    // Disable dates after today
    return current && current > moment().endOf("day");
  };
  return (
    <>
      <div className="p-1 ">
        <div className="flex gap-2 create-quotation-title">
          <ArrowLeftOutlined
            onClick={() => {
              navigate("/sales");
            }}
          />

          <Text
            value={quotationId ? "Edit Quotation" : "Create Quotations"}
            variant="wearlay-headline-1"
          />
        </div>
        <div className="create-quotation-bg-color mt-24 ">
          <div className="create-quotation-title-2 margin-block">
            {" "}
            New Quotation
          </div>
          {/* <div className="create-quotation-border-main">
            {/* <div className="create-quotation-placeholder">
              Customer Unique Identification Number
            </div> */}
          {/* <Text
            value="Customer Unique Identification Number"
            variant="wearlay-label-bold"
            className="create-quotation-placeholder"
          /> */}
          {/* <div className="create-quotation-placeholder-2">12455678</div> */}
          {/* <Text
              value={data?.data?.sales_lead_id}
              variant="wearlay-label-bold"
            />
          </div>  */}
          <div className="create-quotation-border-main">
            {/* <div className="create-quotation-placeholder">
              Customer Unique Identification Number
            </div> */}
            <Text
              value="Lead Name"
              variant="wearlay-label-bold"
              className="create-quotation-placeholder"
            />
            {/* <div className="create-quotation-placeholder-2">12455678</div> */}
            <Text
              value={
                data?.data?.lead_name
                  ? data?.data?.lead_name
                  : npdData?.data?.lead_name
                  ? npdData?.data?.lead_name
                  : salesData?.data[0]?.lead_name
                  ? salesData?.data[0]?.lead_name
                  : npdDatas?.data[0]?.lead_name
                  ? npdDatas?.data[0]?.lead_name
                  : "-"
              }
              variant="wearlay-paragraph-bold"
            />
          </div>
          <Row>
            <Col span={24} md={{ span: 8 }}>
              <div className="create-quotation-border-main">
                {/* <div className="create-quotation-placeholder">Region</div>
                <div className="create-quotation-placeholder-2">Gujarath</div> */}
                <Text
                  value="Code"
                  variant="wearlay-label-bold"
                  className="create-quotation-placeholder"
                />
                <Text
                  value={
                    data?.data?.sales_lead_id
                      ? data?.data?.sales_lead_id
                      : npdData?.data?.sales_lead_id
                      ? npdData?.data?.sales_lead_id
                      : salesData?.data[0]?.sales_lead_id
                      ? salesData?.data[0]?.sales_lead_id
                      : npdDatas?.data[0]?.code
                      ? npdDatas?.data[0]?.code
                      : "-"
                  }
                  variant="wearlay-paragraph-bold"
                />
              </div>
              <div className="create-quotation-border-main">
                {/* <div className="create-quotation-placeholder">Region</div>
                <div className="create-quotation-placeholder-2">Gujarath</div> */}
                <Text
                  value="Region"
                  variant="wearlay-label-bold"
                  className="create-quotation-placeholder"
                />
                <Text
                  value={
                    data?.data?.region_name
                      ? data?.data?.region_name
                      : npdData?.data?.region_name
                      ? npdData?.data?.region_name
                      : salesData?.data[0]?.region_name
                      ? salesData?.data[0]?.region_name
                      : npdDatas?.data[0]?.region_name
                      ? npdDatas?.data[0]?.region_name
                      : "-"
                  }
                  variant="wearlay-paragraph-bold"
                />
              </div>
              <div className="create-quotation-border-main ">
                {/* <div className="create-quotation-placeholder">GST</div>
                <div className="create-quotation-placeholder-2">
                  24AAACC1206D1ZM
                </div> */}
                <Text
                  value="GST"
                  variant="wearlay-label-bold"
                  className="create-quotation-placeholder"
                />
                <Text
                  value={
                    data?.data?.gst
                      ? data?.data?.gst
                      : npdData?.data?.gst
                      ? npdData?.data?.gst
                      : salesData?.data[0]?.gst
                      ? salesData?.data[0]?.gst
                      : npdDatas?.data[0]?.gst
                      ? npdDatas?.data[0]?.gst
                      : "-"
                  }
                  variant="wearlay-paragraph-bold"
                  className=""
                />
              </div>
            </Col>
            <Col span={24} md={{ span: 8 }}>
              <div className="create-quotation-border-main">
                {/* <div className="create-quotation-placeholder">Company Name</div>
                <div className="create-quotation-placeholder-2">Chema</div> */}
                <Text
                  value="Company Name"
                  variant="wearlay-label-bold"
                  className="create-quotation-placeholder"
                />
                <Text
                  value={
                    data?.data?.company_name
                      ? data?.data?.company_name
                      : npdData?.data?.company_name
                      ? npdData?.data?.company_name
                      : salesData?.data[0]?.company_name
                      ? salesData?.data[0]?.company_name
                      : npdDatas?.data[0]?.company_name
                      ? npdDatas?.data[0]?.company_name
                      : "-"
                  }
                  variant="wearlay-paragraph-bold"
                />
              </div>
              <div className="create-quotation-border-main">
                {/* <div className="create-quotation-placeholder">Email</div>
                <div className="create-quotation-placeholder-2">
                  roshan@chema.com
                </div> */}
                <Text
                  value="Email"
                  variant="wearlay-label-bold"
                  className="create-quotation-placeholder"
                />
                <Text
                  value={
                    data?.data?.email
                      ? data?.data?.email
                      : npdData?.data?.email
                      ? npdData?.data?.email
                      : salesData?.data[0]?.email
                      ? salesData?.data[0]?.email
                      : npdDatas?.data[0]?.email
                      ? npdDatas?.data[0]?.email
                      : "-"
                  }
                  variant="wearlay-paragraph-bold"
                />
              </div>
              <div className="create-quotation-border-main">
                <Text
                  value="Address"
                  variant="wearlay-label-bold"
                  className="create-quotation-placeholder"
                />
                <Text
                  value={
                    data?.data?.address
                      ? data?.data?.address
                      : npdData?.data?.address
                      ? npdData?.data?.address
                      : salesData?.data[0]?.address
                      ? salesData?.data[0]?.address
                      : npdDatas?.data[0]?.address
                      ? npdDatas?.data[0]?.address
                      : "-"
                  }
                  variant="wearlay-paragraph-bold"
                />
              </div>
            </Col>
            <Col span={24} md={{ span: 8 }}>
              <div className="create-quotation-border-main">
                <Text
                  value="Client Name"
                  variant="wearlay-label-bold"
                  className="create-quotation-placeholder"
                />
                <Text
                  value={
                    data?.data?.client_name
                      ? data?.data?.client_name
                      : npdData?.data?.client_name
                      ? npdData?.data?.client_name
                      : salesData?.data[0]?.client_name
                      ? salesData?.data[0]?.client_name
                      : npdDatas?.data[0]?.client_name
                      ? npdDatas?.data[0]?.client_name
                      : "-"
                  }
                  variant="wearlay-paragraph-bold"
                />
              </div>
              <div className="create-quotation-border-main">
                <Text
                  value="Phone No"
                  variant="wearlay-label-bold"
                  className="create-quotation-placeholder"
                />
                <Text
                  value={
                    data?.data?.phone
                      ? data?.data?.phone
                      : npdData?.data?.phone
                      ? npdData?.data?.phone
                      : salesData?.data[0]?.phone
                      ? salesData?.data[0]?.phone
                      : npdDatas?.data[0]?.phone
                      ? npdDatas?.data[0]?.phone
                      : "-"
                  }
                  variant="wearlay-paragraph-bold"
                />
              </div>
              <div className="create-quotation-border-main">
                <Text
                  value="Enquiry Category"
                  variant="wearlay-label-bold"
                  className="create-quotation-placeholder"
                />
                <Text
                  value={
                    data?.data?.enquiry_category_name
                      ? data?.data?.enquiry_category_name
                      : npdData?.data?.enquiry_category_name
                      ? npdData?.data?.enquiry_category_name
                      : salesData?.data[0]?.enquiry_category_name
                      ? salesData?.data[0]?.enquiry_category_name
                      : npdDatas?.data[0]?.enquiry_category_name
                      ? npdDatas?.data[0]?.enquiry_category_name
                      : "-"
                  }
                  variant="wearlay-paragraph-bold"
                  className=""
                />
              </div>
            </Col>
          </Row>
          <Divider />
          <Form
            layout="vertical"
            onFinish={formik.handleSubmit}
            onKeyPress={handleKeyPress}
          >
            <div>
              <Row gutter={16}>
                <Col span={24} md={{ span: 8 }}>
                  <Form.Item
                    label={"Material Grade"}
                    required
                    // name={"material_grade"}
                    hasFeedback
                    validateStatus={
                      formik.errors.material_grade &&
                      formik.touched.material_grade
                        ? "error"
                        : undefined
                    }
                    help={
                      formik.errors.material_grade &&
                      formik.touched.material_grade &&
                      formik.errors.material_grade
                    }
                  >
                    <Input
                      placeholder="Material Grade"
                      value={formik.values.material_grade}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="material_grade"
                    />
                  </Form.Item>
                  <Form.Item
                    label={"Drawing Number"}
                    required
                    // name={"drawing_number"}
                    hasFeedback
                    validateStatus={
                      formik.errors.drawing_number &&
                      formik.touched.drawing_number
                        ? "error"
                        : undefined
                    }
                    help={
                      formik.errors.drawing_number &&
                      formik.touched.drawing_number &&
                      formik.errors.drawing_number
                    }
                  >
                    <Input
                      placeholder="Drawing Number"
                      value={formik.values.drawing_number}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="drawing_number"
                    />
                  </Form.Item>
                </Col>
                <Col span={24} md={{ span: 8 }}>
                  <Form.Item
                    label={"Mould Code"}
                    required
                    // name={"mould_code"}
                    hasFeedback
                    validateStatus={
                      formik.errors.mould_code && formik.touched.mould_code
                        ? "error"
                        : undefined
                    }
                    help={
                      formik.errors.mould_code &&
                      formik.touched.mould_code &&
                      formik.errors.mould_code
                    }
                  >
                    <Input
                      placeholder="Mould Code"
                      value={formik.values.mould_code}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="mould_code"
                    />
                  </Form.Item>
                  <Form.Item
                    label={"Design Code"}
                    required
                    // name={"design_code"}
                    hasFeedback
                    validateStatus={
                      formik.errors.design_code && formik.touched.design_code
                        ? "error"
                        : undefined
                    }
                    help={
                      formik.errors.design_code &&
                      formik.touched.design_code &&
                      formik.errors.design_code
                    }
                  >
                    <Input
                      placeholder="Design Code"
                      value={formik.values.design_code}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="design_code"
                    />
                  </Form.Item>
                </Col>
                <Col span={24} md={{ span: 8 }}>
                  <Form.Item
                    label={"Date Created"}
                    required
                    className="w-full"
                    // name={"date"}
                    hasFeedback
                    validateStatus={
                      formik.errors.date && formik.touched.date
                        ? "error"
                        : undefined
                    }
                    help={
                      formik.errors.date &&
                      formik.touched.date &&
                      formik.errors.date
                    }
                  >
                    <DatePicker
                      placeholder="Select date"
                      onBlur={formik.handleBlur}
                      className="w-full"
                      onChange={(value) =>
                        formik.setFieldValue(
                          "date",
                          value ? value.format("YYYY-MM-DD") : dayjs()
                        )
                      }
                      value={
                        formik.values.date
                          ? dayjs(formik.values.date)
                          : dayjs("YYYY-MM-DD")
                      }
                      format="DD-MM-YYYY"
                      disabledDate={disabledDate}
                      // defaultValue={dayjs()}
                      name="date"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Divider />
              <div className="create-quotation-title-2 margin-block">
                Add Items
              </div>
              <Form.List
                name="items"
                initialValue={
                  (quotationByIdData || data)?.data?.items
                    ? (quotationByIdData || data)?.data?.items?.map(() => ({}))
                    : [{}]
                }
              >
                {(fields, { add, remove }) => (
                  <div className="item-table-width">
                    <div className="child-item-table-width">
                      <Row>
                        <Col
                          span={5}
                          className="create-quotation-border-main create-quotation-placeholder-2"
                        >
                          *Item Information
                        </Col>
                        <Col
                          span={4}
                          className="create-quotation-border-main create-quotation-placeholder-2"
                        >
                          *Brand
                        </Col>
                        <Col
                          span={3}
                          className="create-quotation-border-main create-quotation-placeholder-2"
                        >
                          *Qty
                        </Col>
                        <Col
                          span={3}
                          className="create-quotation-border-main create-quotation-placeholder-2"
                        >
                          *Rate
                        </Col>
                        {/* {discountData?.data[0]?.enabled && (
                        <Col
                          span={3}
                          className="create-quotation-border-main create-quotation-placeholder-2"
                        >
                          *Discount %
                        </Col>
                      )} */}
                        <Col
                          span={3}
                          className="create-quotation-border-main create-quotation-placeholder-2"
                        >
                          *GST%
                        </Col>
                        <Col
                          span={3}
                          className="create-quotation-border-main create-quotation-placeholder-2"
                        >
                          *Total
                        </Col>

                        <Col
                          span={3}
                          className="create-quotation-border-main create-quotation-placeholder-2"
                        >
                          Action
                        </Col>
                      </Row>
                      {fields?.map(({ key, name, ...restField }) => {
                        const item = formik.values.items[name];
                        // const itemData = data?.data?.items?.[name];
                        // setFormik(item);
                        return (
                          <Row>
                            <Col
                              span={5}
                              className="create-quotation-border-main"
                            >
                              <Form.Item
                                {...restField}
                                // name={[name, "item_information"]}
                                required
                                hasFeedback
                                validateStatus={
                                  formik.touched.items &&
                                  formik.touched.items[name] &&
                                  formik.errors.items &&
                                  formik.errors.items[name] &&
                                  (
                                    formik.errors.items[name] as FormikErrors<{
                                      item_information: string;
                                      qty: string;
                                      rate: string;
                                      discount: any;
                                      brand: string;
                                      total: string;
                                      gst: string;
                                    }>
                                  ).item_information
                                    ? "error"
                                    : ""
                                }
                                help={
                                  formik.touched.items &&
                                  formik.touched.items[name] &&
                                  formik.errors.items &&
                                  formik.errors.items[name] &&
                                  (
                                    formik.errors.items[name] as FormikErrors<{
                                      item_information: string;
                                      qty: string;
                                      rate: string;
                                      discount: any;
                                      brand: string;
                                      total: string;
                                      gst: string;
                                    }>
                                  ).item_information
                                    ? (
                                        formik.errors.items[
                                          name
                                        ] as FormikErrors<{
                                          item_information: string;
                                          qty: string;
                                          rate: string;
                                          discount: any;
                                          brand: string;
                                          total: string;
                                          gst: string;
                                        }>
                                      ).item_information
                                    : ""
                                }
                              >
                                <Input
                                  name={`items.${name}.item_information`}
                                  value={item?.item_information || ""}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  placeholder="Item Information"
                                />
                              </Form.Item>
                            </Col>
                            <Col
                              span={4}
                              className="create-quotation-border-main"
                            >
                              <Form.Item
                                {...restField}
                                // name={[name, "brand"]}
                                required
                                hasFeedback
                                validateStatus={
                                  formik.touched.items &&
                                  formik.touched.items[name] &&
                                  formik.errors.items &&
                                  formik.errors.items[name] &&
                                  (
                                    formik.errors.items[name] as FormikErrors<{
                                      item_information: string;
                                      qty: string;
                                      rate: string;
                                      discount: any;
                                      brand: string;
                                      total: string;
                                      gst: string;
                                    }>
                                  ).brand
                                    ? "error"
                                    : ""
                                }
                                help={
                                  formik.touched.items &&
                                  formik.touched.items[name] &&
                                  formik.errors.items &&
                                  formik.errors.items[name] &&
                                  (
                                    formik.errors.items[name] as FormikErrors<{
                                      item_information: string;
                                      qty: string;
                                      rate: string;
                                      discount: any;
                                      brand: string;
                                      total: string;
                                      gst: string;
                                    }>
                                  ).brand
                                    ? (
                                        formik.errors.items[
                                          name
                                        ] as FormikErrors<{
                                          item_information: string;
                                          qty: string;
                                          rate: string;
                                          discount: any;
                                          brand: string;
                                          total: string;
                                          gst: string;
                                        }>
                                      ).brand
                                    : ""
                                }
                              >
                                <Input
                                  defaultValue={
                                    formik.values.items[name]?.brand || ""
                                  }
                                  name={`items.${name}.brand`}
                                  value={formik.values.items[name]?.brand || ""}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  placeholder="Brand"
                                />
                              </Form.Item>
                            </Col>
                            <Col
                              span={3}
                              className="create-quotation-border-main"
                            >
                              {" "}
                              <Form.Item
                                {...restField}
                                // name={[name, "qty"]}
                                hasFeedback
                                validateStatus={
                                  formik.touched.items &&
                                  formik.touched.items[name] &&
                                  formik.errors.items &&
                                  formik.errors.items[name] &&
                                  (
                                    formik.errors.items[name] as FormikErrors<{
                                      item_information: string;
                                      qty: string;
                                      rate: string;
                                      discount: any;
                                      brand: string;
                                      total: string;
                                      gst: string;
                                    }>
                                  ).qty
                                    ? "error"
                                    : ""
                                }
                                help={
                                  formik.touched.items &&
                                  formik.touched.items[name] &&
                                  formik.errors.items &&
                                  formik.errors.items[name] &&
                                  (
                                    formik.errors.items[name] as FormikErrors<{
                                      item_information: string;
                                      qty: string;
                                      rate: string;
                                      discount: any;
                                      brand: string;
                                      total: string;
                                      gst: string;
                                    }>
                                  ).qty
                                    ? (
                                        formik.errors.items[
                                          name
                                        ] as FormikErrors<{
                                          item_information: string;
                                          qty: string;
                                          rate: string;
                                          discount: any;
                                          brand: string;
                                          total: string;
                                          gst: string;
                                        }>
                                      ).qty
                                    : ""
                                }
                              >
                                <Input
                                  name={`items.${name}.qty`}
                                  value={formik.values.items[name]?.qty || ""}
                                  // onChange={formik.handleChange}
                                  onChange={(e: any) => {
                                    const { value } = e.target;

                                    formik.setFieldValue(
                                      `items.${name}.gst`,
                                      "18"
                                    );
                                    formik.setFieldValue(
                                      `items.${name}.qty`,
                                      value
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                  placeholder="Quantity"
                                />
                              </Form.Item>
                            </Col>
                            <Col
                              span={3}
                              className="create-quotation-border-main"
                            >
                              <Form.Item
                                {...restField}
                                // name={[name, "rate"]}
                                hasFeedback
                                validateStatus={
                                  formik.touched.items &&
                                  formik.touched.items[name] &&
                                  formik.errors.items &&
                                  formik.errors.items[name] &&
                                  (
                                    formik.errors.items[name] as FormikErrors<{
                                      item_information: string;
                                      qty: string;
                                      rate: string;
                                      discount: any;
                                      brand: string;
                                      total: string;
                                      gst: string;
                                    }>
                                  ).rate
                                    ? "error"
                                    : ""
                                }
                                help={
                                  formik.touched.items &&
                                  formik.touched.items[name] &&
                                  formik.errors.items &&
                                  formik.errors.items[name] &&
                                  (
                                    formik.errors.items[name] as FormikErrors<{
                                      item_information: string;
                                      qty: string;
                                      rate: string;
                                      discount: any;
                                      brand: string;
                                      total: string;
                                      gst: string;
                                    }>
                                  ).rate
                                    ? (
                                        formik.errors.items[
                                          name
                                        ] as FormikErrors<{
                                          item_information: string;
                                          qty: string;
                                          rate: string;
                                          discount: any;
                                          brand: string;
                                          total: string;
                                          gst: string;
                                        }>
                                      ).rate
                                    : ""
                                }
                              >
                                <Input
                                  name={`items.${name}.rate`}
                                  value={formik.values.items[name]?.rate || ""}
                                  onChange={(e: any) => {
                                    const { value } = e.target;

                                    formik.setFieldValue(
                                      `items.${name}.gst`,
                                      "18"
                                    );
                                    formik.setFieldValue(
                                      `items.${name}.rate`,
                                      value
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                  placeholder="Rate"
                                  onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                      add(); // Set the state to true when the Enter key is pressed
                                    }
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            {/* {discountData?.data[0]?.enabled && (
                            <Col
                              span={3}
                              className="create-quotation-border-main"
                            >
                              <Form.Item
                                {...restField}
                                name={[name, "discount"]}
                                hasFeedback
                                validateStatus={
                                  formik.errors.items &&
                                  formik.errors.items[name] &&
                                  (
                                    formik.errors.items[name] as FormikErrors<{
                                      item_information: string;
                                      quantity: string;
                                      rate: string;
                                      discount: string;
                                    }>
                                  )?.discount
                                    ? "error"
                                    : ""
                                }
                                help={
                                  formik.errors.items &&
                                  formik.errors.items[name] &&
                                  (
                                    formik.errors.items[name] as FormikErrors<{
                                      item_information: string;
                                      quantity: string;
                                      rate: string;
                                      discount: string;
                                    }>
                                  )?.discount
                                    ? (
                                        formik.errors.items[
                                          name
                                        ] as FormikErrors<{
                                          item_information: string;
                                          quantity: string;
                                          rate: string;
                                          discount: string;
                                        }>
                                      )?.discount
                                    : ""
                                }
                              >
                                <Input
                                  defaultValue={
                                    discountData?.data[0]?.discount_percentage
                                  }
                                  name={`items.${name}.discount`}
                                  value={
                                    formik.values.items[name]?.discount ||
                                    discountData?.data[0]?.discount_percentage
                                  }
                                  onChange={() => {
                                    formik.setFieldValue(
                                      "items",
                                      discountData?.data[0]?.discount_percentage
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                  // placeholder="Discount"
                                  disabled
                                />
                              </Form.Item>
                            </Col>
                          )} */}
                            <Col
                              span={3}
                              className="create-quotation-border-main"
                            >
                              <Form.Item
                                {...restField}
                                name={[name, "gst"]}
                                hasFeedback
                                validateStatus={
                                  formik.touched.items &&
                                  formik.touched.items[name] &&
                                  formik.errors.items &&
                                  formik.errors.items[name] &&
                                  (
                                    formik.errors.items[name] as FormikErrors<{
                                      item_information: string;
                                      qty: string;
                                      rate: string;
                                      discount: any;
                                      brand: string;
                                      total: string;
                                      gst: string;
                                    }>
                                  ).gst
                                    ? "error"
                                    : ""
                                }
                                help={
                                  formik.touched.items &&
                                  formik.touched.items[name] &&
                                  formik.errors.items &&
                                  formik.errors.items[name] &&
                                  (
                                    formik.errors.items[name] as FormikErrors<{
                                      item_information: string;
                                      qty: string;
                                      rate: string;
                                      discount: any;
                                      brand: string;
                                      total: string;
                                      gst: string;
                                    }>
                                  ).gst
                                    ? (
                                        formik.errors.items[
                                          name
                                        ] as FormikErrors<{
                                          item_information: string;
                                          qty: string;
                                          rate: string;
                                          discount: any;
                                          brand: string;
                                          total: string;
                                          gst: string;
                                        }>
                                      ).gst
                                    : ""
                                }
                              >
                                <Input
                                  name={`items.${name}.gst`}
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                  placeholder="GST"
                                  disabled
                                  defaultValue="18"
                                />
                              </Form.Item>
                            </Col>
                            <Col
                              span={3}
                              className="create-quotation-border-main"
                            >
                              <Form.Item
                                {...restField}
                                // name={[name, "total"]}
                                hasFeedback
                                validateStatus={
                                  formik.errors.items &&
                                  formik.errors.items[name] &&
                                  (
                                    formik.errors.items[name] as FormikErrors<{
                                      item_information: string;
                                      qty: string;
                                      rate: string;
                                      discount: string;
                                      gst: string;
                                      total: string;
                                    }>
                                  )?.total
                                    ? "error"
                                    : ""
                                }
                                help={
                                  formik.errors.items &&
                                  formik.errors.items[name] &&
                                  (
                                    formik.errors.items[name] as FormikErrors<{
                                      item_information: string;
                                      qty: string;
                                      rate: string;
                                      discount: string;
                                      gst: string;
                                      total: string;
                                    }>
                                  )?.total
                                    ? (
                                        formik.errors.items[
                                          name
                                        ] as FormikErrors<{
                                          item_information: string;
                                          qty: string;
                                          rate: string;
                                          discount: string;
                                          gst: string;
                                          total: string;
                                        }>
                                      )?.total
                                    : ""
                                }
                              >
                                <Input
                                  disabled
                                  value={
                                    isNaN(parseFloat(item?.qty)) ||
                                    isNaN(parseFloat(item?.rate))
                                      ? // isNaN(parseFloat(item?.gst))
                                        "0.00"
                                      : calculateTotal(
                                          item?.qty || "0",
                                          item?.rate || "0",
                                          "18"
                                        )
                                  }
                                  // onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  onChange={() => {
                                    formik.setFieldValue(
                                      `items.${name}.total`,
                                      total
                                    );
                                  }}
                                  name={`items.${name}.total`}
                                />
                              </Form.Item>
                            </Col>

                            <Col
                              span={3}
                              className="create-quotation-border-main"
                            >
                              {fields.length > 1 && (
                                <img
                                  src="/assets/remove.svg"
                                  onClick={() => {
                                    handleRemove(name);
                                    remove(name);
                                  }}
                                />
                              )}
                            </Col>
                          </Row>
                        );
                      })}
                      <Row>
                        <Col
                          // span={discountData?.data[0]?.enabled ? 15 : 12}
                          span={15}
                          className="create-quotation-border-main"
                        ></Col>
                        <Col span={3} className="create-quotation-border-main">
                          *Shipping Cost
                        </Col>
                        <Col span={3} className="create-quotation-border-main">
                          <Form.Item
                            hasFeedback
                            validateStatus={
                              formik.errors.shipping_cost &&
                              formik.touched.shipping_cost
                                ? "error"
                                : undefined
                            }
                            help={
                              formik.errors.shipping_cost &&
                              formik.touched.shipping_cost &&
                              formik.errors.shipping_cost
                            }
                          >
                            <Input
                              name="shipping_cost"
                              value={formik.values.shipping_cost}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              placeholder="Shipping Cost"
                            ></Input>
                          </Form.Item>
                        </Col>
                        <Col span={3} className="create-quotation-border-main">
                          <img src="/assets/add.svg" onClick={() => add()} />
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
              </Form.List>

              {/* <Row>
                <Col span={15} className="create-quotation-border-main"></Col>
                <Col span={3} className="create-quotation-border-maii will be in touchn">
                  Total Discount
                </Col>
                <Col span={3} className="create-quotation-border-main">
                  <Input disabled value={totalDiscount}></Input>
                </Col>
                <Col span={3} className="create-quotation-border-main"></Col>
              </Row> */}
              {/* <Row>
                <Col span={15} className="create-quotation-border-main"></Col>
                <Col span={3} className="create-quotation-border-main">
                  Shipping Cost
                </Col>
                <Col span={3} className="create-quotation-border-main">
                  <Input
                    name="shippingCost"
                    value={formik.values.shippingCost}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Shipping Cost"
                  ></Input>
                </Col>
                <Col span={3} className="create-quotation-border-main"></Col>
              </Row> */}
              <Row>
                <Col
                  // span={discountData?.data[0]?.enabled ? 15 : 12}
                  span={10}
                  md={{ span: 15 }}
                  className="create-quotation-border-main"
                ></Col>
                <Col
                  span={5}
                  md={{ span: 3 }}
                  className="create-quotation-border-main"
                >
                  Grand Total
                </Col>
                <Col
                  span={9}
                  md={{ span: 3 }}
                  className="create-quotation-border-main"
                >
                  <Form.Item>
                    <Input
                      defaultValue={"0.00"}
                      disabled
                      value={
                        isNaN(parseFloat(grandTotal)) ? "00.0" : grandTotal
                      }
                      className="grand-total"
                      name={"grand_total"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    ></Input>
                  </Form.Item>
                </Col>
                <Col
                  span={0}
                  md={{ span: 3 }}
                  className="create-quotation-border-main"
                ></Col>
              </Row>
              <Row justify={"start"} className="flex flex-col">
                {/* <div className="create-quotation-placeholder-2 margin-block">
                  Note
                </div> */}
                {/* <Text
                  value="D"
                  variant="wearlay-paragraph-bold"
                  className="margin-block"
                /> */}
                <Form.Item
                  label={"Delivery Period"}
                  className="w-full"
                  required
                  // name={"delivery_period"}
                  hasFeedback
                  validateStatus={
                    formik.errors.delivery_period &&
                    formik.touched.delivery_period
                      ? "error"
                      : undefined
                  }
                  help={
                    formik.errors.delivery_period &&
                    formik.touched.delivery_period &&
                    formik.errors.delivery_period
                  }
                >
                  <Input
                    value={formik.values.delivery_period}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Delivery date"
                    name="delivery_period"
                  />
                </Form.Item>
              </Row>
              <Row justify={"start"} className="flex flex-col">
                {/* <div className="create-quotation-placeholder-2 margin-block">
                  Note
                </div> */}
                <Text
                  value="Note"
                  variant="wearlay-paragraph-bold"
                  className="margin-block"
                />
                <Form.Item
                  // label={"Design Code"}
                  className="w-full"
                  required
                  // name={"note"}
                  hasFeedback
                  validateStatus={
                    formik.errors.note && formik.touched.note
                      ? "error"
                      : undefined
                  }
                  help={
                    formik.errors.note &&
                    formik.touched.note &&
                    formik.errors.note
                  }
                >
                  <TextArea
                    value={formik.values.note}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Note"
                    name="note"
                  />
                </Form.Item>
              </Row>
              <Divider />
              <Text value="Follow Up Status" variant="wearlay-headline-1" />

              <Text value="Follow Up 1" variant="wearlay-headline-2" />
              <Row gutter={16}>
                <Col span={24} md={{ span: 8 }}>
                  <Form.Item
                    label="Date"
                    className="w-full"
                    required
                    // name={`follow_up[0].date`}
                    validateStatus={
                      formik.touched.follow_up &&
                      formik.touched.follow_up[0] &&
                      formik.errors.follow_up &&
                      formik.errors.follow_up[0] &&
                      (
                        formik.errors.follow_up[0] as FormikErrors<{
                          date: string;
                          notes: string;
                          next_follow_up_date: string;
                        }>
                      )?.date
                        ? "error"
                        : undefined
                    }
                    help={
                      formik.touched.follow_up &&
                      formik.touched.follow_up[0] &&
                      formik.errors.follow_up &&
                      formik.errors.follow_up[0] &&
                      (
                        formik.errors.follow_up[0] as FormikErrors<{
                          date: string;
                          notes: string;
                          next_follow_up_date: string;
                        }>
                      )?.date
                        ? (
                            formik.errors.follow_up[0] as FormikErrors<{
                              date: string;
                              notes: string;
                              next_follow_up_date: string;
                            }>
                          )?.date
                        : undefined
                    }
                  >
                    <DatePicker
                      className="w-full"
                      onBlur={formik.handleBlur}
                      onChange={(value) =>
                        formik.setFieldValue(
                          `follow_up[0].date`,
                          value ? value.format("YYYY-MM-DD") : dayjs()
                        )
                      }
                      value={
                        formik.values.follow_up[0].date
                          ? dayjs(formik.values.follow_up[0].date)
                          : dayjs()
                      }
                      name={`follow_up[0].date`}
                      format="DD-MM-YYYY"
                    />
                  </Form.Item>
                </Col>

                <Col span={24} md={{ span: 8 }}>
                  <Form.Item
                    label="Next Follow up Date"
                    className="w-full"
                    required
                    // name={`follow_up$[0].next_follow_up_date`}
                    validateStatus={
                      formik.touched.follow_up &&
                      formik.touched.follow_up[0] &&
                      formik.errors.follow_up &&
                      formik.errors.follow_up[0] &&
                      (
                        formik.errors.follow_up[0] as FormikErrors<{
                          date: string;
                          notes: string;
                          next_follow_up_date: string;
                        }>
                      )?.next_follow_up_date
                        ? "error"
                        : undefined
                    }
                    help={
                      formik.touched.follow_up &&
                      formik.touched.follow_up[0] &&
                      formik.errors.follow_up &&
                      formik.errors.follow_up[0] &&
                      (
                        formik.errors.follow_up[0] as FormikErrors<{
                          date: string;
                          notes: string;
                          next_follow_up_date: string;
                        }>
                      )?.next_follow_up_date
                        ? (
                            formik.errors.follow_up[0] as FormikErrors<{
                              date: string;
                              notes: string;
                              next_follow_up_date: string;
                            }>
                          )?.next_follow_up_date
                        : undefined
                    }
                  >
                    <DatePicker
                      className="w-full"
                      onBlur={formik.handleBlur}
                      onChange={(value) =>
                        formik.setFieldValue(
                          `follow_up[0].next_follow_up_date`,
                          value ? value.format("YYYY-MM-DD") : ""
                        )
                      }
                      value={
                        formik.values.follow_up[0].next_follow_up_date
                          ? dayjs(
                              formik.values.follow_up[0].next_follow_up_date
                            )
                          : null
                      }
                      format="DD-MM-YYYY"
                      disabledDate={(current) =>
                        current && current < moment().endOf("day")
                      }
                      name={`follow_up[0].next_follow_up_date`}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} md={{ span: 8 }}>
                  <Form.Item
                    label="Follow Up Note"
                    className="w-full"
                    required
                    validateStatus={
                      formik.touched.follow_up &&
                      formik.touched.follow_up[0] &&
                      formik.errors.follow_up &&
                      formik.errors.follow_up[0] &&
                      (
                        formik.errors.follow_up[0] as FormikErrors<{
                          date: string;
                          notes: string;
                          next_follow_up_date: string;
                        }>
                      )?.notes
                        ? "error"
                        : undefined
                    }
                    help={
                      formik.touched.follow_up &&
                      formik.touched.follow_up[0] &&
                      formik.errors.follow_up &&
                      formik.errors.follow_up[0] &&
                      (
                        formik.errors.follow_up[0] as FormikErrors<{
                          date: string;
                          notes: string;
                          next_follow_up_date: string;
                        }>
                      )?.notes
                        ? (
                            formik.errors.follow_up[0] as FormikErrors<{
                              date: string;
                              notes: string;
                              next_follow_up_date: string;
                            }>
                          )?.notes
                        : undefined
                    }
                    // name={`follow_up[0].notes`}
                  >
                    <TextArea
                      value={formik.values.follow_up[0].notes}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Note"
                      name={`follow_up[0].notes`}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Divider />
              <Row justify={"end"}>
                {/* <Button className="create-quotation-btn-color ">
                  Create Quotation
                </Button> */}
                <CommonButton
                  className=" create-quotation-btn-color"
                  variant="wealay-primary-btn"
                  value={quotationId ? "Update" : "Create Quotation"}
                  isLoading={
                    createQuotationLoading ||
                    createNpdQuotationLoading ||
                    editQuotationLoading
                  }
                  disabled={!formik.isValid}
                />
              </Row>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};
