import React, { useEffect } from "react";
import "./index.css";
import "./variables.css";
import "../src/antd.override.css";
import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { PathConstants } from "./pathConstants/pathConstants";
import { PublicRoute } from "./helpers/publicRoute";
import { LoginPage } from "./Pages/Login";
import { PageLayout } from "./Pages/PageLayout";
import { PrivateRoute } from "./helpers/privateRoute";
import { Overview } from "./Pages/overview";
import { Sales } from "./Pages/Sales";
import { Quotation } from "./Pages/Quatations";
import { Orders } from "./Pages/Orders";
import { ForgotPasswordPage } from "./Pages/forgotPassword";
import { ResetPassword } from "./Pages/ResetPassword";
import { CreateQuotion } from "./Pages/CreateQuotation";
import { Profile } from "./Pages/Profile";
import { Inquiries } from "./Pages/Inquiries";
import { Allocated } from "./Pages/Allocated";
import { Employee } from "./Pages/Employee";
import { Notification } from "./Pages/Notificarion";
import { EmployeeDetailPage } from "./Pages/Employee/detailPage";
import { CreateNewLead } from "./Pages/AddNewLead";
import { Contacts } from "./Pages/Contacts";
import { MyDocument } from "./components/Export/export";
import { PDFRenderer, PDFViewer } from "@react-pdf/renderer";
import { ErrorHandler } from "./components/Errorhandler";
import { QuotationExport } from "./components/Export/quotationExport";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            index
            path={PathConstants.LOGIN}
            element={
              <PublicRoute>
                <LoginPage />
              </PublicRoute>
            }
          />
          <Route
            path={PathConstants.FORGOT_PASSWORD}
            element={
              <PublicRoute>
                <ForgotPasswordPage />
              </PublicRoute>
            }
          />
          <Route
            path={PathConstants.RESET_PASSWORD}
            element={
              <PublicRoute>
                <ResetPassword />
              </PublicRoute>
            }
          />
          <Route
            path={PathConstants.HOME}
            element={
              <PrivateRoute>
                <PageLayout />
              </PrivateRoute>
            }
          >
            <Route index element={<Overview />} />
            <Route path={PathConstants.OVERVIEW} element={<Overview />} />
            <Route path={PathConstants.SALES} element={<Sales />} />
            <Route path={PathConstants.SALES_DETAILS} element={<Sales />} />
            <Route path={PathConstants.QUOTATION} element={<Quotation />} />
            <Route
              path={PathConstants.DETAIL_QUOTATION}
              element={<Quotation />}
            />
            <Route path={PathConstants.DETAIL_ORDER} element={<Orders />} />
            <Route path={PathConstants.ORDER} element={<Orders />} />
            <Route path={PathConstants.EMPLOYEE} element={<Employee />} />
            <Route
              path={PathConstants.DETAIL_PAGE}
              element={<EmployeeDetailPage />}
            />
            <Route path={PathConstants.ADD_LEAD} element={<CreateNewLead />} />
            <Route path={PathConstants.ERROR_403} element={<ErrorHandler />} />
            <Route
              path={PathConstants.NOTIFICATION}
              element={<Notification />}
            />

            <Route path={PathConstants.CONTACTS} element={<Contacts />} />
            <Route
              path={PathConstants.INQUIRIES}
              element={<Inquiries />}
            ></Route>
            <Route
              path={PathConstants.ALLOCATED}
              element={<Allocated />}
            ></Route>
            <Route
              path={PathConstants.CREATE_QUOTATION}
              element={<CreateQuotion />}
            />

            <Route path={PathConstants.PROFILE} element={<Profile />}></Route>
          </Route>
          {/* <Route
            path="/pdf"
            element={
              <PDFViewer className="h-full-vh w-full">
                <QuotationExport />
              </PDFViewer>
            }
          ></Route> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
