import React from "react";
import { Navigate } from "react-router";

import { getAccessToken } from "../helpers/localStorage";
import { PathConstants } from "../pathConstants/pathConstants";

export const PublicRoute = ({ children, ...rest }: any) => {
  if (getAccessToken()) {
    return <Navigate to={PathConstants.OVERVIEW} />;
  } else {
    return children;
  }
};
