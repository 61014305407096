import { Button, Col, Form, Input, message, Row, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import * as Yup from "yup";
import { CommonButton } from "../../CommonButton/button";
import { useFormik } from "formik";
import "./index.css";
import {
  useCreateCreateNpdMutation,
  useCreateRegionMutation,
  useEditNpdMutation,
  useGetDesignationQuery,
  useGetEnquiryCategoryQuery,
  useGetGroupQuery,
  useGetIndustryQuery,
  useGetNatureQuery,
  useGetNpdByIdQuery,
  useGetRegionQuery,
  useGetSourcesQuery,
  useGetTypesQuery,
} from "../../../services/api";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";

interface ICreateContact {
  setOpen: (data: boolean) => void;
  setEdit?: (data: boolean) => void;
  datas?: any;
  isEdit?: boolean;
}
export const AddNewInquiry: React.FC<ICreateContact> = ({
  setOpen,
  datas,
  isEdit,
  setEdit,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const npd_id = searchParams.get("id");
  const [designationValue, setDesignationValue] = useState("");
  const [natureValue, setNatureValue] = useState("");
  const [sourceValue, setSourceValue] = useState("");
  const [categoryValue, setCategoryValue] = useState("");
  const [industryValue, setIndustryValue] = useState("");
  const [typeValue, setTypeValue] = useState("");
  const [groupValue, setgroupValue] = useState("");
  const [inputValue, setInputValue] = useState("");

  const { data: editData, isLoading: editLoading } = useGetNpdByIdQuery(npd_id);
  const { data: sourceData, isLoading: sourceLoading } = useGetSourcesQuery("");
  const { data: typeData, isLoading: typeLoading } = useGetTypesQuery("");
  const { data: natureData, isLoading: natureLoading } = useGetNatureQuery("");
  const { data: designationData, isLoading: designationLoading } =
    useGetDesignationQuery("");
  const { data: industryData, isLoading: industyLoading } =
    useGetIndustryQuery("");
  const { data: groupData, isLoading: groupLoading } = useGetGroupQuery("");
  const { data: regionData, isLoading: regionLoading } = useGetRegionQuery("");
  const { data: categoryData, isLoading: categoryLoading } =
    useGetEnquiryCategoryQuery("");
  const [createNpd, { data, isLoading }] = useCreateCreateNpdMutation();
  const [editNpd, { data: editNpdById, isLoading: editNpdLoading }] =
    useEditNpdMutation();
  const [mutation, { data: regioncreatedata }] = useCreateRegionMutation();

  const userId = localStorage.getItem("user_id");
  const [form] = Form.useForm();

  const validationSchema = Yup.object().shape({
    product_name: Yup.string()
      .matches(
        /^[A-Za-z\s]+$/,
        "Product Name  must contain only letters and spaces"
      )
      .required("Product Name is required"),
    specification: Yup.string()
      // .matches(/^[A-Za-z\s]+$/, "Specification must contain only letters")
      .required("Specification is required"),
    quantity: Yup.string()
      .matches(/^[0-9]+$/, "Quantity  must contain only numbers")
      .required("Quantity is required"),
    client_name: Yup.string()
      // .matches(
      //   /^[A-Za-z\s]+$/,
      //   "Client Name  must contain only letters and spaces"
      // )
      .required("Client Name is required"),
    lead_name: Yup.string().required("Lead Name is required"),
    designation: Yup.object().required("Designation is required"),
    phone: Yup.string()
      // .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
      // .required("Phone number is required"),
      .matches(/^[0-9+]+$/, "Invalid phone number")
      .required("Phone number is required"),
    group: Yup.object().required("Group is required"),
    source: Yup.object().required("Source is required"),
    enquiry_category: Yup.object().required("Source is required"),

    region: Yup.object().required("Region is required"),
    company_name: Yup.string()
      // .matches(
      //   /^[A-Za-z\s]+$/,
      //   "Company Name must contain only letters and spaces"
      // )
      .required("Company Name is required"),
    email: Yup.string().matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid email format"
    ),
    type: Yup.object().required("Type is required"),
    address: Yup.string()
      // .matches(/^[A-Za-z\s]+$/, "Address  must contain only letters and spaces")
      .required("Address is required"),
    gst: Yup.string().matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
      "Invalid GST number"
    ),
    industry: Yup.object().required("Industry is required"),
    // nature: Yup.object().required("Nature is required"),
  });

  const formik = useFormik({
    initialValues: {
      product_name: "",
      specification: "",
      quantity: "",
      client_name: "",
      lead_name: "",
      enquiry_category: undefined,
      designation: undefined,
      phone: "",
      group: undefined,
      region: undefined,
      source: undefined,
      company_name: "",
      email: "",
      type: undefined,
      address: "",
      gst: "",
      industry: undefined,
      // nature: undefined,
    },
    validationSchema,
    onSubmit: async (values: any) => {
      const dropDownValue = {
        ...values,
        region: values.region.value,
        group: values.group.value,
        industry: values.industry.value,
        // nature: values.nature.value,
        source: values.source.value,
        designation: values.designation.value,
        type: values.type.value,

        enquiry_category: values.enquiry_category.value,
      };
      const createParams = {
        body: dropDownValue,
        id: userId,
      };
      const editParams = {
        body: dropDownValue,
        id: npd_id,
      };

      await (isEdit ? editNpd(editParams) : createNpd(createParams))
        .unwrap()
        .then((data) => {
          setOpen(false);
          setEdit && setEdit(false);
          form.resetFields();
          message.success(data?.message);
        })

        .catch((err) => {
          message.error(err?.data?.message);
        });
    },
  });

  useEffect(() => {
    if (editData) {
      formik.setFieldValue("company_name", editData?.data?.company_name);
      formik.setFieldValue("client_name", editData.data?.client_name);
      formik.setFieldValue("region", {
        value: editData.data?.region,
        label: editData.data?.region_name,
      });
      formik.setFieldValue("designation", {
        value: editData.data?.designation,
        label: editData.data?.designation_name,
      });
      formik.setFieldValue("email", editData.data?.email);
      formik.setFieldValue("phone", editData.data?.phone);
      formik.setFieldValue("type", {
        value: editData.data?.type,
        label: editData.data?.type_name,
      });
      formik.setFieldValue("address", editData.data?.address);
      formik.setFieldValue("gst", editData.data?.gst);
      formik.setFieldValue("industry", {
        value: editData.data?.industry,
        label: editData.data?.industry_name,
      });
      formik.setFieldValue("source", {
        value: editData.data?.source,
        label: editData.data?.source_name,
      });
      formik.setFieldValue("specification", editData.data?.specification);
      formik.setFieldValue("product_name", editData.data?.product_name);
      formik.setFieldValue("enquiry_category", {
        value: editData.data?.enquiry_category,
        label: editData.data?.enquiry_category_name,
      });
      // formik.setFieldValue("nature", {
      //   value: editData.data?.nature,
      //   label: editData.data?.nature_name,
      // });
      formik.setFieldValue("lead_name", editData.data?.lead_name);
      formik.setFieldValue("quantity", editData.data?.quantity);
      formik.setFieldValue("group", {
        value: editData.data?.group,
        label: editData.data?.group_name,
      });
    }
  }, [editData]);

  interface RegionOption {
    value: number;
    label: string;
  }
  const optionsRegion: RegionOption[] | undefined = regionData?.map(
    ({ id, name }: { id: number; name: string }) => ({
      value: id,
      label: name,
    })
  );
  const optionsDesignation: RegionOption[] | undefined = designationData?.map(
    ({ id, name }: { id: number; name: string }) => ({
      value: id,
      label: name,
    })
  );
  const optionsNature: RegionOption[] | undefined = natureData?.map(
    ({ id, name }: { id: number; name: string }) => ({
      value: id,
      label: name,
    })
  );
  const optionsType: RegionOption[] | undefined = typeData?.map(
    ({ id, name }: { id: number; name: string }) => ({
      value: id,
      label: name,
    })
  );
  const optionsIndustry: RegionOption[] | undefined = industryData?.map(
    ({ id, name }: { id: number; name: string }) => ({
      value: id,
      label: name,
    })
  );
  const optionsSource: RegionOption[] | undefined = sourceData?.map(
    ({ id, name }: { id: number; name: string }) => ({
      value: id,
      label: name,
    })
  );
  const optionsGroup: RegionOption[] | undefined = groupData?.map(
    ({ id, name }: { id: number; name: string }) => ({
      value: id,
      label: name,
    })
  );
  const optionsCategory: RegionOption[] | undefined = categoryData?.map(
    ({ id, name }: { id: number; name: string }) => ({
      value: id,
      label: name,
    })
  );

  const handleChangeRegion = (option: any) => {
    formik.setFieldValue("region", {
      value: option?.value,
      label: option?.label,
    });
  };
  const handleChangeCategory = (option: any) => {
    formik.setFieldValue("enquiry_category", {
      value: option?.value,
      label: option?.label,
    });
  };
  const handleChangeGroup = (option: any) => {
    formik.setFieldValue("group", {
      value: option?.value,
      label: option?.label,
    });
  };
  const handleChangeDesignation = (option: any) => {
    formik.setFieldValue("designation", {
      value: option?.value,
      label: option?.label,
    });
  };
  const handleChangeSource = (option: any) => {
    formik.setFieldValue("source", {
      value: option?.value,
      label: option?.label,
    });
  };
  const handleChangeIndustry = (option: any) => {
    formik.setFieldValue("industry", {
      value: option?.value,
      label: option?.label,
    });
  };
  const handleChangeType = (option: any) => {
    formik.setFieldValue("type", {
      value: option?.value,
      label: option?.label,
    });
  };
  const handleChangeNature = (option: any) => {
    formik.setFieldValue("nature", {
      value: option?.value,
      label: option?.label,
    });
  };
  const handleRegion = async (data: any) => {
    const dataParams = {
      name: data,
    };
    const finalData = {
      id: "region",
      body: dataParams,
    };

    await mutation(finalData)
      .unwrap()
      .then((data: any) => {
        message.success(data?.message);

        // setIsDataAvailable(false);
      })
      .catch((err: any) => {
        message.error(err?.data?.message);
        // setIsDataAvailable(true);
      });
  };
  const handleDesignation = async (data: any) => {
    const dataParams = {
      name: data,
    };
    const finalData = {
      id: "designation",
      body: dataParams,
    };

    await mutation(finalData)
      .unwrap()
      .then((data: any) => {
        message.success(data?.message);

        // setIsDataAvailable(false);
      })
      .catch((err: any) => {
        message.error(err?.data?.message);
        // setIsDataAvailable(true);
      });
  };
  const handleGroup = async (data: any) => {
    const dataParams = {
      name: data,
    };
    const finalData = {
      id: "group",
      body: dataParams,
    };

    await mutation(finalData)
      .unwrap()
      .then((data: any) => {
        message.success(data?.message);

        // setIsDataAvailable(false);
      })
      .catch((err: any) => {
        message.error(err?.data?.message);
        // setIsDataAvailable(true);
      });
  };

  const handleType = async (data: any) => {
    const dataParams = {
      name: data,
    };
    const finalData = {
      id: "type",
      body: dataParams,
    };

    await mutation(finalData)
      .unwrap()
      .then((data: any) => {
        message.success(data?.message);

        // setIsDataAvailable(false);
      })
      .catch((err: any) => {
        message.error(err?.data?.message);
        // setIsDataAvailable(true);
      });
  };
  const handleIndustry = async (data: any) => {
    const dataParams = {
      name: data,
    };
    const finalData = {
      id: "industry",
      body: dataParams,
    };

    await mutation(finalData)
      .unwrap()
      .then((data: any) => {
        message.success(data?.message);

        // setIsDataAvailable(false);
      })
      .catch((err: any) => {
        message.error(err?.data?.message);
        // setIsDataAvailable(true);
      });
  };
  const handleSource = async (data: any) => {
    const dataParams = {
      name: data,
    };
    const finalData = {
      id: "source",
      body: dataParams,
    };

    await mutation(finalData)
      .unwrap()
      .then((data: any) => {
        message.success(data?.message);

        // setIsDataAvailable(false);
      })
      .catch((err: any) => {
        message.error(err?.data?.message);
        // setIsDataAvailable(true);
      });
  };

  const handleEnquiry = async (data: any) => {
    const dataParams = {
      name: data,
    };
    const finalData = {
      id: "enquiry_category",
      body: dataParams,
    };

    await mutation(finalData)
      .unwrap()
      .then((data: any) => {
        message.success(data?.message);

        // setIsDataAvailable(false);
      })
      .catch((err: any) => {
        message.error(err?.data?.message);
        // setIsDataAvailable(true);
      });
  };
  const handleNature = async (data: any) => {
    const dataParams = {
      name: data,
    };
    const finalData = {
      id: "nature",
      body: dataParams,
    };

    await mutation(finalData)
      .unwrap()
      .then((data: any) => {
        message.success(data?.message);

        // setIsDataAvailable(false);
      })
      .catch((err: any) => {
        message.error(err?.data?.message);
        // setIsDataAvailable(true);
      });
  };
  const handleInputChange = (newValue: any) => {
    // Convert the input value to uppercase
    const upperCaseValue = newValue.toUpperCase();
    setInputValue(upperCaseValue);
  };
  const handleGroupChange = (newValue: any) => {
    // Convert the input value to uppercase
    const upperCaseValue = newValue.toUpperCase();
    setgroupValue(upperCaseValue);
  };
  const handleDesignationChange = (newValue: any) => {
    // Convert the input value to uppercase
    const upperCaseValue = newValue.toUpperCase();
    setDesignationValue(upperCaseValue);
  };
  const handleCategoryChange = (newValue: any) => {
    // Convert the input value to uppercase
    const upperCaseValue = newValue.toUpperCase();
    setCategoryValue(upperCaseValue);
  };
  const handleNatureChange = (newValue: any) => {
    // Convert the input value to uppercase
    const upperCaseValue = newValue.toUpperCase();
    setNatureValue(upperCaseValue);
  };
  const handleSourceChange = (newValue: any) => {
    // Convert the input value to uppercase
    const upperCaseValue = newValue.toUpperCase();
    setSourceValue(upperCaseValue);
  };
  const handleIndustryChange = (newValue: any) => {
    // Convert the input value to uppercase
    const upperCaseValue = newValue.toUpperCase();
    setIndustryValue(upperCaseValue);
  };
  const handleTypeChange = (newValue: any) => {
    // Convert the input value to uppercase
    const upperCaseValue = newValue.toUpperCase();
    setTypeValue(upperCaseValue);
  };

  return (
    <>
      <div>
        <Form layout="vertical" onFinish={formik.handleSubmit}>
          <Row>
            <Form.Item
              label="Product Name"
              // name="product_name"
              required
              hasFeedback
              validateStatus={
                formik.errors.product_name && formik.touched.product_name
                  ? "error"
                  : ""
              }
              help={
                formik.errors.product_name && formik.touched.product_name
                  ? formik.errors.product_name
                  : undefined
              }
              className="w-full"
            >
              <Input
                placeholder="Product Name"
                value={formik.values.product_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="product_name"
              />
            </Form.Item>
          </Row>
          <Row>
            <Form.Item
              // name="specification"
              label="Specification"
              required
              className="w-full"
              hasFeedback
              validateStatus={
                formik.errors.specification && formik.touched.specification
                  ? "error"
                  : ""
              }
              help={
                formik.errors.specification && formik.touched.specification
                  ? formik.errors.specification
                  : undefined
              }
            >
              <TextArea
                name="specification"
                placeholder="Enter Specification"
                showCount
                maxLength={250}
                value={formik.values.specification}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Form.Item>
          </Row>
          <Row gutter={16}>
            <Col span={24} md={{ span: 12 }}>
              <Form.Item
                // name="quantity"
                label="Quantity"
                required
                hasFeedback
                validateStatus={
                  formik.errors.quantity && formik.touched.quantity
                    ? "error"
                    : ""
                }
                help={
                  formik.errors.quantity && formik.touched.quantity
                    ? formik.errors.quantity
                    : undefined
                }
              >
                <Input
                  placeholder="Quantity"
                  value={formik.values.quantity}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="quantity"
                />
              </Form.Item>

              <Form.Item
                // name="client_name"
                label="Client Name"
                required
                hasFeedback
                validateStatus={
                  formik.errors.client_name && formik.touched.client_name
                    ? "error"
                    : ""
                }
                help={
                  formik.errors.client_name && formik.touched.client_name
                    ? formik.errors.client_name
                    : undefined
                }
              >
                <Input
                  name="client_name"
                  placeholder="Client Name"
                  value={formik.values.client_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
              <Form.Item
                // name="lead_name"
                label="Lead Name"
                required
                hasFeedback
                validateStatus={
                  formik.errors.lead_name && formik.touched.lead_name
                    ? "error"
                    : ""
                }
                help={
                  formik.errors.lead_name && formik.touched.lead_name
                    ? formik.errors.lead_name
                    : undefined
                }
              >
                <Input
                  name="lead_name"
                  placeholder="Lead Name"
                  value={formik.values.lead_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
              <Form.Item
                // name="designation"
                label="Designation"
                required
                hasFeedback
                validateStatus={
                  formik.errors.designation && formik.touched.designation
                    ? "error"
                    : ""
                }
                help={
                  formik.errors.designation && formik.touched.designation
                    ? formik.errors.designation
                    : undefined
                }
              >
                {/* <Select
                  placeholder="Designation"
                  value={formik.values.designation}
                  onChange={(value: string) =>
                    formik.setFieldValue("designation", value)
                  }
                  onBlur={formik.handleBlur}
                >
                  {designationData?.map((resp: any) => (
                    <Select.Option key={resp.id} value={resp.id}>
                      {resp?.name}
                    </Select.Option>
                  ))}
                </Select> */}
                <CreatableSelect
                  isClearable
                  name="designation"
                  options={optionsDesignation}
                  onCreateOption={(option) => {
                    handleDesignation(option);
                  }}
                  onChange={(option) => handleChangeDesignation(option)}
                  value={formik.values.designation}
                  placeholder="Designation"
                  inputValue={designationValue}
                  onInputChange={handleDesignationChange}
                />
              </Form.Item>
              <Form.Item
                // name="phone"
                label="Phone Number"
                required
                hasFeedback
                validateStatus={
                  formik.errors.phone && formik.touched.phone ? "error" : ""
                }
                help={
                  formik.errors.phone && formik.touched.phone
                    ? formik.errors.phone
                    : undefined
                }
              >
                <Input
                  name="phone"
                  placeholder="Phone Number"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={{ span: 12 }}>
              <Form.Item
                // name="group"
                label="Group"
                required
                hasFeedback
                validateStatus={
                  formik.errors.group && formik.touched.group ? "error" : ""
                }
                help={
                  formik.errors.group && formik.touched.group
                    ? formik.errors.group
                    : undefined
                }
              >
                {/* <Select
                  placeholder="Group"
                  value={formik.values.group}
                  onChange={(value: string) =>
                    formik.setFieldValue("group", value)
                  }
                  onBlur={formik.handleBlur}
                >
                  {groupData?.map((resp: any) => (
                    <Select.Option key={resp.id} value={resp.id}>
                      {resp?.name}
                    </Select.Option>
                  ))}
                </Select> */}
                <CreatableSelect
                  isClearable
                  name="group"
                  options={optionsGroup}
                  onCreateOption={(option) => {
                    handleGroup(option);
                  }}
                  onChange={(option) => handleChangeGroup(option)}
                  value={formik.values.group}
                  placeholder="Group"
                  inputValue={groupValue}
                  onInputChange={handleGroupChange}
                />
              </Form.Item>
              <Form.Item
                // name="region"
                label="Region"
                required
                hasFeedback
                validateStatus={
                  formik.errors.region && formik.touched.region ? "error" : ""
                }
                help={
                  formik.errors.region && formik.touched.region
                    ? formik.errors.region
                    : undefined
                }
              >
                {/* <Select
                  placeholder="Region"
                  value={formik.values.region}
                  onChange={(value: string) =>
                    formik.setFieldValue("region", value)
                  }
                  onBlur={formik.handleBlur}
                >
                  {regionData?.map((resp: any) => (
                    <Select.Option key={resp.id} value={resp.id}>
                      {resp?.name}
                    </Select.Option>
                  ))}
                </Select> */}
                <CreatableSelect
                  isClearable
                  name="region"
                  options={optionsRegion}
                  onCreateOption={(option) => {
                    handleRegion(option);
                  }}
                  onChange={(option) => handleChangeRegion(option)}
                  value={formik.values.region}
                  placeholder="Region"
                  inputValue={inputValue}
                  onInputChange={handleInputChange}
                />
              </Form.Item>
              <Form.Item
                // name="company_name"
                label="Company Name"
                required
                hasFeedback
                validateStatus={
                  formik.errors.company_name && formik.touched.company_name
                    ? "error"
                    : ""
                }
                help={
                  formik.errors.company_name && formik.touched.company_name
                    ? formik.errors.company_name
                    : undefined
                }
              >
                <Input
                  name="company_name"
                  placeholder="Company Name"
                  value={formik.values.company_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
              <Form.Item
                // name="email"
                label="Email"
                // required
                hasFeedback
                validateStatus={
                  formik.errors.email && formik.touched.email ? "error" : ""
                }
                help={
                  formik.errors.email && formik.touched.email
                    ? formik.errors.email
                    : undefined
                }
              >
                <Input
                  name="email"
                  placeholder="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
              <Form.Item
                // name="type"
                label="Type"
                required
                hasFeedback
                validateStatus={
                  formik.errors.type && formik.touched.type ? "error" : ""
                }
                help={
                  formik.errors.type && formik.touched.type
                    ? formik.errors.type
                    : undefined
                }
              >
                {/* <Select
                  placeholder="Type"
                  value={formik.values.type}
                  onChange={(value: string) =>
                    formik.setFieldValue("type", value)
                  }
                  onBlur={formik.handleBlur}
                >
                  {typeData?.map((resp: any) => (
                    <Select.Option key={resp.id} value={resp.id}>
                      {resp?.name}
                    </Select.Option>
                  ))}
                </Select> */}
                <CreatableSelect
                  isClearable
                  name="type"
                  options={optionsType}
                  onCreateOption={(option) => {
                    handleType(option);
                  }}
                  onChange={(option) => handleChangeType(option)}
                  value={formik.values.type}
                  placeholder="Type"
                  inputValue={typeValue}
                  onInputChange={handleTypeChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Form.Item
              className="w-full"
              // name="address"
              label="Address"
              required
              hasFeedback
              validateStatus={
                formik.errors.address && formik.touched.address ? "error" : ""
              }
              help={
                formik.errors.address && formik.touched.address
                  ? formik.errors.address
                  : undefined
              }
            >
              <TextArea
                name="address"
                showCount
                maxLength={250}
                placeholder="Address"
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Form.Item>
          </Row>
          <Row gutter={16}>
            <Col span={24} md={{ span: 12 }}>
              <Form.Item
                // name="gst"
                label="GST"
                // required
                hasFeedback
                validateStatus={
                  formik.errors.gst && formik.touched.gst ? "error" : ""
                }
                help={
                  formik.errors.gst && formik.touched.gst
                    ? formik.errors.gst
                    : undefined
                }
              >
                <Input
                  name="gst"
                  placeholder="gst"
                  value={formik.values.gst}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
              {/* <Form.Item
                // name="nature"
                label="Nature"
                required
                hasFeedback
                validateStatus={
                  formik.errors.nature && formik.touched.nature ? "error" : ""
                }
                help={
                  formik.errors.nature && formik.touched.nature
                    ? formik.errors.nature
                    : undefined
                }
              > */}
              {/* <Select
                  placeholder="Nature"
                  value={formik.values.nature}
                  onChange={(value: string) =>
                    formik.setFieldValue("nature", value)
                  }
                  onBlur={formik.handleBlur}
                >
                  {natureData?.map((resp: any) => (
                    <Select.Option key={resp.id} value={resp.id}>
                      {resp?.name}
                    </Select.Option>
                  ))}
                </Select> */}
              {/* <CreatableSelect
                  isClearable
                  name="nature"
                  options={optionsNature}
                  onCreateOption={(option) => {
                    handleNature(option);
                  }}
                  onChange={(option) => handleChangeNature(option)}
                  value={formik.values.nature}
                  placeholder="Nature"
                  inputValue={natureValue}
                  onInputChange={handleNatureChange}
                />
              </Form.Item> */}
              <Form.Item
                // name="enquiry_category"
                label="Enquiry Category"
                required
                hasFeedback
                validateStatus={
                  formik.errors.enquiry_category &&
                  formik.touched.enquiry_category
                    ? "error"
                    : ""
                }
                help={
                  formik.errors.enquiry_category &&
                  formik.touched.enquiry_category
                    ? formik.errors.enquiry_category
                    : undefined
                }
              >
                {/* <Select
                  placeholder="Enquiry Category"
                  value={formik.values.enquiry_category}
                  onChange={(value: string) =>
                    formik.setFieldValue("enquiry_category", value)
                  }
                  onBlur={formik.handleBlur}
                >
                  {categoryData?.map((resp: any) => (
                    <Select.Option key={resp.id} value={resp.id}>
                      {resp?.name}
                    </Select.Option>
                  ))}
                </Select> */}
                <CreatableSelect
                  isClearable
                  name="enquiry_category"
                  options={optionsCategory}
                  onCreateOption={(option) => {
                    handleEnquiry(option);
                  }}
                  onChange={(option) => handleChangeCategory(option)}
                  value={formik.values.enquiry_category}
                  placeholder="Enquiry Category"
                  inputValue={categoryValue}
                  onInputChange={handleCategoryChange}
                />
              </Form.Item>
            </Col>
            <Col span={24} md={{ span: 12 }}>
              <Form.Item
                // name="source"
                label="Source"
                required
                hasFeedback
                validateStatus={
                  formik.errors.source && formik.touched.source ? "error" : ""
                }
                help={
                  formik.errors.source && formik.touched.source
                    ? formik.errors.source
                    : undefined
                }
              >
                {/* <Select
                  placeholder="Source"
                  value={formik.values.source}
                  onChange={(value: string) =>
                    formik.setFieldValue("source", value)
                  }
                  onBlur={formik.handleBlur}
                >
                  {sourceData?.map((resp: any) => (
                    <Select.Option key={resp.id} value={resp.id}>
                      {resp?.name}
                    </Select.Option>
                  ))}
                </Select> */}
                <CreatableSelect
                  isClearable
                  name="source"
                  options={optionsSource}
                  onCreateOption={(option) => {
                    handleSource(option);
                  }}
                  onChange={(option) => handleChangeSource(option)}
                  value={formik.values.source}
                  placeholder="Source"
                  inputValue={sourceValue}
                  onInputChange={handleSourceChange}
                />
              </Form.Item>
              <Form.Item
                // name="industry"
                label="Industry"
                required
                hasFeedback
                validateStatus={
                  formik.errors.industry && formik.touched.industry
                    ? "error"
                    : ""
                }
                help={
                  formik.errors.industry && formik.touched.industry
                    ? formik.errors.industry
                    : undefined
                }
              >
                {/* <Select
                  placeholder="industry"
                  value={formik.values.industry}
                  onChange={(value: string) =>
                    formik.setFieldValue("industry", value)
                  }
                  onBlur={formik.handleBlur}
                >
                  {industryData?.map((resp: any) => (
                    <Select.Option key={resp.id} value={resp.id}>
                      {resp?.name}
                    </Select.Option>
                  ))}
                </Select> */}
                <CreatableSelect
                  isClearable
                  name="industry"
                  options={optionsIndustry}
                  onCreateOption={(option) => {
                    handleIndustry(option);
                  }}
                  onChange={(option) => handleChangeIndustry(option)}
                  value={formik.values.industry}
                  placeholder="Industry"
                  inputValue={industryValue}
                  onInputChange={handleIndustryChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Form.Item className="w-full">
              <CommonButton
                value={isEdit ? "Update" : "Add New Inquiry"}
                variant="wealay-primary-btn"
                className="w-full add-new-inquiry-btn"
                isLoading={isEdit ? editNpdLoading : isLoading}
                disabled={!formik.isValid}
              />
            </Form.Item>
          </Row>
        </Form>
      </div>
    </>
  );
};
