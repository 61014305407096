import { Avatar } from "antd";
import { getInitials } from "../../helpers/avatarConvertion";
interface IAvatar {
  text: string;
  size?: number;
}
export const CommonAvatar: React.FC<IAvatar> = ({ text, size }) => {
  return (
    <>
      <Avatar size={size} style={{ backgroundColor: `var(--primary)` }}>
        {text && getInitials(text)}
      </Avatar>
    </>
  );
};
