import { ResponsiveFunnel } from "@nivo/funnel";
import { Empty } from "antd";
import { Loader } from "../loader";
interface ICreateDashboard {
  datas: any;
  loader: boolean;
}

export const FunnelGraph: React.FC<ICreateDashboard> = ({ datas, loader }) => {
  const funnelData = datas?.map((item: any) => {
    return {
      id: item?.name,
      value: item?.count,
      label: item?.name,
    };
  });

  const data = [
    {
      id: "step_sent",
      value: 93719,
      label: "Sent",
    },
    {
      id: "step_viewed",
      value: 65529,
      label: "Viewed",
    },
    {
      id: "step_clicked",
      value: 53955,
      label: "Clicked",
    },
    {
      id: "step_add_to_card",
      value: 33536,
      label: "Add To Card",
    },
    {
      id: "step_purchased",
      value: 21459,
      label: "Purchased",
    },
  ];
  return (
    <>
      {loader ? (
        <div className="flex w-full justify-center items-center h-full">
          <Loader />
        </div>
      ) : datas?.length > 0 ? (
        <ResponsiveFunnel
          data={funnelData}
          margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
          // valueFormat=">-.4s"
          colors={{ scheme: "spectral" }}
          borderWidth={20}
          labelColor={{
            from: "color",
            modifiers: [["darker", 3]],
          }}
          beforeSeparatorLength={100}
          beforeSeparatorOffset={20}
          afterSeparatorLength={100}
          afterSeparatorOffset={20}
          currentPartSizeExtension={10}
          currentBorderWidth={40}
          motionConfig="wobbly"
        />
      ) : (
        <Empty className="w-full h-full flex justify-center items-center flex-col" />
      )}
    </>
  );
};
