export const PathConstants = {
  LOGIN: "/login",
  OVERVIEW: "/overview",
  HOME: "/",
  ORDER: "/order",
  SALES: "/sales",
  QUOTATION: "/quotation",
  DETAIL_QUOTATION: "/quotation/details",
  SALES_DETAILS: "/sales/details",

  DETAIL_ORDER: "/order/details",
  ERROR_403: "/403",

  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  TICKET_OPEN_URL: "/ticket",
  CREATE_QUOTATION: "/create-quotation",
  PROFILE: "/profile",
  INQUIRIES: "/inquiries",
  ALLOCATED: "/allocated",
  EMPLOYEE: "/employee",
  NOTIFICATION: "/notification",
  CONTACTS: "/contacts",

  ADD_LEAD: "/add-lead",

  DETAIL_PAGE: "/employee-detail-page",
};
