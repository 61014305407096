import { Avatar, Button, Col, Form, Input, Row, Select } from "antd";
import { CommonAvatar } from "../../CommonAvatar";
import { CommonButton } from "../../CommonButton/button";
import "./index.css";
export const AddNewLead = () => {
  return (
    <>
      <div>
        <Row justify={"center"}>
          {/* <Avatar size={100}>FC</Avatar> */}
          <CommonAvatar text={"FC"} size={100} />
        </Row>
        <Form layout="vertical">
          <Row>
            <Form.Item label="Company Name" className="w-full" required>
              <Input />
            </Form.Item>
          </Row>
          <Row gutter={16}>
            <Col>
              <Form.Item label="Client Name" required>
                <Input />
              </Form.Item>
              <Form.Item label="Designation" required>
                <Input />
              </Form.Item>
              <Form.Item label="Phone No" required>
                <Input />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="Region" required>
                <Input />
              </Form.Item>
              <Form.Item label="Email" required>
                <Input />
              </Form.Item>
              <Form.Item label="Type" required>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Form.Item label="Address" className="w-full" required>
              <Input />
            </Form.Item>
          </Row>
          <Row gutter={16}>
            <Col>
              <Form.Item label="GST" required>
                <Input />
              </Form.Item>
              <Form.Item label="Enquiry Category" required>
                <Input />
              </Form.Item>
              <Form.Item label="Nature" required>
                <Select
                  defaultValue="lucy"
                  style={{ width: 120 }}
                  //   onChange={handleChange}
                  options={[
                    { value: "jack", label: "Jack" },
                    { value: "lucy", label: "Lucy" },
                    { value: "Yiminghe", label: "yiminghe" },
                    { value: "disabled", label: "Disabled", disabled: true },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="Industry" required>
                <Input />
              </Form.Item>
              <Form.Item label="Source" required>
                <Select
                  defaultValue="lucy"
                  style={{ width: 120 }}
                  className="w-full"
                  //   onChange={handleChange}
                  options={[
                    { value: "jack", label: "Jack" },
                    { value: "lucy", label: "Lucy" },
                    { value: "Yiminghe", label: "yiminghe" },
                    { value: "disabled", label: "Disabled", disabled: true },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            {/* <Button className="w-full add-lead-btn-clr ">Add Lead</Button> */}
            <CommonButton variant="wealay-primary-btn" value="Add Lead" />
          </Row>
        </Form>
      </div>
    </>
  );
};
