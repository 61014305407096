import "./index.css";
import { Row, Col, Form, Input, Button, message } from "antd";
import { LoginLayout } from "../../components/LoginLayout";
import { useNavigate, redirect } from "react-router";
import { CommonButton } from "../../components/CommonButton/button";
import { Text } from "../../components/CommonFont/text";
import * as Yup from "yup";
import { Formik, FormikValues, useFormik } from "formik";
import { useLoginMutation } from "../../services/api";
import { setAccessToken } from "../../helpers/localStorage";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

export const LoginPage = () => {
  const navigate = useNavigate();
  interface FormValues {
    email: string;
    password: string;
  }
  const [login, { data, isLoading }] = useLoginMutation();
  const isManager = localStorage.getItem("user_id");
  const isQuotation = sessionStorage.getItem("current_path");
  const isQuotationUrl =
    (isQuotation && isQuotation.includes("/quotation/details")) ||
    (isQuotation && isQuotation.includes("/order/details"));

  const handleSubmit = (value: any) => {
    localStorage.setItem("token", "sdsdsdsd");
    localStorage.setItem("user", "manager");

    navigate("/overview");
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email format"
      )
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });
  const initialValues: FormValues = {
    email: "",
    password: "",
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      await login(values)
        .unwrap()
        .then((data) => {
          if (data?.user?.user_role === 0) {
            window.location.href = "http://139.59.81.163:8001/admin/";
          } else {
            setAccessToken(data && data?.token);

            localStorage.setItem("user_role", data?.user?.user_role);
            localStorage.setItem("user_id", data?.user?.id);
            if (isQuotationUrl) {
              window.location.href = isQuotation;
            } else {
              navigate("/overview");
              // Perform some action for isQuotation being false
              // For example: navigate("/other-page");
            }
          }

          message.success(data?.message);
        })

        .catch((err) => {
          message.error(err?.data?.message);
        });
    },
  });

  return (
    <>
      <div>
        <LoginLayout>
          <div className="login-page-div-1 ">
            <Text
              value="User Login"
              variant="wearlay-display"
              className="login-page-title-1"
            />
            <div className="login-page-title-2">
              <Text
                value="Please enter your login credentials to access your account."
                variant="wearlay-paragraph"
                className="login-page-title-1"
              />
            </div>

            <Form layout="vertical" onFinish={formik.handleSubmit}>
              <Form.Item
                required
                name="email"
                label="Email"
                hasFeedback
                validateStatus={
                  formik.errors.email && formik.touched.email ? "error" : ""
                }
                help={
                  formik.errors.email && formik.touched.email
                    ? formik.errors.email
                    : ""
                }
              >
                <Input
                  className="login-inputs"
                  placeholder="Enter Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
              <Form.Item
                label="Password"
                required
                hasFeedback
                validateStatus={
                  formik.errors.password && formik.touched.password
                    ? "error"
                    : ""
                }
                help={
                  formik.errors.password && formik.touched.password
                    ? formik.errors.password
                    : ""
                }
              >
                <Input.Password
                  name="password"
                  type="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="login-inputs"
                  placeholder="Password"
                />
              </Form.Item>

              <Form.Item>
                <CommonButton
                  variant="wealay-primary-btn"
                  value="Login"
                  className="login-btn"
                  disabled={!formik.isValid}
                  isLoading={isLoading}
                />
              </Form.Item>
              <div
                className="forgot-password-div "
                onClick={() => {
                  navigate("/forgot-password");
                }}
              >
                Forgot Password?
              </div>
            </Form>
          </div>
        </LoginLayout>
      </div>
    </>
  );
};
