import "./index.css";
import { Row, Col, Form, Input, Button, message } from "antd";
import { LoginLayout } from "../../components/LoginLayout";
import { useNavigate } from "react-router";
import { CommonButton } from "../../components/CommonButton/button";
import { Text } from "../../components/CommonFont/text";
import * as Yup from "yup";
import { Formik, FormikValues, useFormik } from "formik";
import { useForgotPasswordMutation } from "../../services/api";

export const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const [forgotPassword, { data, isLoading }] = useForgotPasswordMutation();

  interface FormValues {
    email: string;
  }

  const initialValues: FormValues = {
    email: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email format"
      )
      .required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      await forgotPassword(values)
        .unwrap()
        .then((data) => {
          navigate("/login");

          message.success(data?.message);
        })

        .catch((err) => {
          message.error(err?.data?.message);
        });
      // localStorage.setItem("token", "sdsdsdsd");
      // localStorage.setItem("user", "manager");

      // navigate("/overview");
      // Handle form submission
    },
  });

  return (
    <>
      <div>
        <LoginLayout>
          <div className="login-page-div-1 ">
            <div className="login-page-title-1 ">
              <Text
                value="Recover password"
                variant="wearlay-display"
                className="login-page-title-1"
              />
            </div>
            <div className="login-page-title-2">
              <Text
                value="Please enter the email address associated with your account to reset your password."
                variant="wearlay-paragraph"
                className="login-page-title-1"
              />
            </div>

            <Form layout="vertical" onFinish={formik.handleSubmit}>
              <Form.Item
                // label={"Email"}
                // required
                // name={"email"}
                // rules={[
                //   {
                //     required: true,
                //     message: "email is required",
                //   },
                // ]}
                required
                name="email"
                label="Email"
                hasFeedback
                validateStatus={
                  formik.errors.email && formik.touched.email ? "error" : ""
                }
                help={
                  formik.errors.email && formik.touched.email
                    ? formik.errors.email
                    : ""
                }
              >
                <Input
                  className="login-inputs"
                  placeholder="Enter Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
              <Form.Item>
                <CommonButton
                  variant="wealay-primary-btn"
                  value=" Recover Password"
                  className="login-btn"
                  disabled={!formik.isValid}
                  isLoading={isLoading}
                />
              </Form.Item>
              <div
                className="forgot-password-div-1"
                onClick={() => {
                  navigate("/login");
                }}
              >
                Cancel
              </div>
            </Form>
          </div>
        </LoginLayout>
      </div>
    </>
  );
};
