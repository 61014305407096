import { Col, Divider, Progress, Row, Select, Space } from "antd";
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { DashboardCard } from "../../components/DashboardCard";
import { DashboardCardLineChart } from "../../components/DashboardGraph";
import "./index.css";
import { Text } from "../../components/CommonFont/text";
import { PieCard } from "../../components/DashboardGraph/pieChart";
import {
  useGetDashboardBar2ChartCountQuery,
  useGetDashboardBarChartCountQuery,
  useGetDashboardCountQuery,
  useGetDashboardFunnelChartCountQuery,
  useGetDashboardManagerCountQuery,
  useGetDashboardPieChartCountQuery,
  useGetNotificationsQuery,
} from "../../services/api";
import { PieChart1 } from "../../components/DashboardGraph/pieChart1";
import moment from "moment";
import { BarChart1 } from "../../components/DashboardGraph/barChart1";
import { BarChart2 } from "../../components/DashboardGraph/barChart2";
import { FunnelGraph } from "../../components/DashboardGraph/funnelGraph";
export const Overview = () => {
  const navigate = useNavigate();
  const [date, setDate] = useState(7);
  const [source, setSource] = useState("source");

  const isManager = localStorage.getItem("user_role");
  const userId = localStorage.getItem("user_id");
  const currentYear = moment().year();

  const { data, isLoading } = useGetDashboardCountQuery(userId);
  const { data: managerCount, isLoading: managerCountLoading } =
    useGetDashboardManagerCountQuery(userId);
  const funnelParams = {
    id: userId,
    source: source,
  };
  const { data: funnelCount, isLoading: funnelCountLoading } =
    useGetDashboardFunnelChartCountQuery(funnelParams);
  const dashboardParams = {
    id: userId,
    date_id: date,
  };
  const { data: pieCountData, isLoading: pieCountLoading } =
    useGetDashboardPieChartCountQuery(dashboardParams);
  const dashboardBarParams = {
    id: userId,
    date_id: currentYear,
  };
  const { data: barCountData, isLoading: barCountLoading } =
    useGetDashboardBarChartCountQuery(dashboardBarParams);
  const dashboardBarParams2 = {
    id: userId,
    date_id: currentYear,
  };
  const { data: barCountData2, isLoading: barCount2Loading } =
    useGetDashboardBar2ChartCountQuery(dashboardBarParams2);
  const currentDate = moment();
  const dateCount = currentDate.daysInMonth();
  const yearCount = moment().endOf("year").dayOfYear();

  useEffect(() => {
    navigate("/overview");
  }, []);
  return (
    <>
      <div className="mt-24 p-1">
        <Text
          value="Overview"
          variant="wearlay-display"
          className="overview-title"
        />
        <div className="flex w-full gap-2 p-1 resp-card">
          {isManager === "1" && (
            <DashboardCard
              count={managerCount?.data?.executives_count}
              subTitle={"Total Employees"}
              img={"/assets/totalEmployee.svg"}
            />
          )}
          <DashboardCard
            count={data?.data?.sales_lead_count}
            subTitle={"Total Sales"}
            img={"/assets/totalSales.svg"}
          />
          <DashboardCard
            count={data?.data?.total_orders_count}
            subTitle={"Total Orders"}
            img={"/assets/totalOrder.svg"}
          />
          <DashboardCard
            count={data?.data?.npd_count}
            subTitle={"Total NPD"}
            img={"/assets/totalNpd.svg"}
          />
          <DashboardCard
            count={data?.data?.total_orders_delivered}
            subTitle={"Orders Delivered"}
            img={"/assets/totalDelivered.svg"}
          />
        </div>
        <div className="mt-24px">
          <Row justify="space-around" className="resp-card-1">
            <Col span={24} md={{ span: 24 }}>
              <div className="h-300">
                <div className="flex justify-between">
                  <Text value="Sales Report" variant="wearlay-headline-2" />
                  <div>
                    <Select
                      className="activity-select "
                      defaultValue="Last week"
                      options={[
                        { value: "7", label: "Last week" },

                        { value: dateCount, label: "Last Month" },
                        { value: yearCount, label: "Last Year" },
                      ]}
                      onChange={(e: any) => {
                        setDate(e);
                      }}
                    />
                  </div>
                </div>
                {/* <DashboardCardLineChart /> */}
                <PieChart1
                  datas={pieCountData?.data}
                  loader={pieCountLoading}
                />
              </div>
              <Row justify={"space-between"} className="mt-24px resp-card-1 ">
                <Col
                  span={24}
                  className="sales-percentage-div "
                  md={{ span: 8 }}
                >
                  <Text
                    value="Sales Amount Closing"
                    variant="wearlay-headline-2"
                  />
                  <div
                    style={{ height: "250px" }}
                    className="overview-relative"
                  >
                    <BarChart2 />
                  </div>
                  {/* <Text
                    value="75/100 Target"
                    variant="wearlay-subtitle"
                    className="flex justify-center"
                  /> */}
                </Col>
                <Col
                  span={24}
                  className="sales-percentage-div"
                  md={{ span: 7 }}
                >
                  {/* <div className="sales-report-title">Upcoming Follow ups</div> */}
                  <Text
                    value="Leads"
                    variant="wearlay-headline-2"
                    className=""
                  />
                  <div className="h-250">
                    <BarChart1
                      datas={barCountData?.data}
                      loader={barCountLoading}
                    />
                  </div>
                </Col>
                <Col
                  span={24}
                  className="sales-percentage-div"
                  md={{ span: 8 }}
                >
                  {/* <div className="sales-report-title">Upcoming Follow ups</div> */}
                  <div className="flex justify-between">
                    <Text
                      value={
                        source === "source"
                          ? "Source Analytics"
                          : "Enquiry Category Analytics"
                      }
                      variant="wearlay-headline-2"
                      className=""
                    />
                    <Select
                      className="activity-select "
                      defaultValue="Source"
                      options={[
                        { value: "source", label: "Source" },

                        // { value: "type", label: "Industry" },
                        {
                          value: "enquiry_category",
                          label: "Enquiry Category",
                        },
                      ]}
                      onChange={(e: any) => {
                        setSource(e);
                      }}
                    />
                  </div>
                  <div className="h-250">
                    <FunnelGraph
                      datas={funnelCount?.data}
                      loader={funnelCountLoading}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
