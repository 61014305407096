import { Avatar, Col, Divider, Empty, Row, Space, Spin } from "antd";
import { CommonAvatar } from "../../components/CommonAvatar";
import "./index.css";
import { Text } from "../../components/CommonFont/text";
import {
  useGetNotificationsQuery,
  useReadedNotificationMutation,
} from "../../services/api";
import moment from "moment";
import { useNavigate, useSearchParams } from "react-router-dom";
export const Notification = () => {
  const userId = localStorage.getItem("user_id");

  const { data: notificationData, isLoading: notificationLoading } =
    useGetNotificationsQuery(userId);

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const createdBy = searchParams.get("created_by");

  const [
    mutation,
    { data: createquotationData, isLoading: createQuotationLoading },
  ] = useReadedNotificationMutation();
  return (
    <>
      <div className="notification-over-div">
        <div className="notification-main-div ">
          <div className="flex flex-col justify-start ">
            <Text
              value="Notifications"
              variant="wearlay-headline-1"
              className="notification-mt24"
            />
            {/* <Text
              value="Today"
              variant="wearlay-label-bold"
              className="notification-mt24 notification-title-4"
            /> */}
          </div>
          {notificationLoading ? (
            <Spin
              className="h-full w-full flex justify-center items-center notification-height"
              size="large"
            />
          ) : notificationData?.data?.length > 0 ? (
            <div className="notification-height">
              {notificationData?.data?.map((item: any) => (
                <Row
                  className={
                    item?.read
                      ? "gap-2 notification-cursor-read"
                      : "gap-2 notification-cursor"
                  }
                  onClick={() => {
                    item?.sales_lead
                      ? navigate(`/sales/details?id=${item?.sales_lead}`)
                      : item?.quotation
                      ? navigate(
                          `/quotation/details?id=${item?.quotation}&created_by=${userId}`
                        )
                      : navigate(
                          `/order/details?id=${item?.order}&created_by=${userId}`
                        );
                    mutation(item?.id);
                  }}
                >
                  {/* <Col span={2}>
              <CommonAvatar text={"C N"} />
            </Col> */}
                  <Col span={24} className="flex flex-col gap-2">
                    <Text
                      value={item?.subject}
                      variant="wearlay-paragraph-bold"
                    />
                    <Text
                      value={`Tomorrow is the last day for the ${
                        item?.sales_company_name
                          ? "sales id"
                          : item?.quotation_name
                          ? "quotation id"
                          : "order id"
                      }  ${
                        item?.sales_company_name
                          ? item?.sales_company_name
                          : item?.quotation_name
                          ? item?.quotation_name
                          : item?.order_name
                      } ${
                        item?.sales_lead
                          ? "sales"
                          : item?.quotation
                          ? "quotation"
                          : "order"
                      }  ${item?.order ? "dipatch" : "follow-up"}`}
                      variant="wearlay-paragraph"
                      className="notification-title-1"
                    />
                    <div className="flex gap-2">
                      <img src="/assets/time.svg"></img>
                      <Text
                        value={moment(
                          item?.created_date.slice(0, 19) + "Z"
                        ).fromNow()}
                        variant="wearlay-paragraph"
                        className="notification-title-1"
                      />
                    </div>
                  </Col>
                  <Divider />
                </Row>
              ))}
            </div>
          ) : (
            <Empty className="h-full w-full flex flex-col justify-center items-center notification-height" />
          )}
        </div>
      </div>
    </>
  );
};
