import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Empty,
  Form,
  Input,
  message,
  Modal,
  Row,
  Skeleton,
  Tooltip,
} from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import { CommonButton } from "../../CommonButton/button";
import "./index.css";
import { Text } from "../../CommonFont/text";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import _ from "lodash";

import {
  useCloseQuotationMutation,
  useCreateCreateOrderMutation,
  useEditQuotationMutation,
  useGetQuotationByIdQuery,
  useGetQuotationQuery,
  useGetQuotationShareQuery,
  useGetSalesAfterNpdQuotationQuery,
  useGetSalesAfterQuotationQuery,
  useLazyGetSalesAfterNpdQuotationQuery,
  useLazyGetSalesAfterQuotationQuery,
} from "../../../services/api";
import moment from "moment";
import FormItem from "antd/es/form/FormItem";
interface IQuotationData {
  data?: any;
  setEdit?: (data: boolean) => void;
  setPreview?: (data: boolean) => void;
  setdata?: (data: any) => void;
  setEditdata?: (data: any) => void;

  loading?: boolean;
}
export const AddNewQuotation: React.FC<IQuotationData> = ({
  setEdit,
  setPreview,
  loading,
  setdata,
  setEditdata,
}) => {
  const [isAdd, setIsAdd] = useState(true);
  const [isSave, setIsSave] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isClosed, setIsClosed] = useState(false);

  const userId = localStorage.getItem("user_id");

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    date: Yup.date().required("This date is required"),
    note: Yup.string().required("This note is required"),
    next_follow_up_date: Yup.date().required("This date is required"),

    // advanceDate: Yup.date(),
    // advanceAmount: Yup.string(),
    // advanceNote: Yup.string(),
  });
  const validationSchema2 = Yup.object().shape({
    advance_received_date: Yup.date().required(
      "This advanced date is required"
    ),
    advance_received_amount: Yup.string()
      .required("This advanced amount is required")
      .matches(/^[0-9]+$/, "Advanced amount  must contain only numbers"),
    // note: Yup.string().required("This advanced note is required"),

    order_punched_date: Yup.date().required("This advanced date is required"),
  });
  const validationSchema3 = Yup.object().shape({
    // note: Yup.string().required("This advanced note is required"),

    order_punched_date: Yup.date().required("This advanced date is required"),
  });
  const [searchParams, setSearchParams] = useSearchParams();

  const quotationId = searchParams.get("id");
  const createdBy = searchParams.get("created_by");
  const disabledDate = (current: any) => {
    // Disable dates greater than today
    return current && current > moment().endOf("day");
  };
  // const { data, isLoading } = useGetQuotationByIdQuery(quotationId);
  const shareParams = {
    id: quotationId,
    user_id: createdBy,
  };
  const { data, isLoading } = useGetQuotationShareQuery(shareParams);

  // const [trigger, { data: salesData, isLoading: salesLoading }] =
  //   useLazyGetSalesAfterQuotationQuery();
  // const [npdTrigger, { data: npdData, isLoading: npdLoading }] =
  //   useLazyGetSalesAfterNpdQuotationQuery();
  const { data: npdData, isLoading: npdLoading } =
    useGetSalesAfterNpdQuotationQuery(data?.data?.npd);

  const { data: salesData, isLoading: salesLoading } =
    useGetSalesAfterQuotationQuery(data?.data?.sales_lead);
  const [
    editQuotation,
    { data: editQuotations, isLoading: editQuotationLoading },
  ] = useEditQuotationMutation();
  const [closeQuotation, { isLoading: closeQuotationLoading }] =
    useCloseQuotationMutation();
  const [createOrder, { data: createOrderData, isLoading: createLoading }] =
    useCreateCreateOrderMutation();
  const formik = useFormik({
    initialValues: {
      // advanceAmount: undefined,
      // advanceDate: undefined,
      date: undefined,
      note: "",
      next_follow_up_date: undefined,
      // advanceNote: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const followUp =
        data?.data?.follow_up?.length > 0 ? [...data.data.follow_up] : [];
      const nextFollowUp = {
        date: values.date,
        notes: values.note,
        next_follow_up_date: values.next_follow_up_date,
      };

      if (followUp.length === 0) {
        followUp.push(nextFollowUp);
      } else if (followUp.length === 1) {
        followUp.push(nextFollowUp);
      } else if (followUp.length === 2) {
        followUp.push(nextFollowUp);
      }

      const editParams = {
        body: { follow_up: followUp },
        id: quotationId,
      };
      await editQuotation(editParams)
        .unwrap()
        .then((data) => {
          setIsSave(false);
          setIsAdd(true);
          setPreview && setPreview(false);
          navigate("/quotation");
          form.resetFields();
          message.success(data?.message);
        })

        .catch((err) => {
          message.error(err?.data?.message);
        });
    },
  });
  const formik2 = useFormik({
    initialValues: {
      advance_received_amount: "",
      advance_received_date: "",
      // note: "",
      order_punched_date: "",
    },
    validationSchema: isChecked ? validationSchema3 : validationSchema2,
    onSubmit: async (values) => {
      const finalData = {
        ...values,
        special_consideration_for_payment: isChecked,
      };
      const createParams = {
        body: finalData,
        id: userId,
        qid: quotationId,
      };
      await createOrder(createParams)
        .unwrap()
        .then((data) => {
          form.resetFields();
          message.success(data?.message);
          navigate("/order");
        })

        .catch((err) => {
          message.error(err?.data?.message);
        });
    },
  });
  const onCloseQuotation = async () => {
    await closeQuotation(quotationId)
      .unwrap()
      .then((data) => {
        form.resetFields();
        message.success(data?.message);
        navigate("/quotation");
        setIsClosed(false);
        setPreview && setPreview(false);
      })

      .catch((err) => {
        message.error(err?.data?.message);
      });
  };
  const formik3 = useFormik({
    initialValues: {
      note: "",
      order_punched_date: "",
    },
    validationSchema: validationSchema2,
    onSubmit: (values) => {},
  });
  const handleSubmit = (value: any) => {
    localStorage.setItem("token", "sdsdsdsd");
  };

  useEffect(() => {
    const currentDate = moment().format("YYYY-MM-DD");
    formik2.setFieldValue("order_punched_date", currentDate);
    formik2.setFieldValue("advance_received_date", currentDate);
  }, []);
  useEffect(() => {
    setdata && setdata(salesData ? salesData : npdData);
    setEditdata && setEditdata(data);
  }, [salesData, npdData]);
  // useEffect(() => {
  //   if (data?.data?.npd) {
  //     npdTrigger(data?.data?.npd && data?.data?.npd);
  //   }
  // }, [data]);
  return (
    <>
      {isLoading ? (
        <Skeleton avatar paragraph={{ rows: 8 }} />
      ) : data ? (
        <div>
          <div>
            <div className="flex justify-between">
              {/* <div className="create-quotation-title-3">Quotation</div> */}
              <Text value="Quotation" variant="wearlay-headline-1" />
              <div>
                <img src="/assets/jaws.svg"></img>
              </div>
            </div>

            <div className="create-quotation-border-no-padding create-quotation-margin-top">
              <div className="p-1 flex flex-col gap-2">
                {/* <div className="create-quotation-title-1">
              Customer Unique Identification Number
            </div> */}
                <Text
                  value="Customer Unique Identification Number"
                  variant="wearlay-label-bold"
                  className="create-quotation-title-1"
                />

                {/* <div className="create-quotations-title-2">12455678</div> */}
                <Text
                  value={
                    salesData?.data[0]
                      ?.contacts_customer_unique_identification_number
                      ? salesData?.data[0]
                          ?.contacts_customer_unique_identification_number
                      : "-"
                  }
                  variant="wearlay-paragraph-bold"
                  className="create-quotations-title-2"
                />
              </div>
              <div className="p-1 flex flex-col gap-2 create-quotation-border ">
                {/* <div className="create-quotation-title-1">
              Customer Unique Identification Number
            </div> */}
                <Text
                  value="Lead Name"
                  variant="wearlay-label-bold"
                  className="create-quotation-title-1"
                />

                {/* <div className="create-quotations-title-2">12455678</div> */}
                <Text
                  value={
                    salesData?.data[0]?.lead_name
                      ? salesData?.data[0]?.lead_name
                      : npdData?.data[0]?.lead_name
                  }
                  variant="wearlay-paragraph-bold"
                  className="create-quotations-title-2"
                />
              </div>
              <Row>
                <Col span={24} md={{ span: 12 }}>
                  <div className="create-quotation-border flex flex-col gap-2">
                    {/* <div className="create-quotation-title-1">Company Name</div> */}
                    <Text
                      value="Code"
                      variant="wearlay-label-bold"
                      className="create-quotation-title-1"
                    />
                    <div className="create-quotations-title-2">
                      {" "}
                      <Tooltip
                        title={
                          salesData?.data[0]?.sales_lead_id
                            ? salesData?.data[0]?.sales_lead_id
                            : npdData?.data[0]?.code
                        }
                      >
                        {(salesData?.data[0]?.sales_lead_id
                          ? salesData?.data[0]?.sales_lead_id
                          : npdData?.data[0]?.code
                        )?.length > 25
                          ? (salesData?.data[0]?.sales_lead_id
                              ? salesData?.data[0]?.sales_lead_id
                              : npdData?.datap[0]?.code
                            )
                              .slice(0, 25)
                              .concat("...")
                          : salesData?.data[0]?.sales_lead_id
                          ? salesData?.data[0]?.sales_lead_id
                          : npdData?.data[0]?.code}
                      </Tooltip>
                    </div>
                    {/* <Text
                      value={
                        salesData?.data[0]?.sales_lead_id
                          ? salesData?.data[0]?.sales_lead_id
                          : npdData?.data?.sales_lead_id
                          ? npdData?.data?.sales_lead_id
                          : "-"
                      }
                      variant="wearlay-paragraph-bold"
                      className="create-quotations-title-2"
                    /> */}
                  </div>
                  <div className="create-quotation-border flex flex-col gap-2">
                    {/* <div className="create-quotation-title-1">Client Name</div>
                <div className="create-quotations-title-2">Roshan</div> */}
                    <Text
                      value="Client Name"
                      variant="wearlay-label-bold"
                      className="create-quotation-title-1"
                    />
                    <Text
                      value={
                        salesData?.data[0]?.client_name
                          ? salesData?.data[0]?.client_name
                          : npdData?.data[0]?.client_name
                      }
                      variant="wearlay-paragraph-bold"
                      className="create-quotations-title-2"
                    />
                  </div>
                  <div className="create-quotation-border flex flex-col gap-2">
                    {/* <div className="create-quotation-title-1">Email</div>
                <div className="create-quotations-title-2">
                  roshan@chema.com
                </div> */}
                    <Text
                      value="Email"
                      variant="wearlay-label-bold"
                      className="create-quotation-title-1"
                    />
                    <Text
                      value={
                        salesData?.data[0]?.email
                          ? salesData?.data[0]?.email
                          : npdData?.data[0]?.email
                      }
                      variant="wearlay-paragraph-bold"
                      className="create-quotations-title-2"
                    />
                  </div>
                  <div className="create-quotation-border flex flex-col gap-2">
                    {/* <div className="create-quotation-title-1">GST</div>
                <div className="create-quotations-title-2">24AAACC1206D1ZM</div> */}
                    <Text
                      value="GST"
                      variant="wearlay-label-bold"
                      className="create-quotation-title-1"
                    />
                    <Text
                      value={
                        salesData?.data[0]?.gst
                          ? salesData?.data[0]?.gst
                          : npdData?.data[0]?.gst
                      }
                      variant="wearlay-paragraph-bold"
                      className="create-quotations-title-2"
                    />
                  </div>

                  {/* <div className="create-quotation-border flex flex-col gap-2"> */}
                  {/* <div className="create-quotation-title-1">Address</div>
                <div className="create-quotations-title-2">
                  City Light Road, Beside Shree Maheshwari Bhawan, Athwalinesrn,
                  Surat, Gujarat - 335009, India
                </div> */}
                  {/* <Text
                      value="Address"
                      variant="wearlay-label-bold"
                      className="create-quotation-title-1"
                    />
                    <Text
                      value={
                        salesData?.data[0]?.address
                          ? salesData?.data[0]?.address
                          : npdData?.data[0]?.address
                      }
                      variant="wearlay-paragraph-bold"
                      className="create-quotations-title-2"
                    />
                  </div> */}
                </Col>
                <Col span={24} md={{ span: 12 }}>
                  <div className="create-quotation-border flex flex-col gap-2">
                    {/* <div className="create-quotation-title-1">Company Name</div> */}
                    <Text
                      value="Company Name"
                      variant="wearlay-label-bold"
                      className="create-quotation-title-1"
                    />
                    {/* <div className="create-quotations-title-2">Chema</div> */}
                    <Text
                      value={
                        salesData?.data[0]?.company_name
                          ? salesData?.data[0]?.company_name
                          : npdData?.data[0]?.company_name
                      }
                      variant="wearlay-paragraph-bold"
                      className="create-quotations-title-2"
                    />
                  </div>
                  <div className="create-quotation-border flex flex-col gap-2">
                    {/* <div className="create-quotation-title-1">Region</div>
                <div className="create-quotations-title-2">Gujarath</div> */}
                    <Text
                      value="Region"
                      variant="wearlay-label-bold"
                      className="create-quotation-title-1"
                    />
                    <Text
                      value={
                        salesData?.data[0]?.region_name
                          ? salesData?.data[0]?.region_name
                          : npdData?.data[0]?.region_name
                      }
                      variant="wearlay-paragraph-bold"
                      className="create-quotations-title-2"
                    />
                  </div>
                  <div className="create-quotation-border flex flex-col gap-2">
                    {/* <div className="create-quotation-title-1">Phone No</div>
                <div className="create-quotations-title-2">098 25 314786</div> */}
                    <Text
                      value="Phone No"
                      variant="wearlay-label-bold"
                      className="create-quotation-title-1"
                    />
                    <Text
                      value={
                        salesData?.data[0]?.phone
                          ? salesData?.data[0]?.phone
                          : npdData?.data[0]?.phone
                      }
                      variant="wearlay-paragraph-bold"
                      className="create-quotations-title-2"
                    />
                  </div>
                  <div className="p-1 flex flex-col gap-2 create-quotation-border">
                    {/* <div className="create-quotation-title-1">Enquiry Category</div>
                <div className="create-quotations-title-2"></div> */}
                    <Text
                      value="Enquiry Category"
                      variant="wearlay-label-bold"
                      className="create-quotation-title-1"
                    />
                    <Text
                      value={
                        salesData?.data[0]?.enquiry_category_name
                          ? salesData?.data[0]?.enquiry_category_name
                          : npdData?.data[0]?.enquiry_category_name
                      }
                      variant="wearlay-paragraph-bold"
                      className="create-quotations-title-2"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <div className="create-quotation-border flex flex-col gap-2 w-full">
                  {/* <div className="create-quotation-title-1">Address</div>
                <div className="create-quotations-title-2">
                  City Light Road, Beside Shree Maheshwari Bhawan, Athwalinesrn,
                  Surat, Gujarat - 335009, India
                </div> */}
                  <Text
                    value="Address"
                    variant="wearlay-label-bold"
                    className="create-quotation-title-1"
                  />
                  <Text
                    value={
                      salesData?.data[0]?.address
                        ? salesData?.data[0]?.address
                        : npdData?.data[0]?.address
                    }
                    variant="wearlay-paragraph-bold"
                    className="create-quotations-title-2 break-all"
                  />
                </div>
              </Row>
            </div>

            <Row className="create-quotation-margin-top create-quotation-div-2 ">
              <Col span={24} md={{ span: 12 }}>
                <div className="create-quotation-border flex flex-col gap-2">
                  {/* <div className="create-quotation-title-1">Material Grade</div>
              <div className="create-quotations-title-2">Stainless Steel</div> */}
                  <Text
                    value="Material Grade"
                    variant="wearlay-label-bold"
                    className="create-quotation-title-1"
                  />
                  <Text
                    value={data?.data?.material_grade}
                    variant="wearlay-paragraph-bold"
                    className="create-quotations-title-2"
                  />
                </div>
                <div className="create-quotation-border flex flex-col gap-2">
                  {/* <div className="create-quotation-title-1">Mould Code</div>
              <div className="create-quotations-title-2">1234</div> */}
                  <Text
                    value="Mould Code"
                    variant="wearlay-label-bold"
                    className="create-quotation-title-1"
                  />
                  <Text
                    value={data?.data?.mould_code}
                    variant="wearlay-paragraph-bold"
                    className="create-quotations-title-2"
                  />
                </div>
                <div className="create-quotation-border flex flex-col gap-2">
                  {/* <div className="create-quotation-title-1">Date Created</div>
              <div className="create-quotations-title-2">24/05/2023</div> */}
                  <Text
                    value="Data Created"
                    variant="wearlay-label-bold"
                    className="create-quotation-title-1"
                  />
                  <Text
                    value={moment(data?.data?.date).format("DD-MM-YYYY")}
                    variant="wearlay-paragraph-bold"
                    className="create-quotations-title-2"
                  />
                </div>
              </Col>
              <Col span={24} md={{ span: 12 }}>
                <div className="create-quotation-border flex flex-col gap-2">
                  {/* <div className="create-quotation-title-1">Drawing Number</div>
              <div className="create-quotations-title-2">4</div> */}
                  <Text
                    value="Drawing Number"
                    variant="wearlay-label-bold"
                    className="create-quotation-title-1"
                  />
                  <Text
                    value={data?.data?.drawing_number}
                    variant="wearlay-paragraph-bold"
                    className="create-quotations-title-2"
                  />
                </div>
                <div className="create-quotation-border flex flex-col gap-2">
                  {/* <div className="create-quotation-title-1">Design Code</div>
              <div className="create-quotations-title-2">321</div> */}
                  <Text
                    value="Design Code"
                    variant="wearlay-label-bold"
                    className="create-quotation-title-1"
                  />
                  <Text
                    value={data?.data?.design_code}
                    variant="wearlay-paragraph-bold"
                    className="create-quotations-title-2"
                  />
                </div>
              </Col>
            </Row>
            <Row className="create-quotation-margin-top">
              <Col
                span={6}
                className="create-quotations-title-2 create-quotation-border "
              >
                Item Information
              </Col>
              <Col
                span={
                  _.some(data?.data?.items, (item) => item?.discount > 0)
                    ? 3
                    : 6
                }
                className="create-quotations-title-2 create-quotation-border "
              >
                Brand
              </Col>
              <Col
                span={3}
                className="create-quotations-title-2 create-quotation-border "
              >
                Qty
              </Col>
              <Col
                span={3}
                className="create-quotations-title-2 create-quotation-border "
              >
                Rate
              </Col>
              {_.some(data.data.items, (item) => item.discount > 0) && (
                <Col
                  span={3}
                  className="create-quotations-title-2 create-quotation-border-10 "
                >
                  Discount
                  <span className="flex justify-center">%</span>
                </Col>
              )}
              <Col
                span={3}
                className="create-quotations-title-2 create-quotation-border "
              >
                GST%
              </Col>
              <Col
                span={3}
                className="create-quotations-title-2 create-quotation-border "
              >
                Total
              </Col>
            </Row>
            {data?.data?.items?.map((items: any) => (
              <Row>
                <Col span={6} className="create-quotation-border ">
                  {/* {items?.item_information} */}
                  <Tooltip title={items?.item_information}>
                    {items?.item_information.length > 10
                      ? items?.item_information.slice(0, 8).concat("...")
                      : items?.item_information}
                  </Tooltip>
                </Col>
                <Col
                  span={
                    _.some(data?.data?.items, (item) => item?.discount > 0)
                      ? 3
                      : 6
                  }
                  className="create-quotation-border "
                >
                  <Tooltip title={items?.brand}>
                    {items?.brand?.length > 5
                      ? items?.brand.slice(0, 5).concat("...")
                      : items?.brand}
                  </Tooltip>
                </Col>
                <Col span={3} className="create-quotation-border ">
                  {items?.qty}
                </Col>
                <Col span={3} className="create-quotation-border ">
                  {items?.rate}
                </Col>
                {items?.discount && (
                  <Col span={3} className="create-quotation-border ">
                    {items?.discount ? items?.discount : "-"}
                  </Col>
                )}
                <Col span={3} className="create-quotation-border ">
                  {items?.gst}
                </Col>
                <Col span={3} className="create-quotation-border ">
                  {items?.total}
                </Col>
              </Row>
            ))}
            {/* <Row>
          <Col
            span={18}
            className="create-quotation-border create-quotations-title-2 flex justify-end "
          >
            Sales Discount
          </Col>
          <Col span={6} className="create-quotation-border ">
            400
          </Col>
        </Row> */}
            {/* <Row>
          <Col
            span={18}
            className="create-quotation-border create-quotations-title-2 flex justify-end"
          >
            Total Discount
          </Col>
          <Col span={6} className="create-quotation-border ">
            400
          </Col>
        </Row> */}
            <Row>
              <Col
                span={18}
                className="create-quotation-border create-quotations-title-2 flex justify-end"
              >
                Shipping Cost
              </Col>
              <Col span={6} className="create-quotation-border ">
                {data?.data?.shipping_cost}
              </Col>
            </Row>
            <Row>
              <Col
                span={18}
                className="create-quotation-border create-quotations-title-2 flex justify-end "
              >
                Grand Total
              </Col>
              <Col span={6} className="create-quotation-border grand-total ">
                {data?.data?.grand_total}
              </Col>
            </Row>
            <Row className="w-full create-quotation-border ">
              <div className="flex flex-col gap-2">
                {/* <div className="create-quotation-title-1">Drawing Number</div>
              <div className="create-quotations-title-2">4</div> */}
                <Text
                  value="Delivery Period"
                  variant="wearlay-label-bold"
                  className="create-quotation-title-1"
                />
                <Text
                  value={data?.data?.delivery_period}
                  variant="wearlay-paragraph-bold"
                  className="create-quotations-title-2"
                />
              </div>
            </Row>
            <Row className=" create-quotation-border flex flex-col">
              {/* <div className="create-quotation-title-1 p-1">Note</div> */}
              <Text
                value="Note"
                variant="wearlay-label-bold"
                className="p-1 "
              />
              {/* <div className="p-1">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </div> */}
              <Text
                value={data?.data?.note}
                variant="wearlay-paragraph"
                className="p-1"
              />
            </Row>
            {/* </div>
        ) : (
          <Empty className="h-full flex items-center flex-col justify-center" />
        )} */}
            {!data?.data?.is_closed && (
              <div>
                <div>
                  <Text
                    variant="wearlay-headline-2"
                    value="Follow Up Status"
                    className="create-quotation-margin-inline"
                  />
                </div>
                <div className="create-quotation-border">
                  <div className="flex justify-between  items-center">
                    <div>
                      <Text
                        value=" Follow Up 1"
                        variant="wearlay-subtitle"
                        className="create-quotation-margin-bottom "
                      />
                    </div>
                    <div>
                      {data?.data?.follow_up?.length === 0 && isAdd && (
                        <CommonButton
                          value="Add Note"
                          variant="wealay-secondary-btn"
                          onClick={() => {
                            setIsAdd(false);
                            setIsSave(true);
                          }}
                        />
                      )}
                      {!data?.data?.follow_up[0] && isSave && (
                        <CommonButton
                          value="Save"
                          variant="wealay-primary-btn"
                          onClick={() => {
                            // setIsSave(false);
                            formik.handleSubmit();
                          }}
                          isLoading={editQuotationLoading}
                        />
                      )}
                    </div>
                  </div>
                  {!data?.data?.follow_up[0] && isSave && (
                    <Form layout="vertical">
                      <Form.Item
                        label="Date"
                        required
                        name="noteDate"
                        className="w-full"
                        hasFeedback
                        validateStatus={
                          formik.errors.date && formik.touched.date
                            ? "error"
                            : undefined
                        }
                        help={
                          formik.errors.date &&
                          formik.touched.date &&
                          formik.errors.date
                        }
                      >
                        <DatePicker
                          className="w-full"
                          placeholder="Select Date"
                          onBlur={formik.handleBlur}
                          onChange={(value) =>
                            formik.setFieldValue(
                              "date",
                              value ? value.format("YYYY-MM-DD") : ""
                            )
                          }
                          value={dayjs(formik.values.date)}
                          format="DD-MM-YYYY"
                        />
                      </Form.Item>
                      <Form.Item
                        label="Note"
                        required
                        name="note"
                        hasFeedback
                        validateStatus={
                          formik.errors.note && formik.touched.note
                            ? "error"
                            : ""
                        }
                        help={
                          formik.errors.note && formik.touched.note
                            ? formik.errors.note
                            : undefined
                        }
                      >
                        <TextArea
                          placeholder="Note"
                          value={formik.values.note}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Next Follow up Date*"
                        required
                        name="next_follow_up_date"
                        hasFeedback
                        validateStatus={
                          formik.errors.next_follow_up_date &&
                          formik.touched.next_follow_up_date
                            ? "error"
                            : undefined
                        }
                        help={
                          formik.errors.next_follow_up_date &&
                          formik.touched.next_follow_up_date &&
                          formik.errors.next_follow_up_date
                        }
                      >
                        <DatePicker
                          className="w-full"
                          placeholder="Select Date"
                          onBlur={formik.handleBlur}
                          onChange={(value) =>
                            formik.setFieldValue(
                              "next_follow_up_date",
                              value ? value.format("YYYY-MM-DD") : ""
                            )
                          }
                          value={dayjs(formik.values.next_follow_up_date)}
                          format="DD-MM-YYYY"
                          disabledDate={(current) =>
                            current && current < moment().endOf("day")
                          }
                        />
                        {/* <RangePicker disabledDate={disabledEndDate} /> */}
                      </Form.Item>
                    </Form>
                  )}
                  {data?.data?.follow_up[0] && (
                    <div className="create-quotation-border">
                      <div className="flex flex-col gap-2">
                        <Text
                          value="Date"
                          variant="wearlay-label-bold"
                          className="create-quotation-text-color"
                        ></Text>
                        <Text
                          value={moment(data?.data?.follow_up[0]?.date).format(
                            "DD-MM-YYYY"
                          )}
                          variant="wearlay-paragraph-bold"
                        ></Text>
                      </div>
                      <Divider />
                      <div className="flex flex-col gap-2">
                        <Text
                          value="Note"
                          variant="wearlay-label-bold"
                          className="create-quotation-text-color"
                        ></Text>
                        <Text
                          value={
                            data?.data?.follow_up[0]?.notes
                              ? data?.data?.follow_up[0]?.notes
                              : "-"
                          }
                          variant="wearlay-paragraph"
                        ></Text>
                      </div>
                      <Divider />
                      <div className="flex flex-col gap-2">
                        <Text
                          value="Next Follow up Date"
                          variant="wearlay-label-bold"
                          className="create-quotation-text-color"
                        ></Text>
                        <Text
                          value={moment(
                            data?.data?.follow_up[0]?.next_follow_up_date
                          ).format("DD-MM-YYYY")}
                          variant="wearlay-paragraph-bold"
                        ></Text>
                      </div>
                    </div>
                  )}
                  {/* //2 */}
                  {/* <div className="create-quotation-border"> */}

                  <div className="flex justify-between  items-center">
                    <div>
                      <Text
                        value=" Follow Up 2"
                        variant="wearlay-subtitle"
                        className="create-quotation-margin-bottom create-quotation-margin-top "
                      />
                    </div>
                    <div>
                      {data?.data?.follow_up.length === 1 && isAdd && (
                        <CommonButton
                          value="Add Note"
                          variant="wealay-secondary-btn"
                          onClick={() => {
                            setIsAdd(false);
                            setIsSave(true);
                          }}
                        />
                      )}
                      {data?.data?.follow_up.length === 1 && isSave && (
                        <CommonButton
                          value="Save"
                          variant="wealay-primary-btn"
                          onClick={() => {
                            // setIsSave(false);
                            formik.handleSubmit();
                          }}
                          isLoading={editQuotationLoading}
                        />
                      )}
                    </div>
                  </div>
                  {data?.data?.follow_up.length === 1 && isSave && (
                    <Form layout="vertical">
                      <Form.Item
                        label="Date"
                        required
                        name="noteDate"
                        hasFeedback
                        validateStatus={
                          formik.errors.date && formik.touched.date
                            ? "error"
                            : undefined
                        }
                        help={
                          formik.errors.date &&
                          formik.touched.date &&
                          formik.errors.date
                        }
                      >
                        <DatePicker
                          className="w-full"
                          placeholder="Select Date"
                          onBlur={formik.handleBlur}
                          onChange={(value) =>
                            formik.setFieldValue(
                              "date",
                              value ? value.format("YYYY-MM-DD") : ""
                            )
                          }
                          value={dayjs(formik.values.date)}
                          format="DD-MM-YYYY"
                        />
                      </Form.Item>
                      <Form.Item
                        label="Note"
                        required
                        name="note"
                        hasFeedback
                        validateStatus={
                          formik.errors.note && formik.touched.note
                            ? "error"
                            : ""
                        }
                        help={
                          formik.errors.note && formik.touched.note
                            ? formik.errors.note
                            : undefined
                        }
                      >
                        <TextArea
                          placeholder="Note"
                          value={formik.values.note}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Next Follow up Date*"
                        required
                        name="next_follow_up_date"
                        hasFeedback
                        validateStatus={
                          formik.errors.next_follow_up_date &&
                          formik.touched.next_follow_up_date
                            ? "error"
                            : undefined
                        }
                        help={
                          formik.errors.next_follow_up_date &&
                          formik.touched.next_follow_up_date &&
                          formik.errors.next_follow_up_date
                        }
                      >
                        <DatePicker
                          className="w-full"
                          placeholder="Select Date"
                          onBlur={formik.handleBlur}
                          onChange={(value) =>
                            formik.setFieldValue(
                              "next_follow_up_date",
                              value ? value.format("YYYY-MM-DD") : ""
                            )
                          }
                          value={dayjs(formik.values.next_follow_up_date)}
                          format="DD-MM-YYYY"
                          disabledDate={(current) =>
                            current && current < moment().endOf("day")
                          }
                        />
                      </Form.Item>
                    </Form>
                  )}
                  {data?.data?.follow_up[1] && (
                    <div className="create-quotation-border">
                      <div className="flex flex-col gap-2">
                        <Text
                          value="Date"
                          variant="wearlay-label-bold"
                          className="create-quotation-text-color"
                        ></Text>
                        <Text
                          value={moment(data?.data?.follow_up[1]?.date).format(
                            "DD-MM-YYYY"
                          )}
                          variant="wearlay-paragraph-bold"
                        ></Text>
                      </div>
                      <Divider />
                      <div className="flex flex-col gap-2">
                        <Text
                          value="Note"
                          variant="wearlay-label-bold"
                          className="create-quotation-text-color"
                        ></Text>
                        <Text
                          value={
                            data?.data?.follow_up[1]?.notes
                              ? data?.data?.follow_up[1]?.notes
                              : "-"
                          }
                          variant="wearlay-paragraph"
                        ></Text>
                      </div>
                      <Divider />
                      <div className="flex flex-col gap-2">
                        <Text
                          value="Next Follow up Date"
                          variant="wearlay-label-bold"
                          className="create-quotation-text-color"
                        ></Text>
                        <Text
                          value={moment(
                            data?.data?.follow_up[1]?.next_follow_up_date
                          ).format("DD-MM-YYYY")}
                          variant="wearlay-paragraph-bold"
                        ></Text>
                      </div>
                    </div>
                  )}
                  {/* //3 */}
                  <div className="flex justify-between  items-center">
                    <div>
                      <Text
                        value=" Follow Up 3"
                        variant="wearlay-subtitle"
                        className="create-quotation-margin-bottom create-quotation-margin-top "
                      />
                    </div>
                    <div>
                      {data?.data?.follow_up.length === 2 && isAdd && (
                        <CommonButton
                          value="Add Note"
                          variant="wealay-secondary-btn"
                          onClick={() => {
                            setIsAdd(false);
                            setIsSave(true);
                          }}
                        />
                      )}
                      {data?.data?.follow_up.length === 2 && isSave && (
                        <CommonButton
                          value="Save"
                          variant="wealay-primary-btn"
                          onClick={() => {
                            // setIsSave(false);
                            formik.handleSubmit();
                          }}
                          isLoading={editQuotationLoading}
                        />
                      )}
                    </div>
                  </div>
                  {data?.data?.follow_up.length === 2 && isSave && (
                    <Form layout="vertical">
                      <Form.Item
                        label="Date"
                        required
                        name="noteDate"
                        hasFeedback
                        validateStatus={
                          formik.errors.date && formik.touched.date
                            ? "error"
                            : undefined
                        }
                        help={
                          formik.errors.date &&
                          formik.touched.date &&
                          formik.errors.date
                        }
                      >
                        <DatePicker
                          className="w-full"
                          placeholder="Select Date"
                          onBlur={formik.handleBlur}
                          onChange={(value) =>
                            formik.setFieldValue(
                              "date",
                              value ? value.format("YYYY-MM-DD") : ""
                            )
                          }
                          value={dayjs(formik.values.date)}
                          format="DD-MM-YYYY"
                        />
                      </Form.Item>
                      <Form.Item
                        label="Note"
                        required
                        name="note"
                        hasFeedback
                        validateStatus={
                          formik.errors.note && formik.touched.note
                            ? "error"
                            : ""
                        }
                        help={
                          formik.errors.note && formik.touched.note
                            ? formik.errors.note
                            : undefined
                        }
                      >
                        <TextArea
                          placeholder="Note"
                          value={formik.values.note}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Next Follow up Date*"
                        required
                        name="next_follow_up_date"
                        hasFeedback
                        validateStatus={
                          formik.errors.next_follow_up_date &&
                          formik.touched.next_follow_up_date
                            ? "error"
                            : undefined
                        }
                        help={
                          formik.errors.next_follow_up_date &&
                          formik.touched.next_follow_up_date &&
                          formik.errors.next_follow_up_date
                        }
                      >
                        <DatePicker
                          className="w-full"
                          placeholder="Select Date"
                          onBlur={formik.handleBlur}
                          onChange={(value) =>
                            formik.setFieldValue(
                              "next_follow_up_date",
                              value ? value.format("YYYY-MM-DD") : ""
                            )
                          }
                          value={dayjs(formik.values.next_follow_up_date)}
                          format="DD-MM-YYYY"
                          disabledDate={(current) =>
                            current && current < moment().endOf("day")
                          }
                        />
                      </Form.Item>
                    </Form>
                  )}
                  {data?.data?.follow_up[2] && (
                    <div className="create-quotation-border">
                      <div className="flex flex-col gap-2">
                        <Text
                          value="Date"
                          variant="wearlay-label-bold"
                          className="create-quotation-text-color"
                        ></Text>
                        <Text
                          value={moment(data?.data?.follow_up[2]?.date).format(
                            "DD-MM-YYYY"
                          )}
                          variant="wearlay-paragraph-bold"
                        ></Text>
                      </div>
                      <Divider />
                      <div className="flex flex-col gap-2">
                        <Text
                          value="Note"
                          variant="wearlay-label-bold"
                          className="create-quotation-text-color"
                        ></Text>
                        <Text
                          value={
                            data?.data?.follow_up[2]?.notes
                              ? data?.data?.follow_up[2]?.notes
                              : "-"
                          }
                          variant="wearlay-paragraph"
                        ></Text>
                      </div>
                      <Divider />
                      <div className="flex flex-col gap-2">
                        <Text
                          value="Next Follow up Date"
                          variant="wearlay-label-bold"
                          className="create-quotation-text-color"
                        ></Text>
                        <Text
                          value={moment(
                            data?.data?.follow_up[2]?.next_follow_up_date
                          ).format("DD-MM-YYYY")}
                          variant="wearlay-paragraph-bold"
                        ></Text>
                      </div>
                    </div>
                  )}
                </div>
                <Divider />
                <div className="create-quotation-checkbox">
                  <Checkbox
                    onChange={(e: any) => {
                      setIsClosed(e.target.checked);
                    }}
                    checked={isClosed}
                  >
                    <span className="close-checkbox-1">Close Quotation</span>
                  </Checkbox>
                  <Modal
                    title="Confirmation"
                    open={isClosed}
                    onCancel={() => {
                      setIsClosed(false);
                    }}
                    className="modal-submit-button"
                    okText="Yes"
                    cancelText="No"
                    destroyOnClose={false}
                    confirmLoading={closeQuotationLoading}
                    onOk={() => {
                      onCloseQuotation();
                    }}
                  >
                    <p className="are-you-sure">
                      Are you sure to close this quotation?
                    </p>
                  </Modal>
                </div>
                <Divider />

                <Form layout="vertical" onFinish={formik2.handleSubmit}>
                  <div className="create-quotation-checkbox">
                    <FormItem name={"special_consideration_For_payment"}>
                      <Checkbox
                        onChange={(e: any) => {
                          setIsChecked(e.target.checked);
                        }}
                      >
                        Special Consideration for Payment
                      </Checkbox>
                    </FormItem>
                  </div>
                  {!isChecked && (
                    <Row gutter={16}>
                      <Col span={24} md={{ span: 12 }}>
                        <Form.Item
                          label="Advance Received Amount"
                          name="advance_received_amount"
                          required
                          hasFeedback
                          validateStatus={
                            formik2.errors.advance_received_amount &&
                            formik2.touched.advance_received_amount
                              ? "error"
                              : ""
                          }
                          help={
                            formik2.errors.advance_received_amount &&
                            formik2.touched.advance_received_amount
                              ? formik2.errors.advance_received_amount
                              : undefined
                          }
                        >
                          <Input
                            placeholder="Advance Amount"
                            value={formik2.values.advance_received_amount}
                            onChange={formik2.handleChange}
                            onBlur={formik2.handleBlur}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24} md={{ span: 12 }}>
                        <Form.Item
                          label="Advance Received Date"
                          required
                          className="w-full"
                          // name="advance_received_date"
                          hasFeedback
                          validateStatus={
                            formik2.errors.advance_received_date &&
                            formik2.touched.advance_received_date
                              ? "error"
                              : undefined
                          }
                          help={
                            formik2.errors.advance_received_date &&
                            formik2.touched.advance_received_date &&
                            formik2.errors.advance_received_date
                          }
                        >
                          <DatePicker
                            name="advance_received_date"
                            className="w-full"
                            placeholder="Select Date"
                            onBlur={formik2.handleBlur}
                            onChange={(value) =>
                              formik2.setFieldValue(
                                "advance_received_date",
                                value ? value.format("YYYY-MM-DD") : ""
                              )
                            }
                            value={
                              formik2.values.advance_received_date
                                ? dayjs(formik2.values.advance_received_date)
                                : dayjs()
                            }
                            format="DD-MM-YYYY"
                            allowClear={false}

                            // disabledDate={disabledDate}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <Form.Item
                      label="Order Punched Date*"
                      required
                      className="w-full"
                      // name="order_punched_date"
                      hasFeedback
                      validateStatus={
                        formik2.errors.order_punched_date &&
                        formik2.touched.order_punched_date
                          ? "error"
                          : undefined
                      }
                      help={
                        formik2.errors.order_punched_date &&
                        formik2.touched.order_punched_date &&
                        formik2.errors.order_punched_date
                      }
                    >
                      <DatePicker
                        name="order_punched_date"
                        className="w-full"
                        placeholder="Select Date"
                        onBlur={formik2.handleBlur}
                        onChange={(value) =>
                          formik2.setFieldValue(
                            "order_punched_date",
                            value ? value.format("YYYY-MM-DD") : ""
                          )
                        }
                        value={
                          formik2.values.order_punched_date
                            ? dayjs(formik2.values.order_punched_date)
                            : dayjs()
                        }
                        format="DD-MM-YYYY"
                        disabledDate={disabledDate}
                        allowClear={false}
                      />
                    </Form.Item>
                  </Row>
                  {/* <Row>
                <Form.Item
                  label="Note"
                  name="note"
                  className="w-full"
                  required
                  hasFeedback
                  validateStatus={
                    formik2.errors.note && formik2.touched.note ? "error" : ""
                  }
                  help={
                    formik2.errors.note && formik2.touched.note
                      ? formik2.errors.note
                      : undefined
                  }
                >
                  <TextArea
                    placeholder="Note"
                    value={formik2.values.note}
                    onChange={formik2.handleChange}
                    onBlur={formik2.handleBlur}
                  />
                </Form.Item>
              </Row> */}
                  <Row>
                    {/* <Button className="w-full create-quotation-btn">
              Create Order
            </Button> */}
                    <CommonButton
                      variant="wealay-primary-btn"
                      value="   Create Order"
                      className="w-full create-quotation-btn"
                      disabled={isChecked || formik2.isValid ? false : true}
                      isLoading={createLoading}
                    />
                  </Row>
                </Form>
              </div>
            )}
          </div>
        </div>
      ) : (
        <Empty className="h-full justify-center items-center flex flex-col" />
      )}
    </>
  );
};
