export interface EmployeeParamType {
  //   search: string;
  page: number;
  page_size: number;
  //   filter: string;
}
export const getContactsParams = (page?: number, size?: number) => {
  const params = {} as EmployeeParamType;
  //   if (search) {
  //     params["search"] = search;
  //   }
  if (page) {
    params["page"] = page;
  }

  if (size) {
    params["page_size"] = size;
  }
  //   if (filter) {
  //     params["filter"] = filter;
  //   }
  return params;
};

export const getSalesParams = (page?: number, size?: number) => {
  const params = {} as EmployeeParamType;
  //   if (search) {
  //     params["search"] = search;
  //   }
  if (page) {
    params["page"] = page;
  }

  if (size) {
    params["page_size"] = size;
  }
  //   if (filter) {
  //     params["filter"] = filter;
  //   }
  return params;
};

export const getEmployeesParams = (page?: number, size?: number) => {
  const params = {} as EmployeeParamType;
  //   if (search) {
  //     params["search"] = search;
  //   }
  if (page) {
    params["page"] = page;
  }

  if (size) {
    params["page_size"] = size;
  }
  //   if (filter) {
  //     params["filter"] = filter;
  //   }
  return params;
};

