import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  message,
  Spin,
  DatePicker,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CommonButton } from "../../components/CommonButton/button";
import "./index.css";
import { Text } from "../../components/CommonFont/text";
import * as Yup from "yup";
import { Formik, FormikErrors, FormikValues, useFormik } from "formik";
import {
  useCreateLeadMutation,
  useGetCompanyNamesQuery,
  useGetNatureQuery,
  useGetRegionQuery,
  useGetSourceQuery,
  useGetDesignationQuery,
  useGetTypesQuery,
  useGetIndustryQuery,
  useGetEnquiryCategoryQuery,
  useGetLeadByIdQuery,
  useGetContactsByIdQuery,
  useLazyGetContactsByIdQuery,
  useLazyGetLeadByIdQuery,
  useEditLeadMutation,
  useCreateRegionMutation,
} from "../../services/api";
import CreatableSelect from "react-select/creatable";
import { useEffect, useState } from "react";
import { add } from "lodash";
import dayjs from "dayjs";
import moment from "moment";
interface Item {
  item_information: string;
  brand: string;
  qty: number;
}

interface FormikErrorsType {
  items: Array<FormikErrors<Item>>;
}
export const CreateNewLead = () => {
  const phoneRegExp = /^[6-9]\d{9}$/;
  const validationSchema = Yup.object().shape({
    email: Yup.string(),
    // .email("Invalid email")
    // .matches(
    //   /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    //   "Invalid email format"
    // )
    // .required("Email is required"),

    phone: Yup.string()
      // .matches(phoneRegExp, "Invalid phone number")
      // .required("Phone number is required"),
      .matches(/^[0-9+]+$/, "Invalid phone number")
      .required("Phone number is required"),
    lead_name: Yup.string().required("Lead name is required"),
    client_name: Yup.string().required("Client name is required"),
    company_name: Yup.string().required("Company name is required"),
    region: Yup.object().required("Region is required"),
    designation: Yup.object().required("Designation is required"),
    type: Yup.object().required("Type is required"),
    address: Yup.string().required("Address is required"),
    gst: Yup.string(),
    // .matches(
    //   /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
    //   "Invalid GST number"
    // )
    // .required("GST number is required"),
    industry: Yup.object().required("Industry is required"),
    source: Yup.object().required("Source is required"),
    // nature: Yup.object().required("Nature is required"),
    enquiry_category: Yup.object().required("Enquiry category is required"),
    // item_information: Yup.string().required("Item Information is required"),
    // qty: Yup.string().required("Quantity is required"),
    note: Yup.string(),
    items: Yup.array().of(
      Yup.object().shape({
        item_information: Yup.string().required("Item Information is required"),
        brand: Yup.string().required("Brand is required"),
        qty: Yup.number()
          .typeError("Quantity must be a number")
          .required("Quantity is required"),
      })
    ),
    follow_up: Yup.array().of(
      Yup.object().shape({
        date: Yup.date().required("This date is required"),
        notes: Yup.string().required("Note is required"),
        next_follow_up_date: Yup.date().required("Follow Up date is required"),
      })
    ),
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const sales_id = searchParams.get("id");
  const contact_id = searchParams.get("contact_id");
  const { data: editPrefillData, isLoading: editPrefillLoading } =
    useGetLeadByIdQuery(sales_id);
  const { data: contactPrefillData, isLoading: contactPrefillLoading } =
    useGetContactsByIdQuery(contact_id);
  const [mutation, { data: regioncreatedata }] = useCreateRegionMutation();
  const userId = localStorage.getItem("user_id");
  const search = searchParams.get("search");
  const [inputValue, setInputValue] = useState("");
  const [designationValue, setDesignationValue] = useState("");
  const [natureValue, setNatureValue] = useState("");
  const [sourceValue, setSourceValue] = useState("");
  const [categoryValue, setCategoryValue] = useState("");
  const [industryValue, setIndustryValue] = useState("");
  const [typeValue, setTypeValue] = useState("");
  const [companyValue, setCompanyValue] = useState("");

  const formik = useFormik({
    initialValues: {
      email: "",
      phone: "",
      client_name: "",
      lead_name: "",
      company_name: editPrefillData?.data?.company_name as string,
      region: undefined,
      designation: undefined,
      type: undefined,
      address: "",
      gst: "",
      industry: undefined,
      source: undefined,
      // nature: undefined,
      enquiry_category: undefined,
      note: "",
      items: [
        {
          item_information: "",
          brand: "",
          qty: undefined,
        },
      ],
      follow_up: [
        {
          date: undefined,
          notes: "",
          next_follow_up_date: undefined,
        },
      ],
    },
    validationSchema,

    onSubmit: async (values: any) => {
      const followUpData = values.follow_up.map((followUpItem: any) => ({
        title:
          (contactPrefillData || editPrefillData)?.data?.follow_up?.length === 0
            ? 1
            : (contactPrefillData || editPrefillData)?.data?.follow_up
                ?.length === 1
            ? 1
            : (contactPrefillData || editPrefillData)?.data?.follow_up
                ?.length === 2
            ? 2
            : (contactPrefillData || editPrefillData)?.data?.follow_up
                ?.length === 3
            ? 3
            : 1,
        date: followUpItem.date,
        notes: followUpItem.notes,
        next_follow_up_date: followUpItem.next_follow_up_date,
      }));
      const submitData = {
        ...values,
        company_name: newCompanyName
          ? newCompanyName
          : contactPrefillData?.data?.company_name
          ? contactPrefillData?.data?.company_name
          : editPrefillData?.data?.company_name,
        contact: Number(prefillData?.data?.id)
          ? Number(prefillData?.data?.id)
          : contact_id,
        region: values.region.value,
        contacts_customer_unique_identification_number: prefillData?.data
          ?.contacts_customer_unique_identification_number
          ? prefillData?.data?.contacts_customer_unique_identification_number
          : editPrefillData?.data
              ?.contacts_customer_unique_identification_number
          ? editPrefillData?.data
              ?.contacts_customer_unique_identification_number
          : contactPrefillData?.data
              ?.contacts_customer_unique_identification_number
          ? contactPrefillData?.data
              ?.contacts_customer_unique_identification_number
          : null,
        industry: values.industry.value,
        // nature: values.nature.value,
        source: values.source.value,
        designation: values.designation.value,
        type: values.type.value,

        enquiry_category: values.enquiry_category.value,
        follow_up: followUpData,
      };

      const createParams = {
        body: submitData,
        id: sales_id ? sales_id : userId,
      };
      const editParams = {
        body: {
          ...submitData,
          contact: Number(contactPrefillData?.data?.contact)
            ? Number(contactPrefillData?.data?.contact)
            : Number(editPrefillData?.data?.contact)
            ? Number(editPrefillData?.data?.contact)
            : null,
        },
        id: sales_id ? sales_id : userId,
      };
      await (sales_id ? editLead(editParams) : createLead(createParams))
        .unwrap()
        .then((data) => {
          message.success(data?.message);
          navigate("/sales");
        })

        .catch((err) => {
          message.error(err?.data?.message);
        });
    },
  });

  const navigate = useNavigate();
  const [createLead, { data: addData, isLoading: createLoading }] =
    useCreateLeadMutation();
  const [editLead, { data: editData, isLoading: editLoading }] =
    useEditLeadMutation();
  const { data: sourceData, isLoading: sourceLoading } = useGetSourceQuery("");
  const { data: natureData, isLoading: natureLoading } = useGetNatureQuery("");
  const { data: companyNameData, isLoading: companyNameLoading } =
    useGetCompanyNamesQuery("");
  const { data: regionData, isLoading: regionLoading } = useGetRegionQuery("");
  const { data: designationData, isLoading: designationLoading } =
    useGetDesignationQuery("");
  const { data: typeData, isLoading: typeLoading } = useGetTypesQuery("");
  const { data: industryData, isLoading: industryLoading } =
    useGetIndustryQuery("");
  const { data: enquiryCategoryData, isLoading: enquiryCategoryLoading } =
    useGetEnquiryCategoryQuery("");
  const [trigger, { data: prefillData, isLoading }] =
    useLazyGetContactsByIdQuery();

  const [newCompanyName, setNewCompanyName] = useState("");
  interface CompanyOption {
    value: number;
    label: string;
  }
  interface RegionOption {
    value: number;
    label: string;
  }

  const handleChange = async (option: any) => {
    setNewCompanyName(option?.label);
    setIsDataAvailable(true);
    formik.setFieldValue("company_name", option?.value || "");
    await handlePrefill(option?.value).catch((err) => {
      //message.error(err?.data?.message);
      setIsDataAvailable(true);
    });
  };
  const handleChange2 = async (option: any) => {
    formik.setFieldValue("enquiry_category", {
      value: option?.value,
      label: option?.label,
    });

    // setNewCompanyName(option?.label);
    // setIsDataAvailable(true);
    // formik.setFieldValue("company_name", option?.value || "");
    // await handlePrefill(option?.value).catch((err) => {
    //   //message.error(err?.data?.message);
    //   setIsDataAvailable(true);
    // });
  };
  const handleChangeRegion = (option: any) => {
    formik.setFieldValue("region", {
      value: option?.value,
      label: option?.label,
    });
  };
  const handleChangeDesignation = (option: any) => {
    formik.setFieldValue("designation", {
      value: option?.value,
      label: option?.label,
    });
  };
  const handleChangeSource = (option: any) => {
    formik.setFieldValue("source", {
      value: option?.value,
      label: option?.label,
    });
  };
  const handleChangeIndustry = (option: any) => {
    formik.setFieldValue("industry", {
      value: option?.value,
      label: option?.label,
    });
  };
  const handleChangeType = (option: any) => {
    formik.setFieldValue("type", {
      value: option?.value,
      label: option?.label,
    });
  };
  const handleChangeNature = (option: any) => {
    formik.setFieldValue("nature", {
      value: option?.value,
      label: option?.label,
    });
  };
  const [isDataAvailable, setIsDataAvailable] = useState(false);

  const handlePrefill = async (id: any) => {
    await trigger(id)
      .unwrap()
      .then((data: any) => {
        // message.success(data?.message);

        setIsDataAvailable(false);
      })
      .catch((err: any) => {
        //message.error(err?.data?.message);
        setIsDataAvailable(true);
      });
  };

  const options: CompanyOption[] | undefined = companyNameData?.data.map(
    ({ id, company_name }: { id: number; company_name: string }) => ({
      value: id,
      label: company_name,
    })
  );

  const optionsRegion: RegionOption[] | undefined = regionData?.map(
    ({ id, name }: { id: number; name: string }) => ({
      value: id,
      label: name,
    })
  );
  const optionsDesignation: RegionOption[] | undefined = designationData?.map(
    ({ id, name }: { id: number; name: string }) => ({
      value: id,
      label: name,
    })
  );
  const optionsNature: RegionOption[] | undefined = natureData?.map(
    ({ id, name }: { id: number; name: string }) => ({
      value: id,
      label: name,
    })
  );
  const optionsType: RegionOption[] | undefined = typeData?.map(
    ({ id, name }: { id: number; name: string }) => ({
      value: id,
      label: name,
    })
  );
  const optionsIndustry: RegionOption[] | undefined = industryData?.map(
    ({ id, name }: { id: number; name: string }) => ({
      value: id,
      label: name,
    })
  );
  const optionsSource: RegionOption[] | undefined = sourceData?.map(
    ({ id, name }: { id: number; name: string }) => ({
      value: id,
      label: name,
    })
  );
  const optionsEnquiry: RegionOption[] | undefined = enquiryCategoryData?.map(
    ({ id, name }: { id: number; name: string }) => ({
      value: id,
      label: name,
    })
  );
  useEffect(() => {
    if (prefillData) {
      formik.setFieldValue(
        "client_name",
        isDataAvailable && prefillData?.data?.client_name
          ? prefillData?.data.client_name
          : ""
      );
      formik.setFieldValue(
        "address",
        isDataAvailable && prefillData?.data?.address
          ? prefillData?.data?.address
          : ""
      );
      formik.setFieldValue(
        "region",
        isDataAvailable && prefillData?.data?.region
          ? {
              value: prefillData?.data.region,
              label: prefillData?.data?.region_name,
            }
          : ""
      );
      formik.setFieldValue(
        "designation",
        isDataAvailable && prefillData?.data?.designation
          ? {
              value: prefillData?.data.designation,
              label: prefillData?.data?.designation_name,
            }
          : ""
      );
      formik.setFieldValue(
        "type",
        isDataAvailable && prefillData?.data?.type
          ? {
              value: prefillData?.data.type,
              label: prefillData?.data?.type_name,
            }
          : ""
      );
      formik.setFieldValue(
        "industry",
        isDataAvailable && prefillData?.data?.industry
          ? {
              value: prefillData?.data.industry,
              label: prefillData?.data?.industry_name,
            }
          : ""
      );
      formik.setFieldValue(
        "source",
        isDataAvailable && prefillData?.data?.source
          ? {
              value: prefillData?.data.source,
              label: prefillData?.data?.source_name,
            }
          : ""
      );
      // formik.setFieldValue(
      //   "nature",
      //   isDataAvailable && prefillData?.data?.nature
      //     ? {
      //         value: prefillData?.data.nature,
      //         label: prefillData?.data?.nature_name,
      //       }
      //     : ""
      // );
      formik.setFieldValue(
        "enquiry_category",
        isDataAvailable && prefillData?.data?.enquiry_category
          ? {
              value: prefillData?.data.enquiry_category,
              label: prefillData?.data?.enquiry_category_name,
            }
          : ""
      );
      formik.setFieldValue(
        "email",
        isDataAvailable && prefillData?.data?.email
          ? prefillData?.data?.email
          : ""
      );
      formik.setFieldValue(
        "phone",
        isDataAvailable && prefillData?.data?.phone
          ? prefillData?.data?.phone
          : ""
      );
      formik.setFieldValue(
        "gst",
        isDataAvailable && prefillData?.data?.gst ? prefillData?.data?.gst : ""
      );
    }
  }, [prefillData?.data]);
  useEffect(() => {
    const currentDate = moment().format("YYYY-MM-DD");

    formik.setFieldValue(`follow_up[0].date`, currentDate);
  }, []);
  useEffect(() => {
    if (editPrefillData) {
      formik.setFieldValue("lead_name", editPrefillData?.data?.lead_name);
      formik.setFieldValue("client_name", editPrefillData?.data?.client_name);
      formik.setFieldValue("email", editPrefillData?.data?.email);
      formik.setFieldValue("phone", editPrefillData?.data?.phone);
      formik.setFieldValue("address", editPrefillData?.data?.address);
      formik.setFieldValue("gst", editPrefillData?.data?.gst);
      formik.setFieldValue("note", editPrefillData?.data?.note);
      formik.setFieldValue("region", {
        value: editPrefillData?.data.region,
        label: editPrefillData?.data?.region_name,
      });
      formik.setFieldValue("designation", {
        value: editPrefillData?.data.designation,
        label: editPrefillData?.data?.designation_name,
      });
      formik.setFieldValue("type", {
        value: editPrefillData?.data.type,
        label: editPrefillData?.data?.type_name,
      });
      formik.setFieldValue("industry", {
        value: editPrefillData?.data.industry,
        label: editPrefillData?.data?.industry_name,
      });
      formik.setFieldValue("enquiry_category", {
        value: editPrefillData?.data?.enquiry_category,
        label: editPrefillData?.data?.enquiry_category_name,
      });
      formik.setFieldValue("source", {
        value: editPrefillData?.data.source,
        label: editPrefillData?.data?.source_name,
      });
      // formik.setFieldValue("nature", {
      //   value: editPrefillData?.data.nature,
      //   label: editPrefillData?.data?.nature_name,
      // });
      formik.setFieldValue("company_name", editPrefillData?.data?.company_name);
      editPrefillData?.data?.items?.forEach((item: any, index: number) => {
        const itemName = `items[${index}].item_information`;
        const itemBrand = `items[${index}].brand`;
        const itemQty = `items[${index}].qty`;
        formik.setFieldValue(itemName, item.item_information);
        formik.setFieldValue(itemBrand, item.brand);
        formik.setFieldValue(itemQty, item.qty);
      });
      editPrefillData?.data?.follow_up?.forEach((item: any, index: number) => {
        const date = `follow_up[${index}].date`;
        const next_follow_up_date = `follow_up[${index}].next_follow_up_date`;
        const notes = `follow_up[${index}].notes`;
        formik.setFieldValue(date, item.date);
        formik.setFieldValue(next_follow_up_date, item.next_follow_up_date);
        formik.setFieldValue(notes, item.notes);
      });
      const prefillCompanyName = editPrefillData?.data?.company_name;
      const selectedOption = options?.find(
        (option) => option.label === prefillCompanyName
      );
      if (selectedOption) {
        formik.setFieldValue("company_name", selectedOption);
      }
    }
  }, [editPrefillData]);
  useEffect(() => {
    if (contactPrefillData) {
      formik.setFieldValue("lead_name", contactPrefillData?.data?.lead_name);
      formik.setFieldValue(
        "client_name",
        contactPrefillData?.data?.client_name
      );
      formik.setFieldValue("email", contactPrefillData?.data?.email);
      formik.setFieldValue("phone", contactPrefillData?.data?.phone);
      formik.setFieldValue("address", contactPrefillData?.data?.address);
      formik.setFieldValue("gst", contactPrefillData?.data?.gst);
      formik.setFieldValue("note", contactPrefillData?.data?.note);
      formik.setFieldValue("region", {
        value: contactPrefillData?.data.region,
        label: contactPrefillData?.data?.region_name,
      });
      formik.setFieldValue("designation", {
        value: contactPrefillData?.data.designation,
        label: contactPrefillData?.data?.designation_name,
      });
      formik.setFieldValue("type", {
        value: contactPrefillData?.data.type,
        label: contactPrefillData?.data?.type_name,
      });
      formik.setFieldValue("industry", {
        value: contactPrefillData?.data.industry,
        label: contactPrefillData?.data?.industry_name,
      });
      // formik.setFieldValue("enquiry_category", {
      //   value: contactPrefillData?.data.enquiry_category,
      //   label: contactPrefillData?.data?.enquiry_category_name,
      // });
      formik.setFieldValue("source", {
        value: contactPrefillData?.data.source,
        label: contactPrefillData?.data?.source_name,
      });
      // formik.setFieldValue("nature", {
      //   value: contactPrefillData?.data.nature,
      //   label: contactPrefillData?.data?.nature_name,
      // });
      formik.setFieldValue("company_name", {
        value: contactPrefillData?.data?.id,

        label: contactPrefillData?.data?.company_name,
      });
      contactPrefillData?.data?.items?.forEach((item: any, index: number) => {
        const itemName = `items[${index}].item_information`;
        const itemBrand = `items[${index}].brand`;
        const itemQty = `items[${index}].qty`;
        formik.setFieldValue(itemName, item.item_information);
        formik.setFieldValue(itemBrand, item.brand);
        formik.setFieldValue(itemQty, item.qty);
      });
      contactPrefillData?.data?.follow_up?.forEach(
        (item: any, index: number) => {
          const date = `follow_up[${index}].date`;
          const next_follow_up_date = `follow_up[${index}].next_follow_up_date`;
          const notes = `follow_up[${index}].notes`;
          formik.setFieldValue(date, item.date);
          formik.setFieldValue(next_follow_up_date, item.next_follow_up_date);
          formik.setFieldValue(notes, item.notes);
        }
      );
      const prefillCompanyName = contactPrefillData?.data?.company_name;
      const selectedOption = options?.find(
        (option) => option.label === prefillCompanyName
      );
      if (selectedOption) {
        formik.setFieldValue("company_name", selectedOption);
      }
    }
  }, [contactPrefillData, contact_id]);
  const [editPrefillCompanyName, setEditPrefillCompanyName] = useState(
    contactPrefillData?.data?.company_name ||
      editPrefillData?.data?.company_name ||
      ""
  );
  useEffect(() => {
    // setEditPrefillCompanyName(editPrefillData?.data?.company_name);
    // editPrefillData?.data?.company_name && formik.setFieldValue("company_name", editPrefillData?.data?.company_name);
    if (editPrefillData || contactPrefillData) {
      const prefillCompanyName = editPrefillData?.data?.company_name;
      const prefillContactName = contactPrefillData?.data?.company_name;
      setEditPrefillCompanyName(prefillCompanyName);
      formik.setFieldValue("company_name", prefillCompanyName);
      formik.setFieldValue("company_name", prefillContactName);
    }
  }, [editPrefillData, editPrefillLoading, contactPrefillData]);

  const setPreFilledValue = () => {
    formik.setFieldValue("company_name", editPrefillData?.data?.company_name);
  };

  useEffect(() => {
    setPreFilledValue();
  }, []);

  const handleRegion = async (data: any) => {
    const dataParams = {
      name: data,
    };
    const finalData = {
      id: "region",
      body: dataParams,
    };

    await mutation(finalData)
      .unwrap()
      .then((data: any) => {
        message.success(data?.message);

        // setIsDataAvailable(false);
      })
      .catch((err: any) => {
        message.error(err?.data?.message);
        // setIsDataAvailable(true);
      });
  };
  const handleDesignation = async (data: any) => {
    const dataParams = {
      name: data,
    };
    const finalData = {
      id: "designation",
      body: dataParams,
    };

    await mutation(finalData)
      .unwrap()
      .then((data: any) => {
        message.success(data?.message);

        // setIsDataAvailable(false);
      })
      .catch((err: any) => {
        message.error(err?.data?.message);
        // setIsDataAvailable(true);
      });
  };

  const handleType = async (data: any) => {
    const dataParams = {
      name: data,
    };
    const finalData = {
      id: "type",
      body: dataParams,
    };

    await mutation(finalData)
      .unwrap()
      .then((data: any) => {
        message.success(data?.message);

        // setIsDataAvailable(false);
      })
      .catch((err: any) => {
        message.error(err?.data?.message);
        // setIsDataAvailable(true);
      });
  };
  const handleIndustry = async (data: any) => {
    const dataParams = {
      name: data,
    };
    const finalData = {
      id: "industry",
      body: dataParams,
    };

    await mutation(finalData)
      .unwrap()
      .then((data: any) => {
        message.success(data?.message);

        // setIsDataAvailable(false);
      })
      .catch((err: any) => {
        message.error(err?.data?.message);
        // setIsDataAvailable(true);
      });
  };
  const handleSource = async (data: any) => {
    const dataParams = {
      name: data,
    };
    const finalData = {
      id: "source",
      body: dataParams,
    };

    await mutation(finalData)
      .unwrap()
      .then((data: any) => {
        message.success(data?.message);

        // setIsDataAvailable(false);
      })
      .catch((err: any) => {
        message.error(err?.data?.message);
        // setIsDataAvailable(true);
      });
  };

  const handleEnquiry = async (data: any) => {
    const dataParams = {
      name: data,
    };
    const finalData = {
      id: "enquiry_category",
      body: dataParams,
    };

    await mutation(finalData)
      .unwrap()
      .then((data: any) => {
        message.success(data?.message);

        // setIsDataAvailable(false);
      })
      .catch((err: any) => {
        message.error(err?.data?.message);
        // setIsDataAvailable(true);
      });
  };
  const handleNature = async (data: any) => {
    const dataParams = {
      name: data,
    };
    const finalData = {
      id: "nature",
      body: dataParams,
    };

    await mutation(finalData)
      .unwrap()
      .then((data: any) => {
        message.success(data?.message);

        // setIsDataAvailable(false);
      })
      .catch((err: any) => {
        message.error(err?.data?.message);
        // setIsDataAvailable(true);
      });
  };
  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission on Enter key press
    }
  };
  const handleInputChange = (newValue: any) => {
    // Convert the input value to uppercase
    const upperCaseValue = newValue.toUpperCase();
    setInputValue(upperCaseValue);
  };
  const handleDesignationChange = (newValue: any) => {
    // Convert the input value to uppercase
    const upperCaseValue = newValue.toUpperCase();
    setDesignationValue(upperCaseValue);
  };
  const handleCategoryChange = (newValue: any) => {
    // Convert the input value to uppercase
    const upperCaseValue = newValue.toUpperCase();
    setCategoryValue(upperCaseValue);
  };
  const handleNatureChange = (newValue: any) => {
    // Convert the input value to uppercase
    const upperCaseValue = newValue.toUpperCase();
    setNatureValue(upperCaseValue);
  };
  const handleSourceChange = (newValue: any) => {
    // Convert the input value to uppercase
    const upperCaseValue = newValue.toUpperCase();
    setSourceValue(upperCaseValue);
  };
  const handleIndustryChange = (newValue: any) => {
    // Convert the input value to uppercase
    const upperCaseValue = newValue.toUpperCase();
    setIndustryValue(upperCaseValue);
  };
  const handleTypeChange = (newValue: any) => {
    // Convert the input value to uppercase
    const upperCaseValue = newValue.toUpperCase();
    setTypeValue(upperCaseValue);
  };
  const handleCompanyChange = (newValue: any) => {
    // Convert the input value to uppercase
    const upperCaseValue = newValue.toUpperCase();
    setCompanyValue(upperCaseValue);
  };
  const handleRemove = (index: any) => {
    const { setFieldValue } = formik;
    const { items } = formik.values;

    const updatedItems = [...items];
    updatedItems.splice(index, 1);

    setFieldValue("items", updatedItems);
  };

  return (
    <>
      <div className="p-1">
        <div className="flex create-quotation-title gap-2">
          <ArrowLeftOutlined
            onClick={() => {
              navigate("/sales");
            }}
          />
          <Text
            value={sales_id ? "Edit Lead" : "Add New Lead"}
            variant="wearlay-headline-1"
          />
        </div>
        {/* {isLoading ? (
          <Spin
            size="large"
            className="flex w-full  justify-center items-center create-lead-loader-bg mt-24 "
          />
        ) : ( */}
        <div className="createlead-main-div mt-24p">
          <Text
            value="New Lead"
            variant="wearlay-headline-2"
            className="createlead-title"
          />
          <Form
            layout="vertical"
            onFinish={formik.handleSubmit}
            onKeyPress={handleKeyPress}
          >
            <Row>
              <Form.Item
                label="Lead Name"
                // name="lead_name"
                required
                className="w-full"
                hasFeedback
                validateStatus={
                  formik.errors.lead_name && formik.touched.lead_name
                    ? "error"
                    : undefined
                }
                help={
                  formik.errors.lead_name && formik.touched.lead_name
                    ? formik.errors.lead_name
                    : undefined
                }
              >
                <Input
                  name="lead_name"
                  value={formik.values.lead_name}
                  onChange={(e) => {
                    const upperCaseValue = e.target.value.toUpperCase();
                    formik.setFieldValue("lead_name", upperCaseValue);
                  }}
                  onBlur={formik.handleBlur}
                  placeholder="Lead Name"
                />
              </Form.Item>
            </Row>
            <Row>
              <Form.Item
                label="Company Name"
                name="company_name"
                required
                className="w-full"
                hasFeedback
                validateStatus={
                  formik.errors.company_name && formik.touched.company_name
                    ? "error"
                    : undefined
                }
                help={
                  formik.errors.company_name && formik.touched.company_name
                    ? formik.errors.company_name
                    : undefined
                }
              >
                <CreatableSelect
                  isClearable
                  defaultInputValue={
                    editPrefillCompanyName && editPrefillCompanyName
                  }
                  name="company_name"
                  options={options as any}
                  // value={
                  //   options && options.find((option : any) => option.value === formik.values.company_name) ||
                  //   null
                  // }
                  //onChange={option => formik.setFieldValue('company_name', editPrefillData?.data?.company_name)}
                  onChange={(option) => handleChange(option)}
                  //onChange={(option) => handleChange(option)}
                  //onChange={(option) => formik.setFieldValue("company_name", option?.value)}
                  value={formik.values.company_name}
                  // value={editPrefillCompanyName}
                  //onChange={(selectedOption) => formik.setFieldValue("company_name", selectedOption)}
                  // onChange={(company_name: string) =>
                  //   formik.setFieldValue("company_name", company_name)
                  // }
                  isDisabled={
                    contactPrefillData || editPrefillData?.data?.client_name
                      ? true
                      : false
                  }
                  // placeholder = { contactPrefillData && contactPrefillData?.data?.company_name}
                  // defaultValue={
                  //   editPrefillCompanyName && {
                  //     label: editPrefillCompanyName,
                  //     value: editPrefillCompanyName,
                  //   }
                  // }
                  placeholder={
                    contactPrefillData
                      ? contactPrefillData?.data?.company_name
                      : editPrefillData?.data?.company_name
                      ? editPrefillData?.data?.company_name
                      : "Company Name"
                  }
                  onBlur={formik.handleBlur}
                  inputValue={companyValue}
                  onInputChange={handleCompanyChange}
                />
              </Form.Item>
            </Row>
            <Row gutter={16}>
              <Col span={24} md={{ span: 8 }}>
                <Form.Item
                  label="Client Name"
                  required
                  //name={"client_name"}
                  className="w-full"
                  hasFeedback
                  validateStatus={
                    formik.errors.client_name && formik.touched.client_name
                      ? "error"
                      : ""
                  }
                  help={
                    formik.errors.client_name && formik.touched.client_name
                      ? formik.errors.client_name
                      : ""
                  }
                >
                  <Input
                    name={"client_name"}
                    //value={(isDataAvailable && prefillData?.data?.client_name) ? formik.values.client_name : ""}
                    value={formik.values.client_name}
                    onChange={(e) => {
                      const upperCaseValue = e.target.value.toUpperCase();
                      formik.setFieldValue("client_name", upperCaseValue);
                    }}
                    onBlur={formik.handleBlur}
                    placeholder="Client Name"
                    disabled={
                      (!isDataAvailable && prefillData?.data?.client_name) ||
                      contactPrefillData?.data?.client_name
                        ? true
                        : false
                    }
                    //disabled={!isDataAvailable && prefillData?.data?.client_name}
                  />
                </Form.Item>
                <Form.Item
                  label="Email"
                  // required
                  // name={"email"}
                  className="w-full"
                  hasFeedback
                  validateStatus={
                    formik.errors.email && formik.touched.email ? "error" : ""
                  }
                  help={
                    formik.errors.email && formik.touched.email
                      ? formik.errors.email
                      : ""
                  }
                >
                  <Input
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Email"
                    // disabled={contactPrefillData?.data?.client_name}
                    disabled={
                      !isDataAvailable &&
                      (prefillData?.data?.email ||
                        contactPrefillData?.data?.email)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={24} md={{ span: 8 }}>
                <Form.Item
                  label="Region"
                  required
                  // name={"region"}
                  className="w-full "
                  hasFeedback
                  validateStatus={
                    formik.errors.region && formik.touched.region ? "error" : ""
                  }
                  help={
                    formik.errors.region && formik.touched.region
                      ? formik.errors.region
                      : ""
                  }
                >
                  {/* <Select
                    value={formik.values.region}
                    onChange={(value: string) =>
                      formik.setFieldValue("region", value)
                    }
                    onBlur={formik.handleBlur}
                    placeholder={"Region"}
                    disabled={
                      !isDataAvailable &&
                      (prefillData?.data?.region ||
                        contactPrefillData?.data?.region)
                    }
                  >
                    {regionData?.map((resp: any) => (
                      <Select.Option key={resp.id} value={resp.id}>
                        {resp?.name}
                      </Select.Option>
                    ))}
                  </Select> */}
                  <CreatableSelect
                    isClearable
                    name="region"
                    className="create-table-height"
                    options={optionsRegion}
                    onCreateOption={(option) => {
                      handleRegion(option);
                    }}
                    onChange={(option) => handleChangeRegion(option)}
                    value={formik.values.region}
                    isDisabled={
                      (!isDataAvailable && prefillData?.data?.region) ||
                      contactPrefillData?.data?.region
                    }
                    placeholder="Region"
                    inputValue={inputValue}
                    onInputChange={handleInputChange}
                  />
                </Form.Item>
                <Form.Item
                  label="Phone No"
                  required
                  //name={"phone"}
                  hasFeedback
                  validateStatus={
                    formik.errors.phone && formik.touched.phone ? "error" : ""
                  }
                  help={
                    formik.errors.phone && formik.touched.phone
                      ? formik.errors.phone
                      : ""
                  }
                >
                  <Input
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Phone No"
                    // disabled={contactPrefillData?.data?.phone}
                    disabled={
                      !isDataAvailable &&
                      (prefillData?.data?.phone ||
                        contactPrefillData?.data?.phone)
                    }
                  />
                </Form.Item>
              </Col>

              <Col span={24} md={{ span: 8 }}>
                <Form.Item
                  label="Designation"
                  //name={"designation"}
                  required
                  hasFeedback
                  validateStatus={
                    formik.errors.designation && formik.touched.designation
                      ? "error"
                      : ""
                  }
                  help={
                    formik.errors.designation && formik.touched.designation
                      ? formik.errors.designation
                      : ""
                  }
                >
                  <CreatableSelect
                    isClearable
                    name="designation"
                    options={optionsDesignation}
                    onCreateOption={(option) => {
                      handleDesignation(option);
                    }}
                    onChange={(option) => handleChangeDesignation(option)}
                    value={formik.values.designation}
                    isDisabled={
                      (!isDataAvailable && prefillData?.data?.designation) ||
                      contactPrefillData?.data?.designation
                    }
                    placeholder="Designation"
                    inputValue={designationValue}
                    onInputChange={handleDesignationChange}
                  />
                  {/* <Select
                    value={formik.values.designation}
                    onChange={(value: string) =>
                      formik.setFieldValue("designation", value)
                    }
                    onBlur={formik.handleBlur}
                    placeholder={"Designation"}
                    disabled={
                      (!isDataAvailable && prefillData?.data?.designation) ||
                      contactPrefillData?.data?.designation
                    }
                  >
                    {designationData?.map((resp: any) => (
                      <Select.Option key={resp.id} value={resp.id}>
                        {resp?.name}
                      </Select.Option>
                    ))}
                  </Select> */}
                </Form.Item>
                <Form.Item
                  label="Type"
                  required
                  //name={"type"}
                  hasFeedback
                  validateStatus={
                    formik.errors.type && formik.touched.type ? "error" : ""
                  }
                  help={
                    formik.errors.type && formik.touched.type
                      ? formik.errors.type
                      : ""
                  }
                >
                  {/* <Select
                    value={formik.values.type}
                    onChange={(value: string) =>
                      formik.setFieldValue("type", value)
                    }
                    onBlur={formik.handleBlur}
                    placeholder={"Type"}
                    disabled={
                      (!isDataAvailable && prefillData?.data?.type) ||
                      contactPrefillData?.data?.type
                    }
                  >
                    {typeData?.map((resp: any) => (
                      <Select.Option key={resp.id} value={resp.id}>
                        {resp?.name}
                      </Select.Option>
                    ))}
                  </Select> */}
                  <CreatableSelect
                    isClearable
                    name="type"
                    options={optionsType}
                    onCreateOption={(option) => {
                      handleType(option);
                    }}
                    onChange={(option) => handleChangeType(option)}
                    value={formik.values.type}
                    isDisabled={
                      (!isDataAvailable && prefillData?.data?.type) ||
                      contactPrefillData?.data?.type
                    }
                    placeholder="Type"
                    inputValue={typeValue}
                    onInputChange={handleTypeChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Form.Item
                label="Address"
                required
                className="w-full"
                //name={"address"}
                hasFeedback
                validateStatus={
                  formik.errors.address && formik.touched.address ? "error" : ""
                }
                help={
                  formik.errors.address && formik.touched.address
                    ? formik.errors.address
                    : ""
                }
              >
                <TextArea
                  name="address"
                  value={formik.values.address}
                  // onChange={formik.handleChange}
                  onChange={(e) => {
                    const upperCaseValue = e.target.value.toUpperCase();
                    formik.setFieldValue("address", upperCaseValue);
                  }}
                  onBlur={formik.handleBlur}
                  placeholder="Address"
                  disabled={
                    contactPrefillData?.data?.address ||
                    (!isDataAvailable && prefillData?.data?.address)
                  }
                />
              </Form.Item>
            </Row>
            <Row gutter={16}>
              <Col span={24} md={{ span: 8 }}>
                <Form.Item
                  label="GST"
                  // required
                  //name={"gst"}
                  hasFeedback
                  validateStatus={
                    formik.errors.gst && formik.touched.gst ? "error" : ""
                  }
                  help={
                    formik.errors.gst && formik.touched.gst
                      ? formik.errors.gst
                      : ""
                  }
                >
                  <Input
                    name="gst"
                    value={formik.values.gst}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="GST"
                    disabled={
                      contactPrefillData?.data?.gst ||
                      (!isDataAvailable && prefillData?.data?.gst)
                    }
                  />
                </Form.Item>
                <Form.Item
                  label="Source"
                  required
                  //name={"source"}
                  hasFeedback
                  validateStatus={
                    formik.errors.source && formik.touched.source ? "error" : ""
                  }
                  help={
                    formik.errors.source && formik.touched.source
                      ? formik.errors.source
                      : ""
                  }
                >
                  {/* <Select
                    value={formik.values.source}
                    onChange={(value: string) =>
                      formik.setFieldValue("source", value)
                    }
                    onBlur={formik.handleBlur}
                    placeholder={"Source"}
                    disabled={
                      (!isDataAvailable && prefillData?.data?.source) ||
                      contactPrefillData?.data?.source
                    }
                  >
                    {sourceData?.map((resp: any) => (
                      <Select.Option key={resp.id} value={resp.id}>
                        {resp?.name}
                      </Select.Option>
                    ))}
                  </Select> */}
                  <CreatableSelect
                    isClearable
                    name="source"
                    options={optionsSource}
                    onCreateOption={(option) => {
                      handleSource(option);
                    }}
                    onChange={(option) => handleChangeSource(option)}
                    value={formik.values.source}
                    isDisabled={
                      (!isDataAvailable && prefillData?.data?.source) ||
                      contactPrefillData?.data?.source
                    }
                    placeholder="Source"
                    inputValue={sourceValue}
                    onInputChange={handleSourceChange}
                  />
                </Form.Item>
              </Col>
              <Col span={24} md={{ span: 8 }}>
                <Form.Item
                  label="Industry"
                  required
                  //name={"industry"}
                  hasFeedback
                  validateStatus={
                    formik.errors.industry && formik.touched.industry
                      ? "error"
                      : ""
                  }
                  help={
                    formik.errors.industry && formik.touched.industry
                      ? formik.errors.industry
                      : ""
                  }
                >
                  {/* <Select
                    value={formik.values.industry}
                    onChange={(value: string) =>
                      formik.setFieldValue("industry", value)
                    }
                    onBlur={formik.handleBlur}
                    placeholder={"Industry"}
                    disabled={
                      (!isDataAvailable && prefillData?.data?.industry) ||
                      contactPrefillData?.data?.industry
                    }
                  >
                    {industryData?.map((resp: any) => (
                      <Select.Option key={resp.id} value={resp.id}>
                        {resp?.name}
                      </Select.Option>
                    ))}
                  </Select> */}
                  <CreatableSelect
                    isClearable
                    name="industry"
                    options={optionsIndustry}
                    onCreateOption={(option) => {
                      handleIndustry(option);
                    }}
                    onChange={(option) => handleChangeIndustry(option)}
                    value={formik.values.industry}
                    isDisabled={
                      (!isDataAvailable && prefillData?.data?.industry) ||
                      contactPrefillData?.data?.industry
                    }
                    placeholder="Industry"
                    inputValue={industryValue}
                    onInputChange={handleIndustryChange}
                  />
                </Form.Item>
                {/* <Form.Item
                  label="Nature"
                  required
                  //name={"nature"}
                  hasFeedback
                  validateStatus={
                    formik.errors.nature && formik.touched.nature ? "error" : ""
                  }
                  help={
                    formik.errors.nature && formik.touched.nature
                      ? formik.errors.nature
                      : ""
                  }
                >
                  <CreatableSelect
                    isClearable
                    name="nature"
                    options={optionsNature}
                    onCreateOption={(option) => {
                      handleNature(option);
                    }}
                    onChange={(option) => handleChangeNature(option)}
                    value={formik.values.nature}
                    isDisabled={
                      prefillData?.data?.nature ||
                      contactPrefillData?.data?.nature
                    }
                    placeholder="Nature"
                    inputValue={natureValue}
                    onInputChange={handleNatureChange}
                  />
                </Form.Item> */}
              </Col>

              <Col span={24} md={{ span: 8 }}>
                <Form.Item
                  label="Enquiry Category"
                  //name={"enquiry_category"}
                  required
                  hasFeedback
                  validateStatus={
                    formik.errors.enquiry_category &&
                    formik.touched.enquiry_category
                      ? "error"
                      : undefined
                  }
                  help={
                    formik.errors.enquiry_category &&
                    formik.touched.enquiry_category
                      ? formik.errors.enquiry_category
                      : undefined
                  }
                >
                  {/* <Select
                    value={formik.values.enquiry_category}
                    onChange={(value: string) =>
                      formik.setFieldValue("enquiry_category", value)
                    }
                    onBlur={formik.handleBlur}
                    placeholder={"Enquiry Category"}
                    disabled={
                      !isDataAvailable && prefillData?.data?.enquiry_category
                    }
                  >
                    {enquiryCategoryData?.map((resp: any) => (
                      <Select.Option key={resp.id} value={resp.id}>
                        {resp?.name}
                      </Select.Option>
                    ))}
                  </Select> */}
                  <CreatableSelect
                    isClearable
                    name="enquiry_category"
                    options={optionsEnquiry}
                    onCreateOption={(option) => {
                      handleEnquiry(option);
                    }}
                    onChange={(option) => handleChange2(option)}
                    // value={
                    //   prefillData?.data?.enquiry_category ||
                    //   editPrefillData?.data?.enquiry_category ||
                    //   contactPrefillData?.data?.enquiry_category
                    //     ? {
                    //         value:
                    //           prefillData?.data?.enquiry_category ||
                    //           editPrefillData?.data?.enquiry_category ||
                    //           contactPrefillData?.data?.enquiry_category,
                    //         label:
                    //           prefillData?.data?.enquiry_category_name ||
                    //           editPrefillData?.data?.enquiry_category_name ||
                    //           contactPrefillData?.data?.enquiry_category_name,
                    //       }
                    //     : formik.values.enquiry_category
                    // }
                    value={formik.values.enquiry_category}
                    isDisabled={
                      (!isDataAvailable &&
                        prefillData?.data?.enquiry_category) ||
                      contactPrefillData?.data?.enquiry_category
                    }
                    placeholder="Enquiry category"
                    inputValue={categoryValue}
                    onInputChange={handleCategoryChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            {/* <div className="create-quotation-title-2 margin-block">Add Items</div> */}
            <Text
              value="Add items"
              variant="wearlay-headline-2"
              className="margin-block"
            />
            <Form.List
              name="items"
              initialValue={
                (editPrefillData || contactPrefillData)?.data?.items
                  ? (editPrefillData || contactPrefillData)?.data?.items?.map(
                      () => ({})
                    )
                  : [{}]
              }
            >
              {(fields, { add, remove }) => (
                <div className="item-table-width">
                  <div className="child-item-table-width">
                    <Row>
                      <Col
                        span={10}
                        className="create-quotation-border-main create-quotation-placeholder-2"
                      >
                        *Item Information
                      </Col>
                      <Col
                        span={6}
                        className="create-quotation-border-main create-quotation-placeholder-2"
                      >
                        *Brand
                      </Col>
                      <Col
                        span={4}
                        className="create-quotation-border-main create-quotation-placeholder-2"
                      >
                        *Qty
                      </Col>
                      <Col
                        span={4}
                        className="create-quotation-border-main create-quotation-placeholder-2"
                      >
                        Action
                      </Col>
                    </Row>
                    {fields.map(({ key, name, ...restField }) => (
                      <Row>
                        <Col span={10} className="create-quotation-border-main">
                          <Form.Item
                            {...restField}
                            //name={[name, "item_information"]}
                            required
                            hasFeedback
                            // validateStatus={
                            //   formik.errors.items &&
                            //   formik.errors.items[name] &&
                            //   (
                            //     formik.errors.items[name] as FormikErrors<{
                            //       item_information: string;
                            //       qty: string;
                            //     }>
                            //   )?.item_information
                            //     ? "error"
                            //     : ""
                            // }
                            validateStatus={
                              formik.touched.items &&
                              formik.touched.items[name] &&
                              formik.errors.items &&
                              formik.errors.items[name] &&
                              (
                                formik.errors.items[name] as FormikErrors<{
                                  item_information: string;
                                  brand: string;
                                  qty: number;
                                }>
                              )?.item_information
                                ? "error"
                                : ""
                            }
                            // help={
                            //   formik.errors.items &&
                            //   formik.errors.items[name] &&
                            //   (
                            //     formik.errors.items[name] as FormikErrors<{
                            //       item_information: string;
                            //       qty: string;
                            //     }>
                            //   )?.item_information
                            //     ? (
                            //         formik.errors.items[name] as FormikErrors<{
                            //           item_information: string;
                            //           qty: string;
                            //         }>
                            //       )?.item_information
                            //     : ""
                            // }
                            help={
                              formik.touched.items &&
                              formik.touched.items[name] &&
                              formik.errors.items &&
                              formik.errors.items[name] &&
                              (
                                formik.errors.items[name] as FormikErrors<{
                                  item_information: string;
                                  brand: string;
                                  qty: number;
                                }>
                              )?.item_information
                                ? (
                                    formik.errors.items[name] as FormikErrors<{
                                      item_information: string;
                                      brand: string;
                                      qty: number;
                                    }>
                                  )?.item_information
                                : ""
                            }
                          >
                            <Input
                              name={`items.${name}.item_information`}
                              value={
                                formik.values.items[name]?.item_information ||
                                ""
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              placeholder="Item Information"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={6} className="create-quotation-border-main">
                          <Form.Item
                            {...restField}
                            //name={[name, "brand"]}
                            required
                            hasFeedback
                            // validateStatus={
                            //   formik.errors.items &&
                            //   formik.errors.items[name] &&
                            //   (
                            //     formik.errors.items[name] as FormikErrors<{
                            //       item_information: string;
                            //       brand: string;
                            //       qty: string;
                            //     }>
                            //   )?.brand
                            //     ? "error"
                            //     : ""
                            // }
                            validateStatus={
                              formik.touched.items &&
                              formik.touched.items[name] &&
                              formik.errors.items &&
                              formik.errors.items[name] &&
                              (
                                formik.errors.items[name] as FormikErrors<{
                                  item_information: string;
                                  brand: string;
                                  qty: number;
                                }>
                              )?.brand
                                ? "error"
                                : ""
                            }
                            // help={
                            //   formik.errors.items &&
                            //   formik.errors.items[name] &&
                            //   (
                            //     formik.errors.items[name] as FormikErrors<{
                            //       item_information: string;
                            //       brand: string;
                            //       qty: string;
                            //     }>
                            //   )?.brand
                            //     ? (
                            //         formik.errors.items[name] as FormikErrors<{
                            //           item_information: string;
                            //           brand: string;
                            //           qty: string;
                            //         }>
                            //       )?.brand
                            //     : ""
                            // }
                            help={
                              formik.touched.items &&
                              formik.touched.items[name] &&
                              formik.errors.items &&
                              formik.errors.items[name] &&
                              (
                                formik.errors.items[name] as FormikErrors<{
                                  item_information: string;
                                  brand: string;
                                  qty: number;
                                }>
                              )?.brand
                                ? (
                                    formik.errors.items[name] as FormikErrors<{
                                      item_information: string;
                                      brand: string;
                                      qty: number;
                                    }>
                                  )?.brand
                                : ""
                            }
                          >
                            <Input
                              name={`items.${name}.brand`}
                              value={formik.values.items[name]?.brand || ""}
                              // onChange={formik.handleChange}
                              onChange={(e) => {
                                const upperCaseValue =
                                  e.target.value.toUpperCase();
                                formik.setFieldValue(
                                  `items.${name}.brand`,
                                  upperCaseValue
                                );
                              }}
                              onBlur={formik.handleBlur}
                              placeholder="Brand"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={4} className="create-quotation-border-main">
                          {" "}
                          <Form.Item
                            {...restField}
                            //name={[name, "qty"]}
                            hasFeedback
                            // validateStatus={
                            //   formik.errors.items &&
                            //   formik.errors.items[name] &&
                            //   (
                            //     formik.errors.items[name] as FormikErrors<{
                            //       item_information: string;
                            //       brand: string;
                            //       qty: string;
                            //     }>
                            //   )?.qty
                            //     ? "error"
                            //     : ""
                            // }
                            validateStatus={
                              formik.touched.items &&
                              formik.touched.items[name] &&
                              formik.errors.items &&
                              formik.errors.items[name] &&
                              (
                                formik.errors.items[name] as FormikErrors<{
                                  item_information: string;
                                  brand: string;
                                  qty: number;
                                }>
                              )?.qty
                                ? "error"
                                : undefined
                            }
                            // help={
                            //   formik.errors.items &&
                            //   formik.errors.items[name] &&
                            //   (
                            //     formik.errors.items[name] as FormikErrors<{
                            //       item_information: string;
                            //       brand: string;
                            //       qty: string;
                            //     }>
                            //   )?.qty
                            //     ? (
                            //         formik.errors.items[name] as FormikErrors<{
                            //           item_information: string;
                            //           brand: string;
                            //           qty: string;
                            //         }>
                            //       )?.qty
                            //     : ""
                            // }
                            help={
                              formik.touched.items &&
                              formik.touched.items[name] &&
                              formik.errors.items &&
                              formik.errors.items[name] &&
                              (
                                formik.errors.items[name] as FormikErrors<{
                                  item_information: string;
                                  brand: string;
                                  qty: number;
                                }>
                              )?.qty
                                ? (
                                    formik.errors.items[name] as FormikErrors<{
                                      item_information: string;
                                      brand: string;
                                      qty: number;
                                    }>
                                  )?.qty
                                : undefined
                            }
                          >
                            <Input
                              name={`items.${name}.qty`}
                              value={
                                formik.values.items[name]?.qty || undefined
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              placeholder="Quantity"
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  add(); // Set the state to true when the Enter key is pressed
                                }
                              }}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={4} className="create-quotation-border-main">
                          {fields.length > 1 && (
                            <img
                              src="/assets/remove.svg"
                              onClick={() => {
                                remove(name);
                                handleRemove(name);
                              }}
                            />
                          )}
                        </Col>
                      </Row>
                    ))}
                    <Row>
                      <Col
                        span={20}
                        className="create-quotation-border-main"
                      ></Col>

                      <Col span={4} className="create-quotation-border-main">
                        <img
                          src="/assets/add.svg"
                          onClick={() => add()}
                          className="close-button-item "
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              )}
            </Form.List>
            <Divider />
            <div className="mt-24p">
              {/* <div className="createlead-title-1">Note</div> */}
              <Text value="Note" variant="wearlay-headline-2" />
              <Form.Item
                //name={"note"}
                hasFeedback
                validateStatus={
                  formik.errors.note && formik.touched.note ? "error" : ""
                }
                help={
                  formik.errors.note && formik.touched.note
                    ? formik.errors.note
                    : ""
                }
              >
                <TextArea
                  name="note"
                  value={formik.values.note}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Note"
                />
              </Form.Item>
            </div>
            <Divider />
            <Text value="Follow Up Status" variant="wearlay-headline-1" />

            <Text value="Follow Up 1" variant="wearlay-headline-2" />

            <Row gutter={16}>
              <Col span={24} md={{ span: 8 }}>
                <Form.Item
                  label="Date"
                  className="w-full"
                  required
                  // name={`follow_up[0].date`}
                  validateStatus={
                    formik.touched.follow_up &&
                    formik.touched.follow_up[0] &&
                    formik.errors.follow_up &&
                    formik.errors.follow_up[0] &&
                    (
                      formik.errors.follow_up[0] as FormikErrors<{
                        date: string;
                        notes: string;
                        next_follow_up_date: string;
                      }>
                    )?.date
                      ? "error"
                      : undefined
                  }
                  help={
                    formik.touched.follow_up &&
                    formik.touched.follow_up[0] &&
                    formik.errors.follow_up &&
                    formik.errors.follow_up[0] &&
                    (
                      formik.errors.follow_up[0] as FormikErrors<{
                        date: string;
                        notes: string;
                        next_follow_up_date: string;
                      }>
                    )?.date
                      ? (
                          formik.errors.follow_up[0] as FormikErrors<{
                            date: string;
                            notes: string;
                            next_follow_up_date: string;
                          }>
                        )?.date
                      : undefined
                  }
                >
                  <DatePicker
                    className="w-full"
                    onBlur={formik.handleBlur}
                    onChange={(value) =>
                      formik.setFieldValue(
                        `follow_up[0].date`,
                        value ? value.format("YYYY-MM-DD") : dayjs()
                      )
                    }
                    value={
                      formik.values.follow_up[0].date
                        ? dayjs(formik.values.follow_up[0].date)
                        : dayjs()
                    }
                    name={`follow_up[0].date`}
                    format="DD-MM-YYYY"
                  />
                </Form.Item>
              </Col>

              <Col span={24} md={{ span: 8 }}>
                <Form.Item
                  label="Next Follow up Date"
                  className="w-full"
                  required
                  // name={`follow_up$[0].next_follow_up_date`}
                  validateStatus={
                    formik.touched.follow_up &&
                    formik.touched.follow_up[0] &&
                    formik.errors.follow_up &&
                    formik.errors.follow_up[0] &&
                    (
                      formik.errors.follow_up[0] as FormikErrors<{
                        date: string;
                        notes: string;
                        next_follow_up_date: string;
                      }>
                    )?.next_follow_up_date
                      ? "error"
                      : undefined
                  }
                  help={
                    formik.touched.follow_up &&
                    formik.touched.follow_up[0] &&
                    formik.errors.follow_up &&
                    formik.errors.follow_up[0] &&
                    (
                      formik.errors.follow_up[0] as FormikErrors<{
                        date: string;
                        notes: string;
                        next_follow_up_date: string;
                      }>
                    )?.next_follow_up_date
                      ? (
                          formik.errors.follow_up[0] as FormikErrors<{
                            date: string;
                            notes: string;
                            next_follow_up_date: string;
                          }>
                        )?.next_follow_up_date
                      : undefined
                  }
                >
                  <DatePicker
                    className="w-full"
                    onBlur={formik.handleBlur}
                    onChange={(value) =>
                      formik.setFieldValue(
                        `follow_up[0].next_follow_up_date`,
                        value ? value.format("YYYY-MM-DD") : ""
                      )
                    }
                    value={
                      formik.values.follow_up[0].next_follow_up_date
                        ? dayjs(formik.values.follow_up[0].next_follow_up_date)
                        : null
                    }
                    format="DD-MM-YYYY"
                    disabledDate={(current) =>
                      current && current < moment().endOf("day")
                    }
                    name={`follow_up[0].next_follow_up_date`}
                  />
                </Form.Item>
              </Col>
              <Col span={24} md={{ span: 8 }}>
                <Form.Item
                  label="Follow Up Note"
                  className="w-full"
                  required
                  validateStatus={
                    formik.touched.follow_up &&
                    formik.touched.follow_up[0] &&
                    formik.errors.follow_up &&
                    formik.errors.follow_up[0] &&
                    (
                      formik.errors.follow_up[0] as FormikErrors<{
                        date: string;
                        notes: string;
                        next_follow_up_date: string;
                      }>
                    )?.notes
                      ? "error"
                      : undefined
                  }
                  help={
                    formik.touched.follow_up &&
                    formik.touched.follow_up[0] &&
                    formik.errors.follow_up &&
                    formik.errors.follow_up[0] &&
                    (
                      formik.errors.follow_up[0] as FormikErrors<{
                        date: string;
                        notes: string;
                        next_follow_up_date: string;
                      }>
                    )?.notes
                      ? (
                          formik.errors.follow_up[0] as FormikErrors<{
                            date: string;
                            notes: string;
                            next_follow_up_date: string;
                          }>
                        )?.notes
                      : undefined
                  }
                  // name={`follow_up[0].notes`}
                >
                  <TextArea
                    value={formik.values.follow_up[0].notes}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Note"
                    name={`follow_up[0].notes`}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row justify={"end"}>
              <Form.Item>
                <CommonButton
                  variant="wealay-primary-btn"
                  className="createlead-button"
                  value={sales_id ? "Update" : "Add Lead"}
                  disabled={!formik.isValid}
                  isLoading={createLoading || editLoading}
                />
              </Form.Item>
            </Row>
          </Form>
        </div>
        {/* )} */}
      </div>
    </>
  );
};
