import { Button, Col, Form, Input, message, Row, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import "./index.css";
import * as Yup from "yup";
import { Formik, FormikValues, useFormik } from "formik";
import { CommonButton } from "../../CommonButton/button";
import CreatableSelect from "react-select/creatable";

import {
  useCreateContactsMutation,
  useCreateRegionMutation,
  useEditContactsMutation,
  useGetContactsByIdQuery,
  useGetDesignationQuery,
  useGetIndustryQuery,
  useGetNatureQuery,
  useGetRegionQuery,
  useGetSourcesQuery,
  useGetTypesQuery,
} from "../../../services/api";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
interface ICreateContact {
  setOpen: (data: boolean) => void;
  setEdit: (data: boolean) => void;
  datas?: any;
  isEdit?: boolean;
}
export const AddNewClient: React.FC<ICreateContact> = ({
  setOpen,
  datas,
  isEdit,
  setEdit,
}) => {
  const [designationValue, setDesignationValue] = useState("");
  const [natureValue, setNatureValue] = useState("");
  const [sourceValue, setSourceValue] = useState("");
  const [categoryValue, setCategoryValue] = useState("");
  const [industryValue, setIndustryValue] = useState("");
  const [typeValue, setTypeValue] = useState("");
  const [groupValue, setgroupValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [createContacts, { data, isLoading }] = useCreateContactsMutation();
  const [editContact, { data: editContacts, isLoading: isContactLoading }] =
    useEditContactsMutation();

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [mutation, { data: regioncreatedata }] = useCreateRegionMutation();

  const [searchParams, setSearchParams] = useSearchParams();

  const id = searchParams.get("id");

  const { data: contactById, isLoading: contactLoading } =
    useGetContactsByIdQuery(id);

  const { data: sourceData, isLoading: sourceLoading } = useGetSourcesQuery("");
  const { data: typeData, isLoading: typeLoading } = useGetTypesQuery("");
  const { data: natureData, isLoading: natureLoading } = useGetNatureQuery("");
  const { data: designationData, isLoading: designationLoading } =
    useGetDesignationQuery("");
  const { data: industryData, isLoading: industyLoading } =
    useGetIndustryQuery("");
  const { data: regionData, isLoading: regionLoading } = useGetRegionQuery("");
  useEffect(() => {
    if (contactById) {
      formik.setFieldValue("company_name", contactById.data?.company_name);
      formik.setFieldValue("client_name", contactById.data?.client_name);
      formik.setFieldValue("region", {
        value: contactById.data?.region,
        label: contactById.data?.region_name,
      });
      formik.setFieldValue("designation", {
        value: contactById.data?.designation,
        label: contactById.data?.designation_name,
      });
      formik.setFieldValue("email", contactById.data?.email);
      formik.setFieldValue("phone", contactById.data?.phone);
      formik.setFieldValue("type", {
        value: contactById.data?.type,
        label: contactById.data?.type_name,
      });
      formik.setFieldValue("address", contactById.data?.address);
      formik.setFieldValue("gst", contactById.data?.gst);
      formik.setFieldValue("industry", {
        value: contactById.data?.industry,
        label: contactById.data?.industry_name,
      });
      formik.setFieldValue("source", {
        value: contactById.data?.source,
        label: contactById.data?.source_name,
      });
      formik.setFieldValue("note", contactById.data?.note);
    }
  }, [contactById]);

  const userId = localStorage.getItem("user_id");
  const phoneRegExp = /^[6-9]\d{9}$/;
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email format"
      ),
    // .required("Email is required"),

    phone: Yup.string()
      .matches(/^[0-9+]+$/, "Invalid phone number")

      .required("Phone number is required"),
    client_name: Yup.string()
      // .matches(
      //   /^[A-Za-z\s]+$/,
      //   "Client Name  must contain only letters and spaces"
      // )
      .required("Client name is required"),
    company_name: Yup.string()
      // .matches(
      //   /^[A-Za-z\s]+$/,
      //   "Company Name  must contain only letters and spaces"
      // )
      .required("Company name is required"),
    region: Yup.object().required("Region is required"),
    designation: Yup.object().required("Designation is required"),
    type: Yup.object().required("Type is required"),
    address: Yup.string()
      // .matches(/^[A-Za-z\s]+$/, "Address must contain only letters and spaces")
      .required("Address is required"),
    gst: Yup.string().matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
      "Invalid GST number"
    ),
    // .required("GST number is required"),
    industry: Yup.object().required("Industry is required"),
    source: Yup.object().required("Source is required"),
    note: Yup.string(),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      note: "",
      phone: "",
      client_name: "",
      company_name: "",
      region: undefined,
      designation: undefined,
      type: undefined,
      address: "",
      gst: "",
      industry: undefined,
      source: undefined,
    },
    validationSchema,
    onSubmit: async (values: any) => {
      const finalData = {
        ...values,
        location: {
          region: values.region,
          address: values.address,
        },
        region: values.region.value,

        industry: values.industry.value,

        source: values.source.value,
        designation: values.designation.value,
        type: values.type.value,
      };
      const resetParams = {
        body: finalData,
        id: userId,
      };
      const editParams = {
        body: finalData,
        id: id,
      };
      await (isEdit ? editContact(editParams) : createContacts(resetParams))
        .unwrap()
        .then((data) => {
          setOpen(false);
          setEdit(false);
          form.resetFields();
          message.success(data?.message);
        })

        .catch((err) => {
          message.error(err?.data?.message);
        });
    },
  });

  interface RegionOption {
    value: number;
    label: string;
  }
  const optionsRegion: RegionOption[] | undefined = regionData?.map(
    ({ id, name }: { id: number; name: string }) => ({
      value: id,
      label: name,
    })
  );
  const optionsDesignation: RegionOption[] | undefined = designationData?.map(
    ({ id, name }: { id: number; name: string }) => ({
      value: id,
      label: name,
    })
  );
  const optionsNature: RegionOption[] | undefined = natureData?.map(
    ({ id, name }: { id: number; name: string }) => ({
      value: id,
      label: name,
    })
  );
  const optionsType: RegionOption[] | undefined = typeData?.map(
    ({ id, name }: { id: number; name: string }) => ({
      value: id,
      label: name,
    })
  );
  const optionsIndustry: RegionOption[] | undefined = industryData?.map(
    ({ id, name }: { id: number; name: string }) => ({
      value: id,
      label: name,
    })
  );
  const optionsSource: RegionOption[] | undefined = sourceData?.map(
    ({ id, name }: { id: number; name: string }) => ({
      value: id,
      label: name,
    })
  );
  // const optionsEnquiry: RegionOption[] | undefined = enquiryCategoryData?.map(
  //   ({ id, name }: { id: number; name: string }) => ({
  //     value: id,
  //     label: name,
  //   })
  // );
  const handleRegion = async (data: any) => {
    const dataParams = {
      name: data,
    };
    const finalData = {
      id: "region",
      body: dataParams,
    };

    await mutation(finalData)
      .unwrap()
      .then((data: any) => {
        message.success(data?.message);

        // setIsDataAvailable(false);
      })
      .catch((err: any) => {
        message.error(err?.data?.message);
        // setIsDataAvailable(true);
      });
  };
  const handleDesignation = async (data: any) => {
    const dataParams = {
      name: data,
    };
    const finalData = {
      id: "designation",
      body: dataParams,
    };

    await mutation(finalData)
      .unwrap()
      .then((data: any) => {
        message.success(data?.message);

        // setIsDataAvailable(false);
      })
      .catch((err: any) => {
        message.error(err?.data?.message);
        // setIsDataAvailable(true);
      });
  };

  const handleType = async (data: any) => {
    const dataParams = {
      name: data,
    };
    const finalData = {
      id: "type",
      body: dataParams,
    };

    await mutation(finalData)
      .unwrap()
      .then((data: any) => {
        message.success(data?.message);

        // setIsDataAvailable(false);
      })
      .catch((err: any) => {
        message.error(err?.data?.message);
        // setIsDataAvailable(true);
      });
  };
  const handleIndustry = async (data: any) => {
    const dataParams = {
      name: data,
    };
    const finalData = {
      id: "industry",
      body: dataParams,
    };

    await mutation(finalData)
      .unwrap()
      .then((data: any) => {
        message.success(data?.message);

        // setIsDataAvailable(false);
      })
      .catch((err: any) => {
        message.error(err?.data?.message);
        // setIsDataAvailable(true);
      });
  };
  const handleSource = async (data: any) => {
    const dataParams = {
      name: data,
    };
    const finalData = {
      id: "source",
      body: dataParams,
    };

    await mutation(finalData)
      .unwrap()
      .then((data: any) => {
        message.success(data?.message);

        // setIsDataAvailable(false);
      })
      .catch((err: any) => {
        message.error(err?.data?.message);
        // setIsDataAvailable(true);
      });
  };

  const handleEnquiry = async (data: any) => {
    const dataParams = {
      name: data,
    };
    const finalData = {
      id: "enquiry_category",
      body: dataParams,
    };

    await mutation(finalData)
      .unwrap()
      .then((data: any) => {
        message.success(data?.message);

        // setIsDataAvailable(false);
      })
      .catch((err: any) => {
        message.error(err?.data?.message);
        // setIsDataAvailable(true);
      });
  };
  const handleNature = async (data: any) => {
    const dataParams = {
      name: data,
    };
    const finalData = {
      id: "nature",
      body: dataParams,
    };

    await mutation(finalData)
      .unwrap()
      .then((data: any) => {
        message.success(data?.message);

        // setIsDataAvailable(false);
      })
      .catch((err: any) => {
        message.error(err?.data?.message);
        // setIsDataAvailable(true);
      });
  };
  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission on Enter key press
    }
  };
  const handleChange2 = (option: any) => {
    formik.setFieldValue("designation", {
      value: option?.value,
      label: option?.label,
    });
  };
  const handleChangeRegion = (option: any) => {
    formik.setFieldValue("region", {
      value: option?.value,
      label: option?.label,
    });
  };
  const handleChangeDesignation = (option: any) => {
    formik.setFieldValue("designation", {
      value: option?.value,
      label: option?.label,
    });
  };
  const handleChangeSource = (option: any) => {
    formik.setFieldValue("source", {
      value: option?.value,
      label: option?.label,
    });
  };
  const handleChangeIndustry = (option: any) => {
    formik.setFieldValue("industry", {
      value: option?.value,
      label: option?.label,
    });
  };
  const handleChangeType = (option: any) => {
    formik.setFieldValue("type", {
      value: option?.value,
      label: option?.label,
    });
  };
  const handleInputChange = (newValue: any) => {
    // Convert the input value to uppercase
    const upperCaseValue = newValue.toUpperCase();
    setInputValue(upperCaseValue);
  };
  const handleGroupChange = (newValue: any) => {
    // Convert the input value to uppercase
    const upperCaseValue = newValue.toUpperCase();
    setgroupValue(upperCaseValue);
  };
  const handleDesignationChange = (newValue: any) => {
    // Convert the input value to uppercase
    const upperCaseValue = newValue.toUpperCase();
    setDesignationValue(upperCaseValue);
  };
  const handleCategoryChange = (newValue: any) => {
    // Convert the input value to uppercase
    const upperCaseValue = newValue.toUpperCase();
    setCategoryValue(upperCaseValue);
  };
  const handleNatureChange = (newValue: any) => {
    // Convert the input value to uppercase
    const upperCaseValue = newValue.toUpperCase();
    setNatureValue(upperCaseValue);
  };
  const handleSourceChange = (newValue: any) => {
    // Convert the input value to uppercase
    const upperCaseValue = newValue.toUpperCase();
    setSourceValue(upperCaseValue);
  };
  const handleIndustryChange = (newValue: any) => {
    // Convert the input value to uppercase
    const upperCaseValue = newValue.toUpperCase();
    setIndustryValue(upperCaseValue);
  };
  const handleTypeChange = (newValue: any) => {
    // Convert the input value to uppercase
    const upperCaseValue = newValue.toUpperCase();
    setTypeValue(upperCaseValue);
  };
  return (
    <>
      <div>
        <div>
          <Form layout="vertical" onFinish={formik.handleSubmit}>
            <Row>
              <Form.Item
                label="Company Name"
                className="w-full"
                // name={"company_name"}
                required
                hasFeedback
                validateStatus={
                  formik.errors.company_name && formik.touched.company_name
                    ? "error"
                    : ""
                }
                help={
                  formik.errors.company_name && formik.touched.company_name
                    ? formik.errors.company_name
                    : ""
                }
              >
                <Input
                  name="company_name"
                  value={formik.values.company_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Company Name"
                />
              </Form.Item>
            </Row>
            <Row gutter={16}>
              <Col span={24} md={{ span: 12 }}>
                <Form.Item
                  label="Client Name"
                  required
                  // name={"client_name"}
                  hasFeedback
                  validateStatus={
                    formik.errors.client_name && formik.touched.client_name
                      ? "error"
                      : ""
                  }
                  help={
                    formik.errors.client_name && formik.touched.client_name
                      ? formik.errors.client_name
                      : ""
                  }
                >
                  <Input
                    name="client_name"
                    value={formik.values.client_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Client Name"
                  />
                </Form.Item>
                <Form.Item
                  label="Designation"
                  required
                  // name={"designation"}
                  hasFeedback
                  validateStatus={
                    formik.errors.designation && formik.touched.designation
                      ? "error"
                      : ""
                  }
                  help={
                    formik.errors.designation && formik.touched.designation
                      ? formik.errors.designation
                      : ""
                  }
                >
                  {/* <Select
                    // name={"designation"}
                    value={formik.values.designation}
                    // onChange={formik.handleChange}
                    onChange={(value: string) =>
                      formik.setFieldValue("designation", value)
                    }
                    onBlur={formik.handleBlur}
                    placeholder="Designation"
                  >
                    {designationData?.map((resp: any) => (
                      <Select.Option key={resp.id} value={resp.id}>
                        {resp?.name}
                      </Select.Option>
                    ))}
                  </Select> */}
                  <CreatableSelect
                    isClearable
                    name="designation"
                    options={optionsDesignation}
                    onCreateOption={(option) => {
                      handleDesignation(option);
                    }}
                    onChange={(option) => handleChange2(option)}
                    value={formik.values.designation}
                    placeholder="Designation"
                    inputValue={designationValue}
                    onInputChange={handleDesignationChange}
                  />
                </Form.Item>
                <Form.Item
                  label="Phone No"
                  // name={"phone"}
                  required
                  hasFeedback
                  validateStatus={
                    formik.errors.phone && formik.touched.phone ? "error" : ""
                  }
                  help={
                    formik.errors.phone && formik.touched.phone
                      ? formik.errors.phone
                      : ""
                  }
                >
                  <Input
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder={"Phone Number"}
                  />
                </Form.Item>
              </Col>
              <Col span={24} md={{ span: 12 }}>
                <Form.Item
                  label="Region"
                  // name={"region"}
                  required
                  hasFeedback
                  validateStatus={
                    formik.errors.region && formik.touched.region ? "error" : ""
                  }
                  help={
                    formik.errors.region && formik.touched.region
                      ? formik.errors.region
                      : ""
                  }
                >
                  {/* <Select
                    // name={"region"}

                    value={formik.values.region}
                    // onChange={formik.handleChange}
                    onChange={(value: string) =>
                      formik.setFieldValue("region", value)
                    }
                    onBlur={formik.handleBlur}
                    placeholder={"Region"}
                  >
                    {regionData?.map((resp: any) => (
                      <Select.Option key={resp.id} value={resp.id}>
                        {resp?.name}
                      </Select.Option>
                    ))}
                  </Select> */}
                  <CreatableSelect
                    isClearable
                    name="region"
                    options={optionsRegion}
                    onCreateOption={(option) => {
                      handleRegion(option);
                    }}
                    onChange={(option) => handleChangeRegion(option)}
                    value={formik.values.region}
                    placeholder="Region"
                    inputValue={inputValue}
                    onInputChange={handleInputChange}
                  />
                </Form.Item>
                <Form.Item
                  label="Email"
                  // name={"email"}
                  // required
                  hasFeedback
                  validateStatus={
                    formik.errors.email && formik.touched.email ? "error" : ""
                  }
                  help={
                    formik.errors.email && formik.touched.email
                      ? formik.errors.email
                      : ""
                  }
                >
                  <Input
                    name={"email"}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Enter Email"
                  />
                </Form.Item>
                <Form.Item
                  label="Type"
                  required
                  // name={"type"}
                  hasFeedback
                  validateStatus={
                    formik.errors.type && formik.touched.type ? "error" : ""
                  }
                  help={
                    formik.errors.type && formik.touched.type
                      ? formik.errors.type
                      : ""
                  }
                >
                  {/* <Select
                    // name={"type"}
                    value={formik.values.type}
                    // onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder={"Type"}
                    onChange={(value: string) =>
                      formik.setFieldValue("type", value)
                    }
                  >
                    {typeData?.map((resp: any) => (
                      <Select.Option key={resp.id} value={resp.id}>
                        {resp?.name}
                      </Select.Option>
                    ))}
                  </Select> */}
                  <CreatableSelect
                    isClearable
                    name="type"
                    options={optionsType}
                    onCreateOption={(option) => {
                      handleType(option);
                    }}
                    onChange={(option) => handleChangeType(option)}
                    value={formik.values.type}
                    placeholder="Type"
                    inputValue={typeValue}
                    onInputChange={handleTypeChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Form.Item
                label="Address"
                // name={"address"}
                required
                className="w-full"
                hasFeedback
                validateStatus={
                  formik.errors.address && formik.touched.address ? "error" : ""
                }
                help={
                  formik.errors.address && formik.touched.address
                    ? formik.errors.address
                    : ""
                }
              >
                <TextArea
                  name={"address"}
                  showCount
                  maxLength={256}
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder={"Address"}
                />
              </Form.Item>
            </Row>
            <Row gutter={16}>
              <Col span={24} md={{ span: 12 }}>
                <Form.Item
                  label="GST"
                  // name={"gst"}
                  // required
                  hasFeedback
                  validateStatus={
                    formik.errors.gst && formik.touched.gst ? "error" : ""
                  }
                  help={
                    formik.errors.gst && formik.touched.gst
                      ? formik.errors.gst
                      : ""
                  }
                >
                  <Input
                    name={"gst"}
                    value={formik.values.gst}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder={"GST"}
                  />
                </Form.Item>
                <Form.Item
                  label="Source"
                  // name={"source"}
                  required
                  hasFeedback
                  validateStatus={
                    formik.errors.source && formik.touched.source ? "error" : ""
                  }
                  help={
                    formik.errors.source && formik.touched.source
                      ? formik.errors.source
                      : ""
                  }
                >
                  {/* <Select
                    // name={"source"}

                    value={formik.values.source}
                    // onChange={formik.handleChange}
                    onChange={(value: string) =>
                      formik.setFieldValue("source", value)
                    }
                    onBlur={formik.handleBlur}
                    placeholder="Source"
                  >
                    {sourceData?.map((resp: any) => (
                      <Select.Option key={resp.id} value={resp.id}>
                        {resp?.name}
                      </Select.Option>
                    ))}
                  </Select> */}
                  <CreatableSelect
                    isClearable
                    name="source"
                    options={optionsSource}
                    onCreateOption={(option) => {
                      handleSource(option);
                    }}
                    onChange={(option) => handleChangeSource(option)}
                    value={formik.values.source}
                    placeholder="Source"
                    inputValue={sourceValue}
                    onInputChange={handleSourceChange}
                  />
                </Form.Item>
              </Col>
              <Col span={24} md={{ span: 12 }}>
                <Form.Item
                  label="Industry"
                  // name={"industry"}
                  required
                  hasFeedback
                  validateStatus={
                    formik.errors.industry && formik.touched.industry
                      ? "error"
                      : ""
                  }
                  help={
                    formik.errors.industry && formik.touched.industry
                      ? formik.errors.industry
                      : ""
                  }
                >
                  {/* <Select
                    // name={"industry"}
                    onChange={(value: string) =>
                      formik.setFieldValue("industry", value)
                    }
                    value={formik.values.industry}
                    // onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Industry"
                  >
                    {industryData?.map((resp: any) => (
                      <Select.Option key={resp.id} value={resp.id}>
                        {resp?.name}
                      </Select.Option>
                    ))}
                  </Select> */}
                  <CreatableSelect
                    isClearable
                    name="industry"
                    options={optionsIndustry}
                    onCreateOption={(option) => {
                      handleIndustry(option);
                    }}
                    onChange={(option) => handleChangeIndustry(option)}
                    value={formik.values.industry}
                    placeholder="Industry"
                    inputValue={industryValue}
                    onInputChange={handleIndustryChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Form.Item
                label="Special note"
                // name={"address"}
                // required
                className="w-full"
                hasFeedback
                validateStatus={
                  formik.errors.note && formik.touched.note ? "error" : ""
                }
                help={
                  formik.errors.note && formik.touched.note
                    ? formik.errors.note
                    : ""
                }
              >
                <TextArea
                  name={"note"}
                  showCount
                  maxLength={256}
                  value={formik.values.note}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder={"Note"}
                />
              </Form.Item>
            </Row>
            <Row>
              <Form.Item className="w-full">
                <CommonButton
                  variant="wealay-primary-btn"
                  value={isEdit ? "Update" : "Add New Client"}
                  className="add-new-client"
                  disabled={!formik.isValid}
                  isLoading={isEdit ? isContactLoading : isLoading}
                />
              </Form.Item>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
};
