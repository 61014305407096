import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Empty,
  Form,
  Input,
  message,
  Row,
  Select,
  Skeleton,
  Space,
  Tag,
  Tooltip,
  Upload,
} from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { CommonButton } from "../../CommonButton/button";
import CreatableSelect from "react-select/creatable";

import "./index.css";
import {
  useCreateRegionMutation,
  useDeleteOrderFileMutation,
  useGetDeliveryStatusQuery,
  useGetDispatchModeQuery,
  useGetOrderByIdQuery,
  useGetOrderShareQuery,
  useGetQuotationByIdQuery,
  useGetQuotationOrNpdQuery,
  useGetSalesAfterNpdQuotationQuery,
  useGetSalesAfterQuotationQuery,
  useUpdateOrderMutation,
  useUploadFileMutation,
  useUploadOrderFileMutation,
} from "../../../services/api";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import {
  concatTextFunction,
  errorHandling,
} from "../../../helpers/helperFunction";
import { CheckCircleOutlined } from "@ant-design/icons";
interface IOrder {
  setPreview?: (data: boolean) => void;
  setNpdData?: (data: any) => void;
  setQuotationData?: (data: any) => void;
}

export const CloseOrder: React.FC<IOrder> = ({
  setPreview,
  setNpdData,
  setQuotationData,
}) => {
  interface RegionOption {
    value: number;
    label: string;
  }
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const formData = new FormData();

  const id = searchParams.get("id");
  const [newDeliveryArray, setNewDeliveryArray] = useState<any>([]);

  const [dispatchDates, setDispatchDates] = useState<string[]>([]);
  const [invoice, setInvoice] = useState<string[]>([]);
  const [status, setStatus] = useState<any>([]);
  const [uploadFiles, setUploadFile] = useState<any>();
  const [uploadItems, setUploadItems] = useState<any>();
  const [items, setItems] = useState<any>();
  const [notes, setNotes] = useState<string>("");

  const [dispatch_mode, setDispathMode] = useState<any>([]);
  const [note, setNote] = useState<string[]>([]);
  const [image, setImage] = useState<any>([
    {
      file_url: "",
      file_name: "",
      file_size: "",
      file_uploaded_date: null,
    },
  ]);

  const [isAssignButton, setAssignButton] = useState(false);
  const [isAssignDetailButton, setAssignDetailButton] = useState(false);

  const [isAddDetails, setAddIsDetail] = useState(true);
  const [isAddSchedule, setAddIsSchedule] = useState(true);
  const [isAssignSchedule, setAddIsAssignSchedule] = useState(false);
  const [isAssignDetailsSchedule, setAddIsAssignDetailsSchedule] =
    useState(false);

  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const [isSelectAllDetailsChecked, setIsSelectAllDetailsChecked] =
    useState(false);

  const [isAddFeedback, setAddIsFeedback] = useState(true);
  const [isDetails, setIsDetail] = useState(false);
  const [isSchedule, setIsSchedule] = useState(false);
  const [isFeedback, setIsFeedback] = useState(false);
  const [isSelectedItem, setIsSelectedItem] = useState<any>();
  const [isImageckeck, setIsImageCheck] = useState<any>();
  const [inputValue, setInputValue] = useState("");

  const [isSelectedDetailItem, setIsSelectedDetailItem] = useState<any>();

  const [isSelectedDate, setIsSelectedDate] = useState<any>();
  const [isSelectedData, setIsSelectedData] = useState<any>();

  const [isSaveDetail, setIsSaveDetail] = useState(false);
  const [isSaveSheduleDetail, setIsSaveSheduleDetail] = useState(false);

  const [isSaveFeedbackDetail, setSaveIsFeedbackDetail] = useState(false);
  // const { data, isLoading } = useGetOrderByIdQuery(id);
  const createdBy = searchParams.get("created_by");

  const shareParams = {
    id: id,
    user_id: createdBy,
  };
  const { data, isLoading, error }: any = useGetOrderShareQuery(shareParams);

  useEffect(() => {
    if (error) {
      const navigationTarget = errorHandling(error);
      if (navigationTarget) {
        navigate(navigationTarget);
      }
    }
  }, [error]);
  const [uploadFile, { data: uploadData, isLoading: uploadLoading }] =
    useUploadOrderFileMutation();
  const { data: npdOrQuotationData, isLoading: npdOrQuotationDataLoading } =
    useGetQuotationOrNpdQuery(data?.data?.quotation);

  const { data: quotationData, isLoading: loadingQuery } =
    useGetSalesAfterQuotationQuery(npdOrQuotationData?.data[0]?.sales_lead);
  const { data: quotationByIdData, isLoading: quotationByIdDataLoading } =
    useGetQuotationByIdQuery(data?.data?.quotation);
  const { data: dispatchData, isLoading: dispatchloading } =
    useGetDispatchModeQuery("");
  const { data: statusData, isLoading: statusloading } =
    useGetDeliveryStatusQuery("");
  const { data: npdData, isLoading: npdLoading } =
    useGetSalesAfterNpdQuotationQuery(npdOrQuotationData?.data[0]?.npd);
  const [updateOrder, { isLoading: updateloader }] = useUpdateOrderMutation();
  const [deleteFile] = useDeleteOrderFileMutation();

  const validationSchema = Yup.object().shape({
    dispatch_mode: Yup.object().required("Dispatch mode is required"),
    delivered_date: Yup.date().required("This date is required"),
    status: Yup.string().required("Status is required"),
    note: Yup.string().required("Note is required"),
    invoice_no: Yup.string()
      .required("Invoice No is required")
      .matches(
        /^[a-zA-Z0-9\s]+$/,
        "Only alphanumeric characters and spaces are allowed"
      ),
    // clientFeedback: Yup.string().required("Client feedback is required"),
  });
  const validationSchema2 = Yup.object().shape({
    dispatch_date: Yup.date().required("This date is required"),
  });
  const validationSchema3 = Yup.object().shape({
    client_feedback: Yup.string().required("This field is required"),
  });

  const objectNames = _.map(data?.data?.dispatch_schedule, "item");

  const objectNamesForAllSelectId = _.map(quotationByIdData?.data?.items, "id");
  const objectNamesForAllDetailsSelectId = _.map(
    quotationByIdData?.data?.items,
    "id"
  );
  const objectNamesForSelectIdsDone = _.map(
    data?.data?.dispatch_schedule,
    "item"
  );
  const objectNamesForAllSelect = _.difference(
    objectNamesForAllSelectId,
    objectNamesForSelectIdsDone
  );
  const objectNamesForAllDetailsSelectIdsDone = _.map(
    data?.data?.delivery_details,
    "item"
  );
  const objectNamesForAllDetailsSelect = _.difference(
    objectNamesForAllDetailsSelectId,
    objectNamesForAllDetailsSelectIdsDone
  );

  // Find the matching items
  const matchingItems = _.filter(quotationByIdData?.data?.items, (detail) => {
    return _.includes(_.map(data?.data?.delivery_details, "item"), detail.id);
  });

  // Extract the item values
  const itemValues = _.map(matchingItems, "id");
  const commonDetailsId = _.difference(isSelectedDetailItem, itemValues);

  // useEffect(() => {
  //   setIsImageCheck([171]);
  // }, [isSelectedDetailItem]);
  const commonItems = _.map(
    _.filter(quotationByIdData?.data?.items, (obj) =>
      _.includes(isSelectedItem || itemValues, obj.id)
    ),
    "id"
  );

  const mappedArray = _.some(commonDetailsId, (element) => element === 171);

  const formik2 = useFormik({
    initialValues: {
      dispatch_date: undefined,
    },
    validationSchema: validationSchema2,
    onSubmit: async (values) => {
      const { dispatch_date } = values;

      const finalData = (
        isSelectAllChecked
          ? objectNamesForAllSelect
          : _.difference(isSelectedItem, objectNames)
      )?.map((itemId: any, index: number) => ({
        item: itemId,
        dispatch_date: dispatchDates[0],
      }));
      const dispatch_schedule = finalData
        ? finalData.map(({ item, dispatch_date }: any) => ({
            item,
            dispatch_date,
          }))
        : [];

      const datas = {
        dispatch_schedule,
        order_status:
          quotationByIdData?.data?.items?.length === isSelectedItem?.length
            ? 1
            : isSelectAllChecked &&
              quotationByIdData?.data?.items?.length ===
                objectNamesForAllSelectId?.length
            ? 1
            : 0,
      };
      const editParams = {
        id: id,

        body: datas,
      };
      await updateOrder(editParams)
        .unwrap()
        .then((data) => {
          message.success(data?.message);
          setPreview && setPreview(false);
          navigate("/order");
        })
        .catch((err) => {
          message.error(err?.data?.message);
        });
    },
  });
  const formik = useFormik({
    initialValues: {
      delivered_date: undefined,
      status: undefined,
      note: "",
      // clientFeedback: "",
      invoice_no: "",
      dispatch_mode: "",
    },
    validationSchema,
    onSubmit: async (values: any) => {
      const finalData = (
        isSelectAllDetailsChecked
          ? objectNamesForAllDetailsSelect
          : _.difference(isSelectedDetailItem, itemValues)
      )?.map((itemId: any, index: number) => ({
        item: itemId,
        delivered_date: dispatchDates[0],
        invoice_no: invoice[0],
        status: status[0],
        dispatch_mode: values.dispatch_mode.value,
        note: note[0],
        // image: image[index]
        //   ? image[index]
        //   : [
        //       data?.data?.delivery_details[index]?.file_url,
        //       data?.data?.delivery_details[index]?.file_name,
        //       data?.data?.delivery_details[index]?.file_size,
        //       data?.data?.delivery_details[index]?.file_uploaded_date,
        //     ],
        file_url: image[0]?.file_url ? image[0]?.file_url : "",
        file_name: image[0]?.file_name ? image[0]?.file_name : "",
        file_size: image[0]?.file_size ? image[0]?.file_size : "",
        file_uploaded_date: image[0]?.file_uploaded_date
          ? image[0]?.file_uploaded_date
          : null,
      }));
      const delivery_details = finalData
        ? finalData.map(
            ({
              item,
              delivered_date,
              invoice_no,
              status,
              dispatch_mode,
              note,
              file_url,
              file_name,
              file_size,
              file_uploaded_date,
            }: any) => ({
              item,
              delivered_date,
              invoice_no,
              status,
              dispatch_mode,
              note,
              file_url,
              file_name,
              file_size,
              file_uploaded_date,
            })
          )
        : [];

      const datas = {
        delivery_details,

        order_status:
          quotationByIdData?.data?.items?.length ===
          isSelectedDetailItem?.length
            ? 2
            : isSelectAllDetailsChecked &&
              quotationByIdData?.data?.items?.length ===
                objectNamesForAllDetailsSelectId.length
            ? 2
            : quotationByIdData?.data?.items?.length ===
              data?.data?.dispatch_schedule?.length
            ? 1
            : 0,
      };
      const editParams = {
        id: id,

        body: datas,
      };
      await updateOrder(editParams)
        .unwrap()
        .then((data) => {
          message.success(data?.message);
          setPreview && setPreview(false);
          navigate("/order");
        })
        .catch((err) => {
          message.error(err?.data?.message);
        });
    },
  });
  const formik3 = useFormik({
    initialValues: {
      client_feedback: "",
    },
    validationSchema: validationSchema3,
    onSubmit: async (values) => {
      const finalData = [
        {
          values,
          // items: isSelectedItem,
        },
      ];
      const datas = {
        client_feedback: values?.client_feedback,
        // order_status:
        //   quotationByIdData?.data?.items?.length ===
        //   data?.data?.dispatch_schedule?.length
        //     ? 1
        //     : quotationByIdData?.data?.items?.length ===
        //       data?.data?.delivery_details?.length
        //     ? 2
        //     : data?.data?.client_feedback
        //     ? 3
        //     : 0,
        order_status: 2,
      };
      const editParams = {
        id: id,

        body: datas,
      };
      await updateOrder(editParams)
        .unwrap()
        .then((data) => {
          message.success(data?.message);
          setPreview && setPreview(false);
          navigate("/order");
        })
        .catch((err) => {
          message.error(err?.data?.message);
        });
    },
  });
  const handleSubmit = (value: any) => {
    localStorage.setItem("token", "sdsdsdsd");
  };
  const handleChange = (selectedItems: any) => {
    setIsSelectedItem(selectedItems);
    const updatedDispatchDates = selectedItems.map((itemId: any) => {
      const existingIndex = isSelectedItem?.findIndex(
        (id: any) => id === itemId
      );
      if (existingIndex !== -1) {
        // Item already has a dispatch date, return the existing date
        return dispatchDates[existingIndex];
      }
    });

    const filter = _.difference(selectedItems, objectNames);

    // Remove items from dispatchDates that are not present in selectedItems
    // const filteredDispatchDates = dispatchDates.filter(
    //   (date: any, index: number) =>
    //     _.difference(selectedItems, objectNames).includes(
    //       _.difference(selectedItems, objectNames)[index]
    //     )
    // );
    // setDispatchDates(filteredDispatchDates);
  };

  const handleChange2 = (selectedItems: any) => {
    setIsSelectedDetailItem(selectedItems);
    const updatedDispatchDates = selectedItems.map((itemId: any) => {
      const existingIndex = commonItems?.findIndex((id: any) => id === itemId);
      if (existingIndex !== -1) {
        // Item already has a dispatch date, return the existing date
        return (
          dispatchDates[existingIndex],
          invoice[existingIndex],
          status[existingIndex],
          dispatch_mode[existingIndex],
          note[existingIndex],
          image[existingIndex]
        );
      } else {
        // Item is newly selected, return an empty date or a default value
        return "";
      }
    });
    // setDispatchDates(updatedDispatchDates);
  };

  const handleUpload = async (file: any) => {
    formData.append("file", file.file);

    await uploadFile(formData)
      .unwrap()
      .then((data) => {
        message.success("Uploaded successfully");
      })
      .catch((err) => {
        message.error(err?.data?.message);
      });
  };
  useEffect(() => {
    setUploadFile(uploadData?.data);
  }, [uploadData, uploadLoading]);

  useEffect(() => {
    setUploadItems({
      file_url: "url",
      file_name: uploadFiles && uploadFiles?.file_name,
      file_size: uploadFiles?.file_size,
      file_uploaded_date: uploadFiles?.upload_date,
    });
  }, [uploadData, uploadLoading]);

  // const handleItems = (item: any) => {
  //   if (commonDetailsId && commonDetailsId.includes(item) && uploadFiles) {
  //     const selectedItemIndex = commonDetailsId.indexOf(item);
  //     const newDispatchDates = [...image];
  //     newDispatchDates[selectedItemIndex] = {
  //       file_url: uploadFiles?.file_url,
  //       file_name: uploadFiles && uploadFiles?.file_name,
  //       file_size: uploadFiles?.file_size,
  //       file_uploaded_date: uploadFiles?.upload_date,
  //     };
  //     setImage(newDispatchDates);
  //   }
  // };
  const disabledDate = (current: any) => {
    // Get the current date without the time part
    const today = moment().startOf("day");

    // Disable dates before today (including today)
    return current && current.isBefore(today);
  };
  // const handleChange = (selectedItems: any) => {
  //   setIsSelectedItem(selectedItems); // Update the state with the selected checkboxes

  //   const updatedDispatchDates: any = { ...dispatchDates }; // Create a copy of the current dispatch dates object

  //   selectedItems.forEach((itemId: any) => {
  //     if (!updatedDispatchDates[itemId]) {
  //       // If the dispatch date is not set for the item, initialize it to the current date
  //       updatedDispatchDates[itemId] = new Date().toISOString().split("T")[0];
  //     }
  //   });

  //   setDispatchDates(updatedDispatchDates); // Update the state with the updated dispatch dates
  // };
  useEffect(() => {
    setImage([
      {
        file_url: uploadFiles?.file_url,
        file_name: uploadFiles && uploadFiles?.file_name,
        file_size: uploadFiles?.file_size,
        file_uploaded_date: uploadFiles?.upload_date,
      },
    ]);
  }, [uploadData, uploadLoading, uploadFiles]);

  useEffect(() => {
    const finalData = commonDetailsId?.map((itemId: any, index: number) => ({
      item: itemId,
      delivered_date: dispatchDates[index],
      invoice_no: invoice[index],
      status: status[index],
      dispatch_mode: dispatch_mode[index],
      note: note[index],
      image: image[index],
    }));
    const delivery_details = finalData
      ? finalData.map(
          ({
            item,
            delivered_date,
            invoice_no,
            status,
            dispatch_mode,
            note,
            image,
          }: any) => ({
            item,
            delivered_date,
            invoice_no,
            status,
            dispatch_mode,
            note,
            image,
          })
        )
      : [];

    const data = {
      delivery_details,
    };

    setIsSelectedData(data);
  }, [uploadData]);

  const dispatchIndex = _.findIndex(isSelectedData?.dispatch_details, {
    item: items,
  });
  const hanleDelete = async (index?: any) => {
    const deleteData = {
      file_name: index,
    };
    await deleteFile(deleteData)
      .unwrap()
      .then((data) => {
        message.success(data?.message);
      })
      .catch((err) => {
        message.error(err?.data?.message);
      });
  };
  // useEffect(() => {
  //   hanleDelete();
  // }, [_.includes(commonItems, isSelectedItem)]);

  const filteredArray = quotationByIdData?.data?.items.map(
    (element: any) => element.id
  );

  const checkIndexForValue = (array: any, index: any, targetObject: any) => {
    const objectAtIndex = _.get(array, index);
    return objectAtIndex && objectAtIndex.status === targetObject;
  };

  const closeOrderSubmit = async () => {
    const datas = {
      order_status: 3,
    };
    const editParams = {
      id: id,

      body: datas,
    };
    await updateOrder(editParams)
      .unwrap()
      .then((data) => {
        message.success(data?.message);
        setPreview && setPreview(false);
        navigate("/order");
      })
      .catch((err) => {
        message.error(err?.data?.message);
      });
  };
  useEffect(() => {
    setQuotationData && setQuotationData(quotationData);
    setNpdData && setNpdData(npdData);
  }, [quotationData, npdData]);
  const [mutation, { data: regioncreatedata }] = useCreateRegionMutation();

  const handleDispatchMode = async (data: any) => {
    const dataParams = {
      name: data,
    };
    const finalData = {
      id: "dispatchmode",
      body: dataParams,
    };

    await mutation(finalData)
      .unwrap()
      .then((data: any) => {
        message.success(data?.message);

        // setIsDataAvailable(false);
      })
      .catch((err: any) => {
        message.error(err?.data?.message);
        // setIsDataAvailable(true);
      });
  };
  const handleChangeDispatchMode = async (option: any) => {
    formik.setFieldValue("dispatch_mode", {
      value: option?.value,
      label: option?.label,
    });
  };
  const optionsDispatchMode: RegionOption[] | undefined = dispatchData?.map(
    ({ id, name }: { id: number; name: string }) => ({
      value: id,
      label: name,
    })
  );
  const handleInputChange = (newValue: any) => {
    // Convert the input value to uppercase
    const upperCaseValue = newValue.toUpperCase();
    setInputValue(upperCaseValue);
  };
  return (
    <>
      {isLoading ? (
        <Skeleton avatar paragraph={{ rows: 8 }} />
      ) : data?.data ? (
        <div>
          <div className="flex justify-between">
            <div className="create-quotation-title-3">Order</div>
            <div>
              <img src="/assets/jaws.svg"></img>
            </div>
          </div>

          <div className="create-quotation-border-no-padding create-quotation-margin-top">
            <div className="p-1 bottom-border close-order-padding">
              <div className="create-quotation-title-1 flex flex-col gap-2">
                Customer Unique Identification Number
              </div>
              <div className="create-quotations-title-2">
                {quotationData?.data[0]
                  ?.contacts_customer_unique_identification_number
                  ? quotationData?.data[0]
                      ?.contacts_customer_unique_identification_number
                  : "-"}
              </div>
            </div>
            <div className="p-1">
              <div className="create-quotation-title-1 flex flex-col gap-2">
                Lead Name
              </div>
              <div className="create-quotations-title-2">
                {" "}
                {quotationData?.data[0]?.lead_name
                  ? quotationData?.data[0]?.lead_name
                  : npdData?.data[0]?.lead_name
                  ? npdData?.data[0]?.lead_name
                  : "-"}
              </div>
            </div>
            <Row>
              <Col span={24} md={{ span: 12 }}>
                <div className="create-quotation-border flex flex-col gap-2">
                  <div className="create-quotation-title-1">Company Name</div>
                  <div className="create-quotations-title-2">
                    {/* {quotationData?.data[0]?.company_name
                      ? quotationData?.data[0]?.company_name
                      : npdData?.data[0]?.company_name
                      ? npdData?.data[0]?.company_name
                      : "-"} */}
                    <Tooltip
                      title={
                        quotationData?.data[0]?.company_name
                          ? quotationData?.data[0]?.company_name
                          : npdData?.data[0]?.company_name
                          ? npdData?.data[0]?.company_name
                          : "-"
                      }
                    >
                      {(quotationData?.data[0]?.company_name
                        ? quotationData?.data[0]?.company_name
                        : npdData?.data[0]?.company_name
                        ? npdData?.data[0]?.company_name
                        : "-"
                      )?.length > 25
                        ? (quotationData?.data[0]?.company_name
                            ? quotationData?.data[0]?.company_name
                            : npdData?.data[0]?.company_name
                            ? npdData?.data[0]?.company_name
                            : "-"
                          )
                            .slice(0, 25)
                            .concat("...")
                        : quotationData?.data[0]?.company_name
                        ? quotationData?.data[0]?.company_name
                        : npdData?.data[0]?.company_name}
                    </Tooltip>
                  </div>
                </div>
                <div className="create-quotation-border flex flex-col gap-2">
                  <div className="create-quotation-title-1">Region</div>
                  <div className="create-quotations-title-2">
                    {" "}
                    {quotationData?.data[0]?.region_name
                      ? quotationData?.data[0]?.region_name
                      : npdData?.data[0]?.region_name
                      ? npdData?.data[0]?.region_name
                      : "-"}
                  </div>
                </div>
                <div className="create-quotation-border flex flex-col gap-2">
                  <div className="create-quotation-title-1">Phone No</div>
                  <div className="create-quotations-title-2">
                    {" "}
                    {quotationData?.data[0]?.phone
                      ? quotationData?.data[0]?.phone
                      : npdData?.data[0]?.phone
                      ? npdData?.data[0]?.phone
                      : "-"}
                  </div>
                </div>
                <div className="create-quotation-border flex flex-col gap-2">
                  <div className="create-quotation-title-1">Code</div>
                  <div className="create-quotations-title-2">
                    {" "}
                    {/* {quotationData?.data[0]?.sales_lead_id
                      ? quotationData?.data[0]?.sales_lead_id
                      : npdData?.data[0]?.code
                      ? npdData?.data[0]?.code
                      : "-"} */}
                    <Tooltip
                      title={
                        quotationData?.data[0]?.sales_lead_id
                          ? quotationData?.data[0]?.sales_lead_id
                          : npdData?.data[0]?.code
                          ? npdData?.data[0]?.code
                          : "-"
                      }
                    >
                      {(quotationData?.data[0]?.sales_lead_id
                        ? quotationData?.data[0]?.sales_lead_id
                        : npdData?.data[0]?.code
                      )?.length > 25
                        ? (quotationData?.data[0]?.sales_lead_id
                            ? quotationData?.data[0]?.sales_lead_id
                            : npdData?.data[0]?.code
                          )
                            .slice(0, 25)
                            .concat("...")
                        : quotationData?.data[0]?.sales_lead_id
                        ? quotationData?.data[0]?.sales_lead_id
                        : npdData?.data[0]?.code}
                    </Tooltip>
                  </div>
                </div>
              </Col>
              <Col span={24} md={{ span: 12 }}>
                <div className="create-quotation-border flex flex-col gap-2">
                  <div className="create-quotation-title-1">Client Name</div>
                  <div className="create-quotations-title-2">
                    {" "}
                    {quotationData?.data[0]?.client_name
                      ? quotationData?.data[0]?.client_name
                      : npdData?.data[0]?.client_name
                      ? npdData?.data[0]?.client_name
                      : "-"}
                  </div>
                </div>
                <div className="create-quotation-border flex flex-col gap-2">
                  <div className="create-quotation-title-1">Email</div>
                  <div className="create-quotations-title-2">
                    {quotationData?.data[0]?.email
                      ? quotationData?.data[0]?.email
                      : npdData?.data[0]?.email
                      ? npdData?.data[0]?.email
                      : "-"}
                  </div>
                </div>
                <div className="create-quotation-border flex flex-col gap-2">
                  <div className="create-quotation-title-1">GST</div>
                  <div className="create-quotations-title-2">
                    {" "}
                    {quotationData?.data[0]?.gst
                      ? quotationData?.data[0]?.gst
                      : npdData?.data[0]?.gst
                      ? npdData?.data[0]?.gst
                      : "-"}
                  </div>
                </div>
                <div className="p-1 flex flex-col gap-2">
                  <div className="create-quotation-title-1">
                    Enquiry Category
                  </div>
                  <div className="create-quotations-title-2">
                    {" "}
                    {quotationData?.data[0]?.enquiry_category_name
                      ? quotationData?.data[0]?.enquiry_category_name
                      : npdData?.data[0]?.enquiry_category_name
                      ? npdData?.data[0]?.enquiry_category_name
                      : "-"}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className="create-quotation-border flex flex-col gap-2">
                  <div className="create-quotation-title-1">Address</div>
                  <div className="create-quotations-title-2 break-all">
                    {quotationData?.data[0]?.address
                      ? quotationData?.data[0]?.address
                      : npdData?.data[0]?.address
                      ? npdData?.data[0]?.address
                      : "-"}
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <Row className="create-quotation-margin-top create-quotation-div-2 ">
            <Col span={24} md={{ span: 12 }}>
              <div className="create-quotation-border flex flex-col gap-2">
                <div className="create-quotation-title-1">Material Grade</div>
                <div className="create-quotations-title-2">
                  {" "}
                  {quotationByIdData?.data?.material_grade}
                </div>
              </div>
              <div className="create-quotation-border flex flex-col gap-2">
                <div className="create-quotation-title-1">Mould Code</div>
                <div className="create-quotations-title-2">
                  {quotationByIdData?.data?.mould_code}
                </div>
              </div>
              <div className="create-quotation-border flex flex-col gap-2">
                <div className="create-quotation-title-1">Date Created</div>
                <div className="create-quotations-title-2">
                  {moment(data?.data?.order_punched_date).format("DD-MM-YYYY")}
                </div>
              </div>
              {/* <div className="create-quotation-border flex flex-col gap-2 ">
              <div className="create-quotation-title-1">
                Advance Received Date
              </div>
              <div className="create-quotations-title-2">24/05/2023</div>
            </div> */}
            </Col>
            <Col span={24} md={{ span: 12 }}>
              <div className="create-quotation-border flex flex-col gap-2">
                <div className="create-quotation-title-1">Drawing Number</div>
                <div className="create-quotations-title-2">
                  {quotationByIdData?.data?.drawing_number}
                </div>
              </div>
              <div className="create-quotation-border flex flex-col gap-2">
                <div className="create-quotation-title-1">Design Code</div>
                <div className="create-quotations-title-2">
                  {quotationByIdData?.data?.design_code}
                </div>
              </div>
              {/* <div className="create-quotation-border flex flex-col gap-2">
              <div className="create-quotation-title-1">
                Advance Received Amount
              </div>
              <div className="create-quotations-title-2">2500</div>
            </div> */}
            </Col>
          </Row>
          <Row className="create-quotation-margin-top">
            <Col
              span={7}
              className="create-quotations-title-2 create-quotation-border "
            >
              Item Information
            </Col>
            <Col
              span={3}
              className="create-quotations-title-2 create-quotation-border "
            >
              Qty
            </Col>
            <Col
              span={3}
              className="create-quotations-title-2 create-quotation-border "
            >
              Rate
            </Col>
            {_.some(
              quotationByIdData?.data?.items,
              (item) => item?.discount > 0
            ) && (
              <Col
                span={5}
                className="create-quotations-title-2 create-quotation-border "
              >
                Discount %
              </Col>
            )}
            <Col
              span={3}
              className="create-quotations-title-2 create-quotation-border "
            >
              GST%
            </Col>
            <Col
              span={
                _.some(
                  quotationByIdData?.data?.items,
                  (item) => item?.discount > 0
                )
                  ? 3
                  : 8
              }
              className="create-quotations-title-2 create-quotation-border "
            >
              Total
            </Col>
          </Row>
          {quotationByIdData?.data?.items?.map((items: any) => (
            <Row>
              <Col span={7} className="create-quotation-border ">
                {items?.item_information}
              </Col>
              <Col span={3} className="create-quotation-border ">
                {items?.qty}
              </Col>
              <Col span={3} className="create-quotation-border ">
                {items?.rate}
              </Col>
              {_.some(
                quotationByIdData?.data?.items,
                (item) => item?.discount > 0
              ) && (
                <Col span={5} className="create-quotation-border ">
                  {items?.discount ? items?.discount : "-"}
                </Col>
              )}
              <Col span={3} className="create-quotation-border ">
                {items?.gst}
              </Col>
              <Col
                span={
                  _.some(
                    quotationByIdData?.data?.items,
                    (item) => item?.discount > 0
                  )
                    ? 3
                    : 8
                }
                className="create-quotation-border "
              >
                {items?.total}
              </Col>
            </Row>
          ))}

          <Row>
            {/* <Col
            span={18}
            className="create-quotation-border create-quotations-title-2 flex justify-end "
          >
            Sales Discount
          </Col>
          <Col span={6} className="create-quotation-border ">
            400
          </Col>
        </Row>
        <Row>
          <Col
            span={18}
            className="create-quotation-border create-quotations-title-2 flex justify-end"
          >
            Total Discount
          </Col>
          <Col span={6} className="create-quotation-border ">
            400
          </Col> */}
          </Row>
          <Row>
            <Col
              span={18}
              className="create-quotation-border create-quotations-title-2 flex justify-end"
            >
              Shipping Cost
            </Col>
            <Col span={6} className="create-quotation-border ">
              {quotationByIdData?.data?.shipping_cost}
            </Col>
          </Row>
          <Row>
            <Col
              span={18}
              className="create-quotation-border create-quotations-title-2 flex justify-end "
            >
              Grand Total
            </Col>
            <Col span={6} className="create-quotation-border grand-total ">
              {quotationByIdData?.data?.grand_total}
            </Col>
          </Row>
          {/* <Row className="create-quotation-margin-top create-quotation-border ">
          <div className="create-quotation-title-1 p-1">Note</div>
          <div className="p-1">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </div>
        </Row> */}
          <Divider />
          {data?.data?.special_consideration_for_payment && (
            <Tag
              icon={<CheckCircleOutlined />}
              color="success"
              className="special-consideration"
            >
              Special consideration for payment
            </Tag>
          )}
          <div className="create-quotation-border-no-padding cancel-order-div-2">
            <Row>
              <Col span={24} md={{ span: 12 }}>
                <div className="create-quotation-border ">
                  <div className="create-quotation-title-1">
                    Advance Received Amount
                  </div>
                  <div className="create-quotations-title-2">
                    {data?.data?.advance_received_amount
                      ? data?.data?.advance_received_amount
                      : "-"}
                  </div>
                </div>
              </Col>

              <Col span={24} md={{ span: 12 }}>
                <div className="create-quotation-border ">
                  <div className="create-quotation-title-1">
                    Advance Received Date
                  </div>
                  <div className="create-quotations-title-2">
                    {data?.data?.advance_received_date
                      ? moment(data?.data?.advance_received_date).format(
                          "DD-MM-YYYY"
                        )
                      : "-"}
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="flex flex-col p-1">
              <div className="create-quotation-title-1 ">Note</div>
              <div className="">{quotationByIdData?.data?.note}</div>
            </Row>
          </div>
          <div className="cancel-order-status-title">Order Status</div>
          <div className=" create-quotation-border items-center cancel-order-border-radius  ">
            <div className="flex justify-between">
              <div className="cancel-order-detail-title ">
                {" "}
                Dispatch Schedule
              </div>

              {isAddSchedule &&
                quotationByIdData?.data?.items?.length !==
                  data?.data?.dispatch_schedule?.length && (
                  <Button
                    onClick={() => {
                      setAddIsSchedule(false);
                      setAddIsAssignSchedule(true);
                      setIsSchedule(true);
                    }}
                    className="close-add-button "
                  >
                    Add Date
                  </Button>
                )}
              {isAssignSchedule && (
                <Button
                  onClick={() => {
                    setAddIsSchedule(false);
                    setIsSchedule(true);
                    setAssignButton(
                      isSelectAllChecked ||
                        _.difference(isSelectedItem, objectNames)?.length !== 0
                        ? true
                        : false
                    );
                    setAddIsAssignSchedule(
                      isSelectAllChecked ||
                        _.difference(isSelectedItem, objectNames)?.length !== 0
                        ? false
                        : true
                    );
                    if (
                      isSelectAllChecked ||
                      _.difference(isSelectedItem, objectNames)?.length !== 0
                        ? false
                        : true
                    ) {
                      message.error(
                        "Please select item from dispatch schedule"
                      );
                    }
                  }}
                  className="close-add-button "
                >
                  Assign Date
                </Button>
              )}
              {isAssignButton && (
                <div className="flex gap-2">
                  <Button
                    onClick={() => {
                      setAssignButton(false);
                      setAddIsAssignSchedule(true);

                      // setAddIsDetail(true);
                      // setIsFeedback(false);
                      // setIsSchedule(false);
                      // setIsSaveSheduleDetail(true);
                    }}
                    className="close-add-button "
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      // setAddIsDetail(true);
                      // setIsFeedback(false);
                      // setIsSchedule(false);
                      // setIsSaveSheduleDetail(true);
                      formik2.handleSubmit();
                    }}
                    disabled={!formik2.isValid}
                    className="close-save-button"
                    loading={updateloader}
                  >
                    Save
                  </Button>
                </div>
              )}
            </div>
            <Divider />
            {((isSchedule &&
              quotationByIdData?.data?.items?.length !==
                data?.data?.dispatch_schedule?.length) ||
              (!isSchedule &&
                quotationByIdData?.data?.items?.length ===
                  data?.data?.dispatch_schedule?.length)) && (
              <div>
                {isAssignButton && (
                  <Form layout="vertical">
                    <Row>
                      <Form.Item
                        label="Dispatch Date"
                        required
                        name="dispatch_date"
                        hasFeedback
                        validateStatus={
                          formik2.errors.dispatch_date &&
                          formik2.touched.dispatch_date
                            ? "error"
                            : undefined
                        }
                        help={
                          formik2.errors.dispatch_date &&
                          formik2.touched.dispatch_date &&
                          formik2.errors.dispatch_date
                        }
                      >
                        <DatePicker
                          placeholder="Select date"
                          onBlur={formik2.handleBlur}
                          // onChange={(value: any) => {
                          //   const formattedDate = value
                          //     ? value.format("YYYY-MM-DD")
                          //     : "";
                          //   formik2.setFieldValue("dispatch_date", formattedDate);

                          //   (isSelectAllChecked
                          //     ? objectNamesForAllSelect
                          //     : _.difference(isSelectedItem, objectNames)
                          //   )?.map((itemId: any, index: number) => {
                          //     const newDispatchDates = [...dispatchDates];
                          //     newDispatchDates[index] = formattedDate;
                          //     setDispatchDates(newDispatchDates);
                          //   });

                          //   // const newDispatchDates = [...dispatchDates];
                          //   // setDispatchDates(newDispatchDates);
                          // }}
                          onChange={(value: any) => {
                            const formattedDate = value
                              ? value.format("YYYY-MM-DD")
                              : "";
                            formik2.setFieldValue(
                              "dispatch_date",
                              formattedDate
                            );

                            const selectedItems = isSelectAllChecked
                              ? objectNamesForAllSelect
                              : _.difference(isSelectedItem, objectNames);

                            const newDispatchDates = selectedItems.map(
                              () => formattedDate
                            );
                            setDispatchDates(newDispatchDates);
                          }}
                          value={dayjs(formik2.values.dispatch_date)}
                          format="DD-MM-YYYY"
                          disabledDate={disabledDate}
                        />
                      </Form.Item>
                    </Row>
                  </Form>
                )}
                {quotationByIdData?.data?.items?.length !==
                  data?.data?.dispatch_schedule?.length && (
                  <Checkbox
                    onChange={(e: any) => {
                      setIsSelectAllChecked(e.target.checked);
                    }}
                    className="select-all"
                  >
                    <span>Select All</span>
                  </Checkbox>
                )}
                <Checkbox.Group
                  onChange={handleChange}
                  value={
                    isSelectAllChecked
                      ? objectNamesForAllSelect
                      : isSelectedItem
                      ? isSelectedItem
                      : objectNames
                  }
                  // className={
                  //   objectNames.some((element) =>
                  //     quotationByIdData?.data?.items?.some(
                  //       (item: any) =>
                  //         item.id === element &&
                  //         _.includes(objectNames, item.id)
                  //     )
                  //   )
                  //     ? "selected-checkbox"
                  //     : ""
                  // }
                >
                  {quotationByIdData?.data?.items?.map((items: any) => (
                    <div className="flex items-start gap-2 w-full close-checkbox close-order-m-b close-order-div-color">
                      <Checkbox
                        onClick={() => {
                          setAddIsSchedule(false);
                          setIsSchedule(true);
                        }}
                        value={items?.id}
                        checked={isSchedule} // Set checked state based on the value of isSchedule
                        disabled={
                          _.some(data?.data?.dispatch_schedule, {
                            item: items?.id,
                          }) || isSelectAllChecked
                        }
                        className={
                          _.includes(objectNames, items.id)
                            ? "selected-checkbox close-margin-top "
                            : "close-margin-top "
                        }
                      />
                      <div className="flex flex-col w-full">
                        <Row className={"w-full close-order-div-color-2 "}>
                          <Col span={12} className="create-quotation-border">
                            {/* {items?.item_information} */}
                            <div className="wearlay-paragraph">
                              <Tooltip title={items?.item_information}>
                                {items?.item_information?.length > 15
                                  ? items?.item_information
                                      ?.slice(0, 15)
                                      .concat("...")
                                  : items?.item_information}
                              </Tooltip>
                            </div>
                          </Col>
                          <Col span={6} className="create-quotation-border">
                            {/* {items?.brand} */}
                            <div className="wearlay-paragraph">
                              <Tooltip title={items?.brand}>
                                {items?.brand?.length > 5
                                  ? items?.brand?.slice(0, 5).concat("...")
                                  : items?.brand}
                              </Tooltip>
                            </div>
                          </Col>
                          <Col span={3} className="create-quotation-border">
                            {items?.qty}
                          </Col>
                          <Col span={3} className="create-quotation-border">
                            {items?.total}
                          </Col>
                        </Row>
                        {/* {isSchedule &&
                          !_.some(data?.data?.dispatch_schedule, {
                            item: items?.id,
                          }) &&
                          _.includes(isSelectedItem, items?.id) && ( // Render the input box only when isSchedule is true and the checkbox item matches the selected item
                            <Form layout="vertical">
                              <Row>
                                <Form.Item
                                  label="Dispatch Date"
                                  required
                                  name="dispatch_date"
                                  hasFeedback
                                  validateStatus={
                                    formik2.errors.dispatch_date &&
                                    formik2.touched.dispatch_date
                                      ? "error"
                                      : undefined
                                  }
                                  help={
                                    formik2.errors.dispatch_date &&
                                    formik2.touched.dispatch_date &&
                                    formik2.errors.dispatch_date
                                  }
                                >
                                  <DatePicker
                                    placeholder="Select date"
                                    onBlur={formik2.handleBlur}
                                    onChange={(value: any) => {
                                      const formattedDate = value
                                        ? value.format("YYYY-MM-DD")
                                        : "";
                                      formik2.setFieldValue(
                                        "dispatch_date",
                                        formattedDate
                                      );

                                      if (
                                        _.includes(isSelectedItem, items?.id)
                                      ) {
                                        const selectedItemIndex = _.difference(
                                          isSelectedItem,
                                          objectNames
                                        ).indexOf(items?.id);
                                        const newDispatchDates = [
                                          ...dispatchDates,
                                        ];
                                        newDispatchDates[selectedItemIndex] =
                                          formattedDate;
                                        setDispatchDates(newDispatchDates);
                                      }
                                    }}
                                    value={dayjs(formik2.values.dispatch_date)}
                                    format="DD-MM-YYYY"
                                    disabledDate={disabledDate}
                                  />
                                </Form.Item>
                              </Row>
                            </Form>
                          )} */}
                        {data?.data?.dispatch_schedule?.map(
                          (item: any) =>
                            _.includes(item, items?.id) && (
                              <div className="create-quotation-border close-order-div-color close-margin-top">
                                <div className="create-quotation-title-1">
                                  Dispatch Date
                                </div>
                                <div className="create-quotations-title-2">
                                  {moment(item?.dispatch_date).format(
                                    "DD-MM-YYYY"
                                  )}
                                </div>
                              </div>
                            )
                        )}
                      </div>
                    </div>
                  ))}
                </Checkbox.Group>
              </div>
            )}
          </div>
          <div className=" create-quotation-border items-center cancel-order-border-radius mt-24p ">
            <div className="flex justify-between">
              <div className="cancel-order-detail-title ">Delivery Details</div>
              {isAddDetails &&
                quotationByIdData?.data?.items?.length !==
                  data?.data?.delivery_details?.length &&
                _.some(data?.data?.dispatch_schedule, (element) => ({
                  item: element.filteredArray,
                })) && (
                  <Button
                    onClick={() => {
                      setIsDetail(true);
                      // setIsSaveDetail(false);
                      setAddIsDetail(false);
                      setAddIsAssignDetailsSchedule(true);
                    }}
                    className="close-add-button "
                  >
                    Add Date
                  </Button>
                )}
              {isAssignDetailsSchedule && (
                <Button
                  onClick={() => {
                    setAddIsDetail(false);

                    setIsDetail(true);
                    // setAssignDetailButton(true);
                    setAssignDetailButton(
                      isSelectAllDetailsChecked ||
                        _.difference(isSelectedDetailItem, itemValues)
                          ?.length !== 0
                        ? true
                        : false
                    );
                    setAddIsAssignDetailsSchedule(
                      isSelectAllDetailsChecked ||
                        _.difference(isSelectedDetailItem, itemValues)
                          ?.length !== 0
                        ? false
                        : true
                    );
                    if (
                      isSelectAllDetailsChecked ||
                      _.difference(isSelectedDetailItem, itemValues)?.length !==
                        0
                        ? false
                        : true
                    ) {
                      message.error("Please select item from delivery details");
                    }
                  }}
                  className="close-add-button "
                >
                  Assign Date
                </Button>
              )}
              {/* {isDetails && ( */}
              {isAssignDetailButton &&
                quotationByIdData?.data?.items !==
                  data?.data?.delivery_details && (
                  <div className="flex gap-2">
                    <Button
                      onClick={() => {
                        setAssignDetailButton(false);
                        setAddIsAssignDetailsSchedule(true);

                        // setAddIsDetail(true);
                        // setIsFeedback(false);
                        // setIsSchedule(false);
                        // setIsSaveSheduleDetail(true);
                      }}
                      className="close-add-button "
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        // setIsSaveDetail(true);
                        // setIsDetail(false);
                        // setAddIsFeedback(true);
                        formik.handleSubmit();
                      }}
                      disabled={!formik.isValid || uploadLoading}
                      className="close-save-button "
                      loading={updateloader}
                    >
                      Save
                    </Button>
                  </div>
                )}
              {/* )} */}
            </div>
            <Divider />
            {((isDetails &&
              quotationByIdData?.data?.items?.length !==
                data?.data?.delivery_details?.length) ||
              (!isDetails &&
                quotationByIdData?.data?.items?.length ===
                  data?.data?.delivery_details?.length)) && (
              <div>
                {isAssignDetailButton && (
                  <div>
                    <Form layout="vertical" className="close-margin-top">
                      <Row gutter={16}>
                        <Col span={24} md={{ span: 12 }}>
                          <Form.Item
                            label="Delivered Date"
                            required
                            name="delivered_date"
                            hasFeedback
                            validateStatus={
                              formik.errors.delivered_date &&
                              formik.touched.delivered_date
                                ? "error"
                                : undefined
                            }
                            help={
                              formik.errors.delivered_date &&
                              formik.touched.delivered_date &&
                              formik.errors.delivered_date
                            }
                          >
                            <DatePicker
                              className="w-full"
                              placeholder="Select date"
                              onBlur={formik.handleBlur}
                              // onChange={(value: any) => {
                              //   const formattedDate = value
                              //     ? value.format("YYYY-MM-DD")
                              //     : "";
                              //   formik.setFieldValue(
                              //     "delivered_date",
                              //     formattedDate
                              //   );

                              //   if (
                              //     commonDetailsId &&
                              //     commonDetailsId.includes(items?.id)
                              //   ) {
                              //     const selectedItemIndex = commonDetailsId.indexOf(
                              //       items?.id
                              //     );
                              //     const newDispatchDates = [...dispatchDates];
                              //     newDispatchDates[selectedItemIndex] =
                              //       formattedDate;
                              //     setDispatchDates(newDispatchDates);
                              //   }
                              // }}
                              onChange={(value: any) => {
                                const formattedDate = value
                                  ? value.format("YYYY-MM-DD")
                                  : "";
                                formik.setFieldValue(
                                  "delivered_date",
                                  formattedDate
                                );

                                const selectedItems = isSelectAllDetailsChecked
                                  ? objectNamesForAllDetailsSelect
                                  : _.difference(
                                      isSelectedDetailItem,
                                      itemValues
                                    );

                                const newDispatchDates = selectedItems.map(
                                  () => formattedDate
                                );
                                setDispatchDates(newDispatchDates);
                              }}
                              value={dayjs(formik.values.delivered_date)}
                              format="DD-MM-YYYY"
                              // disabledDate={disabledDate}
                            />
                          </Form.Item>
                          <Form.Item
                            label="Invoice"
                            required
                            name="invoice_no"
                            hasFeedback
                            validateStatus={
                              formik.errors.invoice_no &&
                              formik.touched.invoice_no
                                ? "error"
                                : undefined
                            }
                            help={
                              formik.errors.invoice_no &&
                              formik.touched.invoice_no &&
                              formik.errors.invoice_no
                            }
                          >
                            <Input
                              name="invoice_no"
                              placeholder="Invoice Number"
                              onBlur={formik.handleBlur}
                              // onChange={(event) => {
                              //   const { value } = event.target;
                              //   formik.handleChange(event);

                              //   if (
                              //     commonDetailsId &&
                              //     commonDetailsId.includes(items?.id)
                              //   ) {
                              //     const selectedItemIndex = commonDetailsId.indexOf(
                              //       items?.id
                              //     );
                              //     const newDispatchDates = [...invoice];
                              //     newDispatchDates[selectedItemIndex] = value;
                              //     setInvoice(newDispatchDates);
                              //   }
                              // }}
                              onChange={(event: any) => {
                                const { value } = event.target;
                                formik.handleChange(event);

                                const selectedItems = isSelectAllDetailsChecked
                                  ? objectNamesForAllDetailsSelect
                                  : _.difference(
                                      isSelectedDetailItem,
                                      itemValues
                                    );

                                const newDispatchDates = selectedItems.map(
                                  () => value
                                );
                                setInvoice(newDispatchDates);
                              }}
                              value={formik.values.invoice_no}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24} md={{ span: 12 }}>
                          <Form.Item
                            label="Status"
                            required
                            name="status"
                            hasFeedback
                            validateStatus={
                              formik.errors.status && formik.touched.status
                                ? "error"
                                : ""
                            }
                            help={
                              formik.errors.status && formik.touched.status
                                ? formik.errors.status
                                : undefined
                            }
                          >
                            <Select
                              placeholder="Status"
                              value={formik.values.status}
                              // onChange={(value) => {
                              //   formik.setFieldValue("status", value);

                              //   if (
                              //     commonDetailsId &&
                              //     commonDetailsId.includes(items?.id)
                              //   ) {
                              //     const selectedItemIndex = commonDetailsId.indexOf(
                              //       items?.id
                              //     );
                              //     const newDispatchStatuses = [...status];
                              //     newDispatchStatuses[selectedItemIndex] = value;
                              //     setStatus(newDispatchStatuses);
                              //   }
                              // }}
                              onChange={(value) => {
                                formik.setFieldValue("status", value);

                                const selectedItems = isSelectAllDetailsChecked
                                  ? objectNamesForAllDetailsSelect
                                  : _.difference(
                                      isSelectedDetailItem,
                                      itemValues
                                    );

                                const newDispatchDates = selectedItems.map(
                                  () => value
                                );
                                setStatus(newDispatchDates);
                              }}
                              onBlur={formik.handleBlur}
                            >
                              {statusData?.map((resp: any) => (
                                <Select.Option key={resp.id} value={resp.id}>
                                  {resp?.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            label="Dispatch Mode"
                            required
                            name="dispatch_mode"
                            hasFeedback
                            validateStatus={
                              formik.errors.dispatch_mode &&
                              formik.touched.dispatch_mode
                                ? "error"
                                : ""
                            }
                            help={
                              formik.errors.dispatch_mode &&
                              formik.touched.dispatch_mode
                                ? formik.errors.dispatch_mode
                                : undefined
                            }
                          >
                            {/* <Select
                              placeholder="Dispatch Mode"
                              value={formik.values.dispatch_mode}
                              // onChange={(value) => {
                              //   formik.setFieldValue("dispatch_mode", value);

                              //   if (
                              //     commonDetailsId &&
                              //     commonDetailsId.includes(items?.id)
                              //   ) {
                              //     const selectedItemIndex = commonDetailsId.indexOf(
                              //       items?.id
                              //     );
                              //     const newDispatchStatuses = [...dispatch_mode];
                              //     newDispatchStatuses[selectedItemIndex] = value;
                              //     setDispathMode(newDispatchStatuses);
                              //   }
                              // }}
                              onChange={(value) => {
                                formik.setFieldValue("dispatch_mode", value);

                                const selectedItems = isSelectAllDetailsChecked
                                  ? objectNamesForAllDetailsSelect
                                  : _.difference(
                                      isSelectedDetailItem,
                                      itemValues
                                    );

                                const newDispatchDates = selectedItems.map(
                                  () => value
                                );
                                setDispathMode(newDispatchDates);
                              }}
                              onBlur={formik.handleBlur}
                            >
                              {dispatchData?.map((resp: any) => (
                                <Select.Option key={resp.id} value={resp.id}>
                                  {resp?.name}
                                </Select.Option>
                              ))}
                            </Select> */}
                            <CreatableSelect
                              isClearable
                              name="dispatch_mode"
                              className="create-table-height"
                              options={optionsDispatchMode as any}
                              onCreateOption={(option) => {
                                handleDispatchMode(option);
                              }}
                              onChange={(option) => {
                                handleChangeDispatchMode(option);
                                const selectedItems = isSelectAllDetailsChecked
                                  ? objectNamesForAllDetailsSelect
                                  : _.difference(
                                      isSelectedDetailItem,
                                      itemValues
                                    );

                                const newDispatchDates = selectedItems.map(
                                  () => option
                                );
                                setDispathMode(newDispatchDates);
                              }}
                              value={formik.values.dispatch_mode}
                              placeholder="Dispatch Mode"
                              inputValue={inputValue}
                              onInputChange={handleInputChange}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Form.Item
                          label="Note"
                          required
                          className="w-full"
                          name="note"
                          hasFeedback
                          validateStatus={
                            formik.errors.note && formik.touched.note
                              ? "error"
                              : ""
                          }
                          help={
                            formik.errors.note && formik.touched.note
                              ? formik.errors.note
                              : undefined
                          }
                        >
                          <TextArea
                            placeholder="Note"
                            value={formik.values.note}
                            // onChange={(event) => {
                            //   const { value } = event.target;
                            //   formik.handleChange(event);

                            //   if (
                            //     commonDetailsId &&
                            //     commonDetailsId.includes(items?.id)
                            //   ) {
                            //     const selectedItemIndex = commonDetailsId.indexOf(
                            //       items?.id
                            //     );
                            //     const newDispatchDates = [...note];
                            //     newDispatchDates[selectedItemIndex] = value;
                            //     setNote(newDispatchDates);
                            //   }
                            // }}
                            onChange={(event: any) => {
                              const { value } = event.target;
                              formik.handleChange(event);

                              const selectedItems = isSelectAllDetailsChecked
                                ? objectNamesForAllDetailsSelect
                                : _.difference(
                                    isSelectedDetailItem,
                                    itemValues
                                  );

                              const newDispatchDates = selectedItems.map(
                                () => value
                              );
                              setNote(newDispatchDates);
                            }}
                            onBlur={formik.handleBlur}
                          />
                        </Form.Item>
                      </Row>
                      {!image[0]?.file_name && !uploadLoading && (
                        <Form.Item>
                          <Upload
                            beforeUpload={() => false}
                            maxCount={0}
                            showUploadList={false}
                            onChange={(value) => {
                              handleUpload(value);
                              // handleItems(items?.id);
                              setItems(items?.id);
                              // setImage({
                              //   file_url: uploadData?.data?.file_url,
                              //   file_name: uploadData?.data?.file_name,
                              //   file_size: uploadData?.data?.file_size,
                              //   file_uploaded_date: uploadData?.data?.file_uploaded_date,
                              // });
                              // if (
                              //   commonItems &&
                              //   commonItems.includes(items?.id)
                              // ) {
                              //   const selectedItemIndex = commonItems.indexOf(
                              //     items?.id
                              //   );
                              //   const newDispatchDates = [...image];
                              //   newDispatchDates[selectedItemIndex] =
                              //     uploadItems;
                              //   setImage(newDispatchDates);
                              //   console.log(
                              //     "this is the array of files",
                              //     newDispatchDates
                              //   );
                              // }
                            }}
                            accept=".pdf"
                          >
                            <Button className="flex items-center gap-2">
                              <span className="attach-icon">
                                {" "}
                                <img src="/assets/attach.svg"></img>
                              </span>
                              Attach File
                            </Button>
                          </Upload>
                        </Form.Item>
                      )}
                    </Form>
                    {uploadLoading ? (
                      <div>
                        <span>Uploading...</span>
                        <Skeleton paragraph={{ rows: 0, width: "100%" }} />
                      </div>
                    ) : (
                      image[0]?.file_name && (
                        <div>
                          <div className="flex justify-between cancel-pdf items-center mt-24">
                            <div className="flex gap-2">
                              <img src="/assets/pdf.svg"></img>
                              <div className="flex flex-col">
                                <div>
                                  <Tooltip title={uploadData?.data?.file_name}>
                                    {concatTextFunction(
                                      uploadData?.data?.file_name
                                    )}
                                  </Tooltip>
                                </div>
                                <div>
                                  {uploadData?.data?.file_size} .{" "}
                                  {uploadData?.data?.upload_date}{" "}
                                </div>
                              </div>
                            </div>

                            <div>
                              <img
                                src="/assets/delete.svg"
                                className="file-delete "
                                onClick={() => {
                                  setImage((prevState: any) => {
                                    const updatedImage = [...prevState];
                                    updatedImage[0] = {
                                      file_url: "",
                                      file_name: "",
                                      file_size: "",
                                      file_uploaded_date: "",
                                    };
                                    return updatedImage;
                                  });
                                  hanleDelete(uploadData?.data?.file_name);
                                }}
                              ></img>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                )}
                {quotationByIdData?.data?.items?.length !==
                  data?.data?.delivery_details?.length && (
                  <Checkbox
                    onClick={(e: any) => {
                      setIsSelectAllDetailsChecked(e.target.checked);
                    }}
                    disabled={
                      // !_.some(data?.data?.dispatch_schedule, {
                      //   item: items?.id,
                      // }) && data?.data?.delivery_details
                      quotationByIdData?.data?.items?.length !==
                      data?.data?.dispatch_schedule?.length
                    }
                    className="select-all"
                  >
                    <span>Select All</span>
                  </Checkbox>
                )}
                <Checkbox.Group
                  onChange={handleChange2}
                  // value={
                  //   isSelectedDetailItem ||
                  //   itemValues ||
                  //   objectNamesForAllDetailsSelect
                  // }

                  value={
                    isSelectAllDetailsChecked
                      ? objectNamesForAllDetailsSelect
                      : isSelectedDetailItem
                      ? isSelectedDetailItem
                      : itemValues
                  }
                >
                  {quotationByIdData?.data?.items?.map(
                    (items: any, index: number) => (
                      <div className="flex items-start gap-2 w-full close-checkbox close-order-m-b close-order-div-color">
                        {/* <Tooltip title="Please select corresponding item from the dispatch schedule"> */}
                        <Checkbox
                          onClick={() => {
                            setIsDetail(true);
                            setIsSaveDetail(false);
                            setAddIsDetail(false);
                          }}
                          // checked={_.includes(isSelectedItem, items?.id)}
                          // disabled={!_.includes(isSelectedItem, items?.id)}
                          disabled={
                            !_.some(data?.data?.dispatch_schedule, {
                              item: items?.id,
                            }) ||
                            _.some(data?.data?.delivery_details, {
                              item: items?.id,
                            }) ||
                            isSelectAllDetailsChecked
                          }
                          value={items?.id}
                          className={
                            _.includes(itemValues, items.id)
                              ? "selected-checkbox close-margin-top "
                              : "close-margin-top "
                          }
                        />
                        {/* </Tooltip> */}

                        <div
                          className={
                            !_.some(data?.data?.dispatch_schedule, {
                              item: items?.id,
                            })
                              ? "w-full close-order-div-color-3 "
                              : "w-full close-order-div-color-2 "
                          }
                        >
                          <Row className="w-full close-order-div-color-2 ">
                            <Col span={12} className="create-quotation-border ">
                              <Tooltip title={items?.item_information}>
                                {items?.item_information?.length > 15
                                  ? items?.item_information
                                      ?.slice(0, 15)
                                      .concat("...")
                                  : items?.item_information}
                              </Tooltip>
                            </Col>
                            <Col span={6} className="create-quotation-border ">
                              <Tooltip title={items?.brand}>
                                {items?.brand?.length > 5
                                  ? items?.brand?.slice(0, 5).concat("...")
                                  : items?.brand}
                              </Tooltip>
                            </Col>
                            <Col span={3} className="create-quotation-border ">
                              {items?.qty}
                            </Col>
                            <Col span={3} className="create-quotation-border ">
                              {items?.total}
                            </Col>
                          </Row>
                          {/* {
                            // checkIndexForValue(
                            //   data?.data?.delivery_details,
                            //   index,
                            //   null
                            // ) ||
                            !_.some(data?.data?.delivery_details, {
                              item: items?.id,
                            }) &&
                              _.includes(isSelectedDetailItem, items?.id) && (
                                <Form
                                  layout="vertical"
                                  className="close-margin-top"
                                >
                                  <Row gutter={16}>
                                    <Col span={24} md={{ span: 12 }}>
                                      <Form.Item
                                        label="Delivered Date"
                                        required
                                        name="delivered_date"
                                        hasFeedback
                                        validateStatus={
                                          formik.errors.delivered_date &&
                                          formik.touched.delivered_date
                                            ? "error"
                                            : undefined
                                        }
                                        help={
                                          formik.errors.delivered_date &&
                                          formik.touched.delivered_date &&
                                          formik.errors.delivered_date
                                        }
                                      >
                                        <DatePicker
                                          className="w-full"
                                          placeholder="Select date"
                                          onBlur={formik.handleBlur}
                                          onChange={(value: any) => {
                                            const formattedDate = value
                                              ? value.format("YYYY-MM-DD")
                                              : "";
                                            formik.setFieldValue(
                                              "delivered_date",
                                              formattedDate
                                            );

                                            if (
                                              commonDetailsId &&
                                              commonDetailsId.includes(
                                                items?.id
                                              )
                                            ) {
                                              const selectedItemIndex =
                                                commonDetailsId.indexOf(
                                                  items?.id
                                                );
                                              const newDispatchDates = [
                                                ...dispatchDates,
                                              ];
                                              newDispatchDates[
                                                selectedItemIndex
                                              ] = formattedDate;
                                              setDispatchDates(
                                                newDispatchDates
                                              );
                                            }
                                          }}
                                          value={dayjs(
                                            formik.values.delivered_date
                                          )}
                                          format="DD-MM-YYYY"
                                          disabledDate={disabledDate}
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        label="Invoice"
                                        required
                                        name="invoice_no"
                                        hasFeedback
                                        validateStatus={
                                          formik.errors.invoice_no &&
                                          formik.touched.invoice_no
                                            ? "error"
                                            : undefined
                                        }
                                        help={
                                          formik.errors.invoice_no &&
                                          formik.touched.invoice_no &&
                                          formik.errors.invoice_no
                                        }
                                      >
                                        <Input
                                          name="invoice_no"
                                          placeholder="Invoice Number"
                                          onBlur={formik.handleBlur}
                                          onChange={(event) => {
                                            const { value } = event.target;
                                            formik.handleChange(event);

                                            if (
                                              commonDetailsId &&
                                              commonDetailsId.includes(
                                                items?.id
                                              )
                                            ) {
                                              const selectedItemIndex =
                                                commonDetailsId.indexOf(
                                                  items?.id
                                                );
                                              const newDispatchDates = [
                                                ...invoice,
                                              ];
                                              newDispatchDates[
                                                selectedItemIndex
                                              ] = value;
                                              setInvoice(newDispatchDates);
                                            }
                                          }}
                                          value={formik.values.invoice_no}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col span={24} md={{ span: 12 }}>
                                      <Form.Item
                                        label="Status"
                                        required
                                        name="status"
                                        hasFeedback
                                        validateStatus={
                                          formik.errors.status &&
                                          formik.touched.status
                                            ? "error"
                                            : ""
                                        }
                                        help={
                                          formik.errors.status &&
                                          formik.touched.status
                                            ? formik.errors.status
                                            : undefined
                                        }
                                      >
                                        <Select
                                          placeholder="Status"
                                          value={formik.values.status}
                                          onChange={(value) => {
                                            formik.setFieldValue(
                                              "status",
                                              value
                                            );

                                            if (
                                              commonDetailsId &&
                                              commonDetailsId.includes(
                                                items?.id
                                              )
                                            ) {
                                              const selectedItemIndex =
                                                commonDetailsId.indexOf(
                                                  items?.id
                                                );
                                              const newDispatchStatuses = [
                                                ...status,
                                              ];
                                              newDispatchStatuses[
                                                selectedItemIndex
                                              ] = value;
                                              setStatus(newDispatchStatuses);
                                            }
                                          }}
                                          onBlur={formik.handleBlur}
                                        >
                                          {statusData?.map((resp: any) => (
                                            <Select.Option
                                              key={resp.id}
                                              value={resp.id}
                                            >
                                              {resp?.name}
                                            </Select.Option>
                                          ))}
                                        </Select>
                                      </Form.Item>
                                      <Form.Item
                                        label="Dispatch Mode"
                                        required
                                        name="dispatch_mode"
                                        hasFeedback
                                        validateStatus={
                                          formik.errors.dispatch_mode &&
                                          formik.touched.dispatch_mode
                                            ? "error"
                                            : ""
                                        }
                                        help={
                                          formik.errors.dispatch_mode &&
                                          formik.touched.dispatch_mode
                                            ? formik.errors.dispatch_mode
                                            : undefined
                                        }
                                      >
                                        <Select
                                          placeholder="Dispatch Mode"
                                          value={formik.values.dispatch_mode}
                                          onChange={(value) => {
                                            formik.setFieldValue(
                                              "dispatch_mode",
                                              value
                                            );

                                            if (
                                              commonDetailsId &&
                                              commonDetailsId.includes(
                                                items?.id
                                              )
                                            ) {
                                              const selectedItemIndex =
                                                commonDetailsId.indexOf(
                                                  items?.id
                                                );
                                              const newDispatchStatuses = [
                                                ...dispatch_mode,
                                              ];
                                              newDispatchStatuses[
                                                selectedItemIndex
                                              ] = value;
                                              setDispathMode(
                                                newDispatchStatuses
                                              );
                                            }
                                          }}
                                          onBlur={formik.handleBlur}
                                        >
                                          {dispatchData?.map((resp: any) => (
                                            <Select.Option
                                              key={resp.id}
                                              value={resp.id}
                                            >
                                              {resp?.name}
                                            </Select.Option>
                                          ))}
                                        </Select>
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Form.Item
                                      label="Note"
                                      required
                                      className="w-full"
                                      name="note"
                                      hasFeedback
                                      validateStatus={
                                        formik.errors.note &&
                                        formik.touched.note
                                          ? "error"
                                          : ""
                                      }
                                      help={
                                        formik.errors.note &&
                                        formik.touched.note
                                          ? formik.errors.note
                                          : undefined
                                      }
                                    >
                                      <TextArea
                                        placeholder="Note"
                                        value={formik.values.note}
                                        onChange={(event) => {
                                          const { value } = event.target;
                                          formik.handleChange(event);

                                          if (
                                            commonDetailsId &&
                                            commonDetailsId.includes(items?.id)
                                          ) {
                                            const selectedItemIndex =
                                              commonDetailsId.indexOf(
                                                items?.id
                                              );
                                            const newDispatchDates = [...note];
                                            newDispatchDates[
                                              selectedItemIndex
                                            ] = value;
                                            setNote(newDispatchDates);
                                          }
                                        }}
                                        onBlur={formik.handleBlur}
                                      />
                                    </Form.Item>
                                  </Row>
                                  {(!uploadData?.data ||
                                    _.some(
                                      commonDetailsId,
                                      (element, index) =>
                                        !image[index]?.file_name &&
                                        element === items?.id
                                    ) ||
                                    !_.some(isSelectedData?.delivery_details, {
                                      item: items?.id,
                                    })) && (
                                    <Form.Item>
                                      <Upload
                                        beforeUpload={() => false}
                                        maxCount={0}
                                        showUploadList={false}
                                        onChange={(value) => {
                                          handleUpload(value);
                                          handleItems(items?.id);
                                          setItems(items?.id);
                                          // setImage({
                                          //   file_url: uploadData?.data?.file_url,
                                          //   file_name: uploadData?.data?.file_name,
                                          //   file_size: uploadData?.data?.file_size,
                                          //   file_uploaded_date: uploadData?.data?.file_uploaded_date,
                                          // });
                                          // if (
                                          //   commonItems &&
                                          //   commonItems.includes(items?.id)
                                          // ) {
                                          //   const selectedItemIndex = commonItems.indexOf(
                                          //     items?.id
                                          //   );
                                          //   const newDispatchDates = [...image];
                                          //   newDispatchDates[selectedItemIndex] =
                                          //     uploadItems;
                                          //   setImage(newDispatchDates);
                                          //   console.log(
                                          //     "this is the array of files",
                                          //     newDispatchDates
                                          //   );
                                          // }
                                        }}
                                        accept=".pdf"
                                      >
                                        <Button className="flex items-center gap-2">
                                          <span className="attach-icon">
                                            {" "}
                                            <img src="/assets/attach.svg"></img>
                                          </span>
                                          Attach File
                                        </Button>
                                      </Upload>
                                    </Form.Item>
                                  )}
                                </Form>
                              )
                          } */}
                          {/* {uploadLoading &&
                          _.includes(
                            _.map(isSelectedData?.delivery_details, "item"),
                            items?.id
                          ) &&
                          image[index] ? (
                            // <Skeleton paragraph={{ rows: 0, width: "100%" }} />
                            <div></div>
                          ) : (
                            _.includes(
                              isSelectedDetailItem || itemValues,
                              items?.id
                            ) &&
                            isSelectedData?.delivery_details?.map(
                              (item: any, index: number) =>
                                _.includes(item, items?.id) &&
                                image[index]?.file_name && (
                                  <div>
                                    <div className="flex justify-between cancel-pdf items-center mt-24">
                                      <div className="flex gap-2">
                                        <img src="/assets/pdf.svg"></img>
                                        <div className="flex flex-col">
                                          <div>
                                            <Tooltip
                                              title={image[index]?.file_name}
                                            >
                                              {concatTextFunction(
                                                image[index]?.file_name
                                              )}
                                            </Tooltip>
                                          </div>
                                          <div>
                                            {image[index]?.file_size} .{" "}
                                            {image[index]?.upload_date}{" "}
                                          </div>
                                        </div>
                                      </div>

                                      <div>
                                        <img
                                          src="/assets/delete.svg"
                                          className="file-delete "
                                          onClick={() => {
                                            setImage((prevState: any) => {
                                              const updatedImage = [
                                                ...prevState,
                                              ];
                                              updatedImage[index] = {
                                                file_url: "",
                                                file_name: "",
                                                file_size: "",
                                                file_uploaded_date: "",
                                              };
                                              return updatedImage;
                                            });
                                            hanleDelete(
                                              image[index]?.file_name
                                            );
                                          }}
                                        ></img>
                                      </div>
                                    </div>
                                  </div>
                                )
                            )
                          )} */}
                          {_.includes(
                            isSelectedItem || itemValues,
                            items?.id
                          ) &&
                            data?.data?.delivery_details?.map(
                              (item: any) =>
                                !checkIndexForValue(
                                  data?.data?.delivery_details,
                                  index,
                                  null
                                ) &&
                                _.includes(item, items?.id) && (
                                  <div className="close-order-div-color ">
                                    <div className="create-quotation-border-no-padding close-order-div-color close-margin-top">
                                      <Row>
                                        <Col
                                          span={12}
                                          className="create-quotation-border-no-padding"
                                        >
                                          <div className="create-quotation-title-1 p-1">
                                            Delivery Date
                                          </div>
                                          <div className="create-quotations-title-2 p-1">
                                            {item?.delivered_date
                                              ? moment(
                                                  item?.delivered_date
                                                ).format("DD-MM-YYYY")
                                              : "-"}
                                          </div>
                                        </Col>
                                        <Col
                                          span={12}
                                          className="create-quotation-border-no-padding"
                                        >
                                          <div className="create-quotation-title-1 p-1">
                                            Status
                                          </div>
                                          <div className="create-quotations-title-2 p-1">
                                            {item?.status_name}
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col
                                          span={12}
                                          className="create-quotation-border-no-padding"
                                        >
                                          <div className="create-quotation-title-1 p-1">
                                            Invoice
                                          </div>
                                          <div className="create-quotations-title-2 p-1">
                                            {item?.invoice_no}
                                          </div>
                                        </Col>
                                        <Col
                                          span={12}
                                          className="create-quotation-border-no-padding"
                                        >
                                          <div className="create-quotation-title-1 p-1">
                                            Dispatch Mode
                                          </div>
                                          <div className="create-quotations-title-2 p-1">
                                            {item?.dispatch_mode_name}
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row className="flex flex-col">
                                        <div className="create-quotation-title-1 p-1">
                                          Note
                                        </div>
                                        <div className="p-1">{item?.note}</div>
                                      </Row>
                                    </div>
                                    {/* <div> */}
                                    {item?.file_name && (
                                      <div className="flex justify-between cancel-pdf items-center mt-24">
                                        <div className="flex gap-2">
                                          <img src="/assets/pdf.svg"></img>
                                          <div className="flex flex-col">
                                            <div>
                                              <Tooltip title={item?.file_name}>
                                                {item?.file_name?.length > 25
                                                  ? item?.file_name
                                                      .slice(0, 25)
                                                      .concat("...")
                                                  : item?.file_name}
                                              </Tooltip>
                                            </div>
                                            <div>
                                              {item.file_size} .{" "}
                                              {item?.upload_date}{" "}
                                            </div>
                                          </div>
                                        </div>
                                        <a href={item?.file_url}>
                                          <img src="/assets/download.svg"></img>
                                        </a>
                                      </div>
                                    )}
                                    {/* </div> */}
                                  </div>
                                )
                            )}
                        </div>
                      </div>
                    )
                  )}
                </Checkbox.Group>
              </div>
            )}
          </div>
          <div className=" create-quotation-border items-center cancel-order-border-radius  mt-24p">
            <div className="flex justify-between">
              <div className="cancel-order-detail-title ">Client Feedback</div>
              {isAddFeedback &&
                !data?.data?.client_feedback &&
                data?.data?.order_status === 2 && (
                  <Button
                    onClick={() => {
                      setAddIsFeedback(false);
                      setIsFeedback(true);
                    }}
                    className="close-add-button "
                  >
                    Add Note
                  </Button>
                )}
              {isFeedback && !data?.data?.client_feedback && (
                <Button
                  className="close-save-button "
                  onClick={() => {
                    // setIsFeedback(false);
                    // setSaveIsFeedbackDetail(true);
                    formik3.handleSubmit();
                  }}
                  loading={updateloader}
                >
                  Save
                </Button>
              )}
            </div>
            <Divider />

            {isFeedback && !data?.data?.client_feedback && (
              <Form layout="vertical">
                <Row>
                  <Form.Item
                    label="Client Feedback"
                    required
                    className="w-full"
                    name="client_feedback"
                    hasFeedback
                    validateStatus={
                      formik3.errors.client_feedback &&
                      formik3.touched.client_feedback
                        ? "error"
                        : ""
                    }
                    help={
                      formik3.errors.client_feedback &&
                      formik3.touched.client_feedback
                        ? formik3.errors.client_feedback
                        : undefined
                    }
                  >
                    <TextArea
                      placeholder="Client Feedback"
                      value={formik3.values.client_feedback}
                      onChange={(e: any) => {
                        formik3.setFieldValue(
                          "client_feedback",
                          e.target.value
                        );
                        setNotes(e.target.value);
                      }}
                      onBlur={formik3.handleBlur}
                    />
                  </Form.Item>
                </Row>
              </Form>
            )}
            {data?.data?.client_feedback && (
              <Row className="flex flex-col">
                <div className="create-quotation-title-1 p-1">
                  Client Feedback
                </div>
                <div className="p-1">{data?.data?.client_feedback}</div>
              </Row>
            )}
          </div>
          {data?.data?.order_status !== 3 && (
            <Row className="mt-24p ">
              {/* <Button className="w-full cancel-order-btn">Close order</Button> */}
              <CommonButton
                variant="wealay-primary-btn"
                value="   Close Order"
                className="w-full create-quotation-btn"
                disabled={
                  data?.data?.order_status !== 2 || notes !== "" ? true : false
                }
                onClick={() => {
                  closeOrderSubmit();
                }}
                isLoading={updateloader}
              />
            </Row>
          )}
        </div>
      ) : (
        <Empty />
      )}
    </>
  );
};
