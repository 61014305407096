import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { env } from "../config/env";
import { getUrlEndpoints } from "../helpers/helperFunction";
import { getAccessToken } from "../helpers/localStorage";
import {
  IForgotPasswordResponse,
  ILoginRequest,
  ILoginResponse,
  IResetPasswordRequest,
  IResetPasswordResponse,
} from "../interface/authInterface";
import { IContactRequest } from "../interface/contactInterface";

interface CustomError {
  data: {
    message: string;
  };
  status: string;
}
export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: env.development.API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getAccessToken();
      if (token) {
        headers.set("Authorization", `Token ${token}`);
      }
      return headers;
    },
  }) as BaseQueryFn<string | FetchArgs, unknown, CustomError, {}>,
  tagTypes: [
    "GetAllContacts",
    "GetContactsById",
    "GetUserById",
    "GetSalesById",
    "GetAllSalesLead",
    "GetLeadById",
    "GetDiscount",
    "GetQuataionList",
    "GetSalesAfterQuotation",
    "GetQuotationById",
    "GetGroup",
    "GetAllNap",
    "GetNpdById",
    "GetStatus",
    "GetShareQuotation",
    "GetSalesUnderExecutive",
    "GetAllOrders",
    "GetDeliveryStatus",
    "GetDispatchMode",
    "GetOrdersById",
    "GetAllAllocatedData",
    "GetShareOrder",
    "GetAllDashboardCount",
    "GetAllDashboardManagerCount",
    "GetdropDownList",
    "GetAllDashboardPieChartCount",
    "GetAllDashboardBarChartCount",
    "GetAllDashboardBar2ChartCount",
    "GetAllRegion",
    "GetAllDesignation",
    "GetAllnature",
    "GetAllSource",
    "GetAllCategory",
    "GetAllTypes",
    "GetAllIndustry",
    "GetNotification",
    "GetAllDashboardFunnelChartCount",
  ],
  endpoints: (builder) => ({
    // login api
    login: builder.mutation<ILoginResponse, ILoginRequest>({
      query: (credentials) => ({
        url: "/login",
        method: "POST",
        body: credentials,
      }),
    }),
    // reset-password
    resetPassword: builder.mutation<
      IResetPasswordResponse,
      IResetPasswordRequest
    >({
      query: ({ credentials, uid, token }) => ({
        url: `/reset-password?uidb64=${uid}&token=${token}`,
        method: "POST",
        body: credentials,
      }),
    }),
    // forgot-password
    forgotPassword: builder.mutation<
      IForgotPasswordResponse,
      IForgotPasswordResponse
    >({
      query: (email) => ({
        url: `/forgot-password-link`,
        method: "POST",
        body: email,
      }),
    }),
    //userbyId
    getUserDetailById: builder.query<any, any>({
      query: (id) => ({
        url: `/me/${id}/`,
        method: "GET",
      }),
      providesTags: ["GetUserById"],
    }),
    //edit profile
    editUser: builder.mutation<any, any>({
      query: ({ id, body }) => ({
        url: `/me/${id}/`,
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: ["GetUserById"],
    }),

    //create-contacts
    createContacts: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `/${id}/contacts/`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["GetAllContacts"],
    }),
    //create-contacts
    editContacts: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `/contacts/${id}/`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["GetContactsById", "GetAllContacts"],
    }),
    //get-all-contacts
    getAllContacts: builder.query<any, any>({
      query: ({ id, params, search, body }) => ({
        url: `/${id}/contacts/?q=${search}&filter_data=${encodeURIComponent(
          body
        )}`,
        method: "GET",
        params: params,
      }),
      providesTags: ["GetAllContacts"],
    }),

    //get-contacts-by-id
    getContactsById: builder.query<any, any>({
      query: (id) => ({
        url: `/contacts/${id}/`,
        method: "GET",
      }),
      providesTags: ["GetContactsById"],
    }),
    //delete-contacts-by-id
    deleteContactsById: builder.mutation<any, any>({
      query: (id) => ({
        url: `/contacts/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["GetAllContacts"],
    }),
    //get-sales-by-id
    getSalesById: builder.query<any, any>({
      query: (id) => ({
        url: `/${id}/sales_lead/`,
        method: "GET",
      }),
      providesTags: ["GetSalesById"],
    }),
    //create-lead
    createLead: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `/${id}/sales_lead/`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["GetAllSalesLead", "GetAllContacts"],
    }),

    //get-all-source
    getSources: builder.query<any, any>({
      query: () => ({
        url: `/content?model_class=source`,
        method: "GET",
      }),
      providesTags: ["GetAllSource"],
    }),
    //get-all-types
    getTypes: builder.query<any, any>({
      query: () => ({
        url: `/content?model_class=type`,
        method: "GET",
      }),
      providesTags: ["GetAllTypes"],
    }),
    //get-all-nature
    getNature: builder.query<any, any>({
      query: () => ({
        url: `/content?model_class=nature`,
        method: "GET",
      }),
      providesTags: ["GetAllnature"],
    }),
    //get-all-designation
    getDesignation: builder.query<any, any>({
      query: () => ({
        url: `/content?model_class=designation`,
        method: "GET",
      }),
      providesTags: ["GetAllDesignation"],
    }),
    //get-all-source
    getSource: builder.query<any, any>({
      query: () => ({
        url: `/content?model_class=source`,
        method: "GET",
      }),
      providesTags: ["GetAllSource"],
    }),
    //get-industry
    getIndustry: builder.query<any, any>({
      query: () => ({
        url: `/content?model_class=industry`,
        method: "GET",
      }),
      providesTags: ["GetAllIndustry"],
    }),
    //get-industry
    getRegion: builder.query<any, any>({
      query: () => ({
        url: `/content?model_class=region`,
        method: "GET",
      }),
      providesTags: ["GetAllRegion"],
    }),
    //get-group
    getGroup: builder.query<any, any>({
      query: () => ({
        url: `/content?model_class=group`,
        method: "GET",
      }),
      providesTags: ["GetGroup"],
    }),
    //get-all-sales-lead
    getAllSalesLead: builder.query<any, any>({
      query: ({ id, params, search, body }) => ({
        url: `/${id}/sales_lead/?q=${search}&filter_data=${encodeURIComponent(
          body
        )}`,
        method: "GET",
        params: params,
      }),
      providesTags: ["GetAllSalesLead"],
    }),
    //get-sales-details
    getSalesDetails: builder.query<any, any>({
      query: (id) => ({
        url: `/sales_lead/${id}/`,
        method: "GET",
      }),
      providesTags: ["GetLeadById"],
    }),
    //get-all-sales-lead
    getLeadById: builder.query<any, any>({
      query: (id) => ({
        url: `/sales_lead/${id}/`,
        method: "GET",
      }),
      providesTags: ["GetLeadById"],
    }),
    //get-downloaded-data
    getDownloadData: builder.query<any, any>({
      query: (id) => ({
        url: `contacts-download/?id=[${id}]`,
        method: "GET",
        responseHandler: async (response) => {
          // Read the response as a Blob
          const blob = await response.blob();

          // Process the Blob or store its URL or metadata in the state
          // For example, you can store the URL of the Blob in the state
          const blobUrl = URL.createObjectURL(blob);

          return blobUrl;
        },
      }),
    }),
    getSalesDownloadData: builder.query<any, any>({
      query: (id) => ({
        url: `sales-lead-download/?id=[${id}]`,
        method: "GET",
        responseHandler: async (response) => {
          const blob = await response.blob();

          const blobUrl = URL.createObjectURL(blob);

          return blobUrl;
        },
      }),
    }),
    // get-company-names
    getCompanyNames: builder.query<any, string>({
      query: (searchTerm) => ({
        url: `/sales-lead-auto-complete/`,
        method: "GET",
      }),
      providesTags: ["GetSalesById"],
    }),
    //get-enquiry-category
    getEnquiryCategory: builder.query<any, any>({
      query: () => ({
        url: `/content?model_class=enquirycategory`,
        method: "GET",
      }),
      providesTags: ["GetAllCategory"],
    }),
    //get-enquiry-status
    getEnquiryStatus: builder.query<any, any>({
      query: () => ({
        url: `/content?model_class=followupstatusstage`,
        method: "GET",
      }),
      providesTags: ["GetStatus"],
    }),
    //create-quatation
    createCreateQuotation: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `/${id}/quotation/`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["GetSalesById", "GetQuataionList", "GetAllSalesLead"],
    }),
    //get-discount
    getDiscount: builder.query<any, any>({
      query: () => ({
        url: `/discounts/`,
        method: "GET",
      }),
      providesTags: ["GetDiscount"],
    }),
    //get-all-quotation
    getQuotation: builder.query<any, any>({
      query: ({ id, params, search, status, body }) => ({
        url: `/${id}/list-quotations-created-by-leads/?q=${search}&is_closed=${status}&filter_data=${encodeURIComponent(
          body
        )}`,
        method: "GET",
        params: params,
      }),
      providesTags: ["GetQuataionList"],
    }),
    editLead: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `/sales_lead/${id}/`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: [
        "GetLeadById",
        "GetAllSalesLead",
        "GetAllAllocatedData",
      ],
    }),
    closeQuotation: builder.mutation<any, any>({
      query: (id) => ({
        url: `quotation-close/${id}/`,
        method: "PUT",
      }),
      invalidatesTags: ["GetQuataionList", "GetShareQuotation"],
    }),
    deleteLead: builder.mutation<any, any>({
      query: (id) => ({
        url: `/sales_lead/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["GetAllSalesLead"],
    }),

    //get-quotation-by-id
    getQuotationById: builder.query<any, any>({
      query: (id) => ({
        url: `/quotations/${id}/`,
        method: "GET",
      }),
      providesTags: ["GetQuotationById"],
    }),
    //get-all-sakes-after-quotation-creation
    getSalesAfterQuotation: builder.query<any, any>({
      query: (id) => ({
        url: `/sales-lead/detail-page/${id}/after-quotation-create`,
        method: "GET",
      }),
      providesTags: ["GetSalesAfterQuotation"],
    }),
    //get-all-npd-after-quotation-creation
    getSalesAfterNpdQuotation: builder.query<any, any>({
      query: (id) => ({
        url: `/npd/detail-page/${id}/after-quotation-create`,
        method: "GET",
      }),
      providesTags: ["GetSalesAfterQuotation"],
    }),
    //create-quotation
    editQuotation: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `/quotations/${id}/`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: [
        "GetQuotationById",
        "GetQuataionList",
        "GetSalesAfterQuotation",
        "GetShareQuotation",
      ],
    }),
    //get-downloaded-data
    getQuotationDownloadData: builder.query<Blob, number>({
      query: (id) => ({
        url: `quotation-download/?id=[${id}]`,
        method: "GET",
        responseHandler: async (response) => {
          // Read the response as a Blob
          const blob = await response.blob();

          // Process the Blob or store its URL or metadata in the state
          // For example, you can store the URL of the Blob in the state
          const blobUrl = URL.createObjectURL(blob);

          return blobUrl;
        },
      }),
    }),
    //create-order
    createCreateOrder: builder.mutation<any, any>({
      query: ({ body, id, qid }) => ({
        url: `/${id}/orders/${qid}/`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["GetQuataionList", "GetAllOrders"],
    }),
    // upload file
    uploadFile: builder.mutation({
      query: (body) => ({
        url: "/upload_file/",
        method: "POST",
        body: body,
      }),
    }),

    //create-npd
    createCreateNpd: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `/${id}/npd/`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["GetAllNap"],
    }),
    //get-Npd
    getNpd: builder.query<any, any>({
      query: ({ id, params, search, body }) => ({
        url: `/${id}/npd/?q=${search}&filter_data=${encodeURIComponent(body)}`,
        method: "GET",
        params: params,
      }),
      providesTags: ["GetAllNap"],
    }),
    //get-Npd-by-id
    getNpdById: builder.query<any, any>({
      query: (id) => ({
        url: `/npd/${id}/`,
        method: "GET",
      }),
      providesTags: ["GetNpdById", "GetAllNap"],
    }),
    //edt-npd
    editNpd: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `/npd/${id}/`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["GetNpdById", "GetAllNap"],
    }),
    //delete-npd

    deleteNpd: builder.mutation<any, any>({
      query: (id) => ({
        url: `/npd/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["GetAllNap"],
    }),
    //npd-download
    getNpdDownload: builder.query<any, any>({
      query: (id) => ({
        url: `npd-download/?id=[${id}]`,
        method: "GET",
        responseHandler: async (response) => {
          const blob = await response.blob();

          const blobUrl = URL.createObjectURL(blob);

          return blobUrl;
        },
      }),
    }),
    //allocated-download
    getAllocatedDownload: builder.query<any, any>({
      query: (id) => ({
        url: `/allocated-download/?id=[${id}]`,
        method: "GET",
        responseHandler: async (response) => {
          const blob = await response.blob();

          const blobUrl = URL.createObjectURL(blob);

          return blobUrl;
        },
      }),
    }),
    getExecutives: builder.query<any, any>({
      query: ({ id, search, params }) => ({
        url: `/${id}/list-of-executives-under-managers/?q=${search}`,
        method: "GET",
        params: params,
      }),
      providesTags: ["GetContactsById"],
    }),
    getSalesUnderExecutive: builder.query<any, any>({
      query: ({ id, params, search, body }) => ({
        url: `/${id}/list-of-sales-lead-customers/?q=${search}&filter_data=${encodeURIComponent(
          body
        )}`,
        method: "GET",
        params,
      }),
      providesTags: ["GetSalesUnderExecutive"],
    }),
    //get-all-sales-lead
    getCustomerById: builder.query<any, any>({
      query: (id) => ({
        url: `/sales_lead/${id}/`,
        method: "GET",
      }),
      // providesTags: ["GetSalesUnderExecutive"],
    }),
    getManagersList: builder.query<any, any>({
      query: ({ user_id, id }) => ({
        url: `/${user_id}/allocation-executive-dropdown-list/${id}/`,
        method: "GET",
      }),
      providesTags: ["GetdropDownList"],
    }),
    allocateManager: builder.mutation<any, any>({
      query: ({ body, managerId, salesId }) => ({
        url: `/allocate-lead-by-manager/${salesId}/?allocate_to=${managerId}`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: [
        "GetNpdById",
        "GetAllNap",
        "GetSalesUnderExecutive",
        "GetdropDownList",
      ],
    }),
    //create-quatation
    createNpdQuotation: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `/${id}/npd-quotation/`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["GetSalesById"],
    }),
    //quotation-share

    getQuotationShare: builder.query<any, any>({
      query: ({ id, user_id }) => ({
        url: `/${id}/share-quotation-details/${user_id}/`,
        method: "GET",
      }),
      providesTags: ["GetShareQuotation"],
    }),
    //get-all-orders

    getAllOrders: builder.query<any, any>({
      query: ({ id, order_status, search, params, body }) => ({
        url: `/${id}/list-orders-created-by-leads/?order_status=${order_status}&q=${search}&filter_data=${encodeURIComponent(
          body
        )}`,
        method: "GET",
        params: params,
      }),
      providesTags: ["GetAllOrders"],
    }),
    //pass-quotation-id-to-get-npd-or-saleslead

    getQuotationOrNpd: builder.query<any, any>({
      query: (id) => ({
        url: `/pass-quotation-id-to-get-npd-or-saleslead/${id}/`,
        method: "GET",
      }),
    }),
    //order-by-id

    getOrderById: builder.query<any, any>({
      query: (id) => ({
        url: `/orders/${id}/`,
        method: "GET",
      }),
      providesTags: ["GetOrdersById"],
    }),

    //update-order

    updateOrder: builder.mutation<any, any>({
      query: ({ id, body }) => ({
        url: `/orders/${id}/`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: [
        "GetOrdersById",
        "GetAllOrders",
        "GetShareOrder",
        "GetAllDashboardCount",
        "GetAllDashboardManagerCount",
      ],
    }),
    // upload order file
    uploadOrderFile: builder.mutation({
      query: (body) => ({
        url: "/order-upload-file/",
        method: "POST",
        body: body,
      }),
    }),
    // Delete order file
    deleteOrderFile: builder.mutation({
      query: (body) => ({
        url: "/order-upload-file/",
        method: "DELETE",
        body: body,
      }),
    }),
    //order-share

    getOrderShare: builder.query<any, any>({
      query: ({ id, user_id }) => ({
        url: `/${id}/share-order-details/${user_id}/`,
        method: "GET",
      }),
      providesTags: ["GetShareOrder"],
    }),
    //get-delivery-status
    getDeliveryStatus: builder.query<any, any>({
      query: () => ({
        url: `/content?model_class=orderstatus`,
        method: "GET",
      }),
      providesTags: ["GetDeliveryStatus"],
    }),
    //get-dispatch-mode
    getDispatchMode: builder.query<any, any>({
      query: () => ({
        url: `/content?model_class=dispatchmode`,
        method: "GET",
      }),
      providesTags: ["GetDispatchMode"],
    }),
    //order-download
    getOrderDownloadData: builder.query<any, any>({
      query: (id) => ({
        url: `order-download/?id=[${id}]`,
        method: "GET",
        responseHandler: async (response) => {
          // Read the response as a Blob
          const blob = await response.blob();

          // Process the Blob or store its URL or metadata in the state
          // For example, you can store the URL of the Blob in the state
          const blobUrl = URL.createObjectURL(blob);

          return blobUrl;
        },
      }),
    }),
    //get-all-allocated
    getAllAllocated: builder.query<any, any>({
      query: ({ id, search, params, body }) => ({
        url: `/allocated-list-for-manager-or-executives/?${id}&q=${search}&filter_data=${encodeURIComponent(
          body
        )}`,
        method: "GET",
        params: params,
      }),
      providesTags: ["GetAllAllocatedData"],
    }),
    //get-all-dashboard-count
    getDashboardCount: builder.query<any, any>({
      query: (id) => ({
        url: `/${id}/executive-dashboard-count/`,
        method: "GET",
      }),
      providesTags: ["GetAllDashboardCount"],
    }),
    //get-all-dashboard-count-manager
    getDashboardManagerCount: builder.query<any, any>({
      query: (id) => ({
        url: `/${id}/manager-dashboard-count/`,
        method: "GET",
      }),
      providesTags: ["GetAllDashboardManagerCount"],
    }),
    //get-all-dashboard-count-pie-chart
    getDashboardPieChartCount: builder.query<any, any>({
      query: ({ id, date_id }) => ({
        url: `/${id}/pie-chart?day_no=${date_id}`,
        method: "GET",
      }),
      providesTags: ["GetAllDashboardPieChartCount"],
    }),
    //get-all-dashboard-count-funnel-chart
    getDashboardFunnelChartCount: builder.query<any, any>({
      query: ({ id, source }) => ({
        url: `/${id}/source-dashboard-count/?option=${source}`,
        method: "GET",
      }),
      providesTags: ["GetAllDashboardFunnelChartCount"],
    }),
    //get-all-dashboard-count-bar-chart
    getDashboardBarChartCount: builder.query<any, any>({
      query: ({ id, date_id }) => ({
        url: `/${id}/bar-chart-for-customer-list?year=${date_id}`,
        method: "GET",
      }),
      providesTags: ["GetAllDashboardBarChartCount"],
    }),
    //get-all-dashboard-count-bar-2-chart
    getDashboardBar2ChartCount: builder.query<any, any>({
      query: ({ id, date_id }) => ({
        url: `/${id}/sales-closing-list?year=${date_id}`,
        method: "GET",
      }),
      providesTags: ["GetAllDashboardBar2ChartCount"],
    }),
    //get-notification
    getNotifications: builder.query<any, any>({
      query: (id) => ({
        url: `/${id}/list-of-notifications`,
        method: "GET",
      }),
      providesTags: ["GetNotification"],
    }),
    //create-region
    createRegion: builder.mutation<any, any>({
      query: ({ id, body }) => ({
        url: `/create/${id}/`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: [
        "GetAllRegion",
        "GetAllDesignation",
        "GetAllTypes",
        "GetAllCategory",
        "GetAllSource",
        "GetAllIndustry",
        "GetAllnature",
        "GetDispatchMode",
        "GetGroup",
      ],
    }),
    //seen
    readedNotification: builder.mutation<any, any>({
      query: (notificationId) => ({
        url: `/notification-read/${notificationId}`,
        method: "PUT",
      }),
      invalidatesTags: ["GetNotification"],
    }),
  }),
});
export const {
  useLoginMutation,
  useResetPasswordMutation,
  useForgotPasswordMutation,
  useGetUserDetailByIdQuery,
  useCreateContactsMutation,
  useGetAllContactsQuery,
  useGetContactsByIdQuery,
  useLazyGetContactsByIdQuery,
  useEditContactsMutation,
  useDeleteContactsByIdMutation,
  useEditUserMutation,
  useGetSalesByIdQuery,
  useCreateLeadMutation,
  useGetSourcesQuery,
  useGetTypesQuery,
  useGetNatureQuery,
  useGetDesignationQuery,
  useGetSourceQuery,
  useGetIndustryQuery,
  useGetRegionQuery,
  useGetDownloadDataQuery,
  useGetCompanyNamesQuery,
  useGetEnquiryCategoryQuery,
  useGetAllSalesLeadQuery,
  useGetLeadByIdQuery,
  useLazyGetLeadByIdQuery,
  useCreateCreateQuotationMutation,
  useGetDiscountQuery,
  useGetQuotationQuery,
  useGetSalesDownloadDataQuery,
  useEditLeadMutation,
  useDeleteLeadMutation,
  useGetSalesAfterQuotationQuery,
  useGetQuotationByIdQuery,
  useEditQuotationMutation,
  useGetQuotationDownloadDataQuery,
  useLazyGetQuotationDownloadDataQuery,
  useCreateCreateOrderMutation,
  useUploadFileMutation,
  useGetGroupQuery,
  useCreateCreateNpdMutation,
  useGetNpdQuery,
  useGetNpdByIdQuery,
  useEditNpdMutation,
  useDeleteNpdMutation,
  useGetNpdDownloadQuery,
  useGetExecutivesQuery,
  useCreateNpdQuotationMutation,
  useGetEnquiryStatusQuery,
  useGetQuotationShareQuery,
  useGetSalesUnderExecutiveQuery,
  useGetCustomerByIdQuery,
  useGetManagersListQuery,
  useAllocateManagerMutation,
  useLazyGetSalesAfterQuotationQuery,
  useGetSalesAfterNpdQuotationQuery,
  useLazyGetSalesAfterNpdQuotationQuery,
  useGetAllOrdersQuery,
  useGetQuotationOrNpdQuery,
  useGetOrderByIdQuery,
  useUpdateOrderMutation,
  useUploadOrderFileMutation,
  useDeleteOrderFileMutation,
  useGetDeliveryStatusQuery,
  useGetDispatchModeQuery,
  useGetOrderDownloadDataQuery,
  useGetAllAllocatedQuery,
  useGetOrderShareQuery,
  useGetDashboardCountQuery,
  useGetDashboardManagerCountQuery,
  useGetAllocatedDownloadQuery,
  useGetDashboardPieChartCountQuery,
  useGetDashboardBarChartCountQuery,
  useGetDashboardBar2ChartCountQuery,
  useCreateRegionMutation,
  useGetNotificationsQuery,
  useGetDashboardFunnelChartCountQuery,
  useReadedNotificationMutation,
  useCloseQuotationMutation,
} = api;
