// import "./index.css";
import { Row, Col, Form, Input, Button, message } from "antd";
import { LoginLayout } from "../../components/LoginLayout";
import { useNavigate } from "react-router";
import { CommonButton } from "../../components/CommonButton/button";
import { Text } from "../../components/CommonFont/text";
import * as Yup from "yup";
import { Formik, FormikValues, useFormik } from "formik";
import { useSearchParams } from "react-router-dom";
import { useResetPasswordMutation } from "../../services/api";

export const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");
  const uid64 = searchParams.get("uidb64");
  const user_role = searchParams.get("user_role");

  const [resetPassword, { data, isLoading }] = useResetPasswordMutation();

  const validationSchema = Yup.object().shape({
    confirm_password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$/,
        "Password must contain at least 8 characters with one uppercase letter, one number, and one special character."
      ),
    new_password: Yup.string()
      .oneOf([Yup.ref("confirm_password")], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      new_password: "",
      confirm_password: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const resetParams = {
        credentials: values,
        uid: uid64,
        token: token,
      };
      await resetPassword(resetParams)
        .unwrap()
        .then((data) => {
          if (user_role === "0") {
            window.location.href = "http://139.59.81.163:8001/admin/";
          } else {
            navigate("/login");
          }

          message.success(data?.message);
        })

        .catch((err) => {
          message.error(err?.data?.message);
        });
    },
  });

  return (
    <>
      <div>
        <LoginLayout>
          <div className="login-page-div-1 ">
            <div className="login-page-title-1 ">
              <Text value="Set New Password" variant="wearlay-display" />
            </div>
            <div className="login-page-title-2">
              <Text
                value="Please enter your new password below to regain access to your account."
                variant="wearlay-paragraph"
                className="login-page-title-1"
              />
            </div>

            <Form layout="vertical" onFinish={formik.handleSubmit}>
              <Form.Item
                label={"New Password"}
                required
                name={"confirm_password"}
                validateStatus={
                  formik.errors.confirm_password &&
                  formik.touched.confirm_password
                    ? "error"
                    : ""
                }
                help={
                  formik.errors.confirm_password &&
                  formik.touched.confirm_password
                    ? formik.errors.confirm_password
                    : undefined
                }
              >
                <Input.Password
                  className="login-inputs"
                  type="password"
                  placeholder="New password"
                  value={formik.values.confirm_password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
              <Form.Item
                label="Confirm Password"
                name={"new_password"}
                required
                hasFeedback
                validateStatus={
                  formik.errors.new_password && formik.touched.new_password
                    ? "error"
                    : ""
                }
                help={
                  formik.errors.new_password && formik.touched.new_password
                    ? formik.errors.new_password
                    : undefined
                }
              >
                <Input.Password
                  type="password"
                  className="login-inputs"
                  placeholder="Re-enter Password"
                  value={formik.values.new_password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
              <Form.Item>
                <CommonButton
                  variant="wealay-primary-btn"
                  value="Set New Password"
                  className="login-btn"
                  disabled={!formik.isValid}
                  isLoading={isLoading}
                />
              </Form.Item>
            </Form>
          </div>
        </LoginLayout>
      </div>
    </>
  );
};
