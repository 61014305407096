import { Layout } from "antd";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "./index.css";
import "../../antd.override.css";

import Sidebar from "../../components/Sidebar";
import { HeaderComponents } from "../../components/Header";
import { useState } from "react";

export const PageLayout = () => {
  const { Sider, Content, Header } = Layout;
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  return (
    <>
      <Layout className="layout-height ">
        <Sider
          breakpoint="sm"
          collapsedWidth="0"
          className="sider"
          theme="light"
          width={100}
          collapsed={collapsed}
          onCollapse={toggleCollapsed}
        >
          <img
            src="/assets/logo.svg"
            className="logo "
            alt="logo"
            onClick={() => {
              navigate("/overview");
            }}
          ></img>
          <Sidebar />
        </Sider>

        <Layout>
          <Header className="header-style">
            <HeaderComponents
              collapsed={collapsed}
              toggleCollapsed={toggleCollapsed}
            />
          </Header>
          <Content className="content-style  form-header">
            <div className="outlet-style ">
              <Outlet />
            </div>
          </Content>
        </Layout>
        {/* </div> */}
      </Layout>
    </>
  );
};
