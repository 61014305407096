import React, { useEffect, useRef, useState } from "react";
import { Menu, Checkbox, Button, Row, Divider, Dropdown } from "antd";
import "./index.css";
import {
  useGetEnquiryCategoryQuery,
  useGetGroupQuery,
  useGetIndustryQuery,
  useGetRegionQuery,
  useGetTypesQuery,
} from "../../services/api";
import { CommonButton } from "../CommonButton/button";
const { SubMenu } = Menu;

const region = [{ region_name: "kerala" }, { region_name: "karnataka" }];

const type = [{ type_name: "type 1" }, { type_name: "type 2" }];

const industry = [
  { industry_name: "industry 1" },
  { industry_name: "industry 2" },
];
interface IFilter {
  setFilter: (data: boolean) => void;
  setFilterData: (data: any) => void;
  filer: boolean;
  setSelectedRegion: (data: any) => void;
  selectedRegion: any;
}
export const NpdFilter: React.FC<IFilter> = ({
  setFilter,
  setFilterData,
  filer,
  setSelectedRegion,
  selectedRegion,
}) => {
  // const [selectedRegion, setSelectedRegion] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);

  const { data: industryData, isLoading: industyLoading } =
    useGetIndustryQuery("");
  const { data: groupData, isLoading: regionLoading } = useGetGroupQuery("");
  const handleRegionChange = (selectedItems: any) => {
    setSelectedRegion(selectedItems);
  };
  const { data: typeData, isLoading: typeLoading } = useGetTypesQuery("");

  const handleTypeChange = (selectedItems: any) => {
    setSelectedType(selectedItems);
  };

  const handleIndustryChange = (selectedItems: any) => {
    setSelectedIndustry(selectedItems);
  };

  const handleFilter = () => {
    // Perform filtering based on selected filters
    // You can implement the filtering logic here
    const filterDatas: any = {};

    if (selectedRegion?.length > 0) {
      filterDatas.group__in = selectedRegion;
    }

    if (selectedType?.length > 0) {
      filterDatas.type__in = selectedType;
    }

    if (selectedIndustry?.length > 0) {
      filterDatas.industry__in = selectedIndustry;
    }
    setFilterData(filterDatas);
    setFilter(false);
  };

  const handleCancel = () => {
    // Reset the selected filters
    // setFilter(false);
    setSelectedRegion([]);
    setSelectedType([]);
    setSelectedIndustry([]);
    setFilterData({});
    setFilter(false);
  };

  const handleOpenChange = (keys: any) => {
    setOpenKeys(keys);
  };

  return (
    <Menu
      mode="inline"
      style={{ width: 215 }}
      defaultOpenKeys={openKeys}
      openKeys={openKeys}
      onOpenChange={handleOpenChange}
      className="filter-drop-down"
    >
      {/* Render region filter */}
      <SubMenu key="group" title="Group" className="w-full">
        <Checkbox.Group value={selectedRegion} onChange={handleRegionChange}>
          {groupData?.map((item: any) => (
            <Menu.Item key={item.id}>
              <Checkbox value={item.id}>{item.name}</Checkbox>
            </Menu.Item>
          ))}
        </Checkbox.Group>
      </SubMenu>

      {/* Render type filter */}
      {/* <SubMenu key="type" title="Type" className="w-full">
        <Checkbox.Group value={selectedType} onChange={handleTypeChange}>
          {typeData?.map((item: any) => (
            <Menu.Item key={item.id}>
              <Checkbox value={item.id}>{item.name}</Checkbox>
            </Menu.Item>
          ))}
        </Checkbox.Group>
      </SubMenu> */}

      {/* Render industry filter */}
      {/* <SubMenu key="industry" title="Industry" className="w-full">
        <Checkbox.Group
          value={selectedIndustry}
          onChange={handleIndustryChange}
        >
          {industryData?.map((item: any) => (
            <Menu.Item key={item.id}>
              <Checkbox value={item.id}>{item.name}</Checkbox>
            </Menu.Item>
          ))}
        </Checkbox.Group>
      </SubMenu> */}

      {/* Render filter and cancel buttons */}
      <Menu.Item
        key="filter"
        style={{
          textAlign: "start",
        }}
        className="filter-drop-down"
      >
        <Row justify={"space-between"}>
          {/* <Button onClick={handleCancel}>Reset</Button> */}
          <CommonButton
            variant="wearlay-without-bg"
            onClick={handleCancel}
            value="Reset"
            className="reset"
          />
          {/* <Button type="primary" onClick={handleFilter}>
            Ok
          </Button> */}
          <CommonButton
            variant="wealay-primary-btn"
            onClick={handleFilter}
            value="Ok"
          />
        </Row>
      </Menu.Item>
    </Menu>
  );
};

export default NpdFilter;
