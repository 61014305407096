import {
  ArrowLeftOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  Button,
  Drawer,
  message,
  Pagination,
  PaginationProps,
  Row,
  Skeleton,
  Space,
  Tag,
} from "antd";
import { useEffect, useState } from "react";
import { SearchBox } from "../../components/CommonSearchBox";
import { CommonTable } from "../../components/commonTable";
import { AddNewLead } from "../../components/Drawer/AddNewLead";
import { SalesDetailById } from "../../components/Drawer/DetailById/sales";
import "./index.css";
import { Text } from "../../components/CommonFont/text";
import { CustomerPreview } from "../../components/Drawer/CustomerPreview";
import { TableCard } from "../../components/CommonTableCard";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useGetExecutivesQuery,
  useGetSalesDownloadDataQuery,
  useGetSalesUnderExecutiveQuery,
  useGetUserDetailByIdQuery,
} from "../../services/api";
import { saveAs } from "file-saver";

import { getEmployeesParams } from "../../helpers/quarery";
import { FilterMenu } from "../../components/CommonFilter";
import SalesFilterMenu from "../../components/CommonFilter/salesFilter";
import _ from "lodash";
import { CommonButton } from "../../components/CommonButton/button";
export const EmployeeDetailPage = () => {
  const [open, setOpen] = useState(false);
  const [openLead, setOpenLead] = useState(false);
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState<any>();
  const [executiveIdParams, setExecutiveIdParams] = useState<any>();
  const [downloadIds, setDownloadIds] = useState<any>();

  const [selectedRegion, setSelectedRegion] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const isExecutive = localStorage.getItem("executive-id");
  const [searchParams, setSearchParams] = useSearchParams();
  // const [executiveId, setExecutiveId] = useState(
  //   searchParams.get("executive-id")
  // );
  const executiveId = searchParams.get("executive-id");
  const executiveById = searchParams.get("id");

  useEffect(() => {
    if (executiveId) {
      localStorage.setItem("executive-id", executiveId);
      setExecutiveIdParams(executiveId);
    }
  }, [executiveId]);

  const { data: userData, isLoading: userLoading } =
    useGetUserDetailByIdQuery(executiveId);

  const userId = localStorage.getItem("user_id");
  const search = searchParams.get("search");

  const getAllsalesParams = {
    id: userId,
    // params: params,
    search: search ? search : "",
    // body: JSON.stringify(filterData)
    //   ? JSON.stringify(filterData)
    //   : JSON.stringify({}),
  };
  const { data: executivesData, isLoading: executivesLoading } =
    useGetExecutivesQuery(getAllsalesParams);

  const onClose = () => {
    setOpen(false);
    setOpenLead(false);
    if (executiveIdParams) {
      // setSearchParams({ "executive-id": executiveId });
      navigate(`/employee-detail-page?executive-id=${executiveIdParams}`);
    } else {
      navigate(`/employee-detail-page`);
    }
    navigate(`/employee-detail-page?executive-id=${executiveIdParams}`);
  };
  const showDrawer = () => {
    setOpenLead(true);
  };
  const [pageData, setPageData] = useState<{
    page: number;
    pageSize: number;
  }>({
    page: 0,
    pageSize: 5,
  });

  const params = getEmployeesParams(pageData.page, pageData.pageSize);

  const getAllExecutiveParams = {
    id: executiveId ? executiveId : isExecutive,
    params: params,
    search: search ? search : "",
    body: JSON.stringify(filterData)
      ? JSON.stringify(filterData)
      : JSON.stringify({}),
  };

  const { data: executiveSalesData, isLoading: executiveSalesLoading } =
    useGetSalesUnderExecutiveQuery(getAllExecutiveParams);

  const navigate = useNavigate();

  const findIndexForObject = (array: [], object: any) => {
    return _.findIndex(array, object);
  };

  const targetObject = { id: Number(executiveId ? executiveId : isExecutive) };
  const targetObject2 = { id: Number(executiveById) };

  const index = findIndexForObject(executivesData?.data, targetObject);
  const index2 = findIndexForObject(executiveSalesData?.results, targetObject2);

  const [currentIndex, setCurrentIndex] = useState(index);
  const [currentIndex2, setCurrentIndex2] = useState(index2);

  const getObjectAtIndex = (array: [], index: number) => {
    const object = _.get(array, index);
    return object;
  };

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const columns = [
    {
      title: "Company",
      dataIndex: "company_name",
      key: "company_name",
      sorter: (a: any, b: any) => a.name - b.name,
      ellipsis: true,
    },
    // {
    //   title: "Region",
    //   dataIndex: "region_name",
    //   key: "region_name",
    //   sorter: (a: any, b: any) => a.age - b.age,
    //   ellipsis: true,
    // },
    {
      title: "Lead Name",
      dataIndex: "lead_name",
      key: "lead_name",
      sorter: (a: any, b: any) => a.lead_name.localeCompare(b.lead_name),

      ellipsis: true,
    },
    {
      title: "Contact",
      dataIndex: "phone",
      key: "phone",
      ellipsis: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ellipsis: true,
      render: (text: any) => {
        return (
          <>
            <div>{text ? text : "-"}</div>
          </>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type_name",
      key: "type_name",
      ellipsis: true,
    },
    {
      title: "Industry",
      dataIndex: "industry_name",
      key: "industry_name",
      ellipsis: true,
    },
    {
      title: "Enquiry Category",
      dataIndex: "enquiry_category_name",
      key: "enquiry_category_name",
      ellipsis: true,
    },
    {
      title: "Follow up Status",
      dataIndex: "status",
      key: "follow_up_status",
      ellipsis: true,
      render: (text: any) => {
        return (
          <>
            {text?.title_name === "Follow Up 1" ? (
              <Tag color="cyan">Follow up 1</Tag>
            ) : text?.title_name === "Follow Up 2" ? (
              <Tag color="orange">Follow up 2</Tag>
            ) : text?.title_name === "Follow Up 3" ? (
              <Tag color="purple">Follow up 3</Tag>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
  ];
  const titleData = [
    "Code",
    "Lead Name",
    "Region",
    "Contact",
    "Email",
    "Type",
    "Industry",
    "Status",
  ];
  useEffect(() => {
    setCurrentIndex(index);
    setCurrentIndex2(index2);
  }, [index, index2]);
  useEffect(() => {
    if (currentIndex > -1) {
      const updatedObject: any = getObjectAtIndex(
        executivesData?.data,
        currentIndex
      );

      navigate(`/employee-detail-page?executive-id=${updatedObject?.id}`);
    }
  }, [currentIndex]);
  useEffect(() => {
    if (currentIndex2 > -1) {
      const updatedObject: any = getObjectAtIndex(
        executiveSalesData?.results,
        currentIndex2
      );

      navigate(`/employee-detail-page?id=${updatedObject?.id}`);
    }
  }, [currentIndex2]);

  const handleNext = () => {
    if (currentIndex < executivesData?.data?.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  const handleNext2 = () => {
    if (currentIndex2 < executiveSalesData?.results?.length - 1) {
      setCurrentIndex2(currentIndex2 + 1);
    }
  };

  const handlePrevious2 = () => {
    if (currentIndex2 > 0) {
      setCurrentIndex2(currentIndex2 - 1);
    }
  };

  const onPaginationChange = (page: number, pageSize: number) => {
    setPageData({ page, pageSize });
  };
  const idArray = _.map(executiveSalesData?.results, "id");

  const handleDownload = () => {
    // Convert the data to a Blob
    // const blob = new Blob([downloadedData], {
    //   type: "application/vnd.ms-excel",
    // });
    // Use the saveAs function to trigger the download
    //saveAs(blob, "sales-export.xlsx");
    if (downloadedData) {
      saveAs(downloadedData, "quotation-export.xlsx");
    } else {
      message.error("Error: Downloaded data is undefined.");
    }
  };

  const { data: downloadedData, isLoading: downloadLoading } =
    useGetSalesDownloadDataQuery(
      downloadIds?.length > 0 ? downloadIds : idArray
    );
  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
    current,
    pageSize
  ) => {
    setPageData({
      page: current,
      pageSize: pageSize,
    });
  };
  return (
    <>
      <div className="mt-24">
        <div className="sales-profile-p-10">
          <ArrowLeftOutlined
            className="customer-back-button"
            onClick={() => {
              navigate("/employee");
            }}
          />
          {userLoading ? (
            <Skeleton avatar paragraph={{ rows: 2 }} />
          ) : (
            <div className="flex items-center w-full justify-between employee-detail-page-main-div">
              <div className="flex items-center gap-2">
                <img
                  className="sale-profile round-avatar"
                  src={
                    userData?.data[0]?.image_url
                      ? userData?.data[0]?.image_url
                      : "/assets/profile.svg"
                  }
                ></img>
                <div className="flex flex-col gap-2">
                  {/* <div className="sales-profile-title-1 ">Nithin K G</div>
                <div className="sales-profile-title-2">Sales Man</div> */}
                  <Text
                    value={
                      userData?.data[0]?.full_name
                        ? userData?.data[0]?.full_name
                        : "-"
                    }
                    variant="wearlay-headline-2"
                  />
                  <Text
                    value="Sales Man"
                    variant="wearlay-label"
                    className="gray-text"
                  />
                </div>
                <div className="employee-detail-page-main-div-2 flex">
                  <div className="flex items-center gap-2 sale-profile-gap-1">
                    <img src="/assets/group.svg"></img>
                    {/* <div className="sales-profile-title-3 ">WY12345</div> */}
                    <Text
                      value={
                        userData?.data[0]?.code ? userData?.data[0]?.code : "-"
                      }
                      variant="wearlay-paragraph-bold"
                    />
                  </div>
                  <div className="flex items-center gap-2 sale-profile-gap">
                    <img src="/assets/message.svg"></img>
                    {/* <div className="sales-profile-title-3 ">roshan@chema.com</div> */}
                    <Text
                      value={
                        userData?.data[0]?.email
                          ? userData?.data[0]?.email
                          : "-"
                      }
                      variant="wearlay-paragraph-bold"
                    />
                  </div>
                  <div className="flex items-center gap-2">
                    <img src="/assets/phone.svg"></img>
                    {/* <div className="sales-profile-title-3 ">098 25 314786</div> */}
                    <Text
                      value={
                        userData?.data[0]?.phone
                          ? userData?.data[0]?.phone
                          : "-"
                      }
                      variant="wearlay-paragraph-bold"
                    />
                  </div>
                </div>
              </div>
              <div>
                <Space>
                  <Button
                    onClick={() => {
                      handlePrevious();
                      // navigate(`/contacts?id=${object?.id}`);
                    }}
                    disabled={currentIndex === 0}
                  >
                    <LeftOutlined />
                  </Button>
                  <Button
                    onClick={() => {
                      // navigate(`/contacts?id=${object?.id}`);
                      handleNext();
                    }}
                    disabled={currentIndex === executivesData?.data?.length - 1}
                  >
                    <RightOutlined />
                  </Button>
                </Space>
              </div>
            </div>
          )}
          <div className="employee-resp-contacts">
            <div className="flex w-full gap-2 ">
              <div className="flex items-center gap-2 ">
                <img src="/assets/group.svg"></img>
                {/* <div className="sales-profile-title-3 ">WY12345</div> */}
                <Text
                  value={
                    userData?.data[0]?.code ? userData?.data[0]?.code : "-"
                  }
                  variant="wearlay-paragraph-bold"
                />
              </div>
              <div className="flex items-center gap-2 ">
                <img src="/assets/message.svg"></img>
                {/* <div className="sales-profile-title-3 ">roshan@chema.com</div> */}
                <Text
                  value={
                    userData?.data[0]?.email ? userData?.data[0]?.email : "-"
                  }
                  variant="wearlay-paragraph-bold"
                />
              </div>
            </div>
            <div className="flex items-center gap-2">
              <img src="/assets/phone.svg"></img>
              {/* <div className="sales-profile-title-3 ">098 25 314786</div> */}
              <Text
                value={
                  userData?.data[0]?.phone ? userData?.data[0]?.phone : "-"
                }
                variant="wearlay-paragraph-bold"
              />
            </div>
          </div>
        </div>

        <div className="p-10">
          <div className="flex justify-between">
            {/* <div className="sales-title-1">Customers List</div> */}
            <Text value="Customers List" variant="wearlay-headline-2" />
            <div className="flex items-center h-full gap-2">
              <div className="search-resp ">
                <SearchBox placeholder="Search" />
              </div>
              <div className="relative">
                <CommonButton
                  variant="wearlay-with-icon"
                  icon={filter ? "/assets/close.svg" : "/assets/filter.svg"}
                  onClick={() => {
                    setFilter(!filter);
                  }}
                  className="download-btn-color"
                />
                {filter && (
                  <SalesFilterMenu
                    setFilter={setFilter}
                    setFilterData={setFilterData}
                    filer={filter}
                    selectedRegion={selectedRegion}
                    setSelectedRegion={setSelectedRegion}
                    setSelectedIndustry={setSelectedIndustry}
                    selectedIndustry={selectedIndustry}
                    setSelectedType={setSelectedType}
                    selectedType={selectedType}
                  />
                )}
              </div>
              <CommonButton
                variant="wearlay-with-icon"
                className="download-btn-color"
                icon={
                  executiveSalesData?.results?.length === 0
                    ? "/assets/disabledDownload.svg"
                    : "/assets/download.svg"
                }
                onClick={() => {
                  handleDownload();
                }}
                disabled={executiveSalesData?.results?.length === 0}
              ></CommonButton>
            </div>
          </div>
          <div className="resp-search">
            <SearchBox placeholder="Search" />
          </div>
          <div className="resp-table">
            <CommonTable
              datasource={executiveSalesData?.results}
              coloumn={columns}
              setDetailsOpen={setOpen}
              isLoading={executiveSalesLoading}
            />
            {executiveSalesData?.count > 0 && (
              <Row justify={"end"}>
                <Pagination
                  // current={pageData.page}
                  onChange={onPaginationChange}
                  total={executiveSalesData?.count}
                  pageSize={pageData?.pageSize}
                  defaultCurrent={1}
                  responsive={true}
                  onShowSizeChange={onShowSizeChange}
                  showSizeChanger={
                    executiveSalesData?.count > 20 ? true : false
                  }
                />
              </Row>
            )}
          </div>
        </div>
        <TableCard
          headingData={titleData}
          data={executiveSalesData?.results}
          setOpen={setOpen}
          loading={executiveSalesLoading}
        />
        {executiveSalesData?.count > 0 && (
          <Pagination
            // current={pageData.page}
            onChange={onPaginationChange}
            total={executiveSalesData?.count}
            pageSize={pageData.pageSize}
            className="pagination-resp"
            defaultCurrent={1}
            onShowSizeChange={onShowSizeChange}
            showSizeChanger={executiveSalesData?.count > 10 ? true : false}
          />
        )}
      </div>
      <Drawer
        title={
          <div className="flex justify-between items-center">
            {/* <div>Customer Preview</div> */}
            <Text value="Customer Preview" variant="wearlay-subtitle" />
            <div>
              <Space>
                <Button
                  onClick={() => {
                    handlePrevious2();
                    // navigate(`/contacts?id=${object?.id}`);
                  }}
                  disabled={currentIndex2 === 0}
                >
                  <LeftOutlined />
                </Button>
                <Button
                  onClick={() => {
                    // navigate(`/contacts?id=${object?.id}`);
                    handleNext2();
                  }}
                  disabled={
                    currentIndex2 === executiveSalesData?.results?.length - 1
                  }
                >
                  <RightOutlined />
                </Button>
              </Space>
            </div>
          </div>
        }
        placement="right"
        onClose={onClose}
        open={open || openLead}
        width="500"
        destroyOnClose={true}
      >
        {<CustomerPreview setPreview={setOpen} />}
      </Drawer>
    </>
  );
};
