import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Row,
  Space,
} from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CommonAvatar } from "../../CommonAvatar";
import { CommonButton } from "../../CommonButton/button";
import "./index.css";
import {
  useDeleteNpdMutation,
  useEditNpdMutation,
  useGetNpdByIdQuery,
} from "../../../services/api";
import { Text } from "../../CommonFont/text";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
interface INpdContact {
  datas?: any;
  setEdit: (data: boolean) => void;
  setPreview?: (data: boolean) => void;
  loading?: boolean;
}
export const InquiryPreview: React.FC<INpdContact> = ({
  datas,
  setEdit,
  setPreview,
  loading,
}) => {
  const [isAdd, setIsAdd] = useState(true);
  const [isSave, setIsSave] = useState(false);
  const [form] = Form.useForm();

  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    date: Yup.date().required("This date is required"),
    note: Yup.string().required("Note is required"),
    next_follow_up_date: Yup.date().required("Follow Up date is required"),
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const npd_id = searchParams.get("id");

  const { data, isLoading } = useGetNpdByIdQuery(npd_id);

  const [trigger, { data: deleteData, isLoading: npdLoading }] =
    useDeleteNpdMutation();

  // const formik = useFormik({
  //   initialValues: {
  //     date: undefined,
  //     note: "",
  //     followUp: undefined,
  //   },
  //   validationSchema,
  //   onSubmit: (values) => {
  //     console.log(values);
  //   },
  // });
  const handleDelete = async () => {
    await trigger(npd_id)
      .unwrap()
      .then((data) => {
        message.success(data?.message);
        setPreview && setPreview(false);
      })
      .catch((err) => {
        message.error(err?.data?.message);
      });
  };
  const [editNpd, { data: editNpdById, isLoading: editNpdLoading }] =
    useEditNpdMutation();
  const formik = useFormik({
    initialValues: {
      // advanceAmount: undefined,
      // advanceDate: undefined,
      date: undefined,
      note: "",
      next_follow_up_date: undefined,
      // advanceNote: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const followUp =
        data?.data?.follow_up?.length > 0 ? [...data.data.follow_up] : [];
      const nextFollowUp = {
        date: values.date,
        notes: values.note,
        next_follow_up_date: values.next_follow_up_date,
      };

      if (followUp.length === 0) {
        followUp.push(nextFollowUp);
      } else if (followUp.length === 1) {
        followUp.push(nextFollowUp);
      } else if (followUp.length === 2) {
        followUp.push(nextFollowUp);
      }

      const editParams = {
        body: { follow_up: followUp },
        id: npd_id,
      };
      await editNpd(editParams)
        .unwrap()
        .then((data) => {
          setIsSave(false);
          setIsAdd(true);
          setPreview && setPreview(false);

          form.resetFields();
          message.success(data?.message);
          // navigate("/inquiries");
        })

        .catch((err) => {
          message.error(err?.data?.message);
        });
    },
  });
  const widgetMenu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          setEdit(true);
        }}
      >
        <div>Edit</div>
      </Menu.Item>
      {/* <Menu.Item
        onClick={() => {
          handleDelete();
        }}
      >
        <div className="logout-div">
          <Space>
       
            <span className="logout-text ">Delete</span>
          </Space>
        </div>
      </Menu.Item> */}
    </Menu>
  );
  return (
    <>
      <div>
        <div className="sales-detail-border-main ">
          <Row gutter={16} className="p5">
            <Col span={6}>
              {/* <Avatar size={100}>C</Avatar> */}
              <CommonAvatar text={data?.data?.company_name} size={100} />
            </Col>
            <Col span={18} className="flex flex-col gap-2">
              <div className="flex justify-between ">
                <div className="sales-detail-title-2">
                  {data?.data?.company_name}
                </div>
                <Dropdown
                  overlay={widgetMenu}
                  trigger={["click"]}
                  className="dropdown-click"
                >
                  <img src="/assets/more.svg"></img>
                </Dropdown>
              </div>
              <div className="flex gap-2 ">
                <img src="/assets/message.svg"></img>
                <div>{data?.data?.email ? data?.data?.email : "-"}</div>
              </div>
              <div className="flex gap-2">
                <img src="/assets/phone.svg"></img>
                <div>{data?.data?.phone}</div>
              </div>
              <div className="flex gap-2">
                <img
                  src="/assets/locattion.svg"
                  className="img-align-self"
                ></img>
                <div className="break-all">{data?.data?.address}</div>
              </div>
              {/* <Button
                  onClick={() => {
                    navigate("/create-quotation");
                  }}
                  className="sales-detail-btn-clr "
                >
                  Create Quotations
                </Button> */}
              <CommonButton
                variant="wealay-primary-btn"
                className="sales-detail-btn-clr w-content"
                value=" Create Quotations"
                onClick={() => {
                  navigate(`/create-quotation?npd_id=${npd_id}`);
                }}
              />
            </Col>
          </Row>
          <div className="inquiry-div-1 mt-24">
            <Row className="sales-detail-border flex flex-col gap-2">
              <div className="sales-detail-placeholder">Product Name</div>
              <div className="sales-detail-sub-title">
                {data?.data?.product_name}
              </div>
            </Row>
            <Row className="sales-detail-border flex flex-col gap-2">
              <div className="sales-detail-placeholder">Specification</div>
              <div className="sales-detail-sub-title">
                {data?.data?.specification}
              </div>
            </Row>
            <Row>
              <Col
                span={24}
                md={{ span: 12 }}
                className="sales-detail-border flex flex-col gap-2"
              >
                <div className="sales-detail-placeholder">Quantity</div>
                <div className="sales-detail-sub-title">
                  {data?.data?.quantity}
                </div>
              </Col>
              <Col
                span={24}
                md={{ span: 12 }}
                className="sales-detail-border flex flex-col gap-2"
              >
                <div className="sales-detail-placeholder">Group</div>
                <div className="sales-detail-sub-title">
                  {data?.data?.group_name}
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <Row className="mt-24">
          <Col span={24}>
            <div className="sales-detail-border flex flex-col gap-2">
              <div className="sales-detail-placeholder">Lead Name</div>
              <div className="sales-detail-sub-title ">
                {data?.data?.lead_name}
              </div>
            </div>
          </Col>
          <Col span={24} md={{ span: 12 }}>
            <div className="sales-detail-border flex flex-col gap-2">
              <div className="sales-detail-placeholder">Designation</div>
              <div className="sales-detail-sub-title ">
                {data?.data?.designation_name}
              </div>
            </div>
            <div className="sales-detail-border flex flex-col gap-2">
              <div className="sales-detail-placeholder">GST</div>
              <div className="sales-detail-sub-title ">
                {" "}
                {data?.data?.gst ? data?.data?.gst : "-"}
              </div>
            </div>
            <div className="sales-detail-border flex flex-col gap-2">
              <div className="sales-detail-placeholder">Type</div>
              <div className="sales-detail-sub-title ">
                {" "}
                {data?.data?.type_name}
              </div>
            </div>
            <div className="sales-detail-border flex flex-col gap-2">
              <div className="sales-detail-placeholder">Enquiry Category</div>
              <div className="sales-detail-sub-title ">
                {data?.data?.enquiry_category_name}
              </div>
            </div>
            {/* <div className="sales-detail-border flex flex-col gap-2">
              <div className="sales-detail-placeholder">Nature</div>
              <div className="sales-detail-sub-title ">
                {" "}
                {data?.data?.nature_name}
              </div>
            </div> */}
            {/* <div className="sales-detail-border">
                <div className="sales-detail-placeholder">Nature</div>
                <div className="sales-detail-sub-title ">New</div>
              </div> */}
          </Col>
          <Col span={24} md={{ span: 12 }}>
            <div className="sales-detail-border flex flex-col gap-2">
              <div className="sales-detail-placeholder">Region</div>
              <div className="sales-detail-sub-title ">
                {data?.data?.region_name}
              </div>
            </div>
            <div className="sales-detail-border flex flex-col gap-2">
              <div className="sales-detail-placeholder">Source</div>
              <div className="sales-detail-sub-title ">
                {data?.data?.source_name}
              </div>
            </div>
            <div className="sales-detail-border flex flex-col gap-2">
              <div className="sales-detail-placeholder">Industry</div>
              <div className="sales-detail-sub-title ">
                {data?.data?.industry_name}
              </div>
            </div>
            {/* <div className="sales-detail-border flex flex-col gap-2">
              <div className="sales-detail-placeholder">Enquiry Category</div>
              <div className="sales-detail-sub-title ">
                {data?.data?.enquiry_category_name}
              </div>
            </div> */}
            <div>
              <div></div>
              <div></div>
            </div>
          </Col>
        </Row>
        <div className="create-quotation-border">
          <div className="flex justify-between  items-center">
            <div>
              <Text
                value=" Follow Up 1"
                variant="wearlay-subtitle"
                className="create-quotation-margin-bottom "
              />
            </div>
            <div>
              {data?.data?.follow_up?.length === 0 && isAdd && (
                <CommonButton
                  value="Add Note"
                  variant="wealay-secondary-btn"
                  onClick={() => {
                    setIsAdd(false);
                    setIsSave(true);
                  }}
                />
              )}
              {!data?.data?.follow_up[0] && isSave && (
                <CommonButton
                  value="Save"
                  variant="wealay-primary-btn"
                  onClick={() => {
                    formik.handleSubmit();

                    // setIsSave(false);
                  }}
                  disabled={!formik.isValid}
                  isLoading={editNpdLoading}
                />
              )}
            </div>
          </div>
          {!data?.data?.follow_up[0] && isSave && (
            <Form layout="vertical">
              <Form.Item
                label="Date"
                required
                name="noteDate"
                className="w-full"
                hasFeedback
                validateStatus={
                  formik.errors.date && formik.touched.date
                    ? "error"
                    : undefined
                }
                help={
                  formik.errors.date &&
                  formik.touched.date &&
                  formik.errors.date
                }
              >
                <DatePicker
                  className="w-full"
                  placeholder="Select Date"
                  onBlur={formik.handleBlur}
                  onChange={(value) =>
                    formik.setFieldValue(
                      "date",
                      value ? value.format("YYYY-MM-DD") : ""
                    )
                  }
                  value={dayjs(formik.values.date)}
                />
              </Form.Item>
              <Form.Item
                label="Note"
                required
                name="note"
                hasFeedback
                validateStatus={
                  formik.errors.note && formik.touched.note ? "error" : ""
                }
                help={
                  formik.errors.note && formik.touched.note
                    ? formik.errors.note
                    : undefined
                }
              >
                <TextArea
                  placeholder="Note"
                  value={formik.values.note}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
              <Form.Item
                label="Next Follow up Date*"
                required
                name="next_follow_up_date"
                hasFeedback
                validateStatus={
                  formik.errors.next_follow_up_date &&
                  formik.touched.next_follow_up_date
                    ? "error"
                    : undefined
                }
                help={
                  formik.errors.next_follow_up_date &&
                  formik.touched.next_follow_up_date &&
                  formik.errors.next_follow_up_date
                }
              >
                <DatePicker
                  className="w-full"
                  placeholder="Select Date"
                  onBlur={formik.handleBlur}
                  onChange={(value) =>
                    formik.setFieldValue(
                      "next_follow_up_date",
                      value ? value.format("YYYY-MM-DD") : ""
                    )
                  }
                  value={dayjs(formik.values.next_follow_up_date)}
                  disabledDate={(current) =>
                    current && current < moment().endOf("day")
                  }
                />
              </Form.Item>
            </Form>
          )}
          {data?.data?.follow_up[0] && (
            <div className="create-quotation-border">
              <div className="flex flex-col gap-2">
                <Text
                  value="Date"
                  variant="wearlay-label-bold"
                  className="create-quotation-text-color"
                ></Text>
                <Text
                  value={moment(data?.data?.follow_up[0]?.date).format(
                    "DD-MM-YYYY"
                  )}
                  variant="wearlay-paragraph-bold"
                ></Text>
              </div>
              <Divider />
              <div className="flex flex-col gap-2">
                <Text
                  value="Note"
                  variant="wearlay-label-bold"
                  className="create-quotation-text-color"
                ></Text>
                <Text
                  value={
                    data?.data?.follow_up[0]?.notes
                      ? data?.data?.follow_up[0]?.notes
                      : "-"
                  }
                  variant="wearlay-paragraph"
                ></Text>
              </div>
              <Divider />
              <div className="flex flex-col gap-2">
                <Text
                  value="Next Follow up Date"
                  variant="wearlay-label-bold"
                  className="create-quotation-text-color"
                ></Text>
                <Text
                  value={moment(
                    data?.data?.follow_up[0]?.next_follow_up_date
                  ).format("DD-MM-YYYY")}
                  variant="wearlay-paragraph-bold"
                ></Text>
              </div>
            </div>
          )}
          {/* //2 */}
          {/* <div className="create-quotation-border"> */}

          <div className="flex justify-between  items-center">
            <div>
              <Text
                value=" Follow Up 2"
                variant="wearlay-subtitle"
                className="create-quotation-margin-bottom create-quotation-margin-top "
              />
            </div>
            <div>
              {data?.data?.follow_up.length === 1 && isAdd && (
                <CommonButton
                  value="Add Note"
                  variant="wealay-secondary-btn"
                  onClick={() => {
                    setIsAdd(false);
                    setIsSave(true);
                  }}
                />
              )}
              {data?.data?.follow_up.length === 1 && isSave && (
                <CommonButton
                  value="Save"
                  variant="wealay-primary-btn"
                  onClick={() => {
                    // setIsSave(false);

                    formik.handleSubmit();
                  }}
                  isLoading={editNpdLoading}
                />
              )}
            </div>
          </div>
          {data?.data?.follow_up.length === 1 && isSave && (
            <Form layout="vertical">
              <Form.Item
                label="Date"
                required
                name="noteDate"
                hasFeedback
                validateStatus={
                  formik.errors.date && formik.touched.date
                    ? "error"
                    : undefined
                }
                help={
                  formik.errors.date &&
                  formik.touched.date &&
                  formik.errors.date
                }
              >
                <DatePicker
                  className="w-full"
                  placeholder="Select Date"
                  onBlur={formik.handleBlur}
                  onChange={(value) =>
                    formik.setFieldValue(
                      "date",
                      value ? value.format("YYYY-MM-DD") : ""
                    )
                  }
                  value={dayjs(formik.values.date)}
                />
              </Form.Item>
              <Form.Item
                label="Note"
                required
                name="note"
                hasFeedback
                validateStatus={
                  formik.errors.note && formik.touched.note ? "error" : ""
                }
                help={
                  formik.errors.note && formik.touched.note
                    ? formik.errors.note
                    : undefined
                }
              >
                <TextArea
                  placeholder="Note"
                  value={formik.values.note}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
              <Form.Item
                label="Next Follow up Date*"
                required
                name="next_follow_up_date"
                hasFeedback
                validateStatus={
                  formik.errors.next_follow_up_date &&
                  formik.touched.next_follow_up_date
                    ? "error"
                    : undefined
                }
                help={
                  formik.errors.next_follow_up_date &&
                  formik.touched.next_follow_up_date &&
                  formik.errors.next_follow_up_date
                }
              >
                <DatePicker
                  className="w-full"
                  placeholder="Select Date"
                  onBlur={formik.handleBlur}
                  onChange={(value) =>
                    formik.setFieldValue(
                      "next_follow_up_date",
                      value ? value.format("YYYY-MM-DD") : ""
                    )
                  }
                  value={dayjs(formik.values.next_follow_up_date)}
                  disabledDate={(current) =>
                    current && current < moment().endOf("day")
                  }
                />
              </Form.Item>
            </Form>
          )}
          {data?.data?.follow_up[1] && (
            <div className="create-quotation-border">
              <div className="flex flex-col gap-2">
                <Text
                  value="Date"
                  variant="wearlay-label-bold"
                  className="create-quotation-text-color"
                ></Text>
                <Text
                  value={moment(data?.data?.follow_up[1]?.date).format(
                    "DD-MM-YYYY"
                  )}
                  variant="wearlay-paragraph-bold"
                ></Text>
              </div>
              <Divider />
              <div className="flex flex-col gap-2">
                <Text
                  value="Note"
                  variant="wearlay-label-bold"
                  className="create-quotation-text-color"
                ></Text>
                <Text
                  value={
                    data?.data?.follow_up[1]?.notes
                      ? data?.data?.follow_up[1]?.notes
                      : "-"
                  }
                  variant="wearlay-paragraph"
                ></Text>
              </div>
              <Divider />
              <div className="flex flex-col gap-2">
                <Text
                  value="Next Follow up Date"
                  variant="wearlay-label-bold"
                  className="create-quotation-text-color"
                ></Text>
                <Text
                  value={moment(
                    data?.data?.follow_up[1]?.next_follow_up_date
                  ).format("DD-MM-YYYY")}
                  variant="wearlay-paragraph-bold"
                ></Text>
              </div>
            </div>
          )}
          {/* //3 */}
          <div className="flex justify-between  items-center">
            <div>
              <Text
                value=" Follow Up 3"
                variant="wearlay-subtitle"
                className="create-quotation-margin-bottom create-quotation-margin-top "
              />
            </div>
            <div>
              {data?.data?.follow_up.length === 2 && isAdd && (
                <CommonButton
                  value="Add Note"
                  variant="wealay-secondary-btn"
                  onClick={() => {
                    setIsAdd(false);
                    setIsSave(true);
                  }}
                />
              )}
              {data?.data?.follow_up.length === 2 && isSave && (
                <CommonButton
                  value="Save"
                  variant="wealay-primary-btn"
                  onClick={() => {
                    // setIsSave(false);
                    formik.handleSubmit();
                  }}
                  isLoading={editNpdLoading}
                />
              )}
            </div>
          </div>
          {data?.data?.follow_up.length === 2 && isSave && (
            <Form layout="vertical">
              <Form.Item
                label="Date"
                required
                name="noteDate"
                hasFeedback
                validateStatus={
                  formik.errors.date && formik.touched.date
                    ? "error"
                    : undefined
                }
                help={
                  formik.errors.date &&
                  formik.touched.date &&
                  formik.errors.date
                }
              >
                <DatePicker
                  className="w-full"
                  placeholder="Select Date"
                  onBlur={formik.handleBlur}
                  onChange={(value) =>
                    formik.setFieldValue(
                      "date",
                      value ? value.format("YYYY-MM-DD") : ""
                    )
                  }
                  value={dayjs(formik.values.date)}
                />
              </Form.Item>
              <Form.Item
                label="Note"
                required
                name="note"
                hasFeedback
                validateStatus={
                  formik.errors.note && formik.touched.note ? "error" : ""
                }
                help={
                  formik.errors.note && formik.touched.note
                    ? formik.errors.note
                    : undefined
                }
              >
                <TextArea
                  placeholder="Note"
                  value={formik.values.note}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Form.Item>
              <Form.Item
                label="Next Follow up Date*"
                required
                name="next_follow_up_date"
                hasFeedback
                validateStatus={
                  formik.errors.next_follow_up_date &&
                  formik.touched.next_follow_up_date
                    ? "error"
                    : undefined
                }
                help={
                  formik.errors.next_follow_up_date &&
                  formik.touched.next_follow_up_date &&
                  formik.errors.next_follow_up_date
                }
              >
                <DatePicker
                  className="w-full"
                  placeholder="Select Date"
                  onBlur={formik.handleBlur}
                  onChange={(value) =>
                    formik.setFieldValue(
                      "next_follow_up_date",
                      value ? value.format("YYYY-MM-DD") : ""
                    )
                  }
                  value={dayjs(formik.values.next_follow_up_date)}
                  disabledDate={(current) =>
                    current && current < moment().endOf("day")
                  }
                />
              </Form.Item>
            </Form>
          )}
          {data?.data?.follow_up[2] && (
            <div className="create-quotation-border">
              <div className="flex flex-col gap-2">
                <Text
                  value="Date"
                  variant="wearlay-label-bold"
                  className="create-quotation-text-color"
                ></Text>
                <Text
                  value={moment(data?.data?.follow_up[2]?.date).format(
                    "DD-MM-YYYY"
                  )}
                  variant="wearlay-paragraph-bold"
                ></Text>
              </div>
              <Divider />
              <div className="flex flex-col gap-2">
                <Text
                  value="Note"
                  variant="wearlay-label-bold"
                  className="create-quotation-text-color"
                ></Text>
                <Text
                  value={
                    data?.data?.follow_up[2]?.notes
                      ? data?.data?.follow_up[2]?.notes
                      : "-"
                  }
                  variant="wearlay-paragraph"
                ></Text>
              </div>
              <Divider />
              <div className="flex flex-col gap-2">
                <Text
                  value="Next Follow up Date"
                  variant="wearlay-label-bold"
                  className="create-quotation-text-color"
                ></Text>
                <Text
                  value={moment(
                    data?.data?.follow_up[2]?.next_follow_up_date
                  ).format("DD-MM-YYYY")}
                  variant="wearlay-paragraph-bold"
                ></Text>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
