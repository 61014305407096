import { CloseOutlined } from "@ant-design/icons";
import { Form, Input } from "antd";

import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import "./index.css";

export interface IInputForm {
  placeholder: string;
  onSearch?: (e: string) => void;
  onClose?: () => void;
}

export const SearchBox: React.FC<IInputForm> = ({
  placeholder,
  onSearch,
  onClose,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState<string>(
    searchParams.get("name") as string
  );
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  return (
    <Form
      className="searchbox-style"
      name="basic"
      onFinish={() => {
        setSearchParams({ search });
      }}
    >
      <img
        src="/assets/search.svg"
        alt=""
        onClick={() => {
          setSearchParams({ search });
        }}
        className="searchbox-icon"
      ></img>

      <Input
        onChange={onInputChange}
        className={"input"}
        size="large"
        placeholder={placeholder}
        value={search}
      />
      {search && (
        <CloseOutlined
          className="cursor-pointer"
          onClick={() => {
            setSearchParams({});
            setSearch("");
            // onClose();
          }}
        />
      )}
    </Form>

    // </div>
  );
};
