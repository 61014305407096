import { Menu } from "antd";
import "../../antd.override.css";

import { useLocation, useNavigate } from "react-router";

// import "./index.css";

import { useEffect, useState } from "react";

import { PathConstants } from "../../pathConstants/pathConstants";
import { ISideBar } from "../../interface/sideBar";
import { ConstantVariable } from "../../constants/sideBarconstants";

export const Sidebar = ({ isPermission }: any) => {
  const isManager = localStorage.getItem("user_role");

  const path = useLocation().pathname;

  const navigate = useNavigate();

  //or simply use const [current, setCurrent] = useState(location.pathname)

  function handleClick(pathName: string) {
    navigate(pathName);
  }

  function selectedKeys() {
    const activeIndex = (
      isManager === "1" ? sideBarListManager : sideBarList
    )?.findIndex((item: any) => item.baseUrl.includes(path));

    if (activeIndex > -1) {
      return [`${activeIndex}`];
    } else {
      return [];
    }
  }

  const sideImage = (item: ISideBar) => {
    if (item?.path?.includes(path)) {
      return item.imgSrcActive;
    } else {
      return item.imgSrc;
    }
  };
  const sideBarList = [
    {
      name: ConstantVariable.OVERVIEW,
      baseUrl: [`${PathConstants.OVERVIEW}`, "/"],
      path: `${PathConstants.OVERVIEW}`,
      // path: `${PathConstants.DASHBOARD}?filter=day&from=${epoch(
      //   currentFromDate
      // )}&to=${epoch(currentToDate)}`,
      imgSrc: "/assets/overview.svg",
      imgSrcActive: "/assets/overview-active.svg",
    },

    {
      name: ConstantVariable.SALES,
      baseUrl: [`${PathConstants.SALES}`],
      path: `${PathConstants.SALES}`,
      // path: `${PathConstants.TICKETS}?filter=day&from=${epoch(
      //   currentFromDate
      // )}&to=${epoch(currentToDate)}`,
      imgSrc: "/assets/sales.svg",
      imgSrcActive: "/assets/sales-active.svg",
    },
    {
      name: ConstantVariable.QUOTATION,
      baseUrl: [`${PathConstants.QUOTATION}`],
      path: PathConstants.QUOTATION,
      imgSrc: "/assets/quotaion.svg",
      imgSrcActive: "/assets/quotation-active.svg",
    },
    {
      name: ConstantVariable.ORDERS,
      baseUrl: [`${PathConstants.ORDER}`],
      path: PathConstants.ORDER,
      imgSrc: "/assets/orders.svg",
      imgSrcActive: "/assets/order-active.svg",
    },
    {
      name: ConstantVariable.INQUIRIES,
      baseUrl: [`${PathConstants.INQUIRIES}`],
      path: PathConstants.INQUIRIES,
      imgSrc: "/assets/inquiry.svg",
      imgSrcActive: "/assets/inquiry-active.svg",
    },
    {
      name: ConstantVariable.ALLOCATED,
      baseUrl: [`${PathConstants.ALLOCATED}`],
      path: PathConstants.ALLOCATED,
      imgSrc: "/assets/allocated.svg",
      imgSrcActive: "/assets/allocated-active.svg",
    },
    {
      name: ConstantVariable.CONATCTS,
      baseUrl: [`${PathConstants.CONTACTS}`],
      path: PathConstants.CONTACTS,
      imgSrc: "/assets/contact.svg",
      imgSrcActive: "/assets/contact-active.svg",
    },
  ];
  const sideBarListManager = [
    {
      name: ConstantVariable.OVERVIEW,
      baseUrl: [`${PathConstants.OVERVIEW}`, "/"],
      path: `${PathConstants.OVERVIEW}`,
      // path: `${PathConstants.DASHBOARD}?filter=day&from=${epoch(
      //   currentFromDate
      // )}&to=${epoch(currentToDate)}`,
      imgSrc: "/assets/overview.svg",
      imgSrcActive: "/assets/overview-active.svg",
    },
    {
      name: ConstantVariable.EMPLOYEE,
      baseUrl: [`${PathConstants.EMPLOYEE}`],
      path: PathConstants.EMPLOYEE,
      imgSrc: "/assets/employee.svg",
      imgSrcActive: "/assets/employee-active.svg",
    },
    {
      name: ConstantVariable.SALES,
      baseUrl: [
        `${PathConstants.SALES}`,
        `${PathConstants.SALES}/ticket-details`,
      ],
      path: `${PathConstants.SALES}`,
      // path: `${PathConstants.TICKETS}?filter=day&from=${epoch(
      //   currentFromDate
      // )}&to=${epoch(currentToDate)}`,
      imgSrc: "/assets/sales.svg",
      imgSrcActive: "/assets/sales-active.svg",
    },
    {
      name: ConstantVariable.QUOTATION,
      baseUrl: [`${PathConstants.QUOTATION}`],
      path: PathConstants.QUOTATION,
      imgSrc: "/assets/quotaion.svg",
      imgSrcActive: "/assets/quotation-active.svg",
    },
    {
      name: ConstantVariable.ORDERS,
      baseUrl: [`${PathConstants.ORDER}`],
      path: PathConstants.ORDER,
      imgSrc: "/assets/orders.svg",
      imgSrcActive: "/assets/order-active.svg",
    },
    {
      name: ConstantVariable.INQUIRIES,
      baseUrl: [`${PathConstants.INQUIRIES}`],
      path: PathConstants.INQUIRIES,
      imgSrc: "/assets/inquiry.svg",
      imgSrcActive: "/assets/inquiry-active.svg",
    },
    {
      name: ConstantVariable.ALLOCATED,
      baseUrl: [`${PathConstants.ALLOCATED}`],
      path: PathConstants.ALLOCATED,
      imgSrc: "/assets/allocated.svg",
      imgSrcActive: "/assets/allocated-active.svg",
    },
    {
      name: ConstantVariable.CONATCTS,
      baseUrl: [`${PathConstants.CONTACTS}`],
      path: PathConstants.CONTACTS,
      imgSrc: "/assets/contact.svg",
      imgSrcActive: "/assets/contact-active.svg",
    },
  ];
  useEffect(() => {
    selectedKeys();
  }, [path]);

  let location = useLocation();
  const [current, setCurrent] = useState(location.pathname);

  useEffect(() => {
    if (location) {
      if (current !== location.pathname) {
        setCurrent(location.pathname);
      }
    }
  }, [location, current]);
  return (
    <>
      <div className="sidebar">
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={selectedKeys()}
          //   onClick={handleMenuClick}
          selectedKeys={selectedKeys()}
        >
          {(isManager === "1" ? sideBarListManager : sideBarList).map(
            (item: any, index) => {
              return (
                <Menu.Item
                  key={index}
                  icon={<img src={sideImage(item)} alt="tem" />}
                  onClick={() => handleClick(item.path)}
                  className="menu-item"
                >
                  {item.name}
                </Menu.Item>
              );
            }
          )}
        </Menu>
      </div>
    </>
  );
};
export default Sidebar;
