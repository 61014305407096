import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Upload,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { CommonButton } from "../../components/CommonButton/button";
import "./index.css";
import { Text } from "../../components/CommonFont/text";
import {
  useEditUserMutation,
  useGetUserDetailByIdQuery,
  useUploadFileMutation,
} from "../../services/api";
import * as Yup from "yup";
import { Formik, FormikErrors, FormikValues, useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

export const Profile = () => {
  const [imgUrl, setImgUrl] = useState<any>();
  const [fileTypeError, setFileTypeError] = useState(false);
  const userId = localStorage.getItem("user_id");
  const { data, isLoading } = useGetUserDetailByIdQuery(userId);
  const id = data?.data[0]?.code;
  const first_name = data?.data[0]?.first_name;
  const last_name = data?.data[0]?.last_name;
  const email = data?.data[0]?.email;
  const phone = data?.data[0]?.phone;
  const reporting_manager_name = data?.data[0]?.reporting_manager_name;
  const full_name = data?.data[0]?.full_name;
  const [form] = Form.useForm();
  const navigate = useNavigate();

  // const onchange = async (file: any) => {
  //   if (file) {
  //     const extension = file?.name?.split(".").pop()?.toLowerCase();

  //     const allowedExtensions = ["jpg", "jpeg", "png", "gif"];

  //     if (!allowedExtensions.includes(extension)) {
  //       setFileTypeError(true);
  //       setImgUrl("");
  //       return;
  //     }

  //     setImgUrl(file.file);
  //     postImage((file = { file }));
  //   } else {
  //     setImgUrl("");
  //   }
  // };
  const onchange = async (file: any) => {
    if (file.file) {
      const extension = file.file.name.split(".").pop()?.toLowerCase();
      const allowedExtensions = ["jpg", "jpeg", "png", "gif"];

      if (!allowedExtensions.includes(extension)) {
        setFileTypeError(true);
        setImgUrl("");
        message.error("Please select a valid image format");
        return;
      }

      setFileTypeError(false);
      setImgUrl(file.file);
      postImage((file = { file }));
    } else {
      setFileTypeError(false);
      setImgUrl("");
    }
  };

  const nameSchema = Yup.string().matches(/^[A-Za-z\s]+$/, {
    message: "Please enter a valid name with only letters and spaces.",
  });

  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .matches(/^[A-Za-z\s]+$/, {
        message: "only accept letters and spaces",
      })
      .required("First name is required"),
    last_name: Yup.string()
      .matches(/^[A-Za-z\s]+$/, {
        message: "only accept letters and spaces",
      })
      .required("Last name is required"),
    gender: Yup.string().required("This is required"),
    phone: Yup.string()
      .matches(/^[0-9]{10}$/, "Invalid phone number")
      .required("Phone number is required"),
  });

  const [editUser, { data: editData, isLoading: editLoading }] =
    useEditUserMutation();
  const [uploadFile, { data: uploadData, isLoading: uploadLoading }] =
    useUploadFileMutation();

  const formData = new FormData();

  const postImage = async (file: any) => {
    if (file) {
      formData.append("file", file.file.file);
    }
    await uploadFile(formData)
      .unwrap()
      .then((data) => {
        message.success("Uploaded successfully");
      })
      .catch((err) => {
        message.error(err?.data?.message);
      });
  };

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      gender: "",
      phone: "",
    },
    validationSchema: validationSchema,
    // onSubmit: (values) => {

    //   console.log(values);
    // },
    onSubmit: async (values) => {
      if (fileTypeError) {
        return;
      }
      const paramsWithoutImage = {
        body: values,
        id: userId,
      };
      const editParams = {
        body: {
          ...values,
          image_url: uploadData?.data?.image_url,
          image_name: uploadData?.data?.image_name,
        },
        id: userId,
      };
      await editUser(
        uploadData?.data?.image_url ? editParams : paramsWithoutImage
      )
        .unwrap()
        .then((data) => {
          // navigate("/login");
          navigate("/overview");

          message.success(data?.message);
        })

        .catch((err) => {
          message.error(err?.data?.message);
        });
      // localStorage.setItem("token", "sdsdsdsd");
      // localStorage.setItem("user", "manager");
      // Handle form submission
    },
  });

  useEffect(() => {
    if (data) {
      formik.setFieldValue("first_name", data?.data[0]?.first_name);
      formik.setFieldValue("last_name", data?.data[0]?.last_name);
      formik.setFieldValue("gender", data?.data[0]?.gender);
      formik.setFieldValue("phone", data?.data[0]?.phone);
    }
  }, [data]);

  return (
    <>
      <div className="profile-over-div ">
        <Form layout="vertical" onFinish={formik.handleSubmit}>
          <div className="profile-main-div">
            <div className="flex gap-2">
              <ArrowLeftOutlined
                onClick={() => {
                  navigate("/");
                }}
                className="profile-back"
              />
              <Text value="User Profile" variant="wearlay-headline-1" />
            </div>
            <Row justify={"center"}>
              <div className="profile-edit profile-img">
                {uploadLoading ? (
                  <div className="profile-loading">
                    <Spin size="large" />
                  </div>
                ) : (
                  <img
                    src={
                      uploadData?.data?.image_url
                        ? uploadData?.data?.image_url
                        : data?.data[0]?.image_url
                        ? data?.data[0]?.image_url
                        : "/assets/profile.svg"
                    }
                    className="profile-img"
                  ></img>
                )}
                <Upload
                  beforeUpload={() => {
                    return false;
                  }}
                  onChange={onchange}
                  showUploadList={false}
                  accept="image/*"
                >
                  <div className="profile-edit-2 ">
                    <img src="/assets/edit.svg"></img>
                  </div>
                </Upload>
              </div>
            </Row>
            <Row gutter={[16, 16]} className="mt-24p">
              <Col span={24} md={{ span: 12 }}>
                <Form.Item
                  label="First Name"
                  //name="full_name"
                  className="w-full selected-item"
                  required
                  hasFeedback
                  validateStatus={
                    formik.errors.first_name && formik.touched.first_name
                      ? "error"
                      : ""
                  }
                  help={
                    formik.errors.first_name && formik.touched.first_name
                      ? formik.errors.first_name
                      : ""
                  }
                >
                  <Input
                    name={"first_name"}
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Form.Item>
              </Col>
              <Col span={24} md={{ span: 12 }}>
                <Form.Item
                  label="Last Name"
                  //name="full_name"
                  className="w-full selected-item"
                  required
                  hasFeedback
                  validateStatus={
                    formik.errors.last_name && formik.touched.last_name
                      ? "error"
                      : ""
                  }
                  help={
                    formik.errors.last_name && formik.touched.last_name
                      ? formik.errors.last_name
                      : ""
                  }
                >
                  <Input
                    name={"last_name"}
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24} md={{ span: 12 }}>
                <Form.Item label="EMP ID" required>
                  <Input disabled value={id} />
                </Form.Item>
                <Form.Item label="Email" required>
                  <Input disabled value={email} />
                </Form.Item>
                <Form.Item label="Reporting Manager" required>
                  <Input disabled value={reporting_manager_name} />
                </Form.Item>
              </Col>
              <Col span={24} md={{ span: 12 }}>
                <Form.Item
                  label="Gender"
                  required
                  className="selected-item"
                  hasFeedback
                  validateStatus={
                    formik.errors.gender && formik.touched.gender
                      ? "error"
                      : undefined
                  }
                  help={
                    formik.errors.gender &&
                    formik.touched.gender &&
                    formik.errors.gender
                  }
                >
                  <Select
                    value={formik.values.gender}
                    onChange={(value: string) =>
                      formik.setFieldValue("gender", value)
                    }
                    onBlur={formik.handleBlur}
                    options={[
                      { value: "M", label: "Male" },
                      { value: "F", label: "Female" },
                      { value: "O", label: "Other" },
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  label="Phone No"
                  required
                  className="selected-item"
                  hasFeedback
                  validateStatus={
                    formik.errors.phone && formik.touched.phone ? "error" : ""
                  }
                  help={
                    formik.errors.phone && formik.touched.phone
                      ? formik.errors.phone
                      : ""
                  }
                >
                  <Input
                    name={"phone"}
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <CommonButton
                variant="wealay-primary-btn"
                value="Save"
                className="w-full profile-save-btn"
                isLoading={editLoading}
                // disabled={!formik.isValid || !uploadData?.data?.image_url}
              />
            </Row>
          </div>
        </Form>
      </div>
    </>
  );
};
