import { Button, Card, Pagination, Row, Skeleton, Space, Table } from "antd";
import { saveAs } from "file-saver";

import { useEffect, useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { boolean } from "yargs";
import { useGetDownloadDataQuery } from "../../services/api";
import "./index.css";
export interface ITableData {
  datasource: any;
  coloumn: any;
  isLoading?: boolean;
  setDetailsOpen?: (data: boolean) => void;
  setId?: (data: any) => void;
  isId?: any;
  isDetail?: boolean;
  isDeactivate?: boolean;
  page?: number;
  totalElements?: number;
  refetch?: () => void;
  setDownloadIds?: (data: any) => void;
}

export const CommonTable: React.FC<ITableData> = ({
  isLoading,
  datasource,
  coloumn,
  setDetailsOpen,
  setId,
  isDetail,
  isDeactivate,
  isId,
  totalElements,
  refetch,
  setDownloadIds,
}) => {
  useEffect(() => {
    console.log();
  }, [isId]);
  const [pageData, setPageData] = useState<{
    page: number;
    pageSize: number;
  }>({
    page: 0,
    pageSize: 10,
  });
  const onPaginationChange = (page: number, pageSize: number) => {
    setPageData({ page, pageSize });
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = location.pathname;
  useEffect(() => {
    refetch && refetch();
  }, [pageData]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    // const selectedId = selectedRows.length > 0 ? selectedRows[0].id : null;
    // console.log("Selected ID: ", selectedId);
    setSelectedRowKeys(newSelectedRowKeys);
    setDownloadIds && setDownloadIds(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const userId = localStorage.getItem("user_id");

  return (
    <div className="main-div-2">
      <Table
        dataSource={datasource}
        columns={coloumn}
        loading={isLoading}
        pagination={false}
        onRow={(record, rowIndex) => ({
          onClick: () => {
            setDetailsOpen && setDetailsOpen(true);
            setId && setId(record.id);

            // setSearchParams({ ["id"]: record.id });
            currentUrl === "/quotation"
              ? navigate(
                  `${currentUrl}/details?id=${record.id}&created_by=${record.lead}`
                )
              : currentUrl === "/order"
              ? navigate(
                  `${currentUrl}/details?id=${record.id}&created_by=${record.lead}`
                )
              : setSearchParams({ ["id"]: record.id });
          },
        })}
        locale={{
          emptyText: isLoading && (
            <Skeleton
              active={true}
              paragraph={{ rows: 7, width: "100%" }}
              title={{ width: "100%" }}
            />
          ),
        }}
        // scroll={{ y: 400 }}
        rowClassName="table-row"
        rowSelection={rowSelection}
        rowKey="id"
      />
      {/* <Row justify={"end"} className="pagination-row">
          <Pagination
            current={pageData.page}
            onChange={onPaginationChange}
            total={totalElements}
            pageSize={5}
          />
        </Row> */}
    </div>
  );
};
