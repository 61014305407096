import {
  Avatar,
  Button,
  Col,
  Row,
  Space,
  Upload,
  Form,
  DatePicker,
  Input,
  Divider,
  message,
  Skeleton,
  Empty,
} from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./index.css";
import ImgCrop from "antd-img-crop";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { useState } from "react";
import { CommonAvatar } from "../../CommonAvatar";
import { Text } from "../../CommonFont/text";
import { CommonButton } from "../../CommonButton/button";
import {
  useEditLeadMutation,
  useGetLeadByIdQuery,
} from "../../../services/api";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
interface ICreateSales {
  setOpen?: (data: boolean) => void;
  setIsEdit?: (data: boolean) => void;
  datas?: any;
  isEdit?: boolean;
  id?: string;
}
export const AllocatedPreview: React.FC<ICreateSales> = ({
  setOpen,
  datas,
  isEdit,
  setIsEdit,
  id,
}) => {
  const navigate = useNavigate();
  const [isAdd, setIsAdd] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isSave, setIsSave] = useState(false);
  const userRole = localStorage.getItem("user_role");

  const sales_id = searchParams.get("id");
  const { data: salesData, isLoading: salesLoading } =
    useGetLeadByIdQuery(sales_id);

  const validationSchema = Yup.object().shape({
    date: Yup.date().required("This date is required"),
    note: Yup.string().required("This note is required"),
    next_follow_up_date: Yup.date().required("This date is required"),
  });
  const [editLead, { data: editLeads, isLoading: editLeadLoading }] =
    useEditLeadMutation();
  const [form] = Form.useForm();

  const formik = useFormik({
    initialValues: {
      date: undefined,
      note: "",
      next_follow_up_date: undefined,
    },
    validationSchema,
    onSubmit: async (values) => {
      const followUp =
        salesData?.data?.follow_up?.length > 0
          ? [...salesData?.data?.follow_up]
          : [];
      const nextFollowUp = {
        date: values.date,
        notes: values.note,
        next_follow_up_date: values.next_follow_up_date,
        title:
          salesData?.data?.follow_up?.length === 0
            ? 1
            : salesData?.data?.follow_up?.length === 1
            ? 2
            : salesData?.data?.follow_up?.length === 2
            ? 3
            : "",
      };

      if (followUp.length === 0) {
        followUp.push(nextFollowUp);
      } else if (followUp.length === 1) {
        followUp.push(nextFollowUp);
      } else if (followUp.length === 2) {
        followUp.push(nextFollowUp);
      }

      const editParams = {
        body: { follow_up: followUp },
        id: sales_id,
      };
      await editLead(editParams)
        .unwrap()
        .then((data) => {
          setIsSave(false);
          setIsAdd(true);
          setOpen && setOpen(false);

          form.resetFields();
          setOpen && setOpen(false);
          message.success(data?.message);
        })

        .catch((err) => {
          message.error(err?.data?.message);
        });
    },
  });

  const handleSubmit = (value: any) => {
    localStorage.setItem("token", "sdsdsdsd");
  };

  return (
    <>
      {salesLoading ? (
        <Skeleton avatar paragraph={{ rows: 8 }} />
      ) : salesData ? (
        <div>
          <div className="sales-detail-border-main ">
            <Row gutter={16} className="p5">
              <Col span={6}>
                <CommonAvatar text={salesData?.data?.company_name} size={100} />
              </Col>
              <Col span={18}>
                <Space direction="vertical">
                  {/* <div className="sales-detail-title-2">Chema</div> */}
                  <Text
                    value={salesData?.data?.company_name}
                    variant="wearlay-headline-2"
                  />
                  <div className="flex gap-2 ">
                    <img src="/assets/message.svg"></img>
                    {/* <div>roshan@chema.com</div> */}
                    <Text
                      value={salesData?.data?.email}
                      variant="wearlay-paragraph"
                    />
                  </div>
                  <div className="flex gap-2">
                    <img src="/assets/phone.svg"></img>
                    {/* <div>098 25 314786</div> */}
                    <Text
                      value={salesData?.data?.phone}
                      variant="wearlay-paragraph"
                    />
                  </div>
                  <div className="flex gap-2">
                    <img
                      src="/assets/locattion.svg"
                      className="img-align-self"
                    ></img>
                    {/* <div>
                    City Light Road, Beside Shree Maheshwari Bhawan,
                    Athwalinesrn, Surat, Gujarat - 335009, India
                  </div> */}
                    {/* <Text
                      value={salesData?.data?.address}
                      variant="wearlay-paragraph"
                    /> */}
                    <div className="break-all">{salesData?.data?.address}</div>
                  </div>
                  {/* <Button
                  onClick={() => {
                    navigate("/create-quotation");
                  }}
                  className="sales-detail-btn-clr "
                >
                  Create Quotations
                </Button> */}
                  {userRole !== "1" && (
                    <CommonButton
                      variant="wealay-primary-btn"
                      className="sales-detail-btn-clr "
                      value=" Create Quotations"
                      onClick={() => {
                        navigate(`/create-quotation?id=${sales_id}`);
                      }}
                    />
                  )}
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className="sales-detail-border flex flex-col gap-2">
                  {/* <div className="sales-detail-placeholder">Client Name</div> */}
                  <Text
                    value="Lead Name"
                    variant="wearlay-label-bold"
                    className="sales-detail-placeholder"
                  />
                  <div className="sales-detail-sub-title ">
                    {salesData?.data?.lead_name}
                  </div>
                </div>
              </Col>
              <Col span={24} md={{ span: 12 }}>
                <div className="sales-detail-border flex flex-col gap-2">
                  {/* <div className="sales-detail-placeholder">Client Name</div> */}
                  <Text
                    value="Code"
                    variant="wearlay-label-bold"
                    className="sales-detail-placeholder"
                  />
                  <div className="sales-detail-sub-title ">
                    {salesData?.data?.sales_lead_id}
                  </div>
                </div>
                <div className="sales-detail-border flex flex-col gap-2">
                  {/* <div className="sales-detail-placeholder">Client Name</div> */}
                  <Text
                    value="Client Name"
                    variant="wearlay-label-bold"
                    className="sales-detail-placeholder"
                  />
                  <div className="sales-detail-sub-title ">
                    {salesData?.data?.client_name}
                  </div>
                </div>
                <div className="sales-detail-border flex flex-col gap-2">
                  {/* <div className="sales-detail-placeholder">Region</div> */}
                  <Text
                    value="Region"
                    variant="wearlay-label-bold"
                    className="sales-detail-placeholder"
                  />
                  <div className="sales-detail-sub-title ">
                    {salesData?.data?.region_name}
                  </div>
                </div>
                <div className="sales-detail-border flex flex-col gap-2">
                  {/* <div className="sales-detail-placeholder">Source</div> */}
                  <Text
                    value="Source"
                    variant="wearlay-label-bold"
                    className="sales-detail-placeholder"
                  />
                  <div className="sales-detail-sub-title ">
                    {salesData?.data?.source_name}
                  </div>
                </div>
                <div className="sales-detail-border flex flex-col gap-2">
                  {/* <div className="sales-detail-placeholder">Industry</div> */}
                  <Text
                    value="Industry"
                    variant="wearlay-label-bold"
                    className="sales-detail-placeholder"
                  />
                  <div className="sales-detail-sub-title ">
                    {salesData?.data?.industry_name}
                  </div>
                </div>
                {/* <div className="sales-detail-border  flex flex-col gap-2"> */}
                {/* <div className="sales-detail-placeholder">Nature</div> */}
                {/* <Text
                    value="Nature"
                    variant="wearlay-label-bold"
                    className="sales-detail-placeholder"
                  />
                  <div className="sales-detail-sub-title ">
                    {salesData?.data?.nature_name}
                  </div>
                </div> */}
              </Col>
              <Col span={24} md={{ span: 12 }}>
                <div className="sales-detail-border flex flex-col gap-2">
                  {/* <div className="sales-detail-placeholder">Designation</div> */}
                  <Text
                    value="Designation"
                    variant="wearlay-label-bold"
                    className="sales-detail-placeholder"
                  />
                  <div className="sales-detail-sub-title ">
                    {salesData?.data?.designation_name}
                  </div>
                </div>
                <div className="sales-detail-border flex flex-col gap-2">
                  {/* <div className="sales-detail-placeholder">GST</div> */}
                  <Text
                    value="GST"
                    variant="wearlay-label-bold"
                    className="sales-detail-placeholder"
                  />
                  <div className="sales-detail-sub-title ">
                    {salesData?.data?.gst}
                  </div>
                </div>
                <div className="sales-detail-border flex flex-col gap-2">
                  {/* <div className="sales-detail-placeholder">Type</div> */}
                  <Text
                    value="Type"
                    variant="wearlay-label-bold"
                    className="sales-detail-placeholder"
                  />
                  <div className="sales-detail-sub-title ">
                    {salesData?.data?.type_name}
                  </div>
                </div>
                <div className="sales-detail-border flex flex-col gap-2">
                  {/* <div className="sales-detail-placeholder">Enquiry Category</div> */}
                  <Text
                    value="Enquiry Category"
                    variant="wearlay-label-bold"
                    className="sales-detail-placeholder"
                  />
                  <div className="sales-detail-sub-title ">
                    {salesData?.data?.enquiry_category_name}
                  </div>
                </div>
                <div className="sales-detail-border flex flex-col gap-2">
                  {/* <div className="sales-detail-placeholder">Allocated By</div> */}
                  <Text
                    value={userRole === "1" ? "Allocated To" : "Allocated By"}
                    variant="wearlay-label-bold"
                    className="sales-detail-placeholder"
                  />
                  <div className="sales-detail-sub-title ">
                    {userRole === "1"
                      ? salesData?.data?.allocated_to_name
                      : salesData?.data?.allocated_by_name}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          {/* <div className="sales-detail-title">Follow Up Status</div> */}
          <Text
            value="Follow up Status"
            variant="wearlay-headline-2"
            className="sales-detail-title"
          />
          <Space direction="vertical" className="w-full">
            <Form layout="vertical">
              <div className=" sales-detail-border">
                <div className="flex justify-between">
                  <div>
                    <Text
                      value=" Follow Up 1"
                      variant="wearlay-subtitle"
                      className="sales-follow-up"
                    />
                  </div>

                  <div>
                    {salesData?.data?.follow_up?.length === 0 &&
                      isAdd &&
                      userRole !== "1" && (
                        <CommonButton
                          value="Add Note"
                          variant="wealay-secondary-btn"
                          onClick={() => {
                            setIsAdd(false);
                            setIsSave(true);
                          }}
                        />
                      )}
                    {!salesData?.data?.follow_up[0] && isSave && (
                      <CommonButton
                        value="Save"
                        variant="wealay-primary-btn"
                        onClick={() => {
                          formik.handleSubmit();
                        }}
                        isLoading={editLeadLoading}
                      />
                    )}
                  </div>
                </div>
                <Divider />
                {!salesData?.data?.follow_up[0] && isSave && (
                  <Form layout="vertical">
                    <Form.Item
                      label="Date"
                      required
                      name="noteDate"
                      className="w-full"
                      hasFeedback
                      validateStatus={
                        formik.errors.date && formik.touched.date
                          ? "error"
                          : undefined
                      }
                      help={
                        formik.errors.date &&
                        formik.touched.date &&
                        formik.errors.date
                      }
                    >
                      <DatePicker
                        className="w-full"
                        placeholder="Select Date"
                        format={"DD-MM-YYYY"}
                        onBlur={formik.handleBlur}
                        onChange={(value) =>
                          formik.setFieldValue(
                            "date",
                            value ? value.format("YYYY-MM-DD") : ""
                          )
                        }
                        value={dayjs(formik.values.date)}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Note"
                      required
                      name="note"
                      hasFeedback
                      validateStatus={
                        formik.errors.note && formik.touched.note ? "error" : ""
                      }
                      help={
                        formik.errors.note && formik.touched.note
                          ? formik.errors.note
                          : undefined
                      }
                    >
                      <TextArea
                        placeholder="Note"
                        value={formik.values.note}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Next Follow up Date*"
                      required
                      name="next_follow_up_date"
                      hasFeedback
                      validateStatus={
                        formik.errors.next_follow_up_date &&
                        formik.touched.next_follow_up_date
                          ? "error"
                          : undefined
                      }
                      help={
                        formik.errors.next_follow_up_date &&
                        formik.touched.next_follow_up_date &&
                        formik.errors.next_follow_up_date
                      }
                    >
                      <DatePicker
                        className="w-full"
                        placeholder="Select Date"
                        onBlur={formik.handleBlur}
                        onChange={(value) =>
                          formik.setFieldValue(
                            "next_follow_up_date",
                            value ? value.format("YYYY-MM-DD") : ""
                          )
                        }
                        value={dayjs(formik.values.next_follow_up_date)}
                        format="DD-MM-YYYY"
                        disabledDate={(current) =>
                          current && current < moment().endOf("day")
                        }
                      />
                    </Form.Item>
                  </Form>
                )}
                {salesData?.data?.follow_up[0] && (
                  <div className="create-quotation-border">
                    <div className="flex flex-col gap-2">
                      <Text
                        value="Date"
                        variant="wearlay-label-bold"
                        className="create-quotation-text-color"
                      ></Text>
                      <Text
                        value={moment(
                          salesData?.data?.follow_up[0]?.date
                        ).format("DD-MM-YYYY")}
                        variant="wearlay-paragraph-bold"
                      ></Text>
                    </div>
                    <Divider />
                    <div className="flex flex-col gap-2">
                      <Text
                        value="Note"
                        variant="wearlay-label-bold"
                        className="create-quotation-text-color"
                      ></Text>
                      <Text
                        value={
                          salesData?.data?.follow_up[0]?.notes
                            ? salesData?.data?.follow_up[0]?.notes
                            : "-"
                        }
                        variant="wearlay-paragraph"
                      ></Text>
                    </div>
                    <Divider />
                    <div className="flex flex-col gap-2">
                      <Text
                        value="Next Follow up Date"
                        variant="wearlay-label-bold"
                        className="create-quotation-text-color"
                      ></Text>
                      <Text
                        value={moment(
                          salesData?.data?.follow_up[0]?.next_follow_up_date
                        ).format("DD-MM-YYYY")}
                        variant="wearlay-paragraph-bold"
                      ></Text>
                    </div>
                  </div>
                )}
              </div>
            </Form>

            <div className="flex justify-between  items-center">
              <div>
                <Text
                  value=" Follow Up 2"
                  variant="wearlay-subtitle"
                  className="create-quotation-margin-bottom create-quotation-margin-top "
                />
              </div>
              <div>
                {salesData?.data?.follow_up.length === 1 &&
                  isAdd &&
                  userRole !== "1" && (
                    <CommonButton
                      value="Add Note"
                      variant="wealay-secondary-btn"
                      onClick={() => {
                        setIsAdd(false);
                        setIsSave(true);
                      }}
                    />
                  )}
                {salesData?.data?.follow_up.length === 1 && isSave && (
                  <CommonButton
                    value="Save"
                    variant="wealay-primary-btn"
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                    isLoading={editLeadLoading}
                  />
                )}
              </div>
            </div>
            {salesData?.data?.follow_up.length === 1 && isSave && (
              <Form layout="vertical">
                <Form.Item
                  label="Date"
                  required
                  name="noteDate"
                  hasFeedback
                  validateStatus={
                    formik.errors.date && formik.touched.date
                      ? "error"
                      : undefined
                  }
                  help={
                    formik.errors.date &&
                    formik.touched.date &&
                    formik.errors.date
                  }
                >
                  <DatePicker
                    className="w-full"
                    placeholder="Select Date"
                    format={"DD-MM-YYYY"}
                    onBlur={formik.handleBlur}
                    onChange={(value) =>
                      formik.setFieldValue(
                        "date",
                        value ? value.format("YYYY-MM-DD") : ""
                      )
                    }
                    value={dayjs(formik.values.date)}
                  />
                </Form.Item>
                <Form.Item
                  label="Note"
                  required
                  name="note"
                  hasFeedback
                  validateStatus={
                    formik.errors.note && formik.touched.note ? "error" : ""
                  }
                  help={
                    formik.errors.note && formik.touched.note
                      ? formik.errors.note
                      : undefined
                  }
                >
                  <TextArea
                    placeholder="Note"
                    value={formik.values.note}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Form.Item>
                <Form.Item
                  label="Next Follow up Date*"
                  required
                  name="next_follow_up_date"
                  hasFeedback
                  validateStatus={
                    formik.errors.next_follow_up_date &&
                    formik.touched.next_follow_up_date
                      ? "error"
                      : undefined
                  }
                  help={
                    formik.errors.next_follow_up_date &&
                    formik.touched.next_follow_up_date &&
                    formik.errors.next_follow_up_date
                  }
                >
                  <DatePicker
                    className="w-full"
                    placeholder="Select Date"
                    onBlur={formik.handleBlur}
                    onChange={(value) =>
                      formik.setFieldValue(
                        "next_follow_up_date",
                        value ? value.format("YYYY-MM-DD") : ""
                      )
                    }
                    value={dayjs(formik.values.next_follow_up_date)}
                    format="DD-MM-YYYY"
                    disabledDate={(current) =>
                      current && current < moment().endOf("day")
                    }
                  />
                </Form.Item>
              </Form>
            )}
            {salesData?.data?.follow_up[1] && (
              <div className="create-quotation-border">
                <div className="flex flex-col gap-2">
                  <Text
                    value="Date"
                    variant="wearlay-label-bold"
                    className="create-quotation-text-color"
                  ></Text>
                  <Text
                    value={moment(salesData?.data?.follow_up[1]?.date).format(
                      "DD-MM-YYYY"
                    )}
                    variant="wearlay-paragraph-bold"
                  ></Text>
                </div>
                <Divider />
                <div className="flex flex-col gap-2">
                  <Text
                    value="Note"
                    variant="wearlay-label-bold"
                    className="create-quotation-text-color"
                  ></Text>
                  <Text
                    value={
                      salesData?.data?.follow_up[1]?.notes
                        ? salesData?.data?.follow_up[1]?.notes
                        : "-"
                    }
                    variant="wearlay-paragraph"
                  ></Text>
                </div>
                <Divider />
                <div className="flex flex-col gap-2">
                  <Text
                    value="Next Follow up Date"
                    variant="wearlay-label-bold"
                    className="create-quotation-text-color"
                  ></Text>
                  <Text
                    value={moment(
                      salesData?.data?.follow_up[1]?.next_follow_up_date
                    ).format("DD-MM-YYYY")}
                    variant="wearlay-paragraph-bold"
                  ></Text>
                </div>
              </div>
            )}
            <div className="flex justify-between  items-center">
              <div>
                <Text
                  value=" Follow Up 3"
                  variant="wearlay-subtitle"
                  className="create-quotation-margin-bottom create-quotation-margin-top "
                />
              </div>
              <div>
                {salesData?.data?.follow_up.length === 2 &&
                  isAdd &&
                  userRole !== "1" && (
                    <CommonButton
                      value="Add Note"
                      variant="wealay-secondary-btn"
                      onClick={() => {
                        setIsAdd(false);
                        setIsSave(true);
                      }}
                    />
                  )}
                {salesData?.data?.follow_up.length === 2 && isSave && (
                  <CommonButton
                    value="Save"
                    variant="wealay-primary-btn"
                    onClick={() => {
                      // setIsSave(false);
                      formik.handleSubmit();
                    }}
                    isLoading={editLeadLoading}
                  />
                )}
              </div>
            </div>
            {salesData?.data?.follow_up.length === 2 && isSave && (
              <Form layout="vertical">
                <Form.Item
                  label="Date"
                  required
                  name="noteDate"
                  hasFeedback
                  validateStatus={
                    formik.errors.date && formik.touched.date
                      ? "error"
                      : undefined
                  }
                  help={
                    formik.errors.date &&
                    formik.touched.date &&
                    formik.errors.date
                  }
                >
                  <DatePicker
                    className="w-full"
                    placeholder="Select Date"
                    format={"DD-MM-YYYY"}
                    onBlur={formik.handleBlur}
                    onChange={(value) =>
                      formik.setFieldValue(
                        "date",
                        value ? value.format("YYYY-MM-DD") : ""
                      )
                    }
                    value={dayjs(formik.values.date)}
                  />
                </Form.Item>
                <Form.Item
                  label="Note"
                  required
                  name="note"
                  hasFeedback
                  validateStatus={
                    formik.errors.note && formik.touched.note ? "error" : ""
                  }
                  help={
                    formik.errors.note && formik.touched.note
                      ? formik.errors.note
                      : undefined
                  }
                >
                  <TextArea
                    placeholder="Note"
                    value={formik.values.note}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Form.Item>
                <Form.Item
                  label="Next Follow up Date*"
                  required
                  name="next_follow_up_date"
                  hasFeedback
                  validateStatus={
                    formik.errors.next_follow_up_date &&
                    formik.touched.next_follow_up_date
                      ? "error"
                      : undefined
                  }
                  help={
                    formik.errors.next_follow_up_date &&
                    formik.touched.next_follow_up_date &&
                    formik.errors.next_follow_up_date
                  }
                >
                  <DatePicker
                    className="w-full"
                    placeholder="Select Date"
                    onBlur={formik.handleBlur}
                    onChange={(value) =>
                      formik.setFieldValue(
                        "next_follow_up_date",
                        value ? value.format("YYYY-MM-DD") : ""
                      )
                    }
                    value={dayjs(formik.values.next_follow_up_date)}
                    format="DD-MM-YYYY"
                    disabledDate={(current) =>
                      current && current < moment().endOf("day")
                    }
                  />
                </Form.Item>
              </Form>
            )}
            {salesData?.data?.follow_up[2] && (
              <div className="create-quotation-border">
                <div className="flex flex-col gap-2">
                  <Text
                    value="Date"
                    variant="wearlay-label-bold"
                    className="create-quotation-text-color"
                  ></Text>
                  <Text
                    value={moment(salesData?.data?.follow_up[2]?.date).format(
                      "DD-MM-YYYY"
                    )}
                    variant="wearlay-paragraph-bold"
                  ></Text>
                </div>
                <Divider />
                <div className="flex flex-col gap-2">
                  <Text
                    value="Note"
                    variant="wearlay-label-bold"
                    className="create-quotation-text-color"
                  ></Text>
                  <Text
                    value={
                      salesData?.data?.follow_up[2]?.notes
                        ? salesData?.data?.follow_up[2]?.notes
                        : "-"
                    }
                    variant="wearlay-paragraph"
                  ></Text>
                </div>
                <Divider />
                <div className="flex flex-col gap-2">
                  <Text
                    value="Next Follow up Date"
                    variant="wearlay-label-bold"
                    className="create-quotation-text-color"
                  ></Text>
                  <Text
                    value={moment(
                      salesData?.data?.follow_up[2]?.next_follow_up_date
                    ).format("DD-MM-YYYY")}
                    variant="wearlay-paragraph-bold"
                  ></Text>
                </div>
              </div>
            )}
          </Space>
        </div>
      ) : (
        <Empty className="h-full justify-center align-center flex flex-col" />
      )}
    </>
  );
};
