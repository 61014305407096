import { Divider, Empty, Skeleton, Tag, Tooltip } from "antd";
import moment from "moment";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import {
  useGetQuotationOrNpdQuery,
  useGetSalesAfterNpdQuotationQuery,
  useGetSalesAfterQuotationQuery,
} from "../../services/api";
import { Text } from "../CommonFont/text";
import "./index.css";
interface ITableForm {
  data?: any;
  headingData: any;
  setOpen: (data: boolean) => void;
  setId?: (data: any) => void;
  dataById?: any;
  loading: boolean;
}
export const TableCard: React.FC<ITableForm> = ({
  data,
  headingData,
  setOpen,
  setId,
  dataById,
  loading,
}) => {
  const navigate = useNavigate();
  const currentUrl = useLocation().pathname;
  const userRole = localStorage.getItem("user_role");

  const Description = ({ keys, item }: any) => {
    return (
      <div>
        <div>{keys === 0 && item?.company_name}</div>
        <div>{keys === 1 && item?.region_name}</div>
        <div>{keys === 2 && item?.phone}</div>
        <div>{keys === 3 && item?.email}</div>
        <div>{keys === 4 && item?.type_name}</div>
        <div>{keys === 5 && item?.industry_name}</div>
      </div>
    );
  };

  const AllocatedDescription = ({ keys, item }: any) => {
    return (
      <div>
        <div>{keys === 0 && item?.sales_lead_id}</div>
        <div>{keys === 1 && item?.company_name}</div>
        <div>{keys === 2 && item?.region_name}</div>
        <div>{keys === 3 && item?.phone}</div>
        <div>{keys === 4 && item?.email}</div>
        <div>{keys === 5 && item?.type_name}</div>
        <div>{keys === 6 && item?.industry_name}</div>
        <div>{keys === 7 && item?.enquiry_category_name}</div>
        <div>
          {keys === 8 &&
            (userRole === "1"
              ? item?.allocated_to_name
              : item?.allocated_by_name)}
        </div>

        <div>
          {keys === 9 &&
            (item?.status?.title_name === "Follow Up 1" ? (
              <Tag color="cyan">Follow up 1</Tag>
            ) : item?.status?.title_name === "Follow Up 2" ? (
              <Tag color="orange">Follow up 2</Tag>
            ) : item?.status?.title_name === "Follow Up 3" ? (
              <Tag color="purple">Follow up 3</Tag>
            ) : (
              "-"
            ))}
        </div>
        {/* <div>{keys === 9 && item?.status}</div> */}
      </div>
    );
  };
  const EmployeeDetailsDescription = ({ keys, item }: any) => {
    return (
      <div>
        <div>{keys === 0 && item?.sales_lead_id}</div>
        <div>{keys === 1 && item?.company_name}</div>
        <div>{keys === 2 && item?.region_name}</div>
        <div>{keys === 3 && item?.phone}</div>
        <div>{keys === 4 && item?.email}</div>
        <div>{keys === 5 && item?.type_name}</div>
        <div>{keys === 6 && item?.industry_name}</div>
        {/* <div>{keys === 7 && item?.enquiry_category_name}</div> */}
        {/* <div>
          {keys === 7 &&
            (userRole === "1"
              ? item?.allocated_to_name
              : item?.allocated_by_name)}
        </div> */}

        <div>
          {keys === 7 &&
            (item?.status?.title_name === "Follow Up 1" ? (
              <Tag color="cyan">Follow up 1</Tag>
            ) : item?.status?.title_name === "Follow Up 2" ? (
              <Tag color="orange">Follow up 2</Tag>
            ) : item?.status?.title_name === "Follow Up 3" ? (
              <Tag color="purple">Follow up 3</Tag>
            ) : (
              "-"
            ))}
        </div>
        {/* <div>{keys === 9 && item?.status}</div> */}
      </div>
    );
  };
  const Companyname = ({ id, npd_id }: any) => {
    const { data: quotationData, isLoading: loadingQuery } =
      useGetSalesAfterQuotationQuery(id);

    const { data: npdData, isLoading: loadingNpdQuery } =
      useGetSalesAfterNpdQuotationQuery(npd_id);
    return (
      <>
        <div>
          {quotationData?.data[0]?.company_name
            ? quotationData?.data[0]?.company_name
            : npdData?.data[0]?.company_name
            ? npdData?.data[0]?.company_name
            : "-"}
        </div>
      </>
    );
  };
  const LeadName = ({ id, npd_id }: any) => {
    const { data: quotationData, isLoading: loadingQuery } =
      useGetSalesAfterQuotationQuery(id);

    const { data: npdData, isLoading: loadingNpdQuery } =
      useGetSalesAfterNpdQuotationQuery(npd_id);
    return (
      <>
        <div>
          {" "}
          {quotationData?.data[0]?.lead_name
            ? quotationData?.data[0]?.lead_name
            : npdData?.data[0]?.lead_name
            ? npdData?.data[0]?.lead_name
            : "-"}
        </div>
      </>
    );
  };
  const EnquiryName = ({ id, npd_id }: any) => {
    const { data: quotationData, isLoading: loadingQuery } =
      useGetSalesAfterQuotationQuery(id);
    const { data: npdData, isLoading: loadingNpdQuery } =
      useGetSalesAfterNpdQuotationQuery(npd_id);
    return (
      <>
        <div>
          {" "}
          {quotationData?.data[0]?.enquiry_category_name
            ? quotationData?.data[0]?.enquiry_category_name
            : npdData?.data[0]?.enquiry_category_name
            ? npdData?.data[0]?.enquiry_category_name
            : "-"}
        </div>
      </>
    );
  };
  const Code = ({ id, npd_id }: any) => {
    const { data: quotationData, isLoading: loadingQuery } =
      useGetSalesAfterQuotationQuery(id);
    const { data: npdData, isLoading: loadingNpdQuery } =
      useGetSalesAfterNpdQuotationQuery(npd_id);
    return (
      <>
        <div>
          {" "}
          {quotationData?.data[0]?.sales_lead_id
            ? quotationData?.data[0]?.sales_lead_id
            : npdData?.data[0]?.sales_lead_id
            ? npdData?.data[0]?.sales_lead_id
            : "-"}
        </div>
      </>
    );
  };
  const OrderCompanyname = ({ id }: any) => {
    const { data: npdOrQuotationData, isLoading: npdOrQuotationDataLoading } =
      useGetQuotationOrNpdQuery(id);
    const { data: quotationData, isLoading: loadingQuery } =
      useGetSalesAfterQuotationQuery(npdOrQuotationData?.data[0]?.sales_lead);
    const { data: npdData, isLoading } = useGetSalesAfterNpdQuotationQuery(
      npdOrQuotationData?.data[0]?.npd
    );

    return (
      <>
        <div>
          {quotationData?.data[0]?.company_name
            ? quotationData?.data[0]?.company_name
            : npdData?.data[0]?.company_name}
        </div>
      </>
    );
  };
  const OrderLeadName = ({ id }: any) => {
    const { data: npdOrQuotationData, isLoading: npdOrQuotationDataLoading } =
      useGetQuotationOrNpdQuery(id);
    const { data: quotationData, isLoading: loadingQuery } =
      useGetSalesAfterQuotationQuery(npdOrQuotationData?.data[0]?.sales_lead);
    const { data: npdData, isLoading } = useGetSalesAfterNpdQuotationQuery(
      npdOrQuotationData?.data[0]?.npd
    );
    return (
      <>
        <div>
          {quotationData?.data[0]?.lead_name
            ? quotationData?.data[0]?.lead_name
            : npdData?.data[0]?.lead_name}
        </div>
      </>
    );
  };
  const OrderCode = ({ id }: any) => {
    const { data: npdOrQuotationData, isLoading: npdOrQuotationDataLoading } =
      useGetQuotationOrNpdQuery(id);
    const { data: quotationData, isLoading: loadingQuery } =
      useGetSalesAfterQuotationQuery(npdOrQuotationData?.data[0]?.sales_lead);
    const { data: npdData, isLoading } = useGetSalesAfterNpdQuotationQuery(
      npdOrQuotationData?.data[0]?.npd
    );

    return (
      <>
        <div>
          {" "}
          {quotationData?.data[0]?.sales_lead_id
            ? quotationData?.data[0]?.sales_lead_id
            : npdData?.data[0]?.code
            ? npdData?.data[0]?.code
            : "-"}
        </div>
      </>
    );
  };
  const CUINo = ({ id }: any) => {
    const { data: quotationData, isLoading: loadingQuery } =
      useGetSalesAfterQuotationQuery(id);

    // useEffect(() => {
    //   if (id) {
    //     trigger(id);
    //   }
    // }, [id]);

    return (
      <>
        <div>
          {" "}
          {quotationData?.data[0]
            ?.contacts_customer_unique_identification_number
            ? quotationData?.data[0]
                ?.contacts_customer_unique_identification_number
            : "-"}
        </div>
      </>
    );
  };
  const QuoatationDescription = ({ keys, item }: any) => {
    return (
      <div>
        <div>{keys === 0 && item?.date}</div>
        <div>{keys === 1 && <CUINo id={item?.sales_lead} />}</div>
        <div>
          {keys === 2 && <Code id={item?.sales_lead} npd_id={item?.npd} />}
        </div>
        <div>
          {keys === 3 && <LeadName id={item?.sales_lead} npd_id={item?.npd} />}
        </div>
        <div>
          {keys === 4 && (
            <Companyname id={item?.sales_lead} npd_id={item?.npd} />
          )}
        </div>
        <div>
          {keys === 5 && (
            <EnquiryName id={item?.sales_lead} npd_id={item?.npd} />
          )}
        </div>
        <div>{keys === 6 && "-"}</div>
      </div>
    );
  };
  const SalesDescription = ({ keys, item }: any) => {
    return (
      <div>
        <div>{keys === 0 && item?.sales_lead_id}</div>
        <div>
          {keys === 1 && (
            <Tooltip title={item?.lead_name}>
              {item?.lead_name?.length > 10
                ? item?.lead_name?.slice(0, 8).concat("...")
                : item?.lead_name}
            </Tooltip>
          )}
        </div>
        <div>{keys === 2 && item?.region_name} </div>
        <div>{keys === 3 && item?.phone}</div>
        <div>{keys === 4 && item?.email}</div>
        <div>{keys === 5 && item?.type_name}</div>
        <div>{keys === 6 && item?.industry_name}</div>
        <div>{keys === 7 && "-"}</div>
      </div>
    );
  };
  const NpdDescription = ({ keys, item }: any) => {
    return (
      <div>
        <div>{keys === 0 && item?.code}</div>
        <div>{keys === 1 && item?.client_name}</div>
        <div>{keys === 2 && item?.product_name} </div>
        <div>{keys === 3 && item?.specification}</div>
        <div>{keys === 4 && item?.quantity}</div>
        <div>{keys === 5 && item?.group_name}</div>
        <div>{keys === 6 && item?.phone}</div>
      </div>
    );
  };
  const OrderDescription = ({ keys, item }: any) => {
    return (
      <div>
        <div>
          {keys === 0 && moment(item?.created_date).format("DD-MM-YYYY")}
        </div>
        <div>{keys === 1 && item?.order_no}</div>
        <div>{keys === 2 && <OrderCode id={item?.quotation} />} </div>
        <div>{keys === 3 && <OrderCompanyname id={item?.quotation} />}</div>
        <div>{keys === 4 && <OrderLeadName id={item?.quotation} />}</div>
        <div>
          {keys === 5 &&
            (item?.advance_received_amount
              ? item?.advance_received_amount
              : "-")}
        </div>
        <div>
          {keys === 6 &&
            (item?.order_status === 3 ? (
              <Tag color={"green"}>Completed</Tag>
            ) : item?.order_status === 2 ? (
              <Tag color={"purple"}>Delivered</Tag>
            ) : item?.order_status === 1 ? (
              <Tag color={"magenta"}>Open</Tag>
            ) : (
              <Tag color={"lime"}>In Progress</Tag>
            ))}
        </div>
      </div>
    );
  };
  return (
    <>
      {loading ? (
        <Skeleton
          paragraph={{ rows: 8, width: "100%" }}
          className="resp-table-card "
        />
      ) : data?.length > 0 ? (
        data?.map((items: any, indexs: number) => (
          <div className="margin">
            <div
              className="resp-table-card "
              onClick={() => {
                setOpen(true);
                setId && setId(items?.id);
                navigate(`${currentUrl}?id=${items?.id}`);
              }}
            >
              {headingData?.map((item: any, index: number) => (
                <div
                  key={index}
                  className="flex justify-between w-full table-card-main-div"
                >
                  <div>
                    <Text variant="wearlay-paragraph-bold" value={item} />
                  </div>
                  <div>
                    {currentUrl === "/quotation" ? (
                      <QuoatationDescription
                        item={items}
                        key={index}
                        keys={index}
                      />
                    ) : currentUrl === "/sales" ? (
                      <SalesDescription item={items} key={index} keys={index} />
                    ) : currentUrl === "/inquiries" ? (
                      <NpdDescription item={items} key={index} keys={index} />
                    ) : currentUrl === "/order" ? (
                      <OrderDescription item={items} key={index} keys={index} />
                    ) : currentUrl === "/allocated" ? (
                      <AllocatedDescription
                        item={items}
                        key={index}
                        keys={index}
                      />
                    ) : currentUrl === "/employee-detail-page" ? (
                      <EmployeeDetailsDescription
                        item={items}
                        key={index}
                        keys={index}
                      />
                    ) : (
                      <Description item={items} key={index} keys={index} />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))
      ) : (
        <div className="no-data-card resp-table-card">
          <Empty />
        </div>
      )}
    </>
  );
};
