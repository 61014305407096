import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import {
  Button,
  Drawer,
  message,
  Pagination,
  PaginationProps,
  Row,
  Space,
} from "antd";
import { useEffect, useState } from "react";
import { CommonButton } from "../../components/CommonButton/button";
import { Text } from "../../components/CommonFont/text";
import "./index.css";
import { TableCard } from "../../components/CommonTableCard";
import _ from "lodash";
import { saveAs } from "file-saver";

import { SearchBox } from "../../components/CommonSearchBox";
import { CommonTable } from "../../components/commonTable";
import { AddNewInquiry } from "../../components/Drawer/AddNewEnquiry";
import { InquiryPreview } from "../../components/Drawer/InquiryPreview";
import {
  useGetNpdByIdQuery,
  useGetNpdDownloadQuery,
  useGetNpdQuery,
} from "../../services/api";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { getContactsParams } from "../../helpers/quarery";
import NpdFilter from "../../components/CommonFilter/npdFilter";

export const Inquiries = () => {
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState([]);

  const [isPreview, setIsPreview] = useState(false);
  const userId = localStorage.getItem("user_id");
  const [pageData, setPageData] = useState<{
    page: number;
    pageSize: number;
  }>({
    page: 0,
    pageSize: 5,
  });
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("search");
  const onPaginationChange = (page: number, pageSize: number) => {
    setPageData({ page, pageSize });
  };
  const [filterData, setFilterData] = useState<any>();
  const [filter, setFilter] = useState(false);

  const params = getContactsParams(pageData.page, pageData.pageSize);
  const getAllContactsParams = {
    id: userId,
    params: params,
    search: search ? search : "",
    body: JSON.stringify(filterData)
      ? JSON.stringify(filterData)
      : JSON.stringify({}),
  };
  const { data, isLoading } = useGetNpdQuery(getAllContactsParams);
  const [downloadIds, setDownloadIds] = useState<any>();
  const idArray = _.map(data?.results, "id");

  const handleDownload = () => {
    if (downloadedData) {
      saveAs(downloadedData, "NPD-export.xlsx");
    } else {
      message.error("Error: Downloaded data is undefined.");
    }
  };
  const { data: downloadedData, isLoading: downloadLoading } =
    useGetNpdDownloadQuery(downloadIds?.length > 0 ? downloadIds : idArray);

  const titleData = [
    "Code",
    "Client Name",
    "Product",
    "Specification",
    "Qty",
    "Group",
    "Contact",
  ];

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      key: "name",

      ellipsis: true,
    },
    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "name",
      sorter: (a: any, b: any) => a.client_name.length - b.client_name.length,
      ellipsis: true,
    },
    {
      title: "Product",
      dataIndex: "product_name",
      key: "age",
      sorter: (a: any, b: any) => a.product_name.length - b.product_name.length,
      ellipsis: true,
    },
    {
      title: "Specification",
      dataIndex: "specification",
      key: "address",
      ellipsis: true,
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Group",
      dataIndex: "group_name",
      key: "age",
      ellipsis: true,
    },
    {
      title: "Contact",
      dataIndex: "phone",
      key: "address",
      ellipsis: true,
    },
  ];
  const onClose = () => {
    setOpen(false);
    setEdit(false);
    setIsPreview(false);
    navigate("/inquiries");
  };
  const showDrawer = () => {
    setOpen(true);
  };
  const findIndexForObject = (array: [], object: any) => {
    return _.findIndex(array, object);
  };
  const npd_id = searchParams.get("id");

  const targetObject = { id: Number(npd_id) };
  const index = findIndexForObject(data?.results, targetObject);

  const [currentIndex, setCurrentIndex] = useState(index);

  const getObjectAtIndex = (array: [], index: number) => {
    const object = _.get(array, index);
    return object;
  };

  useEffect(() => {
    setCurrentIndex(index);
  }, [index]);
  useEffect(() => {
    if (currentIndex > -1) {
      const updatedObject: any = getObjectAtIndex(data?.results, currentIndex);

      navigate(`/inquiries?id=${updatedObject?.id}`);
    }
  }, [currentIndex]);

  const handleNext = () => {
    // console.log("final object trance", !contactLoading && object?.id);
    // navigate(`/contacts?id=${object?.id}`);

    if (currentIndex < data?.results?.length - 1) {
      setCurrentIndex(currentIndex + 1);

      // navigate(`/contacts?id=${object?.id}`);
      // setSearchParams({ ["id"]: object?.id });
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);

      // navigate(`/contacts?id=${object?.id}`);
    }
  };
  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
    current,
    pageSize
  ) => {
    setPageData({
      page: current,
      pageSize: pageSize,
    });
  };
  return (
    <>
      <div className="mt-24">
        <div className="flex justify-between items-center">
          <span className="sales-title">
            <Text value="NPD" variant="wearlay-display" />
          </span>
          {/* <Button className="primary-btn" onClick={showDrawer}>
            + Add New Lead
          </Button> */}
          <CommonButton
            variant="wealay-primary-btn"
            value="+ Add New Inquiry"
            className="primary-btn"
            onClick={showDrawer}
          />
          <Drawer
            title={edit ? "Edit NPD" : "New Product Development"}
            placement="right"
            onClose={onClose}
            open={open || edit}
            width="500"
            destroyOnClose={true}
          >
            <AddNewInquiry setOpen={setOpen} isEdit={edit} setEdit={setEdit} />
          </Drawer>
          <Drawer
            title={
              <div className="flex justify-between items-center">
                {/* <div>Inquiry Preview</div> */}
                <Text value="NPD Preview" variant="wearlay-subtitle" />
                <div>
                  <Space>
                    <Button
                      onClick={() => {
                        handlePrevious();
                        // navigate(`/contacts?id=${object?.id}`);
                      }}
                      disabled={currentIndex === 0}
                    >
                      <LeftOutlined />
                    </Button>
                    <Button
                      onClick={() => {
                        // navigate(`/contacts?id=${object?.id}`);
                        handleNext();
                      }}
                      disabled={currentIndex === data?.results?.length - 1}
                    >
                      <RightOutlined />
                    </Button>
                  </Space>
                </div>
              </div>
            }
            placement="right"
            onClose={onClose}
            open={isPreview}
            width="500"
          >
            <InquiryPreview setEdit={setEdit} setPreview={setIsPreview} />
          </Drawer>
        </div>
        {/* <div className="p-10 mt-24p">
          <div className="flex justify-between">
            <Text value="NPD List" variant="wearlay-headline-2" />
            <div className="flex items-center h-full gap-2">
              <SearchBox placeholder="Search" />
              <CommonButton
                variant="wearlay-with-icon"
                icon="/assets/filter.svg"
              />
              <CommonButton
                variant="wearlay-with-icon"
                icon="/assets/download.svg"
              />
            </div>
          </div>
          <CommonTable
            datasource={dataSource}
            coloumn={columns}
            setDetailsOpen={setIsPreview}
          />
        </div> */}
        <div className="p-10 mt-24p">
          <div className="flex justify-between">
            <Text
              value="Customers List"
              variant="wearlay-headline-2"
              className="p-1 "
            />

            <div className="flex items-center h-full gap-2">
              <div className="search-resp ">
                <SearchBox placeholder="Search" />
              </div>
              <div className="relative">
                <CommonButton
                  variant="wearlay-with-icon"
                  icon={filter ? "/assets/close.svg" : "/assets/filter.svg"}
                  onClick={() => {
                    setFilter(!filter);
                  }}
                />
                {filter && (
                  <NpdFilter
                    setFilter={setFilter}
                    setFilterData={setFilterData}
                    filer={filter}
                    setSelectedRegion={setSelectedRegion}
                    selectedRegion={selectedRegion}
                  />
                )}
              </div>
              <CommonButton
                variant="wearlay-with-icon"
                icon={
                  data?.results?.length === 0
                    ? "/assets/disabledDownload.svg"
                    : "/assets/download.svg"
                }
                onClick={() => {
                  handleDownload();
                }}
                disabled={data?.results?.length === 0}
              />
            </div>
          </div>
          <div className="resp-search">
            <SearchBox placeholder="Search" />
          </div>
          <div className="resp-table">
            <CommonTable
              datasource={data?.results}
              coloumn={columns}
              setDetailsOpen={setIsPreview}
              setDownloadIds={setDownloadIds}
            />
            {data?.count > 0 && (
              <Row justify={"end"}>
                <Pagination
                  // current={pageData.page}
                  onChange={onPaginationChange}
                  total={data?.count}
                  pageSize={pageData?.pageSize}
                  defaultCurrent={1}
                  responsive={true}
                  onShowSizeChange={onShowSizeChange}
                  showSizeChanger={data?.count > 10 ? true : false}
                />
              </Row>
            )}
          </div>
        </div>
        <TableCard
          headingData={titleData}
          setOpen={setIsPreview}
          data={data?.results}
          loading={isLoading}
        />
        {data?.count > 0 && (
          <Pagination
            // current={pageData.page}
            onChange={onPaginationChange}
            total={data?.count}
            pageSize={pageData?.pageSize}
            className="pagination-resp"
            defaultCurrent={1}
            onShowSizeChange={onShowSizeChange}
            showSizeChanger={data?.count > 10 ? true : false}
          />
        )}
      </div>
    </>
  );
};
