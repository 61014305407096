import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import {
  Button,
  Drawer,
  Dropdown,
  Menu,
  message,
  Pagination,
  PaginationProps,
  Row,
  Space,
  Tabs,
  TabsProps,
  Tag,
} from "antd";
import { useEffect, useState } from "react";
import { CommonButton } from "../../components/CommonButton/button";
import { SearchBox } from "../../components/CommonSearchBox";
import { CommonTable } from "../../components/commonTable";
import { CloseOrder } from "../../components/Drawer/CloseOrder";
import "./index.css";
import { Text } from "../../components/CommonFont/text";
import { TableCard } from "../../components/CommonTableCard";
import { headerVariable } from "../../constants/headerConstants";
import copy from "clipboard-copy";

import {
  useGetAllOrdersQuery,
  useGetOrderByIdQuery,
  useGetOrderDownloadDataQuery,
  useGetQuotationByIdQuery,
  useGetQuotationOrNpdQuery,
  useGetSalesAfterNpdQuotationQuery,
  useGetSalesAfterQuotationQuery,
  useLazyGetSalesAfterNpdQuotationQuery,
  useLazyGetSalesAfterQuotationQuery,
} from "../../services/api";
import _ from "lodash";
import { saveAs } from "file-saver";

import moment from "moment";
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { getContactsParams } from "../../helpers/quarery";
import OrderFilter from "../../components/CommonFilter/orderFilter";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { OrderPdf } from "../../components/Export/orderPdf";
export const Orders = () => {
  const [open, setOpen] = useState(false);
  const [isInProgress, setIsProgress] = useState(false);
  const [isDispatch, setDispatch] = useState(false);
  const [isDelivery, setDelivery] = useState(false);
  const [isCompleted, setCompleted] = useState(false);
  const [orderStatus, setorderStatus] = useState(0);
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState<any>();
  const [selectedRegion, setSelectedRegion] = useState([]);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [npdData, setNpdData] = useState<any>();
  const [quotationData, setQuotationData] = useState<any>();

  const id = searchParams.get("id");

  const [downloadIds, setDownloadIds] = useState<any>();
  const { data: dataById, isLoading: dataByIdLoading } =
    useGetOrderByIdQuery(id);
  const { data: quotationByIdData, isLoading: quotationByIdDataLoading } =
    useGetQuotationByIdQuery(dataById?.data?.quotation);
  const { data: npdOrQuotationData, isLoading: npdOrQuotationDataLoading } =
    useGetQuotationOrNpdQuery(dataById?.data?.quotation);
  // const { data: npdData, isLoading: npdLoading } =
  //   useGetSalesAfterNpdQuotationQuery(npdOrQuotationData?.data[0]?.npd);

  // const { data: quotationData, isLoading: loadingQuery } =
  //   useGetSalesAfterQuotationQuery(npdOrQuotationData?.data[0]?.sales_lead);
  const userId = localStorage.getItem("user_id");
  const navigate = useNavigate();

  const search = searchParams.get("search");

  const [pageData, setPageData] = useState<{
    page: number;
    pageSize: number;
  }>({
    page: 0,
    pageSize: 5,
  });
  const params = getContactsParams(pageData.page, pageData.pageSize);

  const getAllContactsParams = {
    id: userId,
    order_status: orderStatus,
    params: params,
    search: search ? search : "",
    body: JSON.stringify(filterData)
      ? JSON.stringify(filterData)
      : JSON.stringify({}),
  };

  const { data, isLoading } = useGetAllOrdersQuery(getAllContactsParams);
  const idArray = _.map(data?.results, "id");

  const onClose = () => {
    setOpen(false);
    navigate("/order");
  };
  const onPaginationChange = (page: number, pageSize: number) => {
    setPageData({ page, pageSize });
  };
  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      date: "11/05/2023",
      code: "CH 1",
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      date: "11/05/2023",
      code: "CH 1",
      address: "10 Downing Street",
    },
  ];
  const Companyname = ({ id }: any) => {
    const { data: npdOrQuotationData, isLoading: npdOrQuotationDataLoading } =
      useGetQuotationOrNpdQuery(id);
    const { data: quotationData, isLoading: loadingQuery } =
      useGetSalesAfterQuotationQuery(npdOrQuotationData?.data[0]?.sales_lead);
    const { data: npdData, isLoading } = useGetSalesAfterNpdQuotationQuery(
      npdOrQuotationData?.data[0]?.npd
    );

    // useEffect(() => {
    //   if (npdOrQuotationData?.data[0]?.sales_lead) {
    //     trigger(npdOrQuotationData?.data[0]?.sales_lead);
    //   }
    // }, [npdOrQuotationData?.data[0]?.sales_lead]);
    return (
      <>
        <div>
          {quotationData?.data[0]?.company_name
            ? quotationData?.data[0]?.company_name
            : npdData?.data[0]?.company_name}
        </div>
      </>
    );
  };
  const Code = ({ id }: any) => {
    const { data: npdOrQuotationData, isLoading: npdOrQuotationDataLoading } =
      useGetQuotationOrNpdQuery(id);
    const { data: quotationData, isLoading: loadingQuery } =
      useGetSalesAfterQuotationQuery(npdOrQuotationData?.data[0]?.sales_lead);
    const { data: npdData, isLoading } = useGetSalesAfterNpdQuotationQuery(
      npdOrQuotationData?.data[0]?.npd
    );

    return (
      <>
        <div>
          {" "}
          {quotationData?.data[0]?.sales_lead_id
            ? quotationData?.data[0]?.sales_lead_id
            : npdData?.data[0]?.code
            ? npdData?.data[0]?.code
            : "-"}
        </div>
      </>
    );
  };
  const EnquiryName = ({ id }: any) => {
    const { data: npdOrQuotationData, isLoading: npdOrQuotationDataLoading } =
      useGetQuotationOrNpdQuery(id);
    const { data: quotationData, isLoading: loadingQuery } =
      useGetSalesAfterQuotationQuery(npdOrQuotationData?.data[0]?.sales_lead);
    const { data: npdData, isLoading } = useGetSalesAfterNpdQuotationQuery(
      npdOrQuotationData?.data[0]?.npd
    );

    return (
      <>
        <div>
          {" "}
          {quotationData?.data[0]?.enquiry_category_name
            ? quotationData?.data[0]?.enquiry_category_name
            : npdData?.data[0]?.enquiry_category_name}
        </div>
      </>
    );
  };
  const LeadName = ({ id }: any) => {
    const { data: npdOrQuotationData, isLoading: npdOrQuotationDataLoading } =
      useGetQuotationOrNpdQuery(id);
    const { data: quotationData, isLoading: loadingQuery } =
      useGetSalesAfterQuotationQuery(npdOrQuotationData?.data[0]?.sales_lead);
    const { data: npdData, isLoading } = useGetSalesAfterNpdQuotationQuery(
      npdOrQuotationData?.data[0]?.npd
    );
    return (
      <>
        <div>
          {quotationData?.data[0]?.lead_name
            ? quotationData?.data[0]?.lead_name
            : npdData?.data[0]?.lead_name}
        </div>
      </>
    );
  };
  const Status = () => {
    return (
      <>
        <div></div>
      </>
    );
  };
  const columns = [
    {
      title: isDispatch ? "Dispatch date" : "Created date",
      dataIndex: "order_punched_date",
      key: "date",
      sorter: (a: any, b: any) => {
        const dateA = isDispatch
          ? moment(
              a.dispatch_schedule[a.dispatch_schedule.length - 1]?.dispatch_date
            ).valueOf()
          : moment(a.order_punched_date).valueOf();

        const dateB = isDispatch
          ? moment(
              b.dispatch_schedule[b.dispatch_schedule.length - 1]?.dispatch_date
            ).valueOf()
          : moment(b.order_punched_date).valueOf();

        return dateA - dateB;
      },
      ellipsis: true,
      render: (text: any, record: any) => (
        <>
          <div>
            {isDispatch
              ? moment(
                  record?.dispatch_schedule[
                    record?.dispatch_schedule?.length - 1
                  ]?.dispatch_date
                ).format("DD-MM-YYYY")
              : moment(record?.order_punched_date)?.format("DD-MM-YYYY")}
          </div>
        </>
      ),
    },
    {
      title: "Order  No.",
      dataIndex: "order_no",
      key: "name",
      // sorter: (a: any, b: any) => a.age - b.age,
      ellipsis: true,
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      ellipsis: true,
      render: (text: any, record: any) => (
        <>
          <Code id={record?.quotation} />
        </>
      ),
    },
    {
      title: "Company",
      dataIndex: "address",
      key: "address",
      render: (text: any, record: any) => (
        <>
          <Companyname id={record?.quotation} />
        </>
      ),
      ellipsis: true,
    },
    {
      title: "Lead Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      render: (text: any, record: any) => (
        <>
          <LeadName id={record?.quotation} />
        </>
      ),
    },
    {
      title: "Enquiry Category",
      dataIndex: "age",
      key: "age",
      // sorter: (a: any, b: any) => a.age - b.age,
      ellipsis: true,
      render: (text: any, record: any) => (
        <>
          <EnquiryName id={record?.quotation} />
        </>
      ),
    },
    {
      title: "Advance Received",
      dataIndex: "advance_received_amount",
      key: "address",
      ellipsis: true,
      render: (text: any) => (
        <>
          <div color="purple">{text ? text : "-"}</div>
        </>
      ),
    },

    {
      title: "Status",
      dataIndex: "order_status",
      key: "age",
      ellipsis: true,

      render: (text: any) => (
        <>
          {text === 3 ? (
            <Tag color="green">Completed</Tag>
          ) : text === 2 ? (
            <Tag color="purple">Delivered</Tag>
          ) : text === 1 ? (
            <Tag color="magenta">Open</Tag>
          ) : (
            <Tag color="lime">In Progress</Tag>
          )}
        </>
      ),
    },
  ];
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `In Progress`,
    },
    {
      key: "2",
      label: `Dispatch Added`,
    },
    {
      key: "3",
      label: `Delivered`,
    },
    {
      key: "4",
      label: `Completed`,
    },
  ];
  const onChange = (key: string) => {
    console.log(key);
  };
  const findIndexForObject = (array: [], object: any) => {
    return _.findIndex(array, object);
  };
  const order_id = searchParams.get("id");

  const targetObject = { id: Number(order_id) };
  const index = findIndexForObject(data?.results, targetObject);

  const [currentIndex, setCurrentIndex] = useState(index);

  const getObjectAtIndex = (array: [], index: number) => {
    const object = _.get(array, index);
    return object;
  };

  useEffect(() => {
    setCurrentIndex(index);
  }, [index]);
  useEffect(() => {
    if (currentIndex > -1) {
      const updatedObject: any = getObjectAtIndex(data?.results, currentIndex);

      navigate(
        `/order/details?id=${updatedObject?.id}&created_by=${updatedObject?.lead}`
      );
    }
  }, [currentIndex]);
  const handleNext = () => {
    // console.log("final object trance", !contactLoading && object?.id);
    // navigate(`/contacts?id=${object?.id}`);

    if (currentIndex < data?.results?.length - 1) {
      setCurrentIndex(currentIndex + 1);

      // navigate(`/contacts?id=${object?.id}`);
      // setSearchParams({ ["id"]: object?.id });
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);

      // navigate(`/contacts?id=${object?.id}`);
    }
  };
  useEffect(() => {
    setIsProgress(true);
  }, []);

  const titleData = [
    "Date ",
    "Order  No.",
    "Code",
    "Company",
    "Lead name",
    "Advance",
    "Status",
  ];
  const CopyButton = () => {
    const currentURL = window?.location?.href;
    copy(currentURL)
      .then(() => {
        message.success("Link copied");
      })
      .catch((error) => {
        message.error("Failed to copy URL");
      });
  };
  const isOrder = location.pathname;
  const isOrderUrl = isOrder && isOrder.includes("/order/details");
  const { data: downloadedData, isLoading: downloadLoading } =
    useGetOrderDownloadDataQuery(
      downloadIds?.length > 0 ? downloadIds : idArray
    );
  const handleDownload = () => {
    // Convert the data to a Blob
    // const blob = new Blob([downloadedData], {
    //   type: "application/vnd.ms-excel",
    // });

    // // Use the saveAs function to trigger the download
    // saveAs(blob, "contact-export.xlsx");
    if (downloadedData) {
      saveAs(downloadedData, "order-export.xlsx");
    } else {
      message.error("Error: Downloaded data is undefined.");
    }
  };
  const widgetMenu = (
    <Menu>
      <Menu.Item
      // onClick={() => {
      //   navigate("/profile");
      // }}
      >
        <PDFDownloadLink
          document={
            <OrderPdf
              quotationData={quotationData}
              quotationByIdData={quotationByIdData}
              npdData={npdData}
              data={dataById}
            />
          }
          fileName={`Order_${
            quotationData?.data[0]?.sales_lead_id
              ? quotationData?.data[0]?.sales_lead_id
              : npdData?.data[0]?.sales_lead_id
          }.pdf`}
        >
          <div>Download</div>
        </PDFDownloadLink>
      </Menu.Item>
      <Menu.Item
        // onClick={() => {
        //   handleLogout();
        // }}
        onClick={() => {
          CopyButton();
        }}
      >
        <div className="logout-div">
          <Space>
            {/* <LoginOutlined className="logout-icon " /> */}
            <span className="logout-text ">Share</span>
          </Space>
        </div>
      </Menu.Item>
    </Menu>
  );
  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
    current,
    pageSize
  ) => {
    setPageData({
      page: current,
      pageSize: pageSize,
    });
  };

  return (
    <>
      <div className="mt-24">
        <div className="order-tab-main-div-3 ">
          {/* <div className="orders-title ">Order</div> */}
          <Text
            value="Order"
            variant="wearlay-display"
            className="orders-title"
          />
          {/* <Tabs
            type="card"
            items={items}
            // onChange={onChange}
            onTabClick={onChange}
            className="order-main-div-2"
          ></Tabs> */}
          <div className="order-tab-main-div ">
            <div
              className={isInProgress ? "order-tabs-active" : "order-tabs"}
              onClick={() => {
                setIsProgress(true);
                setDelivery(false);
                setDispatch(false);
                setCompleted(false);
                setorderStatus(0);
              }}
            >
              In Progress
            </div>
            <div
              className={isDispatch ? "order-tabs-active" : "order-tabs"}
              onClick={() => {
                setIsProgress(false);
                setDelivery(false);
                setDispatch(true);
                setCompleted(false);
                setorderStatus(1);
              }}
            >
              Dispatch Added
            </div>

            <div
              className={isDelivery ? "order-tabs-active" : "order-tabs"}
              onClick={() => {
                setIsProgress(false);
                setDelivery(true);
                setDispatch(false);
                setCompleted(false);
                setorderStatus(2);
              }}
            >
              Delivered
            </div>
            <div
              className={isCompleted ? "order-tabs-active" : "order-tabs"}
              onClick={() => {
                setIsProgress(false);
                setDelivery(false);
                setDispatch(false);
                setCompleted(true);
                setorderStatus(3);
              }}
            >
              Completed
            </div>
          </div>
          <div className="order-tab-main-div-2 ">
            <div>
              <div
                className={isInProgress ? "order-tabs-active" : "order-tabs"}
                onClick={() => {
                  setIsProgress(true);
                  setDelivery(false);
                  setDispatch(false);
                  setCompleted(false);
                  setorderStatus(0);
                }}
              >
                In Progress
              </div>
              <div
                className={isDispatch ? "order-tabs-active" : "order-tabs"}
                onClick={() => {
                  setIsProgress(false);
                  setDelivery(false);
                  setDispatch(true);
                  setCompleted(false);
                  setorderStatus(1);
                }}
              >
                Dispatch Added
              </div>
            </div>
            <div>
              <div
                className={isDelivery ? "order-tabs-active" : "order-tabs"}
                onClick={() => {
                  setIsProgress(false);
                  setDelivery(true);
                  setDispatch(false);
                  setCompleted(false);
                  setorderStatus(2);
                }}
              >
                Delivered
              </div>
              <div
                className={isCompleted ? "order-tabs-active" : "order-tabs"}
                onClick={() => {
                  setIsProgress(false);
                  setDelivery(false);
                  setDispatch(false);
                  setCompleted(true);
                  setorderStatus(3);
                }}
              >
                Completed
              </div>
            </div>
          </div>
        </div>
        <div className="p-10 mt-24p">
          <div className="flex justify-between">
            {/* <div className="sales-title-1">Orders List</div> */}
            <Text value="Orders List" variant="wearlay-headline-2" />
            <div className="flex items-center h-full gap-2">
              <div className="search-resp ">
                <SearchBox placeholder="Search" />
              </div>
              <div className="relative">
                <CommonButton
                  variant="wearlay-with-icon"
                  icon={filter ? "/assets/close.svg" : "/assets/filter.svg"}
                  onClick={() => {
                    setFilter(!filter);
                  }}
                  className="download-btn-color"
                />
                {filter && (
                  <OrderFilter
                    setFilter={setFilter}
                    setFilterData={setFilterData}
                    filer={filter}
                    setSelectedRegion={setSelectedRegion}
                    selectedRegion={selectedRegion}
                  />
                )}
              </div>
              <CommonButton
                variant="wearlay-with-icon"
                icon={
                  data?.results?.length === 0
                    ? "/assets/disabledDownload.svg"
                    : "/assets/download.svg"
                }
                disabled={data?.results?.length === 0}
                onClick={() => {
                  handleDownload();
                }}
              />
            </div>
          </div>
          <div className="resp-search">
            <SearchBox placeholder="Search" />
          </div>
          <div className="resp-table">
            <CommonTable
              datasource={data?.results}
              coloumn={columns}
              setDetailsOpen={setOpen}
              isLoading={isLoading}
              setDownloadIds={setDownloadIds}
            />
            {data?.count > 0 && (
              <Row justify={"end"}>
                <Pagination
                  // current={pageData.page}
                  onChange={onPaginationChange}
                  total={data?.count}
                  pageSize={pageData?.pageSize}
                  defaultCurrent={1}
                  responsive={true}
                  onShowSizeChange={onShowSizeChange}
                  showSizeChanger={data?.count > 10 ? true : false}
                />
              </Row>
            )}
          </div>
        </div>
        <TableCard
          headingData={titleData}
          setOpen={setOpen}
          data={data?.results}
          loading={isLoading}
        />
        {data?.count > 5 && (
          <Pagination
            // current={pageData.page}
            onChange={onPaginationChange}
            total={data?.count}
            pageSize={pageData?.pageSize}
            className="pagination-resp"
            defaultCurrent={1}
            onShowSizeChange={onShowSizeChange}
            showSizeChanger={data?.count > 10 ? true : false}
          />
        )}
      </div>
      <Drawer
        title={
          <div className="flex justify-between items-center">
            <Text value="Order Preview" variant="wearlay-subtitle" />
            <div className="quotation-option">
              <Space>
                <Button className="flex items-center pdf-download-button">
                  <PDFDownloadLink
                    document={
                      <OrderPdf
                        quotationData={quotationData}
                        quotationByIdData={quotationByIdData}
                        npdData={npdData}
                        data={dataById}
                      />
                    }
                    // fileName="order.pdf"
                    fileName={`Order_${
                      quotationData?.data[0]?.sales_lead_id
                        ? quotationData?.data[0]?.sales_lead_id
                        : npdData?.data[0]?.sales_lead_id
                    }.pdf`}
                  >
                    {/* {({ blob, url, loading, error }) =>
                      loading ? (
                        "Loading document..."
                      ) : ( */}
                    <img
                      src="/assets/download.svg"
                      className=" h-full flex items-center downlaod-pdf justify-center"
                    ></img>
                    {/* )
                    } */}
                  </PDFDownloadLink>
                </Button>
                {/* <Button className="flex items-center ">
                  <img src="/assets/printer.svg"></img>
                </Button> */}
                <Button className="flex items-center pdf-download-button">
                  <img
                    src="/assets/share.svg"
                    onClick={() => {
                      CopyButton();
                    }}
                    className="downlaod-pdf"
                  ></img>
                </Button>
              </Space>
            </div>
            <Dropdown
              overlay={widgetMenu}
              trigger={["click"]}
              className="dropdown-click"
            >
              <img
                src="/assets/option.svg"
                className="quotation-option-2"
              ></img>
            </Dropdown>
            <div>
              <Space>
                <Button
                  onClick={() => {
                    handlePrevious();
                    // navigate(`/contacts?id=${object?.id}`);
                  }}
                  disabled={currentIndex === 0}
                >
                  <LeftOutlined />
                </Button>
                <Button
                  onClick={() => {
                    // navigate(`/contacts?id=${object?.id}`);
                    handleNext();
                  }}
                  disabled={currentIndex === data?.results?.length - 1}
                >
                  <RightOutlined />
                </Button>
              </Space>
            </div>
          </div>
        }
        width={600}
        placement="right"
        onClose={onClose}
        open={open || (isOrderUrl as boolean)}
        destroyOnClose={true}
      >
        <CloseOrder
          setPreview={setOpen}
          setNpdData={setNpdData}
          setQuotationData={setQuotationData}
        />
      </Drawer>
    </>
  );
};
