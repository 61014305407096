import { Navigate } from "react-router";
import React, { useEffect } from "react";

import { getAccessToken, removeTokens } from "./localStorage";
import { PathConstants } from "../pathConstants/pathConstants";

export const PrivateRoute = ({ children, ...rest }: any) => {
  const currentURL = window.location.href;
  const isQuotation =
    currentURL.includes("/quotation/details") ||
    currentURL.includes("/order/details");

  useEffect(() => {
    if (isQuotation) {
      sessionStorage.setItem("current_path", currentURL);
    }
  }, []);
  // useEffect(() => {
  //   if (!getAccessToken()) {
  //     removeTokens();
  //   }
  // }, []);
  if (getAccessToken()) {
    return children;
  } else {
    return <Navigate to={PathConstants.LOGIN} />;
  }
};
