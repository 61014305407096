import { useNavigate } from "react-router-dom";
import { Text } from "../CommonFont/text";
import "./index.css";
export const ErrorHandler = () => {
  const navigate = useNavigate();
  return (
    <>
      <div>
        <div className="flex w-full flex-col items-center justify-center h-full-vh gap-2 error-403">
          <img src="/assets/403.svg"></img>
          <Text variant="wearlay-headline-1" value="Access Denied" />
          <div>
            Sorry, but you don’t have permission to access this page. You can go
            back to{" "}
            <span
              className="error-a-tag"
              onClick={() => {
                navigate("/");
              }}
            >
              home page
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
