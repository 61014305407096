import {
  Button,
  Empty,
  Pagination,
  PaginationProps,
  Row,
  Skeleton,
  Spin,
} from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CommonButton } from "../../components/CommonButton/button";
import { SearchBox } from "../../components/CommonSearchBox";
import "./index.css";
import { Text } from "../../components/CommonFont/text";
import { useGetExecutivesQuery } from "../../services/api";
import { getContactsParams } from "../../helpers/quarery";
import { useState } from "react";
export const Employee = () => {
  const navigate = useNavigate();
  const dummyData = [
    {
      img: "/assets/profile.svg",
      title: "Nidhin",
      subTitle: "Sales Man",
      code: "W039P23",
      email: "rochan@chema.com",
      phone: "0938283982",
    },
    {
      img: "/assets/profile.svg",
      title: "Nidhin",
      subTitle: "Sales Man",
      code: "W039P23",
      email: "rochan@chema.com",
      phone: "0938283982",
    },
  ];
  const [searchParams, setSearchParams] = useSearchParams();

  const search = searchParams.get("search");

  const userId = localStorage.getItem("user_id");

  const [pageData, setPageData] = useState<{
    page: number;
    pageSize: number;
  }>({
    page: 0,
    pageSize: 12,
  });
  const params = getContactsParams(pageData.page, pageData.pageSize);
  const getAllExecutiveParams = {
    id: userId,
    params: params,
    search: search ? search : "",
    // body: JSON.stringify(filterData)
    //   ? JSON.stringify(filterData)
    //   : JSON.stringify({}),
  };
  const { data: executivesData, isLoading: executivesLoading } =
    useGetExecutivesQuery(getAllExecutiveParams);

  const onPaginationChange = (page: number, pageSize: number) => {
    setPageData({ page, pageSize });
  };

  return (
    <>
      <div className="emp-outer-main-div">
        <div className="flex justify-between employee-resp-search">
          {/* <div className="emp-profile-title">Employees</div> */}
          <Text value="Employees" variant="wearlay-display" />
          <div className="flex gap-2 items-center">
            <SearchBox placeholder="Search" />
            {/* <CommonButton
              variant="wearlay-with-icon"
              icon="/assets/filter.svg"
            /> */}
          </div>
        </div>
        {executivesLoading ? (
          <Skeleton avatar paragraph={{ rows: 4 }} />
        ) : executivesData?.results?.length > 0 ? (
          <div className="emp-height">
            <Row gutter={[16, 16]} className="flex resp-emp-details-card">
              {executivesData?.results?.map((item: any) => (
                <div
                  className="emp-profile-main-div flex flex-col gap-2"
                  onClick={() => {
                    navigate(`/employee-detail-page?executive-id=${item?.id}`);
                  }}
                >
                  <div className="flex flex-col justify-center items-center gap-2">
                    <img
                      src={
                        item?.image_url
                          ? item?.image_url
                          : "/assets/profile.svg"
                      }
                      className="emp-profile-pic"
                    ></img>
                    {/* <div className="emp-profile-title-1">Nithin K G</div>
            <div className="emp-profile-title-2">Sales Man</div> */}
                    <Text value={item.get_name} variant="wearlay-subtitle" />
                    <Text
                      value={item?.user_role_name}
                      variant="wearlay-paragraph"
                    />
                  </div>
                  <div className="flex gap-2">
                    <img src="/assets/group.svg"></img>
                    {/* <div className="emp-profile-title-3">WY12345</div> */}
                    <Text
                      value={item?.code ? item?.code : "-"}
                      variant="wearlay-caption"
                    />
                  </div>
                  <div className="flex gap-2">
                    <img src="/assets/message.svg"></img>

                    {/* <div className="emp-profile-title-3">roshan@chema.com</div> */}
                    <Text value={item?.email} variant="wearlay-caption" />
                  </div>

                  <div className="flex gap-2">
                    <img src="/assets/phone.svg"></img>

                    {/* <div className="emp-profile-title-3">098 25 314786</div> */}
                    <Text value={item?.phone} variant="wearlay-caption" />
                  </div>
                </div>
              ))}
            </Row>
            <div className="flex h-full items-end justify-end">
              {executivesData?.count > 0 && (
                <Pagination
                  // current={pageData.page}
                  onChange={onPaginationChange}
                  total={executivesData?.count}
                  pageSize={12}
                  defaultCurrent={1}
                />
              )}
            </div>
          </div>
        ) : (
          <Empty className="flex customer-no-data justify-center items-center flex-col " />
        )}
      </div>
    </>
  );
};
