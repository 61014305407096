import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import {
  Button,
  Drawer,
  Dropdown,
  Menu,
  message,
  Pagination,
  PaginationProps,
  Row,
  Space,
  Tag,
} from "antd";
import { useEffect, useState } from "react";
import { CommonButton } from "../../components/CommonButton/button";
import { SearchBox } from "../../components/CommonSearchBox";
import { CommonTable } from "../../components/commonTable";
import { AddNewQuotation } from "../../components/Drawer/AddNewQuotation";
import { Text } from "../../components/CommonFont/text";
import "./index.css";
import { TableCard } from "../../components/CommonTableCard";
import copy from "clipboard-copy";
import {
  useGetLeadByIdQuery,
  useGetNpdByIdQuery,
  useGetQuotationByIdQuery,
  useGetQuotationDownloadDataQuery,
  useGetQuotationQuery,
  useGetQuotationShareQuery,
  useGetSalesAfterNpdQuotationQuery,
  useGetSalesAfterQuotationQuery,
  useLazyGetQuotationDownloadDataQuery,
  useLazyGetSalesAfterQuotationQuery,
} from "../../services/api";
import { getContactsParams } from "../../helpers/quarery";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { saveAs } from "file-saver";
import _ from "lodash";
import QuotationFilter from "../../components/CommonFilter/quotationFilter";
import moment from "moment";
import { MyDocument } from "../../components/Export/export";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { log } from "console";
import { QuotationExport } from "../../components/Export/quotationExport";

export const Quotation = () => {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");
  const location = useLocation();
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [isInProgress, setIsProgress] = useState(false);
  const [isDispatch, setDispatch] = useState(false);
  const [isStatus, setStatus] = useState(0);

  const isQuotation = location.pathname;
  const isQuotationUrl =
    isQuotation && isQuotation.includes("/quotation/details");
  const onClose = () => {
    setOpen(false);
    navigate("/quotation");
  };
  const [downloadIds, setDownloadIds] = useState<any>();
  const [editData, setEditData] = useState<any>();

  const [filterData, setFilterData] = useState<any>();
  const [downloadQuotationdta, setDownloadQuotationdta] = useState<any>();

  const [filter, setFilter] = useState(false);

  const [pageData, setPageData] = useState<{
    page: number;
    pageSize: number;
  }>({
    page: 0,
    pageSize: 5,
  });
  const onPaginationChange = (page: number, pageSize: number) => {
    setPageData({ page, pageSize });
  };
  const params = getContactsParams(pageData.page, pageData.pageSize);
  const [searchParams, setSearchParams] = useSearchParams();

  const search = searchParams.get("search");

  const userId = localStorage.getItem("user_id");
  const getAllQuotationParams = {
    id: userId,
    params: params,
    search: search ? search : "",
    status: isStatus,
    body: JSON.stringify(filterData)
      ? JSON.stringify(filterData)
      : JSON.stringify({}),
  };
  const { data, isLoading } = useGetQuotationQuery(getAllQuotationParams);

  const Companyname = ({ id, npd_id }: any) => {
    const { data: quotationData, isLoading: loadingQuery } =
      useGetSalesAfterQuotationQuery(id);
    const { data: npdData, isLoading } = useGetSalesAfterNpdQuotationQuery(
      npd_id && npd_id
    );
    // useEffect(() => {
    //   if (id) {
    //     trigger(id);
    //   }
    // }, [id]);
    return (
      <>
        <div>
          {quotationData?.data[0]?.company_name
            ? quotationData?.data[0]?.company_name
            : npdData?.data[0]?.company_name}
        </div>
      </>
    );
  };
  const LeadName = ({ id, npd_id }: any) => {
    const { data: quotationData, isLoading: loadingQuery } =
      useGetSalesAfterQuotationQuery(id);
    const { data: npdData, isLoading } = useGetSalesAfterNpdQuotationQuery(
      npd_id && npd_id
    );
    // useEffect(() => {
    //   if (id) {
    //     trigger(id);
    //   }
    // }, [id]);
    return (
      <>
        <div>
          {quotationData?.data[0]?.lead_name
            ? quotationData?.data[0]?.lead_name
            : npdData?.data[0]?.lead_name}
        </div>
      </>
    );
  };
  const EnquiryName = ({ id, npd_id }: any) => {
    const { data: quotationData, isLoading: loadingQuery } =
      useGetSalesAfterQuotationQuery(id);
    const { data: npdData, isLoading } = useGetSalesAfterNpdQuotationQuery(
      npd_id && npd_id
    );
    // useEffect(() => {
    //   if (id) {
    //     trigger(id);
    //   }
    // }, [id]);

    return (
      <>
        <div>
          {" "}
          {quotationData?.data[0]?.enquiry_category_name
            ? quotationData?.data[0]?.enquiry_category_name
            : npdData?.data[0]?.enquiry_category_name}
        </div>
      </>
    );
  };
  const CUINo = ({ id }: any) => {
    const { data: quotationData, isLoading: loadingQuery } =
      useGetSalesAfterQuotationQuery(id);

    // useEffect(() => {
    //   if (id) {
    //     trigger(id);
    //   }
    // }, [id]);

    return (
      <>
        <div>
          {" "}
          {quotationData?.data[0]
            ?.contacts_customer_unique_identification_number
            ? quotationData?.data[0]
                ?.contacts_customer_unique_identification_number
            : "-"}
        </div>
      </>
    );
  };
  const Code = ({ id, npd_id }: any) => {
    const { data: quotationData, isLoading: loadingQuery } =
      useGetSalesAfterQuotationQuery(id);
    const { data: npdData, isLoading } = useGetSalesAfterNpdQuotationQuery(
      npd_id && npd_id
    );
    // useEffect(() => {
    //   if (id) {
    //     trigger(id);
    //   }
    // }, [id]);

    return (
      <>
        <div>
          {" "}
          {quotationData?.data[0]?.sales_lead_id
            ? quotationData?.data[0]?.sales_lead_id
            : npdData?.data[0]?.code
            ? npdData?.data[0]?.code
            : "-"}
        </div>
      </>
    );
  };
  const idArray = _.map(data?.results, "id");
  const navigate = useNavigate();
  const handleDownload = () => {
    if (downloadedData) {
      saveAs(downloadedData, "quotation-export.xlsx");
    } else {
      message.error("Error: Downloaded data is undefined.");
    }
  };
  const { data: downloadedData, isLoading: downloadLoading } =
    useGetQuotationDownloadDataQuery(
      downloadIds?.length > 0 ? downloadIds : idArray
    );
  // const [trigger, { data: downloadedData, isLoading: downloadLoading }] =
  //   useLazyGetQuotationDownloadDataQuery();

  const findIndexForObject = (array: [], object: any) => {
    return _.findIndex(array, object);
  };
  const quotation_id = searchParams.get("id");

  const targetObject = { id: Number(quotation_id) };
  const index = findIndexForObject(data?.results, targetObject);

  const [currentIndex, setCurrentIndex] = useState(index);

  const getObjectAtIndex = (array: [], index: number) => {
    const object = _.get(array, index);
    return object;
  };

  useEffect(() => {
    setCurrentIndex(index);
  }, [index]);
  useEffect(() => {
    if (currentIndex > -1) {
      const updatedObject: any = getObjectAtIndex(data?.results, currentIndex);

      navigate(
        `/quotation/details?id=${updatedObject?.id}&created_by=${updatedObject?.lead}`
      );
    }
  }, [currentIndex]);

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);

      // navigate(`/contacts?id=${object?.id}`);
    }
  };
  const handleNext = () => {
    // console.log("final object trance", !contactLoading && object?.id);
    // navigate(`/contacts?id=${object?.id}`);

    if (currentIndex < data?.results?.length - 1) {
      setCurrentIndex(currentIndex + 1);

      // navigate(`/contacts?id=${object?.id}`);
      // setSearchParams({ ["id"]: object?.id });
    }
  };
  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      date: "11/05/2023",
      code: "CH 1",
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      date: "11/05/2023",
      code: "CH 1",
      address: "10 Downing Street",
    },
  ];

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a: any, b: any) =>
        moment(a.date).valueOf() - moment(b.date).valueOf(),
      // sorter: (a: any, b: any) => a.age - b.age,
      ellipsis: true,
      render: (text: any) => (
        <>
          <div>{moment(text).format("DD-MM-YYYY")}</div>
        </>
      ),
    },
    {
      title: "CUI No.",
      dataIndex: "contacts_customer_unique_identification_number",
      // sorter: (a: any, b: any) => {
      //   const cuiA = a.contacts_customer_unique_identification_number;
      //   const cuiB = b.contacts_customer_unique_identification_number;
      //   if (cuiA < cuiB) {
      //     return -1;
      //   }
      //   if (cuiA > cuiB) {
      //     return 1;
      //   }
      //   return 0;
      // },
      ellipsis: true,
      render: (text: any, record: any) => (
        <>
          <CUINo id={record.sales_lead} />
        </>
      ),
    },
    {
      title: "Code",
      dataIndex: "",

      ellipsis: true,
      render: (text: any, record: any) => (
        <>
          <Code id={record.sales_lead} npd_id={record.npd} />
        </>
      ),
    },
    {
      title: "Lead Name",
      dataIndex: "",

      ellipsis: true,
      render: (text: any, record: any) => (
        <>
          <LeadName
            id={record.sales_lead && record.sales_lead}
            npd_id={record?.npd && record?.npd}
          />
        </>
      ),
    },
    {
      title: "Company",
      dataIndex: "",

      ellipsis: true,
      render: (text: any, record: any) => (
        <>
          <Companyname
            id={record?.sales_lead && record?.sales_lead}
            npd_id={record?.npd && record?.npd}
          />
        </>
      ),
    },
    {
      title: "Enquiry Category",
      dataIndex: "sales_lead",

      ellipsis: true,
      render: (text: any, record: any) => (
        <>
          <EnquiryName
            id={record.sales_lead && record.sales_lead}
            npd_id={record.npd}
          />
        </>
      ),
    },
    {
      title: "Last Followed ",
      dataIndex: "status",

      ellipsis: true,
      render: (text: any) => (
        <>
          <div>
            {text?.date ? moment(text?.date).format("DD-MM-YYYY") : "-"}
          </div>
        </>
      ),
    },
  ];
  // const { data: printData } = useGetQuotationByIdQuery(quotation_id);
  const createdBy = searchParams.get("created_by");

  const shareParams = {
    id: quotation_id,
    user_id: createdBy,
  };
  const { data: printData, isLoading: printLoading } =
    useGetQuotationShareQuery(shareParams);

  const widgetMenu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          if (editData?.data?.npd) {
            navigate(
              `/create-quotation?npd_id=${editData?.data?.npd}&quotation_id=${quotation_id}`
            );
          }
          if (editData?.data?.sales_lead) {
            navigate(
              `/create-quotation?id=${editData?.data?.sales_lead}&quotation_id=${quotation_id}`
            );
          }
        }}
      >
        <div className="logout-div">
          <Space>
            {/* <LoginOutlined className="logout-icon " /> */}
            <span className="logout-text ">Edit</span>
          </Space>
        </div>
      </Menu.Item>
      <Menu.Item
      // onClick={() => {
      //   navigate("/profile");
      // }}
      >
        <PDFDownloadLink
          document={
            <QuotationExport
              datas={printData?.data}
              salesData={downloadQuotationdta}
            />
          }
          fileName={`Quotation_${downloadQuotationdta?.data[0]?.sales_lead_id}.pdf`}
        >
          {/* {({ blob, url, loading, error }) =>
                      loading ? (
                        "Loading document..."
                      ) : ( */}
          <div>Download</div>

          {/* )
                    } */}
        </PDFDownloadLink>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          CopyButton();
        }}
      >
        <div className="logout-div">
          <Space>
            {/* <LoginOutlined className="logout-icon " /> */}
            <span className="logout-text ">Share</span>
          </Space>
        </div>
      </Menu.Item>
    </Menu>
  );
  const titleData = [
    "Date ",
    "CUI No.",
    "Code",
    "Lead Name",
    "Company",
    "Enquiry Category",
    "Last Followed",
  ];
  const [trigger, { data: salesData, isLoading: salesLoading }] =
    useLazyGetSalesAfterQuotationQuery();

  // useEffect(() => {
  //   if (printData?.data?.sales_lead) {
  //     trigger(printData?.data?.sales_lead);
  //   }
  // }, []);
  // const triggerDownload = () => {
  //   if (printData?.data?.sales_lead) {
  //     trigger(printData?.data?.sales_lead);
  //   }
  // };
  const CopyButton = () => {
    const currentURL = window?.location?.href;
    copy(currentURL)
      .then(() => {
        message.success("Link copied");
      })
      .catch((error) => {
        message.error("Failed to copy URL");
      });

    // navigator?.clipboard
    //   .writeText(currentURL && currentURL)
    //   .then(() => {
    //     message.success("Link copied");
    //   })
    //   .catch((error) => {
    //     message.error("Failed to copy URL");
    //   });
  };
  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (
    current,
    pageSize
  ) => {
    setPageData({
      page: current,
      pageSize: pageSize,
    });
  };
  useEffect(() => {
    setIsProgress(true);
    setStatus(0);
  }, []);

  // useEffect(() => {
  //   const currentURL = window.location.href;

  //   localStorage.setItem("current_path", currentURL);
  // }, []);
  return (
    <>
      <div className="mt-24">
        <Text
          value="Quotation"
          variant="wearlay-display"
          className="quotation-title"
        />
        <div className="order-tab-main-div justify-end">
          <div
            className={isInProgress ? "order-tabs-active" : "order-tabs"}
            onClick={() => {
              setIsProgress(true);
              setStatus(0);

              setDispatch(false);
            }}
          >
            Active
          </div>
          <div
            className={isDispatch ? "order-tabs-active" : "order-tabs"}
            onClick={() => {
              setIsProgress(false);
              setStatus(1);

              setDispatch(true);
            }}
          >
            Closed
          </div>
        </div>
        <div className="order-tab-main-div-2 ">
          <div className="flex m-inline-24 ">
            <div
              className={isInProgress ? "order-tabs-active" : "order-tabs"}
              onClick={() => {
                setIsProgress(true);
                setStatus(0);

                setDispatch(false);
              }}
            >
              Active
            </div>
            <div
              className={isDispatch ? "order-tabs-active" : "order-tabs"}
              onClick={() => {
                setIsProgress(false);
                setStatus(1);

                setDispatch(true);
              }}
            >
              Closed
            </div>
          </div>
        </div>
        <div className="p-10 mt-24p">
          <div className="flex justify-between">
            <Text value="Quotation Created" variant="wearlay-headline-2" />
            <div className="flex items-center h-full gap-2">
              <div className="search-resp ">
                <SearchBox placeholder="Search" />
              </div>
              <div className="relative">
                <CommonButton
                  variant="wearlay-with-icon"
                  icon={filter ? "/assets/close.svg" : "/assets/filter.svg"}
                  onClick={() => {
                    setFilter(!filter);
                  }}
                  className="download-btn-color"
                />

                {filter && (
                  <QuotationFilter
                    setFilter={setFilter}
                    setFilterData={setFilterData}
                    filer={filter}
                    setSelectedRegion={setSelectedRegion}
                    selectedRegion={selectedRegion}
                  />
                )}
              </div>
              <CommonButton
                variant="wearlay-with-icon"
                icon={
                  data?.results?.length === 0
                    ? "/assets/disabledDownload.svg"
                    : "/assets/download.svg"
                }
                onClick={() => {
                  handleDownload();
                }}
                // isLoading={downloadLoading}
                className="download-btn-color"
                disabled={data?.results?.length === 0}
              />
            </div>
          </div>
          <div className="resp-search">
            <SearchBox placeholder="Search" />
          </div>
          <div className="resp-table">
            <CommonTable
              datasource={data?.results}
              coloumn={columns}
              setDetailsOpen={setOpen}
              isLoading={isLoading}
              setDownloadIds={setDownloadIds}
              setId={setId}
            />
            {data?.count > 0 && (
              <Row justify={"end"}>
                <Pagination
                  // current={pageData.page}
                  onChange={onPaginationChange}
                  total={data?.count}
                  pageSize={5}
                  defaultCurrent={1}
                  responsive={true}
                  onShowSizeChange={onShowSizeChange}
                  showSizeChanger={data?.count > 10 ? true : false}
                />
              </Row>
            )}
          </div>
        </div>
      </div>
      <TableCard
        headingData={titleData}
        setOpen={setOpen}
        data={data?.results}
        loading={isLoading}
      />
      {data?.count > 5 && (
        <Pagination
          // current={pageData.page}
          onChange={onPaginationChange}
          total={data?.count}
          pageSize={pageData?.pageSize}
          className="pagination-resp"
          defaultCurrent={1}
          onShowSizeChange={onShowSizeChange}
          showSizeChanger={data?.count > 10 ? true : false}
        />
      )}
      <Drawer
        title={
          <div className="flex justify-between items-center">
            <Text value="Quotation Preview" variant="wearlay-subtitle" />
            <div className="quotation-option">
              <Space>
                <Button
                  className="flex items-center pdf-download-button"
                  onClick={() => {
                    // triggerDownload();
                    // navigate(`/export`);
                    // handleDownloadClick();
                  }}
                >
                  <PDFDownloadLink
                    document={
                      <QuotationExport
                        datas={printData?.data}
                        salesData={downloadQuotationdta}
                      />
                    }
                    fileName={`Quotation_${downloadQuotationdta?.data[0]?.sales_lead_id}.pdf`}
                  >
                    {/* {({ blob, url, loading, error }) =>
                      loading ? (
                        "Loading document..."
                      ) : ( */}
                    <img
                      src="/assets/download.svg"
                      className=" h-full flex items-center downlaod-pdf justify-center"
                    ></img>
                    {/* )
                    } */}
                  </PDFDownloadLink>
                </Button>

                <Button className="flex items-center pdf-download-button">
                  <img
                    src="/assets/share.svg"
                    onClick={() => {
                      CopyButton();
                    }}
                    className="downlaod-pdf"
                  ></img>
                </Button>

                <Button
                  className="flex items-center downlaod-pdf"
                  onClick={() => {
                    if (editData?.data?.npd) {
                      navigate(
                        `/create-quotation?npd_id=${editData?.data?.npd}&quotation_id=${quotation_id}`
                      );
                    }
                    if (editData?.data?.sales_lead) {
                      navigate(
                        `/create-quotation?id=${editData?.data?.sales_lead}&quotation_id=${quotation_id}`
                      );
                    }
                  }}
                >
                  <img src="/assets/edit-1.svg"></img>
                </Button>
              </Space>
            </div>
            <Dropdown
              overlay={widgetMenu}
              trigger={["click"]}
              className="dropdown-click"
            >
              <img
                src="/assets/option.svg"
                className="quotation-option-2"
              ></img>
            </Dropdown>
            <div>
              <Space>
                <Button
                  onClick={() => {
                    handlePrevious();
                    // navigate(`/contacts?id=${object?.id}`);
                  }}
                  disabled={currentIndex === 0}
                >
                  <LeftOutlined />
                </Button>
                <Button
                  onClick={() => {
                    // navigate(`/contacts?id=${object?.id}`);
                    handleNext();
                  }}
                  disabled={currentIndex === data?.results?.length - 1}
                >
                  <RightOutlined />
                </Button>
              </Space>
            </div>
          </div>
        }
        width={600}
        placement="right"
        onClose={onClose}
        open={(isQuotationUrl as boolean) || open}
        destroyOnClose={true}
      >
        <AddNewQuotation
          setdata={setDownloadQuotationdta}
          setPreview={setOpen}
          setEditdata={setEditData}
        />
      </Drawer>
    </>
  );
};
